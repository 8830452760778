// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import { amountFormat } from "../../../assets/js/numberFormatter"
import customStyles from "../../../assets/css/customTableStyle"


function UserWalletLogs(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const fileInput = useRef(null);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [modal, setModal] = useState(false);

  const [pending, setPending] = useState(true);

  const [id, setId] = useState(null);
  const [title, setTitle] = useState(null);
  const [excerpt, setExcerpt] = useState(null);
  const [description, setDescription] = useState(null);
  const [category_id, setCategoryID] = useState(null);
  const [blogImage, setblogImage] = useState("");


  const columns = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      width:"130px",
      cell: (row) => moment(row.date).format('MMM DD, YYYY')
    },
    {
      name: 'Comp. Order',
      // selector: 'count',
      selector: row=>row.count,

      width:"120px"
      //  sortable: true,
    },
    {
      name: 'Sum Disc. Price',
      selector: 'sum_discounted_price',
      width:"140px",
      //  sortable: true,
      cell: row => row.sum_discounted_price ? '$' + amountFormat(row.sum_discounted_price) : "$0"
    },
    {
      name: 'Credit Balance',
      selector: 'credit',
      width:"140px",
      //  sortable: true,
      cell: row => row.credit ? '$' + amountFormat(row.credit) : "$0"
    },
    {
      name: 'Debit Balance',
      selector: 'debit',
      width:"140px",
      //  sortable: true,
      cell: row => row.debit ? '$' + amountFormat(row.debit) : "$0"
    },
    {
      name: 'Difference',
      selector: 'difference',
      width:"100px",
      //  sortable: true,
      cell: row => row.difference ? '$' + amountFormat(row.difference) : "$0"
    },
    {
      name: 'FedEx',
      // selector: 'fedex_count',
      selector: row=>row.fedex_count,

      width:"80px"
      //  sortable: true,
    },
    {
      name: 'UPS',
      // selector: 'ups_count',
      selector: row=>row.ups_count,

      width:"80px"
      //  sortable: true,
    },
    {
      name: 'UPSv2',
      // selector: 'upsv2_count',
      selector: row=>row.upsv2_count,

      width:"80px"
      //  sortable: true,
    },
    {
      name: 'USPS',
      // selector: 'usps_count',
      selector: row=>row.usps_count,

      width:"80px"
      //  sortable: true,
    },
    {
      name: 'DHL',
      // selector: 'dhl_count',
      selector: row=>row.dhl_count,

      width:"80px"
      //  sortable: true,
    },
  ];

  const retrieveUsers = async () => {
    try {
      get('/admin/getUsers', {headers}).then((response) => {
        // console.log("getUsers", response);
        setUsers(response.data);
      })
    }
    catch (e) {
      // // console.log(e)
    }
  }

  // const tableData = ;
  const retrieveOrdersReporting = async (e) => {
    try {
      setPending(true);
      let body = {
        user_id: e.target.value
      }
      post('/user/get-orders-reporting', body, {headers}).then((response) => {
        // console.log("response", response);
        setData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // // console.log("here");
    retrieveUsers();
    setPending(false);
  }, []);

  //meta title
  document.title = "User Wallet Logs | ShipCheap";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" breadcrumbItem="User Wallet Logs" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <div className={"col-md-4"}>
              <label>Select User</label>
              <select className={"form-select"} onChange={retrieveOrdersReporting}>
                <option disabled={true} selected={true}>{users.length==0?"Loading users...":"Select a User"}</option>
                {users.length>0?
                  users.map((part, id) => (
                    <option value={part.id} key={id}>{part.email}</option>
                  ))
                  :""}
              </select>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter User Wallet Logs...`}
            >
              <DataTable columns={columns} data={data} customStyles={customStyles} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}/>
            </DataTableExtensions>
            {
              data.length>0 && !pending ?<><hr/>
                <div style={{display:"flex",padding:"5px 10px"}}>
                  <div style={{width:"130px",margin:"0px 4px"}}></div>
                  <div style={{width:"120px"}}>{data.reduce((accumulator, currentItem) => {
                    return accumulator + currentItem.count;
                  }, 0)}</div>
                  <div style={{width:"140px"}}>${amountFormat(data.reduce((accumulator, currentItem) => {
                    return accumulator + currentItem.sum_discounted_price;
                  }, 0))}</div>
                  <div style={{width:"140px"}}>${amountFormat(data.reduce((accumulator, currentItem) => {
                    return accumulator + currentItem.credit;
                  }, 0))}</div>
                  <div style={{width:"140px"}}>${amountFormat(data.reduce((accumulator, currentItem) => {
                    return accumulator + currentItem.debit;
                  }, 0))}</div>
                  <div style={{width:"100px"}}>${amountFormat(data.reduce((accumulator, currentItem) => {
                    return accumulator + currentItem.difference;
                  }, 0))}</div>
                  <div style={{width:"80px"}}>{data.reduce((accumulator, currentItem) => {
                    return accumulator + currentItem.fedex_count;
                  }, 0)}</div>
                  <div style={{width:"80px"}}>{data.reduce((accumulator, currentItem) => {
                    return accumulator + currentItem.ups_count;
                  }, 0)}</div>
                  <div style={{width:"80px"}}>{data.reduce((accumulator, currentItem) => {
                    return accumulator + currentItem.upsv2_count;
                  }, 0)}</div>
                  <div style={{width:"80px"}}>{data.reduce((accumulator, currentItem) => {
                    return accumulator + currentItem.usps_count;
                  }, 0)}</div>
                  <div style={{width:"80px"}}>{data.reduce((accumulator, currentItem) => {
                    return accumulator + currentItem.dhl_count;
                  }, 0)}</div>
                </div></>:""
            }
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
UserWalletLogs.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default UserWalletLogs;