export const amountFormat = (x) => {
  if(x=="-") {
    return "...";
  }
  return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const countFormat = (x) => {
  if(x=="-") {
    return "...";
  }
  return parseFloat(x).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}