import React, { useEffect, useRef, useState } from "react"
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl, postFile  } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import Select from 'react-select';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"

function addPaymentMethods(props) {
   const fileInput = useRef(null);

   // Page Title
   document.title = "Add Payment Method || ShipCheap"
   
   const headersMultipart = {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${localStorage.getItem("token")}`,
   };
   const [name, setName] = useState("")
   const [accountDetails, setAccountDetails] = useState("")

   const hanldeAddPaymentMethods = (e) => {
      e.preventDefault();
      const formData = new FormData()
      formData.append('file', fileInput.current.files[0]);
      formData.append('created_by', JSON.parse(localStorage.getItem("authUser")).id)
      formData.append('name', name)
      formData.append('accountDetails', accountDetails)

      console.log("Form Data", formData)
      postFile('/admin/add-payment-option', formData, {headersMultipart}).then((response) => {
         if(response.status === 200) {
            toast.success(response.message)
            setTimeout(() => {
               props.history.push('/payment-methods')
            }, 1500)
         } else {
            toast.error(response.message)
         }
      })
      try {
      } catch (error) {
         
      }
   }

   const handleChange = () => {
      console.log(fileInput.current.files);
   }

   useEffect(() => {

   }, [])

  return (
   <div className="page-content">
      <div className="container-fluid">
         <Breadcrumbs title="ShipCheap" sourceItem="Payments" breadcrumbItem="Add Payment Method" />
         <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
               <Card className="overflow-hidden-not">
                  <CardBody>
                     <div className="">
                        <Form className="form-horizontal" encType={"multipart/form-data"}>
                           <div className="mb-3">
                              <Label className="form-label">Name <span style={{color:"red"}}>*</span></Label>
                              <Input
                                 name="name"
                                 className="form-control non-arrow-number"
                                 placeholder="Enter name"
                                 type="text"
                                 value={name}
                                 maxLength={50}
                                 onChange={(e) => {setName(e.target.value)}}
                                 required={true}
                              />
                           </div>
                           <div className="mb-3">
                              <Label className="form-label">Image <span style={{color:"red"}}>*</span></Label>
                              <input
                                 name="file"
                                 className="form-control non-arrow-number"
                                 ref={fileInput}
                                 id={"fileInput"}
                                 type="file"
                                 accept="image/png, image/jpeg, image/jpg, image/gif"
                                 onChange={handleChange}
                                 required={true}
                              />
                           </div>
                           <div className="mb-3">
                              <Label className="form-label">Account Details <span style={{color:"red"}}>*</span></Label>
                              <Input
                                 name="accountDetails"
                                 className="form-control non-arrow-number h-150 mb-2"
                                 placeholder="Enter account details"
                                 type="textarea"
                                 value={accountDetails}
                                 maxLength={300}
                                 onChange={(e) => {setAccountDetails(e.target.value)}}
                                 required={true}
                              />
                              <p style={{color:"red"}}>Note: Use : to separate the label and value (i.e. Account Number <b>:</b> 123).</p>
                              <p style={{color:"red",marginTop:"-15px"}}>Note: Use | to break the line.</p>
                           </div>
                           
                           <Row className="mb-3">
                              <Col className="text-end">
                                 <button
                                 className="btn btn-success w-md"
                                 type="submit"
                                 onClick={(e) => hanldeAddPaymentMethods(e)}
                                 disabled={(name && accountDetails) ? false : true}
                                 >
                                    Add Payment Method
                                 </button>
                              </Col>
                           </Row>
                        </Form>
                     </div>
                  </CardBody>
               </Card>
            </Col>
         </Row>
      </div>
   </div>

  )
}

export default addPaymentMethods