import React, { useEffect, useRef, useState } from "react"
let selected = [];
function CustomMultiselectFilters(props) {
  const componentRef = useRef(null)
  const [options, setOptions] = useState(props.options);
  const [visible, setVisible] = useState(false);
  // const [selected, setSelected] = useState([]);

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
     setOptions(props.options);
  },[props.options])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (data) => {
    // setVisible(false);
    if(data.value == "none") {
      selected = [];
    }
    else {
      let found = selected.some(el => el == data.value);
      if(!found) {
        selected.push(data.value);
      }
      else {
        let index = selected.findIndex(el => el == data.value);
        selected.splice(index, 1);
      }
    }

    console.log("selected options",selected);
    props.change({target:selected});
  }
  // bx-check
  return (<>
    <div className={"custom-filter "+ props.class} ref={componentRef}>
      <span onClick={() => setVisible(!visible)}><span style={{color:selected.length>0?(localStorage.getItem("theme") == "dark"?"#a6b0c4":"#ff4800"):localStorage.getItem("theme") == "dark"?"#a6b0c4":"black"}}>{props.title}</span> <i className={`bx bx-${visible?"chevron-up":"chevron-down"} font-size-16`}></i></span>
      <ul className={"custom-filter-options"} style={{display: (visible?"block":"none"),backgroundColor:localStorage.getItem("theme") == "dark"?"#070A1F":"white"}}>
        {options.length>0?
          options.map((el,index) => {
            return (<li title={el.name} key={index} style={{color:(el.value=="none"?(selected.length>0?"#FF6969":"gray"):localStorage.getItem("theme") == "dark"?"#a6b0c4":"black")}} onClick={() => {
              if(el.value=="none") {
                if(selected.length>0) {
                  handleSelect(el)
                }
              }
              else {
                handleSelect(el)
              }
            }}>{selected.length>0?selected.some(sl => sl == el.value)?<i style={{float:"right"}} className={"bx bx-check"}></i>:"":""}<div className={"name"}>{el.name}</div></li>)
          })
          :<li>Loading...</li>}
      </ul>
    </div>
  </>);
}

export default CustomMultiselectFilters;