const customStyles = {
  headCells: {
    style: {
      border:"1px solid #F6F6FA",
      background:"#F6F6FA"
    },
  },
  cells: {
    style: {
    },
  },
  rows: {
    style: {
    },
  },
};
export default customStyles;