// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from '../../../assets/templete/Carrier ID import template.xlsx'
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import ups_logo from "../../../assets/images/ups-logo.png"

import { saveAs } from 'file-saver';

import OrderViewModal from "components/OrderViewModal";
import customStyles from "../../../assets/css/customTableStyle"

function ApiLogs() {

  const fileInput = useRef(null);
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [filteredAllData, setFilteredAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [pending, setPending] = useState(true);

  const columns = [
    {
      name: 'User',
      // selector: 'email',
      selector: row=>row.email,

      //  sortable: true,
      width: '170px'
    },
    {
      name: 'Carrier',
      // selector: 'carrier',
      selector: row=>row.carrier,

      //  sortable: true,
      // width: '80px'
    },
    {
      name: 'Type',
      selector: 'type',
      //  sortable: true,
      // width: '80px',
      cell: row => row.type ?row.type == "Success"?<span className={"badge badge-success"}>{row.type}</span>:<span className={"badge badge-danger"}>{row.type}</span>:"-"
    },
    // {
    //   name: 'Request',
    //   selector: 'request_data',
    //   //  sortable: true,
    //   width: '300px',
    //   cell: row => <span onDoubleClick={() => handleDownloadError(row.request_data)} title={"Double click to download"}>{row.request_data.toString().substring(0,100)}</span>
    // },
    // {
    //   name: 'Response',
    //   selector: 'response_data',
    //   //  sortable: true,
    //   width: '300px',
    //   cell: row => <span onDoubleClick={() => handleDownloadError(row.response_data)} title={"Double click to download"}>{row.response_data.toString().substring(0,100)}</span>
    // },
    {
      name: 'Created At',
      selector: 'created_at',
      sortable: true,
      width: "210px",
      cell: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
    {
      name: 'Actions',
      selector: 'id',
      width:"200px",
      cell: (row) => row.id ? <>
        <button className={"btn btn-warning btn-sm"} style={{marginLeft:"5px"}} onClick={() => handleDownloadError(row.id)}>Download Response</button>
      </> : ""
    }
  ];

  const handleDownloadError = (id) => {
    const body = {
      id
    };

    post('/admin/get-api-order-log-response', body, {headers}).then((response)=> {
      if(response.status == 200 && response.data.length > 0) {
        const data = JSON.stringify(response.data[0].response_data);
        const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
        saveAs(blob, 'api-order-logs-' + moment(new Date).format("DD-MMM-YYYY") + '.json');
        toast.success("Success log downloaded");
      }
    })
  }

  // const handleDownloadError = (error) => {
  //   const data = error.toString();
  //   const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
  //   saveAs(blob, 'error-' + moment(new Date).format("DD-MMM-YYYY") + '.json');
  //   toast.success("Log downloaded");
  // }


  // const tableData = ;
  const retrieveLogs = async () => {
    try {
      get('/admin/get-api-logs', { headers }).then((response) => {
        // // console.log("response", response);
        setFilteredData(response.data);
        setFilteredAllData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // // console.log("here");
    retrieveLogs();
  }, []);


  //meta title
  document.title = "Api Order Logs | ShipCheap";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" sourceItem="Logs" breadcrumbItem="Api Order" />
        <Card>
          <CardBody>
            <div style={{marginBottom:"-46px"}}>
              <label>Filter Carrier</label>
              <select className={"form-select"} style={{width:"150px"}} onChange={(e) => {
                const data = e.target.value == "All" ? filteredAllData : filteredAllData.filter(el => el.carrier == e.target.value);
                setFilteredData(data);
                setTableData({columns, data});
              }}>
                <option selected={true} value={"All"}>All</option>
                <option value={"FedEx"}>FedEx</option>
                <option value={"UPS"}>UPS</option>
                <option value={"UPSv2"}>UPSv2</option>
                <option value={"USPS"}>USPS</option>
              </select>
            </div>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Api Order Logs...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
ApiLogs.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default ApiLogs;