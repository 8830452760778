import React, { useEffect, useState } from "react"
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import Select from 'react-select';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"

function addPayment(props) {
   // Page Title
   document.title = "Add Payment || ShipCheap"

   const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("token")}`,
   };

   // console.log(headers)

   const [emailList, setEmailList] = useState([]);
   const [userEmail, setUserEmail] = useState();
   const [paymentMode, setPaymentMode] = useState();
   const [totalOrders, setTotalOrders] = useState();
   const [amount, setAmount] = useState();

   const retrieveUsers = () => {
      try {
         get('/admin/getUsers', {headers}).then((response) => {
            if(response.status === 200) {
               // console.log(response)
               let arr = []
               const getUserEmails = response.data;
               getUserEmails.forEach(item => {
                  arr.push({
                     value: item.id,
                     label: item.email
                  })
               })
               // let number = 2 + 1;
               console.log(arr)
               setEmailList(arr)
            }
         })
      } catch (error) {
         
      }
   }

   const handleEmailSelection = (value) => {
      setUserEmail(value)
   }

   const hanldeAddUser = (e) => {
      e.preventDefault();
      console.log("submit", userEmail.value, paymentMode, totalOrders, amount)
      try {
         let body = {
            paid_by: JSON.parse(localStorage.getItem("authUser")).id,
            user_id: userEmail.value,
            payment_mode: paymentMode,
            order_count: totalOrders,
            amount: amount
         }   
         post('/admin/add-referrer-payment', body, {headers}).then((response) => {
            if(response.status === 200) {
               toast.success(response.message)
               setTimeout(() => {
                  props.history.push('/referral')
               }, 1500)
            } else {
               toast.error(response.message)
            }
         })
      } catch (error) {

      }
   }

   useEffect( () => {
      retrieveUsers();
   },[])

  return (
   <div className="page-content">
      <div className="container-fluid">
         <Breadcrumbs title="ShipCheap" sourceItem="Referral" breadcrumbItem="Add Payment" />
         <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
               <Card className="overflow-hidden-not">
                  <CardBody>
                     <div className="">
                        <Form className="form-horizontal">
                           <div className="mb-3">
                              <Label className="form-label">Email <span style={{color:"red"}}>*</span></Label>
                              <Select
                                 autoFocus={true}
                                 options={emailList}
                                 placeholder="Select Email"
                                 value={userEmail}
                                 onChange={e => handleEmailSelection(e)}
                                 isSearchable={true}
                                 isClearable={true}
                              />
                           </div>
                           <div className="mb-3">
                              <Label className="form-label">Payment Mode <span style={{color:"red"}}>*</span></Label>
                              <Input
                                 name="paymentMode"
                                 className="form-control non-arrow-number"
                                 placeholder="Enter payment mode"
                                 type="text"
                                 value={paymentMode}
                                 maxLength={50}
                                 onChange={(e) => {setPaymentMode(e.target.value)}}
                                 required={true}
                              />
                           </div>
                           <div className="mb-3">
                              <Label className="form-label">Total Labels <span style={{color:"red"}}>*</span></Label>
                              <Input
                                 name="totalLabels"
                                 className="form-control non-arrow-number"
                                 placeholder="Enter payment mode"
                                 type="text"
                                 value={totalOrders}
                                 maxLength={50}
                                 onChange={(e) => {setTotalOrders(e.target.value)}}
                                 required={true}
                              />
                           </div>
                           <div className="mb-3">
                              <Label className="form-label">Amount (USD) <span style={{color:"red"}}>*</span></Label>
                              <Input
                                 name="amount"
                                 className="form-control non-arrow-number"
                                 placeholder="Enter payment mode"
                                 type="text"
                                 value={amount}
                                 maxLength={50}
                                 onChange={(e) => {setAmount(e.target.value)}}
                                 required={true}
                              />
                           </div>
                           <Row className="mb-3">
                              <Col className="text-end">
                                 <button
                                 className="btn btn-success w-md"
                                 type="submit"
                                 onClick={(e) => hanldeAddUser(e)}
                                 disabled={(userEmail && amount && totalOrders && paymentMode) ? false : true}
                                 >
                                 Add Payment
                                 </button>
                              </Col>
                           </Row>
                        </Form>
                     </div>
                  </CardBody>
               </Card>
            </Col>
         </Row>
      </div>
   </div>

  )
}

export default addPayment