module.exports = `{
  "status": 200,
  "data": [
    {
      "carrier_name": [String],
      "validations": [Object]
  ]
}`;

// ,
// {
//   "carrier_name": "UPSv2",
//   "validations": {
//   "to_address": "Lenght of (to_street1 + to_city + to_state) should not exceed 27 characters. Note: If the shipment is over 70 lbs then this validation will not apply.",
//     "wight": [
//     "Required",
//     "Maximum 150 lbs"
//   ],
//     "dimensions": [
//     "Required",
//     "Maximum 108 inch"
//   ],
//     "grith": "Maximum 165"
// }
// }