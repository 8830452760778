import React, { useEffect, useState } from "react"
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import Select from 'react-select';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"

function addUser(props) {
   // Page Title
   document.title = "Add User | ShipCheap";

   const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("token")}`,
   };

   const [referredData, setReferredData] = useState([]);
   const [userEmails, setUserEmails] = useState({})

   const retrieveUsers = () => {
      try {
         get('/admin/getUsers', {headers}).then((response) => {
            if(response.status === 200) {
               console.log("Get users", response.data)
               let arr = []
               const getUserEmails = response.data;
               getUserEmails.forEach((item, index) => {
                  arr.push({
                     value: item.id,
                     label: item.email
                  })
               })
               console.log("id and Email - List", arr)
               setReferredData(arr)
            }
         })
      } catch {

      }
   }

   const handleEmailSelection = (key, value) => {
      setUserEmails(prevUserEmails => ({...prevUserEmails, [key]:value}))
      console.log("selected email", userEmails['referredBy'])
   }

   const hanldeAddUser = (e) => {
      e.preventDefault();
      console.log("Submit", userEmails['referredBy'], userEmails['referredTo'])
      try {
         const body = {
            created_by: JSON.parse(localStorage.getItem("authUser")).id,
            refer_by_user_id: userEmails.referredBy.value,
            refer_user_id: userEmails.referredTo.value
         }
         post('/admin/create-referrer', body, {headers}).then((response) => {
            if(response.status === 200) {
               toast.success(response.message)
               setTimeout(() => {
                  props.history.push('/referral')
               }, 1500)
            } else {
               toast.error(response.message)
            }
         })
      } catch (error) {
         
      }
   }

   useEffect( () => {
      retrieveUsers();
      // console.log("useEffect runningg....")
      // console.log("selected email", userEmails['refferedBy'])
   }, [])

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" sourceItem="Reference" breadcrumbItem="Add User" />
        <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
               <Card className="overflow-hidden-not">
                  <CardBody>
                     <div className="">
                        <Form className="form-horizontal">
                           <div className="mb-3">
                              <Label className="form-label">Email Referred By <span style={{color:"red"}}>*</span></Label>
                              <Select
                                 autoFocus={true}
                                 options={referredData.filter(item => item.label != userEmails['referredTo']?.label)}
                                 placeholder="Select Email"
                                 value={userEmails['referredBy']}
                                 onChange={e => handleEmailSelection('referredBy', e)}
                                 isSearchable={true}
                                 isClearable={true}
                              />
                           </div>
                           <div className="mb-3">
                              <Label className="form-label">Referred To <span style={{color:"red"}}>*</span></Label>
                              <Select
                                 options={referredData.filter(item => item?.label != userEmails['referredBy']?.label)}
                                 placeholder="Select Email"
                                 value={userEmails['referredBy'] ? userEmails['referredTo'] : ''}
                                 // value={userEmails['referredBy'] === 0 ? userEmails['referredTo'] = null : ''}
                                 onChange={e => handleEmailSelection('referredTo', e)}
                                 isSearchable={true}
                                 isClearable={true}
                                 isDisabled={userEmails['referredBy'] ? false : true}
                              />
                           </div>
                           <Row className="mb-3">
                              <Col className="text-end">
                                 <button
                                 className="btn btn-success w-md"
                                 type="submit"
                                 onClick={(e) => hanldeAddUser(e)}
                                 disabled={userEmails['referredTo'] ? false : true}
                                 >
                                 Add User
                                 </button>
                              </Col>
                           </Row>
                        </Form>
                     </div>
                  </CardBody>
               </Card>
            </Col>
         </Row>
      </div>
   </div>
  )
}

export default addUser