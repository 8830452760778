import React, { forwardRef, useEffect, useRef, useState, useImperativeHandle } from "react"
import '../../assets/css/dropdown.css';
import { amountFormat, countFormat } from "../../assets/js/numberFormatter"
function TreeCheckboxDropdown(props, ref) {
  const componentRef = useRef(null)
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [showAll, setShowAll] = useState(false);

  useImperativeHandle(ref, () => ({
    cancelAll() {
      handleCancelAll();
    },
  }))
  useEffect(() => {
    // props.setChildFunction(childFunction);

    let arr = props.data;
    for (let i = 0; i < arr.length; i++) {
      if (!arr[i].parent_id) {
        arr[i].checked = false;
        arr[i].open = false;
      }
    }

    setData(arr);
    // console.log("load", arr)
  }, []);
  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const parentDiv = document.querySelector('.input-div');
    const parentHeight = parentDiv.clientHeight;
    // console.log("parentHeight",parentHeight);

    // if(parentHeight>40) {
    if (document.getElementById("options")) {
      document.getElementById("options").style.top = +parentHeight + +2;
    }

    // }


  }, [])

  const handleParentClick = (parent_id) => {
    let index = data.findIndex(el => el.id == parent_id);
    let updatedValue = [...data];
    let newValue = data[index];
    newValue.open = !newValue.open;
    updatedValue.splice(index, 1, newValue);
    setData(updatedValue)
  }

  const handleParentCheck = (e) => {
    let id = e.target.getAttribute('data-id');
    // console.log("id", id);
    let { checked } = e.target;
    // console.log("parent checked", checked);
    let index = data.findIndex(el => el.id == id);
    // if (checked) {
    //   // let updatedValue = [...data];
    //   // let newValue = data[index];
    //   // newValue.checked = true;
    //   // updatedValue.splice(index, 1, newValue);
    //   // setData(updatedValue)
    //
    //   let children = document.getElementsByClassName("child-" + id);
    //   for (let i = 0; i < children.length; i++) {
    //     children[i].checked = false;
    //   }
    //   if (children) {
    //     for (let i = 0; i < children.length; i++) {
    //       children[i].click();
    //     }
    //   }
    // } else {
    //   // let updatedValue = [...data];
    //   // let newValue = data[index];
    //   // newValue.checked = false;
    //   // updatedValue.splice(index, 1, newValue);
    //   // setData(updatedValue)
    //
    //   let children = document.getElementsByClassName("child-" + id);
    //   if (children) {
    //     for (let i = 0; i < children.length; i++) {
    //       if (children[i].checked) {
    //         children[i].click();
    //       }
    //     }
    //   }
    // }

    if (checked) {
      // let updatedValue1 = [...data];
      // let newValue1 = data[index];
      // newValue.checked = true;
      // updatedValue.splice(index, 1, newValue);
      // setData(updatedValue)

      let children = document.getElementsByClassName("child-" + id);
      for (let i = 0; i < children.length; i++) {
        children[i].checked = false;
      }
      if (children) {
        // console.log("total childs",children.length);
        for (let i = 0; i < children.length; i++) {
          children[i].checked = true;
          let indexx = data.findIndex(el => el.id == children[i].getAttribute("data-id"));
          let updatedValue = data;
          let newValue = data[indexx];
          newValue.checked = true;
          updatedValue.splice(indexx, 1, newValue);
          setData(updatedValue)
          // console.log("child id", children[i].getAttribute("data-id"));
          // children[i].click();
        }
      }
    } else {
      // let updatedValue = [...data];
      // let newValue = data[index];
      // newValue.checked = false;
      // updatedValue.splice(index, 1, newValue);
      // setData(updatedValue)

      let children = document.getElementsByClassName("child-" + id);
      if (children) {
        for (let i = 0; i < children.length; i++) {
          if (children[i].checked) {
            let indexx = data.findIndex(el => el.id == children[i].getAttribute("data-id"));
            let updatedValue = data;
            let newValue = data[indexx];
            newValue.checked = false;
            updatedValue.splice(indexx, 1, newValue);
            setData(updatedValue)
            // console.log("child id", children[i].getAttribute("data-id"));
            children[i].checked = false;
            // children[i].click();
          }
        }
      }
    }

    response();
  }

  const handleChildCheck = (e) => {
    let parent = e.target.getAttribute('data-parent');
    let id = e.target.getAttribute('data-id');
    // console.log("parent", parent);
    // console.log("id", id);
    let { checked } = e.target;
    // console.log("child checked", checked);
    let index = data.findIndex(el => el.id == id);

    if (checked) {
      let updatedValue = [...data];
      let newValue = data[index];
      newValue.checked = true;
      updatedValue.splice(index, 1, newValue);
      setData(updatedValue)
    } else {
      let updatedValue = [...data];
      let newValue = data[index];
      newValue.checked = false;
      updatedValue.splice(index, 1, newValue);
      setData(updatedValue)
    }

    let parentChildCount = data.filter(el => el.parent_id == parent).length;
    let parentChildCheckedCount = data.filter(el => el.parent_id == parent && el.checked == true).length;
    if (parentChildCount == parentChildCheckedCount) {
      document.getElementById("parent-" + parent).checked = true;
    } else {
      // console.log("parent not all")
      document.getElementById("parent-" + parent).checked = false;
    }
    // console.log("parentChildCount", parentChildCount)
    // console.log("parentChildCheckedCount", parentChildCheckedCount)

    response();
  }

  const response = () => {
    let responseArr = [];
    let includedArr = [];
    let dataArray = data.filter(el => el.checked == true);
    for (let i = 0; i < dataArray.length; i++) {
      let parentLength = data.filter(e => e.parent_id == dataArray[i].parent_id).length;
      let checkedLength = data.filter(el => el.checked == true && el.parent_id == dataArray[i].parent_id).length;
      let parentIndex = data.filter(el => el.id == dataArray[i].parent_id);
      // console.log("parentLength",parentLength);
      // console.log("checkedLength",checkedLength);
      if (parentLength == checkedLength && !includedArr.includes(parentIndex[0].id)) {
        responseArr.push(parentIndex[0]);
        includedArr.push(parentIndex[0].id)
      }
      else if (parentLength != checkedLength) {
        responseArr.push(dataArray[i]);
      }
    }

    // console.log("includedArr",includedArr);
    // console.log("responseArr",responseArr);
    let finalData = {
      "to_display": responseArr,
      "to_filter": data.filter(el => el.checked == true)
    }
    props.change(finalData);
  }

  const handleCancel = (id) => {
    let index = data.findIndex(el => el.id == id);
    let updatedValue = [...data];
    let newValue = data[index];
    newValue.checked = false;
    updatedValue.splice(index, 1, newValue);
    setData(updatedValue);
    // console.log("isParent", data[index].hasChild, "parent-" + id);
    if (data[index].hasChild == true || data[index].hasChild == false) {
      document.getElementById("parent-" + id).checked = false;
    } else {
      document.getElementById("child-" + id).checked = false;
      let parentChildCount = data.filter(el => el.parent_id == data[index].parent_id).length;
      let parentChildCheckedCount = data.filter(el => el.parent_id == data[index].parent_id && el.checked == true).length;
      if (parentChildCount != parentChildCheckedCount) {
        document.getElementById("parent-" + data[index].parent_id).checked = false;
      }
    }

    response();
  }

  const handleCancelAll = () => {
    let uncheck = data.filter(el => el.checked);
    for (let i = 0; i < uncheck.length; i++) {
      if (uncheck[i].hasChild == false || uncheck[i].hasChild == true) {
        document.getElementById("parent-" + uncheck[i].id).click();
      }
      else {
        document.getElementById("child-" + uncheck[i].id).click();
      }
    }
  }


  return (
    <div className={"main"} ref={componentRef}>
      <div className={"input"}>
        <div className={"form-control input-div "
          // + (showAll?"height-auto":"height-fixed")
        } onClick={() => {
          setVisible(true);
          // console.log("setVisibletrue")
        }}
        //      onMouseOver={() => {
        //     if(data.filter(el => el.checked).length>1) {
        //         setShowAll(true)
        //     }
        // }}
        >
          {/*{data.filter(el => el.checked).map((el) => (*/}
          {/*    <span className={"custom-badge custom-badge-light"}>*/}
          {/*    {el.id} <span className={"cancel-btn"} onClick={() => {handleCancel(el.id, el.hasChild)}}>✕</span>*/}
          {/*</span>*/}
          {/*))}*/}
          {props.placeholder}
          {/*<MdKeyboardArrowDown style={{float:"right"}}/>*/}
        </div>
        {/*<div className={"input-reset"}>*/}
        {/*    <span className={"cancel-all-btn " + (data.filter(el => el.checked).length>0?"cancel-all-btn-active":"cancel-all-btn-inactive")} onClick={handleCancelAll}>✕</span>*/}
        {/*</div>*/}
      </div>
      {data.length > 0 ?
        <div className={"col-md-12 m-0 options border rounded p-2 " + (visible ? "d-block" : "d-none")} style={{ backgroundColor: localStorage.getItem("theme") == "dark" ? "#070A1F" : "white" }} id="options">
          <span className={"cancel-all-btn mb-2 " + (data.filter(el => el.checked).length > 0 ? "cancel-all-btn-active" : "cancel-all-btn-inactive")} onClick={handleCancelAll}>Clear</span>
          {data.filter(el => !el.parent_id).map((el, id) => (
            <div key={id}>
              <div className={"col-md-12 m-0 parent-node node d-flex parent-" + el.id}>
                {el.hasChild ? el.open ? <i className='bx bxs-down-arrow' onClick={() => { handleParentClick(el.id) }}></i> : <i className='bx bxs-right-arrow' onClick={() => { handleParentClick(el.id) }}></i> : ""}
                <input type={"checkbox"} data-id={el.id} onChange={handleParentCheck} className={"form-check parent-" + el.id + " " + (data.filter(ell => ell.parent_id == el.id && ell.checked == true).length < data.filter(ell => ell.parent_id == el.id).length && data.some(ell => ell.parent_id == el.id && ell.checked == true) ? "checkbox-custom" : "")} id={"parent-" + el.id} /> {el.color ? <span style={{ backgroundColor: el.color, width: "13px", height: "13px", borderRadius: "50%", marginTop: "6px", marginRight: "5px" }}></span> : ""}<span className={"value"}>{el.value}</span> {el.count >= 0 ? <span className={"count"}>{countFormat(el.count)}</span> : ""}
              </div>
              {data.filter(elc => elc.parent_id == el.id).map((elc, idd) => (
                <div key={idd} className={"col-md-12 m-0 node child-node " + (el.open ? "d-flex" : "d-none")}>
                  <input type={"checkbox"} data-id={elc.id} onChange={handleChildCheck} data-parent={el.id} className={"form-check child-" + el.id} id={"child-" + elc.id} /> <span className={"value"}>{elc.value}</span> {elc.count >= 0 ? <span className={"count"}>{countFormat(elc.count)}</span> : ""}
                </div>
              ))}
            </div>
          ))}
        </div>
        : ""
      }
    </div>
  )
}

export default forwardRef(TreeCheckboxDropdown);