// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"
import Select from "react-select/base"


function AddService(props) {
  const fileInput = useRef(null);
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [discountType, setDiscountType] = useState("None");
  const [carriers, setCarriers] = useState([]);
  const [serviceImage, setServiceImage] = useState("");

  const retrieveCarriers = async () => {
    try {
      get('/admin/get-carrier-names', {headers}).then((response) => {
        setCarriers(response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }


  useEffect(() => {
    retrieveCarriers();
  },[]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      carrierId: '',
      carrierName: '',
      serviceKey: '',
      price: '',
      above_five_lbs_price: '',
      under_one_lbs_price: '',
      discount: '',
      description: '',
      weight: '',
    },
    validationSchema: Yup.object({
      carrierId: Yup.string().required("Please Enter Service Id"),
      carrierName: Yup.string().required("Please Enter Service Name"),
      serviceKey: Yup.string().required("Please Enter Service Key"),
      price: Yup.string().required("Please Enter Price"),
      above_five_lbs_price: Yup.string().required("Please Enter Above 5lb. Price"),
      under_one_lbs_price: Yup.string().required("Please Enter Below 1lb. Price"),
      // description: Yup.string().required("Please Enter Description"),
      // weight: Yup.string().required("Please Enter Weight"),
    }),
    onSubmit: (values) => {
      dispatch(handleAddService(values));
    }
  });

  const handleDiscountType = (e) => {
    // console.log(e.target.value);
    setDiscountType(e.target.value);
  }


  // const tableData = ;
  const handleAddService = async (values) => {
    try {
      const {carrierId, carrierName, serviceKey, price, discount, description, weight,above_five_lbs_price, under_one_lbs_price} = values;

      if(discountType !== "None" && discount == "" || discount == "0") {
        toast.error("Discount must be greater than 0");
      }
      else {
        if(discountType === "Flat" && price <= discount) {
          toast.error("Discount amount must be lesser than actual amount");
          return true;
        }

        if(discountType === "Percent" && discount > 100) {
          toast.error("Discount percentage must be lesser than 100");
          return true;
        }

        const body = {
          carrierId: carrierId,
          carrierName: carrierName,
          serviceKey: serviceKey,
          price: price,
          above_five_lbs_price: above_five_lbs_price,
          under_one_lbs_price: under_one_lbs_price,
          discountType: discountType,
          discount: discount,
          description: description,
          weight: 1,
          serviceImage: serviceImage
        };

        post('/admin/add-service', body, {headers}).then((response) => {
          // // console.log("resss",response);
          if(response.status === 200) {
            toast.success(response.message);
            setTimeout(() => {
              props.history.push("/services");
            },2000)
          }
          else {
            toast.error(response.message);
          }
        })
      }
    }
    catch (e) {
      // console.log(e)
    }
  }

  const handleImage = (e) => {
    const file = e.target.files[0];
    // console.log("file",);
    const reader = new FileReader();
    reader.onloadend = () => {
      // Use a regex to remove data url part
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');

      // console.log("base64String",base64String);
      setServiceImage(base64String);
      // Logs wL2dvYWwgbW9yZ...
    };
    reader.readAsDataURL(file);
  }


  //meta title
  document.title = "Add Service | ShipCheap";


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" sourceItem="Services" breadcrumbItem="Add Service" />
        {/* <Table columns={columns} data={data} /> */}
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody>
                  <CardTitle className="mb-4">Service Information</CardTitle>
                  <div className="">
                    {responseSuccess && !responseSuccess ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {responseMessage}
                      </Alert>
                    ) : null}
                    {responseSuccess && responseSuccess ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {responseMessage}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <label>Service Image</label>
                        <input type={"file"} multiple={false} ref={fileInput} required={true} onChange={handleImage} className={"form-control"} accept={".png"}/>
                      </div>
                      <div className="mb-3">
                        <label>Carriers</label>
                        <select
                          name={"carrierId"}
                          className="form-select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.carrierId || ""}
                          // invalid={
                          //   validation.touched.to_state && validation.errors.to_state ? true : false
                          // }
                        >
                          <option disabled={true} value={""}>Select a carrier</option>
                          {carriers.map((part, id) => {
                            return (
                              part.is_active == 1?<option key={id} value={part.id}>{part.name}</option>:"");
                          })};
                        </select>
                      </div>
                      {/*<div className="mb-3">*/}
                      {/*  <Label className="form-label">Service ID</Label>*/}
                      {/*  <Input*/}
                      {/*    name="carrierId"*/}
                      {/*    className="form-control"*/}
                      {/*    placeholder="Enter Service Id"*/}
                      {/*    type="text"*/}
                      {/*    onChange={validation.handleChange}*/}
                      {/*    onBlur={validation.handleBlur}*/}
                      {/*    value={validation.values.carrierId || ""}*/}
                      {/*    invalid={*/}
                      {/*      validation.touched.carrierId && validation.errors.carrierId ? true : false*/}
                      {/*    }*/}
                      {/*  />*/}
                      {/*  {validation.touched.carrierId && validation.errors.carrierId ? (*/}
                      {/*    <FormFeedback type="invalid">{validation.errors.carrierId}</FormFeedback>*/}
                      {/*  ) : null}*/}
                      {/*</div>*/}
                      <div className="mb-3">
                        <Label className="form-label">Service Name</Label>
                        <Input
                          name="carrierName"
                          className="form-control"
                          placeholder="Enter Service Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.carrierName || ""}
                          invalid={
                            validation.touched.carrierName && validation.errors.carrierName ? true : false
                          }
                        />
                        {validation.touched.carrierName && validation.errors.carrierName ? (
                          <FormFeedback type="invalid">{validation.errors.carrierName}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Service Key</Label>
                        <Input
                          name="serviceKey"
                          className="form-control"
                          placeholder="Enter Service Key"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.serviceKey || ""}
                          invalid={
                            validation.touched.serviceKey && validation.errors.serviceKey ? true : false
                          }
                        />
                        {validation.touched.serviceKey && validation.errors.serviceKey ? (
                          <FormFeedback type="invalid">{validation.errors.serviceKey}</FormFeedback>
                        ) : null}
                      </div>
                      {/*<div className="mb-3">*/}
                      {/*  <Label className="form-label">Weight (Kilogram)</Label>*/}
                      {/*  <Input*/}
                      {/*    name="weight"*/}
                      {/*    className="form-control non-arrow-number"*/}
                      {/*    placeholder="Enter Weight"*/}
                      {/*    // style={{width:"60%"}}*/}
                      {/*    type="number"*/}
                      {/*onWheel={(e) => e.target.blur()}*/}
                      {/*    onChange={validation.handleChange}*/}
                      {/*    onBlur={validation.handleBlur}*/}
                      {/*    value={validation.values.weight || ""}*/}
                      {/*  />*/}
                      {/*  {validation.touched.weight && validation.errors.weight ? (*/}
                      {/*    <FormFeedback type="invalid">{validation.errors.weight}</FormFeedback>*/}
                      {/*  ) : null}*/}
                      {/*</div>*/}
                      <div className="mb-3">
                        <Label className="form-label">Price</Label>
                        <Input
                          name="price"
                          className="form-control non-arrow-number"
                          placeholder="Enter Price"
                          type="number"
                          step="any"
                          onWheel={(e) => e.target.blur()}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.price || ""}
                          invalid={
                            validation.touched.price && validation.errors.price ? true : false
                          }
                        />
                        {validation.touched.price && validation.errors.price ? (
                          <FormFeedback type="invalid">{validation.errors.price}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Above 5 lbs Price</Label>
                        <Input
                          name="above_five_lbs_price"
                          className="form-control non-arrow-number"
                          placeholder="Enter Above 5 lbs Price"
                          type="number"
                          step="any"
                          onWheel={(e) => e.target.blur()}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.above_five_lbs_price || ""}
                          invalid={
                            validation.touched.above_five_lbs_price && validation.errors.above_five_lbs_price ? true : false
                          }
                        />
                        {validation.touched.above_five_lbs_price && validation.errors.above_five_lbs_price ? (
                          <FormFeedback type="invalid">{validation.errors.above_five_lbs_price}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Below 1 lbs Price</Label>
                        <Input
                          name="under_one_lbs_price"
                          className="form-control non-arrow-number"
                          placeholder="Enter Below 1 lbs Price"
                          type="number"
                          step="any"
                          onWheel={(e) => e.target.blur()}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.under_one_lbs_price || ""}
                          invalid={
                            validation.touched.under_one_lbs_price && validation.errors.under_one_lbs_price ? true : false
                          }
                        />
                        {validation.touched.under_one_lbs_price && validation.errors.under_one_lbs_price ? (
                          <FormFeedback type="invalid">{validation.errors.under_one_lbs_price}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Discount Type</Label>
                        <select
                          className="form-select"
                          name="discountType"
                          // onChange={validation.handleChange}
                          onChange={handleDiscountType}
                        >
                          <option>None</option>
                          <option>Flat</option>
                          <option>Percent</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Discount</Label>
                        <Input
                          name="discount"
                          className="form-control non-arrow-number"
                          placeholder="Enter Discount"
                          type="number"
                          step="any"
                          onWheel={(e) => e.target.blur()}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={discountType==="None"?"":(validation.values.discount || "")}
                          max={discountType==="Percent"?100:""}
                          disabled={discountType==="None"?true:false}
                          invalid={
                            validation.touched.discount && validation.errors.discount ? true : false
                          }
                        />
                        {validation.touched.discount && validation.errors.discount ? (
                          <FormFeedback type="invalid">{validation.errors.discount}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Description</Label>
                        <Input
                          name="description"
                          className="form-control non-arrow-number"
                          placeholder="Enter Description"
                          type="textarea"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                          invalid={
                            validation.touched.description && validation.errors.description ? true : false
                          }
                        />
                        {validation.touched.description && validation.errors.description ? (
                          <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <Button type="button" onClick={() => props.history.push('/services') } color="danger" style={{marginRight:"10px"}}>
                            Cancel
                          </Button>
                          <button
                            className="btn btn-success w-md "
                            type="submit"
                          >
                            Save
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
      </div>
    </div>
  );
}
AddService.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default AddService;