// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import { amountFormat } from "../../../assets/js/numberFormatter"
import customStyles from "../../../assets/css/customTableStyle"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"


function OrdersReporting(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const fileInput = useRef(null);
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [modal, setModal] = useState(false);

  const [pending, setPending] = useState(true);

  const [id, setId] = useState(null);
  const [title, setTitle] = useState(null);
  const [excerpt, setExcerpt] = useState(null);
  const [description, setDescription] = useState(null);
  const [category_id, setCategoryID] = useState(null);
  const [blogImage, setblogImage] = useState("");


  const columns = [
    {
      name: 'Date',
      selector: 'date',
     sortable: true,
      cell: (row) => moment(row.date).format('MMM DD, YYYY')
    },
    {
      name: 'Completed Order Count',
      selector: 'count',
      selector: row=>row.count,

    //  sortable: true,
    },
    {
      name: 'Credit Balance',
      selector: 'credit',
    //  sortable: true,
      cell: row => row.credit ? '$' + amountFormat(row.credit) : "$0"
    },
    {
      name: 'Debit Balance',
      selector: 'debit',
    //  sortable: true,
      cell: row => row.debit ? '$' + amountFormat(row.debit) : "$0"
    },
  ];



  // const tableData = ;
  const retrieveOrdersReporting = async () => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      }
      post('/user/get-orders-reporting', body, {headers}).then((response) => {
        // console.log("response", response);
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  createTheme('solarized', {
    text: {
      primary: '#fffff',
      secondary: '#fffff',
    },
    background: {
      default: '#070A1F',
    },
    context: {
      background: '#cb4b16',
      text: '#070A1F',
    },
    divider: {
      default: '#282828',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  useEffect(() => {
    // // console.log("here");
    retrieveOrdersReporting();
  }, []);

  //meta title
  document.title = "Balance Reporting | ShipCheap";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" breadcrumbItem="Balance Reporting" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Balance Reporting...`}
            >
              <DataTable columns={columns} data={filteredData} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}
                         theme={localStorage.getItem("theme")=="dark"?"solarized":""} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
OrdersReporting.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default OrdersReporting;