// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal";
import customStyles from "../../../assets/css/customTableStyle"
import Select from "react-select"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"

const displayNames = [
  {
    "cron_name": "sellercloud_import_order",
    "display_name": "Order Sync / Update Scheduler"
  },
  {
    "cron_name": "sellercloud_disable_shipped_void_cancelled_order_cron",
    "display_name": "Order Status Update Scheduler"
  },
  {
    "cron_name": "sellercloud_picklist_update_cron",
    "display_name": "Picklist Update Scheduler"
  },
]
function UserCronReport() {
  // let selectedStoreId = "";
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  // const dispatch = useDispatch();

  const fileInput = useRef(null);

  const [orderImportData, setOrderImportData] = useState([]);
  const [orderDisableData, setOrderDisableData] = useState([]);
  const [picklistData, setPicklistData] = useState([]);
  const [stores, setStores] = useState([]);
  const [pending, setPending] = useState(false);
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [upcomingOrderImportCron, setUpcomingOrderImportCron] = useState("")
  const [upcomingOrderDisableCron, setUpcomingOrderDisableCron] = useState("")

  const retrieveStores = async () => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      };
      post('/user/getStores', body, {headers}).then((response) => {
        let stores = [{value: "all",label: "All Stores"}];
        for (let i=0;i<response.data.length;i++) {
          if(response.data[i].store_type == "sellercloud") {
            stores.push({
              label: response.data[i].name,
              value: response.data[i].id
            })
          }
        }

        setStores(stores);
        // console.log("response.data",response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveAllCronReport = async () => {
    try {
      setPending(true);
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      }
      post('/user/get-all-store-cron-job-logs', body, {headers}).then((response) => {
        if(response.status == 200) {
          setOrderImportData(response.order_import_logs);
          setOrderDisableData(response.order_disable_logs);
        }
        setPending(false);
      })
    }
    catch (e) {
    }
  }

  const retrieveCronReport= async (e) => {
    try {
      if(e.value == "all") {
        setSelectedStoreId("");
        setUpcomingOrderImportCron("");
        setUpcomingOrderDisableCron("");
        await retrieveAllCronReport()
      }
      else {
        setPending(true);
        setSelectedStoreId(e.value);
        const body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).id,
          store_id: e.value
        }
        post('/user/get-store-cron-job-logs', body, {headers}).then((response) => {
          if(response.status == 200) {
            setOrderImportData(response.order_import_logs);
            setOrderDisableData(response.order_disable_logs);
            if(response.order_import_logs.length > 0) {
              setUpcomingOrderImportCron(moment(response.order_import_logs[0].created_at).tz(moment.tz.guess()).add(1, "hour").format("MMM DD, YYYY hh:mm A"));
            }

            if(response.order_disable_logs.length > 0) {
              setUpcomingOrderDisableCron(moment(response.order_disable_logs[0].created_at).tz(moment.tz.guess()).add(10, "minute").format("MMM DD, YYYY hh:mm A"));
            }
          }

          setPending(false);
        })
      }
    }
    catch (e) {
      // console.log(e)
    }
  }

  const reloadCronReport= async () => {
    try {
      setPending(true);
      if(selectedStoreId) {
        const body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).id,
          store_id: selectedStoreId
        }
        post('/user/get-store-cron-job-logs', body, {headers}).then((response) => {
          if(response.status == 200) {
            setOrderImportData(response.order_import_logs);
            setOrderDisableData(response.order_disable_logs);
            if(response.order_import_logs.length > 0) {
              setUpcomingOrderImportCron(moment(response.order_import_logs[0].created_at).tz(moment.tz.guess()).add(1, "hour").format("MMM DD, YYYY hh:mm A"));
            }

            if(response.order_disable_logs.length > 0) {
              setUpcomingOrderDisableCron(moment(response.order_disable_logs[0].created_at).tz(moment.tz.guess()).add(10, "minute").format("MMM DD, YYYY hh:mm A"));
            }
          }
          setPending(false);
        })
      }
      else {
        await retrieveAllCronReport();
      }
    }
    catch (e) {
      // console.log(e)
    }
  }

  const columns = [
    {
      name: 'Store Name',
      selector: 'store_name',
      width: '250px',
      cell: (row) => (
        <span>
          {row.store_name}
        </span>
      )
    },
    // {
    //   name: 'Upcoming Fetch Date Time',
    //   selector: 'start_date',
    //   sortable: true,
    //   width:"210px",
    //   cell: (row) => (
    //     row.cron_name == "sellercloud_picklist_update_cron"?"-":
    //       <span>
    //       {moment(new Date(row.start_date * 1000)).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
    //     </span>
    //   )
    // },
    {
      name: 'Scheduler Start Date Time',
      selector: 'created_at',
      sortable: true,
      minWidth:"210px",
      cell: (row) => (
        <span>
          {moment(row.created_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
        </span>
      )
    },
    {
      name: 'Scheduler End Date Time',
      selector: 'end_date',
      sortable: true,
      minWidth:"210px",
      cell: (row) => (
        row.end_date?
          <span>
          {moment(new Date(row.end_date * 1000)).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
        </span>:"-"
      )
    },
    {
      name: 'Message',
      selector: 'message',
      sortable: true,
      width:"210px",
      cell: (row) => (<span>{row.message?row.message.toString().split("-->").map((el,index) => (<p key={index}>{el.length<180?el:el.substr(0,180)}</p>)):"-"}</span>)
    },
    {
      name: 'Status',
      selector: 'running_status',
      minWidth: "120px",
      cell: (row) => (
        row.running_status == 1?
          <span className={"badge badge-warning"}>Running</span> :
          (row.running_status == 2?
              <span className={"badge badge-danger"}>Aborted</span> :
              (row.running_status == 4 ?
                  <span className={"badge badge-danger"}>Error</span> :
                  <span className={"badge badge-success"}>Completed</span>
              )
          )
      )
    }
  ];

  const handleForceStop = (id) => {
    if(confirm("Are you sure! you want to force stop the scheduler?")) {
      const body = {
        id: id
      }
      post('/admin/stop-cron-job', body, {headers}).then(async (response) => {
        if(response.status == 200) {
          toast.success(response.message);
          await reloadCronReport();
        }
        else {
          toast.error(response.message);
        }
      })
    }
  }

  createTheme('solarized', {
    text: {
      primary: '#fffff',
      secondary: '#fffff',
    },
    background: {
      default: '#070A1F',
    },
    context: {
      background: '#cb4b16',
      text: '#070A1F',
    },
    divider: {
      default: '#282828',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  useEffect(() => {
    retrieveStores();
    retrieveAllCronReport();
  }, []);

  //meta title
  document.title = "Scheduler Logs | ShipCheap";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" breadcrumbItem="Scheduler Logs" />
        <Card>
          <CardBody>
            <div className={"row"}>
              <div className={"col-sm-10 col-md-6 col-lg-4"}>
                <label>Select Store</label>
                <Select
                  options={stores}
                  onChange={async (selectedGroup) => {
                    // console.log("selectedGroup",selectedGroup);
                    await retrieveCronReport(selectedGroup)
                  }}
                  styles={{
                    singleValue: (base) => ({ ...base, color: localStorage.getItem("theme") == "dark"?"white":"black" }),
                    valueContainer: (base) => ({
                      ...base,
                      // background: colourOptions[2].color,
                      //placeholder color
                      color: "hotpink",
                      width: "100%"
                    }),
                    control: (base, state) => ({
                      ...base,
                      background: (localStorage.getItem("theme") == "dark"?"#121212":"white")
                    })
                  }}
                  theme={(theme) => ({
                    ...theme,
                    // borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      /*
                       * control/backgroundColor
                       * menu/backgroundColor
                       * option/color(selected)
                       */
                      // neutral0: "black",
                      neutral30: (localStorage.getItem("theme")=="dark"?"white":"black"), //control/borderColor(focused)
                      // neutral10: "grey",
                      neutral80: (localStorage.getItem("theme")=="dark"?"white":"black"), //input color
                      primary25: "#ccc", //option bg color focued
                      primary: (localStorage.getItem("theme")=="dark"?"black":"black"), //option bg color selected
                      primary50: (localStorage.getItem("theme")=="dark"?"white":"black"), // option bg color active(enavled or available)
                      neutral90: (localStorage.getItem("theme")=="dark"?"black":"hotpink")
                    }})}
                  // isClearable={true}
                  className="select2-selection"
                />
              </div>
              <div className={"col-sm-4 col-md-2"} style={{justifyContent:"center",textAlign:"center"}}>
                <label style={{visibility:"hidden"}}>Reload</label>
                <button className={"btn btn-light"} style={{display:"block"}} onClick={reloadCronReport}>Reload</button>
              </div>

            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            {orderImportData.length>0?
              <>
                <h4>Order Sync / Update Scheduler ({moment(new Date()).tz(moment.tz.guess()).subtract(2, "days").format("MMM DD, YYYY")} - {moment(new Date()).tz(moment.tz.guess()).format("MMM DD, YYYY")})
                  {upcomingOrderImportCron?<span style={{float:"right",fontSize:"13px"}}>Expected Upcoming Scheduler: {upcomingOrderImportCron}</span>:null}
                </h4>
              <DataTable columns={columns} data={orderImportData} theme={localStorage.getItem("theme")=="dark"?"solarized":""} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles} pagination={15} paginationPerPage={15} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationRowsPerPageOptions={[50, 100, 200, 500]}
                         highlightOnHover={true}/>
                         </>
            :null}
            {orderDisableData.length>0?
              <>
                <h4>Order Status Update Scheduler ({moment(new Date()).tz(moment.tz.guess()).subtract(2, "days").format("MMM DD, YYYY")} - {moment(new Date()).tz(moment.tz.guess()).format("MMM DD, YYYY")})
                  {upcomingOrderDisableCron?<span style={{float:"right",fontSize:"13px"}}>Expected Upcoming Scheduler: {upcomingOrderDisableCron}</span>:null}
                </h4>
                <DataTable columns={columns} data={orderDisableData} theme={localStorage.getItem("theme")=="dark"?"solarized":""} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles} pagination={15} paginationPerPage={15} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationRowsPerPageOptions={[50, 100, 200, 500]}
                           highlightOnHover={true}/>
              </>
              :null}

            {orderDisableData.length == 0 && orderImportData.length == 0 ? <h6 style={{textAlign:"center",marginTop:"5px"}}>No record to show</h6> : null}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
UserCronReport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};
export default UserCronReport;