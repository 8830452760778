// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from '../../../assets/templete/Carrier ID import template.xlsx'
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import ups_logo from "../../../assets/images/ups-logo.png"

import { saveAs } from 'file-saver';

import OrderViewModal from "components/OrderViewModal";
import defaultLoader from "../../../assets/images/default_loader.gif"
import customStyles from "../../../assets/css/customTableStyle"
let page = 1;
let dataCompleted = false;
function GoogleAddressValidationLogs() {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);

  const [viewModal, setViewModal] = useState(false);

  const [pending, setPending] = useState(true);
  const [loading, setLoading] = useState(false);

  const [rowId, setRowId] = useState()

  const columns = [
    {
      name: 'User Email',
      // selector: 'email',
      selector: row=> row.email,
    //  sortable: true,
      width: '180px'
    },
    {
      name: 'Type',
      // selector: 'type',
      selector: row=>row.type,
    //  sortable: true,
      width: '150px',
      cell: row => row.type.includes("success")?<span className={"badge badge-success"}>{row.type.toString().toUpperCase()}</span>:<span className={"badge badge-danger"}>{row.type.toString().toUpperCase()}</span>
    },
    {
      name: 'Request',
      selector: 'request_data',
    //  sortable: true,
      width: '300px',
      cell: row => <span onDoubleClick={() => handleDownloadError(row.request_data)} title={"Double click to download"}>{row.request_data}</span>
    },
    {
      name: 'Response',
      selector: 'response_data',
    //  sortable: true,
      width: '300px',
      cell: row => <span onDoubleClick={() => handleDownloadError(row.response_data)} title={"Double click to download"}>{row.response_data.toString().substring(0,100)}...</span>
    },
    {
      name: 'Created At',
      selector: 'created_at',
     sortable: true,
      cell: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
    // {
    //   name: 'Actions',
    //   selector: 'order_id',
    //   cell: (row) => row.order_id ? <><button className={"btn btn-success btn-sm"} onClick={() => {
    //     setRowId(row.order_id)
    //     setViewModal(true)
    //     // handleView(row.order_id)
    //   }}>View Order</button></> : "-"
    // }
  ];

  const handleDownloadError = (error) => {
    const data = error.toString();
    const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
    saveAs(blob, 'google-address-validation-' + moment(new Date).format("DD-MMM-YYYY") + '.json');
    toast.success("Google address validation log downloaded");
  }

  // const tableData = ;
  const retrieveGoogleAddressValidationLogs = async () => {
    try {
      get('/admin/get-google-address-validation-logs/'+page, { headers }).then((response) => {
        // // console.log("response", response);
        if(response.data.length > 0) {
          let mergeData = filteredData.concat(response.data);
          setFilteredData(mergeData);
          let data = mergeData;
          // console.log("table data", { columns, data });
          setTableData({ columns, data });
          setPending(false);
          setLoading(false);
          dataCompleted = false;
          page = page + 1;
        }
        else {
          dataCompleted = true;
          setLoading(false);
          setPending(false);
        }
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    dataCompleted = false;
    page = 1;
    setFilteredData([]);
    let data = [];
    setTableData({columns, data});
    retrieveGoogleAddressValidationLogs();
  }, []);

  async function handleScroll() {
    // console.log("scrolling")
    if (parseInt(window.innerHeight + document.documentElement.scrollTop) == parseInt(document.documentElement.scrollHeight)) {
      // console.log("scrolling bottom",dataCompleted);
      if(!dataCompleted) {
        setLoading(true);
      }
      await retrieveGoogleAddressValidationLogs();
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [filteredData]);


  //meta title
  document.title = "Google Address Validations | ShipCheap";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" sourceItem="Logs" breadcrumbItem="Google Address Validations" />
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Google Address Validation Logs...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles}
                         // pagination={30}
                         // paginationPerPage={30}
                         progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}/>
            </DataTableExtensions>
            {loading?<h4 className={"text-center"}><img src={defaultLoader} className={"mt-3 mb-3"} width={"40px"} /></h4>:""}
          </CardBody>
        </Card>

        {/*Custom Modal*/}
        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          setViewModal={(data) => { setViewModal(data) }}
        />
        {/*Custom Modal*/}


      </div>
    </div>
  );
}
GoogleAddressValidationLogs.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default GoogleAddressValidationLogs;