// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import {
  Button, Col, Form, FormFeedback, Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Row,
  Table,
  Toast,
  ToastBody,
  ToastHeader,
  Card,
  CardBody
} from "reactstrap"
import logo from "../../../assets/images/logo.svg"
import moment from "moment-timezone"
import modalimage1 from "../../../assets/images/product/img-7.png"
import modalimage2 from "../../../assets/images/product/img-4.png"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { amountFormat } from "../../../assets/js/numberFormatter"
import customStyles from "../../../assets/css/customTableStyle"


function Users(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const email = JSON.parse(localStorage.getItem("authUser")).email;
  const id = JSON.parse(localStorage.getItem("authUser")).id;

  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [orders, setOrders] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalAccess, setModalAccess] = useState(false);

  const [modalPlaid, setModalPlaid] = useState(false);
  const [plaidAmount, setPlaidAmount] = useState("");
  const [plaidUserID, setPlaidUserID] = useState("");
  const [plaidBtnDisabled, setPlaidBtnDisabled] = useState(false);

  const [modalConnectedStore, setModalConnectedStore] = useState(false);
  const [connectedStores, setConnectedStores] = useState([]);
  const [connectedStoresEmail, setConnectedStoresEmail] = useState([]);


  const [modalCreditSettings, setModalCreditSettings] = useState(false);
  const [is_credit_enable, setIsCreditEnable] = useState("");
  const [max_credit_amount, setMaxCreditAmount] = useState("");
  const [creditSettingsEmail, setCreditSettingsEmail] = useState("");

  const [userId, setId] = useState(null);
  const [userName, setName] = useState(null);
  const [userEmail, setEmail] = useState(null);
  const [userBalance, setBalance] = useState(null);

  const [pending, setPending] = useState(true);

  const [shopifyAccess, setShopifyAccess] = useState("0");
  const [ebayAccess, setEbayAccess] = useState("0");
  const [walmartAccess, setWalmartAccess] = useState("0");
  const [zenventoryAccess, setZenventoryAccess] = useState("0");
  const [sellercloudAccess, setSellercloudAccess] = useState("0");
  const [shipstationAccess, setShipstationAccess] = useState("0");

  const handleViewCount = (id) => {
    try {
      let body = {
        id: id
      }
      post('/admin/get-user-completed-order-count', body, { headers }).then((response) => {
        if (response.status == 200) {
          // alert(response.data);
          document.getElementById("user" + id).innerText = response.data;
        }
      })
    }
    catch (e) {

    }
  }

  const columns = [
    {
      name: 'Full Name',
      selector: ['first_name', 'last_name'],
      //  sortable: true,
      width: "200px",
      cell: row => row.first_name + " " + row.last_name
    },
    {
      name: 'Email',
      // selector: 'email',
      //  sortable: true,
      width: "300px",
      cell: row => <span title={row.email}>{row.email}</span>
    },
    {
      name: 'Balance',
      selector: 'balance',
      //  sortable: true,
      width: "140px",
      cell: row => "$" + amountFormat(parseFloat(row.balance).toFixed(2))
    },
    {
      name: 'Label Generated',
      selector: ['order_count', 'id'],
      //  sortable: true,
      width: "150px",
      // cell: row => row.order_count?row.order_count:0
      cell: row => (
        <button className={"btn btn-dark btn-sm"} id={"user" + row.id} onClick={() => handleViewCount(row.id)}>Load Count</button>
      )
    },
    {
      name: 'Created At',
      selector: 'created_at',
      sortable: true,
      width: "150px",
      cell: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Status',
      selector: 'status',
      //  sortable: true,
      width: "90px",
      cell: (row) =>
        <>
          {row.status === 1 ? <span className={"badge badge-success"}>Active</span> : (row.status === 2 ? <span className={"badge badge-danger"}>Ban</span> : (row.status === 3 ? <span className={"badge badge-warning"}>Unverified</span> : <span className={"badge badge-danger"}>Inactive</span>))}
        </>
    },
    {
      name: 'Action',
      selector: ['id', 'status'],
      width: "1080px",
      cell: (row) =>
        <>
          {
            row.status === 1 ?
              <>
                <button className={"btn btn-danger btn-sm waves-effect waves-light"} onClick={() => handleBan(row.id)}>Ban</button>
                <button className={"btn btn-danger btn-sm waves-effect waves-light"} onClick={() => handleSuspend(row.id)} style={{ marginLeft: "10px" }}>Suspend</button>
                <button className={"btn btn-warning btn-sm waves-effect waves-light"} onClick={() => handleBalance(row.id)} style={{ marginLeft: "10px" }}>Add Balance</button>
                <button className={"btn btn-info btn-sm waves-effect waves-light"} onClick={() => handleBalanceHistory(row.id)} style={{ marginLeft: "10px" }}>Balance History</button>
                <button className={"btn btn-success btn-sm waves-effect waves-light"} onClick={() => handleAccess(row.id)} style={{ marginLeft: "10px" }}>Access</button>
                <button className={"btn btn-info btn-sm waves-effect waves-light"} onClick={() => handleConnectedStores(row.id, row.email)} style={{ marginLeft: "10px" }}>Stores</button>
                <button className={"btn btn-primary btn-sm waves-effect waves-light"} onClick={() => handleCreditSettings(row.id, row.email)} style={{ marginLeft: "10px" }}>Credit Settings</button>
                <button className={"btn btn-light btn-sm waves-effect waves-light"} onClick={() => props.history.push('/disabled-orders-report/' + row.id)} style={{ marginLeft: "10px" }}>Disabled Orders Report</button>
                <button className={"btn btn-light btn-sm waves-effect waves-light"} onClick={() => props.history.push('/completed-orders-report/' + row.id)} style={{ marginLeft: "10px" }}>Completed Orders Report</button>
                {id == 68 || email == "admin@ups.com" ?
                  <>
                    <button className={"btn btn-dark btn-sm waves-effect waves-light"} onClick={() => handleLoginAs(row.id)} style={{ marginLeft: "10px" }}>Login As</button>
                  </> : ""}
                {row.plaid_status === 1 ?
                  <>
                    <button className={"btn btn-success btn-sm waves-effect waves-light"} onClick={() => handlePlaid(row.id)} style={{ marginLeft: "10px" }}>Plaid</button>
                    {/* <button className={"btn btn-success btn-sm waves-effect waves-light"} onClick={() => transferWithPlaid(row.id)} style={{marginLeft:"10px"}}>Plaid</button> */}
                  </> : ""}
              </>
              :
              (row.status === 2 ?
                <button className={"btn btn-success btn-sm waves-effect waves-light"} onClick={() => handleUnban(row.id)}>Unban</button>
                :
                (row.status === 3 ? <button className={"btn btn-success btn-sm waves-effect waves-light"} onClick={() => handleVerify(row.id)}>Verify</button> : "")
              )
          }
        </>
    }
  ];

  const transferWithPlaid = async (event) => {

    let loading = toast.loading("Processing transaction");
    console.log(plaidAmount);
    console.log(plaidUserID);
    setPlaidBtnDisabled(true);

    const body = {
      user_id: plaidUserID,
      amount: plaidAmount
    }

    await post('/user/plaid-transfer-request', body, { headers }).then(async (response) => {
      console.log("res", response);
      toast.remove(loading);
      if (response.status === 200) {
        await retrieveUsers();
        toast.success(response.message);
        setModalPlaid(false);
        setPlaidAmount(null);
        setPlaidUserID(null);
        setPlaidBtnDisabled(false);
      }
      else {
        toast.error(response.message);
        setPlaidBtnDisabled(false);
      }
    });
  }

  const handleSuspend = async (id) => {
    let confrm = confirm("Are you sure you want to suspend this user?");
    if (confrm) {
      // const id = e.target.getAttribute("data-id");
      const body = {
        id: id
      }
      await post('/admin/user/suspend', body, { headers }).then(async (response) => {
        // console.log("res", response);
        if (response.status === 200) {
          await retrieveUsers();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      });
    }
  }

  const handleBan = async (id) => {
    // const id = e.target.getAttribute("data-id");
    let confrm = confirm("Are you sure you want to ban this user?");
    if (confrm) {
      const body = {
        id: id
      }
      await post('/admin/user/ban', body, { headers }).then(async (response) => {
        // console.log("res", response);
        if (response.status === 200) {
          await retrieveUsers();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      });
    }
  }

  const handleBalanceHistory = async (id) => {
    // const id = e.target.getAttribute("data-id");
    props.history.push('/user/balance-history/' + id);
  }

  // const tableData = ;
  const retrieveUsers = async () => {
    try {
      get('/admin/getUsers', { headers }).then((response) => {
        console.log("getUsers", response);
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // // console.log(e)
    }
  }

  // const retrieveOrders = async () => {
  //   try {
  //     get('/admin/get-user-completed-orders', {headers}).then((response) => {
  //       // console.log("get-user-completed-orders", response);
  //       setOrders(response.data);
  //       return response.data;
  //     })
  //   }
  //   catch (e) {
  //     // console.log(e)
  //   }
  // }


  const handleBalance = async (id) => {
    // const id = e.target.getAttribute("data-id");
    setId(id);
    await get('/admin/get-user-by-id/' + id, { headers }).then(async (response) => {
      // console.log("res",response);
      if (response.status === 200) {
        validation.values.balance = "";
        setEmail(response.data.email);
        setBalance(response.data.balance);
        setName(response.data.first_name + " " + response.data.last_name);
        setModal(true);
        // toast.success(response.message);
      }
      else {
        toast.error(response.message);
      }
    });

  }

  const handleAccess = async (id) => {
    // const id = e.target.getAttribute("data-id");
    setId(id);
    await get('/admin/get-user-by-id/' + id, { headers }).then(async (response) => {
      // console.log("res",response);
      if (response.status === 200) {
        setEmail(response.data.email);
        setName(response.data.first_name + " " + response.data.last_name);
        setShopifyAccess(response.data.shopify);
        setEbayAccess(response.data.ebay);
        setWalmartAccess(response.data.walmart);
        setZenventoryAccess(response.data.zenventory);
        setSellercloudAccess(response.data.sellercloud);
        setShipstationAccess(response.data.shipstation);
        setModalAccess(true);
      }
      else {
        toast.error(response.message);
      }
    });

  }

  const handlePlaid = async (id) => {

    await get('/admin/get-user-by-id/' + id, { headers }).then(async (response) => {
      // console.log("res",response);
      if (response.status === 200) {

        setEmail(response.data.email);
        setBalance(response.data.balance);
        setName(response.data.first_name + " " + response.data.last_name);
        setPlaidUserID(id);
        setModalPlaid(true);
        // toast.success(response.message);
      }
      else {
        toast.error(response.message);
      }
    });
  }


  const handleConnectedStores = async (id, email) => {
    setConnectedStoresEmail(email)
    await get('/admin/get-user-connected-stores/' + id, { headers }).then(async (response) => {
      console.log("res", response.data);
      if (response.status === 200) {
        setConnectedStores(response.data);
        setModalConnectedStore(true);
      }
      else {
        toast.error(response.message);
      }
    });

  }

  const handleCreditSettings = async (id, email) => {
    await get('/admin/get-user-credit-settings/' + id, { headers }).then(async (response) => {
      console.log("is_credit_enable", response.is_credit_enable);
      console.log("max_credit_amount", response.max_credit_amount);
      if (response.status === 200) {
        setId(id);
        setCreditSettingsEmail(email)
        setIsCreditEnable(response.is_credit_enable);
        setMaxCreditAmount(response.max_credit_amount);
        setModalCreditSettings(true);
      }
      else {
        toast.error(response.message);
      }
    });
  }

  const submitCreditSettings = async (e) => {
    e.preventDefault()
    try {
      const body = {
        id: userId,
        is_credit_enable,
        max_credit_amount
      }

      post('/admin/update-user-credit-settings', body, { headers }).then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          setId("");
          setCreditSettingsEmail("");
          setIsCreditEnable("");
          setMaxCreditAmount("");
          setModalCreditSettings(false);
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {
      console.log("error", e)
    }
  }

  const handleUnban = async (id) => {
    // const id = e.target.getAttribute("data-id");
    let confrm = confirm("Are you sure you want to unban this user?");
    if (confrm) {
      const body = {
        id: id
      }
      await post('/admin/user/unban', body, { headers }).then(async (response) => {
        if (response.status === 200) {
          await retrieveUsers();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      });
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: userId,
      balance: 0
    },
    validationSchema: Yup.object({
      balance: Yup.string().required("Please Enter Balance"),
    }),
    onSubmit: (values) => {
      dispatch(handleAddBalance(values));
    }
  });

  const handleAddBalance = async (values) => {
    const { id, balance } = values;
    const body = {
      id: id,
      balance: balance,
      payment_mode: 'Admin'
    };
    // console.log("body",id, balance);
    await post('/admin/user/add-balance', body, { headers }).then(async (response) => {
      // console.log("res",response);
      if (response.status === 200) {
        await retrieveUsers();
        toast.success(response.message);
        setModal(!modal);
      }
      else {
        toast.error(response.message);
      }
    });
  }

  useEffect(() => {
    setPending(true)
    retrieveUsers().then(() => {
      // retrieveOrders().then(() => {

      // });
    });
    // alert("fafa");
  }, []);

  const handleAccessUpdate = (user_id, type, value) => {
    const body = {
      user_id: user_id,
      type: type,
      value: value
    }

    post('/admin/update-user-access', body, { headers }).then((response) => {
      if (response.status == 200) {
        toast.success(response.message);
        retrieveUsers();
      }
      else {
        toast.error(response.message);
      }
    })
  }

  const handleLoginAs = (user_id) => {
    if (confirm("Are you sure! you want to login as a user?")) {
      const body = {
        user_id: user_id,
        id: id,
        email: email
      }
      let loading = toast.loading("Loading...")
      post('/admin/login-as-user', body, { headers }).then((response) => {
        // console.log("response",response);
        if (response.status == 200) {
          toast.remove(loading);
          localStorage.removeItem("authUser");
          localStorage.removeItem("token");
          localStorage.setItem("authUser", JSON.stringify(response.user));
          // localStorage.setItem("gapi", response.google_api);
          localStorage.setItem("token", response.token);
          localStorage.setItem("skt", "false");
          localStorage.setItem("balance", response.user.balance);
          //Store 0 = Manual Store
          localStorage.setItem("store", 0);
          localStorage.setItem("admvu", 1)
          window.location = "/dashboard";
        }
        else {
          toast.error(response.message);
        }
      })
    }
  }

  const handleVerify = (user_id) => {
    if (confirm("Are you sure! you want to verify the user?")) {
      const body = {
        user_id: user_id,
        email: JSON.parse(localStorage.getItem("authUser")).email
      }
      post('/admin/user-verify', body, { headers }).then((response) => {
        // console.log("response",response);
        if (response.status == 200) {
          toast.success(response.message);
          retrieveUsers();
        }
        else {
          toast.error(response.message);
        }
      })
    }
  }

  //meta title
  document.title = "Users | ShipCheap";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" breadcrumbItem="Users" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Users...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles} pagination={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationPerPage={30}
                highlightOnHover={true} />
            </DataTableExtensions>
          </CardBody>
        </Card>

        {/*Modal*/}
        <Modal
          isOpen={modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setModal(!modal);
          }}
          onClosed={() => {
            setId(null);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModal(!modal);
              }}
            >
              Add Balance
            </ModalHeader>
            <ModalBody>
              <p className="mb-2">
                User id: <span className="text-primary">#{userId}</span>
              </p>
              <p className="mb-2">
                User Name: <span className="text-primary">{userName}</span>
              </p>
              <p className="mb-2">
                User Email: <span className="text-primary">{userEmail}</span>
              </p>
              <p className="mb-2">
                Current Balance: <span className="text-primary">${amountFormat(parseFloat(userBalance).toFixed(2))}</span>
              </p>
              <hr />
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">Balance</Label>
                  <Input
                    name="balance"
                    className="form-control"
                    placeholder="Enter Balance"
                    type="number"
                    step="any"
                    onWheel={(e) => e.target.blur()}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.balance || ""}
                    invalid={
                      validation.touched.balance && validation.errors.balance ? true : false
                    }
                  />
                  {validation.touched.balance && validation.errors.balance ? (
                    <FormFeedback type="invalid">{validation.errors.balance}</FormFeedback>
                  ) : null}
                </div>
                <Row className="mb-3">
                  <Col className="text-end">
                    <button
                      className="btn btn-primary w-md "
                      type="submit"
                    >
                      Add Balance
                    </button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            {/*<ModalFooter>*/}
            {/*  <Button*/}
            {/*    type="button"*/}
            {/*    color="secondary"*/}
            {/*    onClick={() => {*/}
            {/*      setModal(!modal);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Close*/}
            {/*  </Button>*/}
            {/*</ModalFooter>*/}
          </div>
        </Modal>
        {/*Modal*/}


        {/*Modal*/}
        <Modal
          isOpen={modalAccess}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setModalAccess(!modalAccess);
          }}
          onClosed={() => {
            setId(null);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModalAccess(!modalAccess);
              }}
            >
              Grant Access
            </ModalHeader>
            <ModalBody>
              <p className="mb-2">
                User id: <span className="text-primary">#{userId}</span>
              </p>
              <p className="mb-2">
                User Name: <span className="text-primary">{userName}</span>
              </p>
              <p className="mb-2">
                User Email: <span className="text-primary">{userEmail}</span>
              </p>
              <hr />
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">Shopify</Label>
                  <select onChange={(e) => handleAccessUpdate(userId, 'shopify', e.target.value)} className={"form-control"}>
                    <option value={"1"} selected={shopifyAccess == "1"}>Granted</option>
                    <option value={"0"} selected={shopifyAccess == "0"}>Revoked</option>
                  </select>
                </div>
                <div className="mb-3">
                  <Label className="form-label">Ebay</Label>
                  <select onChange={(e) => handleAccessUpdate(userId, 'ebay', e.target.value)} className={"form-control"}>
                    <option value={"1"} selected={ebayAccess == "1"}>Granted</option>
                    <option value={"0"} selected={ebayAccess == "0"}>Revoked</option>
                  </select>
                </div>
                <div className="mb-3">
                  <Label className="form-label">Walmart</Label>
                  <select onChange={(e) => handleAccessUpdate(userId, 'walmart', e.target.value)} className={"form-control"}>
                    <option value={"1"} selected={walmartAccess == "1"}>Granted</option>
                    <option value={"0"} selected={walmartAccess == "0"}>Revoked</option>
                  </select>
                </div>
                <div className="mb-3">
                  <Label className="form-label">Zenvenotry</Label>
                  <select onChange={(e) => handleAccessUpdate(userId, 'zenventory', e.target.value)} className={"form-control"}>
                    <option value={"1"} selected={zenventoryAccess == "1"}>Granted</option>
                    <option value={"0"} selected={zenventoryAccess == "0"}>Revoked</option>
                  </select>
                </div>
                <div className="mb-3">
                  <Label className="form-label">Sellercloud</Label>
                  <select onChange={(e) => handleAccessUpdate(userId, 'sellercloud', e.target.value)} className={"form-control"}>
                    <option value={"1"} selected={sellercloudAccess == "1"}>Granted</option>
                    <option value={"0"} selected={sellercloudAccess == "0"}>Revoked</option>
                  </select>
                </div>
                <div className="mb-3">
                  <Label className="form-label">Shipstation</Label>
                  <select onChange={(e) => handleAccessUpdate(userId, 'shipstation', e.target.value)} className={"form-control"}>
                    <option value={"1"} selected={shipstationAccess == "1"}>Granted</option>
                    <option value={"0"} selected={shipstationAccess == "0"}>Revoked</option>
                  </select>
                </div>
              </Form>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Modal Plaid*/}
        <Modal
          isOpen={modalPlaid}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setModalPlaid(!modalPlaid);
          }}
          onClosed={() => {
            setPlaidUserID(null);
            setPlaidBtnDisabled(false);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModalPlaid(!modalPlaid);
              }}
            >
              Plaid Transfer
            </ModalHeader>
            <ModalBody>
              <p className="mb-2">
                User id: <span className="text-primary">#{plaidUserID}</span>
              </p>
              <p className="mb-2">
                User Name: <span className="text-primary">{userName}</span>
              </p>
              <p className="mb-2">
                User Email: <span className="text-primary">{userEmail}</span>
              </p>
              <p className="mb-2">
                User Balance: <span className="text-primary">{userBalance}</span>
              </p>
              <hr />
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">Enter Amount (USD)</Label>
                  <input
                    type="number"
                    name="plaidAmount"
                    // step="any"
                    placeholder="Please enter minimum 1 amount"
                    value={plaidAmount}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setPlaidAmount(e.target.value)}
                    required={true}
                    min={0}
                    className={"form-control non-arrow-number"} />
                </div>
                <div className="mb-3" style={{ textAlign: "right" }}>
                  <button type={"submit"} className={"btn btn-success btn-sm"} disabled={plaidBtnDisabled} onClick={() => transferWithPlaid()}>Transfer</button>
                </div>
              </Form>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal Plaid*/}

        {/*Modal*/}
        <Modal
          isOpen={modalConnectedStore}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setModalConnectedStore(!modalConnectedStore);
          }}
          onClosed={() => {
            setId(null);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModalConnectedStore(!modalConnectedStore);
              }}
            >
              Connected Store
            </ModalHeader>
            <ModalBody>
              <p className="mb-2">
                User Email: <span className="text-primary">{connectedStoresEmail}</span>
              </p>
              <hr />
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <td>Store Type</td>
                    <td>Store Count</td>
                  </tr>
                </thead>
                <tbody>
                  {connectedStores.length > 0 ?
                    connectedStores.map((el, index) => (
                      <tr key={index}>
                        <td className={"bg-white"}>{el.store_type}</td>
                        <td className={"bg-white"}>{el.store_count}</td>
                      </tr>
                    ))
                    : <tr><td colSpan={2} className={"bg-white"} style={{ textAlign: "center" }}>No Store Found</td></tr>}
                </tbody>
              </table>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Modal*/}
        <Modal
          isOpen={modalCreditSettings}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setModalCreditSettings(!modalCreditSettings);
          }}
          onClosed={() => {
            setId(null);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModalCreditSettings(!modalCreditSettings);
              }}
            >
              Connected Store
            </ModalHeader>
            <ModalBody>
              <p className="mb-2">
                User Email: <span className="text-primary">{creditSettingsEmail}</span>
              </p>
              <hr />
              <form onSubmit={submitCreditSettings}>
                <div className="mb-3">
                  <Label className="form-label">Enable Credit</Label>
                  <select onChange={(e) => {
                    setIsCreditEnable(e.target.value);
                    if (e.target.value == "0") {
                      setMaxCreditAmount("");
                    }
                  }} className={"form-control"}>
                    <option value="1" selected={is_credit_enable == "1"}>Enable</option>
                    <option value="0" selected={is_credit_enable == "0"}>Disable</option>
                  </select>
                </div>
                <div className="mb-3">
                  <Label className="form-label">Max Credit Amount</Label>
                  <input
                    type="number"
                    step="any"
                    placeholder="Enter max credit amount"
                    value={max_credit_amount}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setMaxCreditAmount(e.target.value)}
                    disabled={is_credit_enable == "0"}
                    required={true}
                    min={0}
                    className={"form-control non-arrow-number"} />
                </div>
                <div>
                  <button type={"submit"} className={"btn btn-success btn-sm"} style={{ float: "right" }}>Save</button>
                  <br />
                  <br />
                </div>
              </form>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}


      </div>
    </div>
  );
}
Users.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default Users;