const customDarkStyles = {
  headCells: {
    style: {
      border:"1px solid #0a0a0a",
      background:"#0a0a0a"
    },
  },
  cells: {
    style: {
    },
  },
  rows: {
    style: {
    },
  },
};
export default customDarkStyles;