import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"
import config from "../config"

//pass new generated access token here
const token = accessToken

//apply base url for axios

// const API_URL = "http://localhost:3000/api"

const API_URL = config.site.BASE_URL;

// const API_URL = "https://login.nullship.gg/apis/api"

// const API_URL = "https://ups.tolearndevops.com/apis/api";

// const API_URL = "https://test.nullship.gg/apis/api"


const axiosApi = axios.create({
  baseURL: API_URL,
  timeout: 12000000, //timeout set to 200 minutes
})

axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function postFile(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export function baseUrl() {
  return API_URL
}
