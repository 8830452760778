// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal";
import customStyles from "../../../assets/css/customTableStyle"
import Select from "react-select"

let selectedUser = "";
let selectedUserId = "";
const displayNames = [
  {
    "cron_name": "sellercloud_import_order",
    "display_name": "Order Sync / Update Scheduler"
  },
  {
    "cron_name": "sellercloud_disable_shipped_void_cancelled_order_cron",
    "display_name": "Order Status Update Scheduler"
  },
  {
    "cron_name": "sellercloud_picklist_update_cron",
    "display_name": "Picklist Update Scheduler"
  },
]
function CronReport() {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  // const dispatch = useDispatch();

  const fileInput = useRef(null);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [users, setUsers] = useState([]);
  const [pending, setPending] = useState(false);

  const retrieveUsers = async () => {
    try {
      get('/admin/getActiveUsers', { headers }).then((response) => {
        if (response.data.length > 0) {
          response.data.sort((a, b) => {
            const nameA = a.email.toLowerCase();
            const nameB = b.email.toLowerCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        }

        let users = [{ value: "all", label: "All Users" }];
        for (let i = 0; i < response.data.length; i++) {
          users.push({
            label: response.data[i].email,
            value: response.data[i].id
          })
        }

        setUsers(users);
        // console.log("response.data",response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveAllCronReport = async () => {
    try {
      setPending(true);
      get('/admin/all-cron-report', { headers }).then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].cron_name) {
            response.data[i].cron_name = displayNames.filter(el => el.cron_name == response.data[i].cron_name).length > 0 ? displayNames.filter(el => el.cron_name == response.data[i].cron_name)[0].display_name : response.data[i].cron_name;
          }
        }
        setData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveCronReport = async (e) => {
    try {
      if (e.value == "all") {
        await retrieveAllCronReport();
      }
      else {
        setPending(true);
        selectedUser = e.label;
        selectedUserId = e.value;
        get('/admin/cron-report/' + e.value, { headers }).then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].cron_name) {
              response.data[i].cron_name = displayNames.filter(el => el.cron_name == response.data[i].cron_name).length > 0 ? displayNames.filter(el => el.cron_name == response.data[i].cron_name)[0].display_name : response.data[i].cron_name;
            }
          }
          setData(response.data);
          let data = response.data;
          // console.log("table data" , { columns, data });
          setTableData({ columns, data });
          setPending(false);
        })
      }
    }
    catch (e) {
      // console.log(e)
    }
  }

  const reloadCronReport = async () => {
    try {
      if (selectedUserId) {
        setPending(true);
        get('/admin/cron-report/' + selectedUserId, { headers }).then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].cron_name) {
              response.data[i].cron_name = displayNames.filter(el => el.cron_name == response.data[i].cron_name).length > 0 ? displayNames.filter(el => el.cron_name == response.data[i].cron_name)[0].display_name : response.data[i].cron_name;
            }
          }
          setData(response.data);
          let data = response.data;
          // console.log("table data" , { columns, data });
          setTableData({ columns, data });
          setPending(false);
        })
      }
      else {
        await retrieveAllCronReport()
      }
    }
    catch (e) {
      // console.log(e)
    }
  }

  const columns = [
    {
      name: 'User Email',
      selector: 'email',
      width: '260px',
      cell: (row) => (
        <span>
          {row.email ? row.email : "-"}
        </span>
      )
    },
    {
      name: 'Store ID',
      selector: 'store_id',
      sortable: true,
      width: '100px',
      cell: (row) => (
        <span>
          {row.store_id && row.store_id != "999999999" ? row.store_id : "-"}
        </span>
      )
    },
    {
      name: 'Warehouse ID - Store Name',
      selector: 'store_name',
      sortable: true,
      width: '250px',
      cell: (row) => (
        <span>
          {row.warehouse_id} - {row.store_name}
        </span>
      )
    },
    {
      name: 'Store Type',
      // selector: 'store_type',
      selector: row => row.store_type,

      sortable: true,
      width: '120px'
    },
    {
      name: 'Cron Name',
      selector: 'cron_name',
      width: '300px',
      cell: (row) => (
        <span title={row.cron_name}>
          {row.cron_name}
        </span>
      )
    },
    {
      name: 'Upcoming Fetching Date',
      selector: 'start_date',
      sortable: true,
      width: "210px",
      cell: (row) => (
        row.cron_name == "sellercloud_picklist_update_cron" ? "-" :
          <span>
            {moment(new Date(row.start_date * 1000)).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
          </span>
      )
    },
    {
      name: 'Started At',
      selector: 'created_at',
      sortable: true,
      width: "210px",
      cell: (row) => (
        <span>
          {moment(row.created_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
        </span>
      )
    },
    {
      name: 'Ended At',
      selector: 'end_date',
      sortable: true,
      width: "210px",
      cell: (row) => (
        row.end_date ?
          <span>
            {moment(new Date(row.end_date * 1000)).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}
          </span> : "-"
      )
    },
    {
      name: 'Duration',
      sortable: true,
      width: "120px",
      selector: (row) => {
        if (row.end_date) {
          let startAt = moment(row.created_at).unix();
          let endAt = moment(row.updated_at).unix();
          // console.log("startAt",startAt);
          // console.log("endAt",endAt);
          const timeDifference = endAt - startAt;
          // console.log("timeDifference",timeDifference);
          const difInMinutes = timeDifference / 6000;
          // console.log("difInMinutes",difInMinutes);
          // console.log("")
          // console.log("")
          return Math.floor(difInMinutes * 100);
        }
        else return "-";
      }
    },
    {
      name: 'Message',
      selector: 'message',
      sortable: true,
      width: "210px",
      cell: (row) => (<span>{row.message ?
        (row.message.toString().includes("displayMessage") ? JSON.parse(row.message).displayMessage :
          row.message.toString().split("-->").map((el, index) => (<p key={index}>{el.length < 180 ? el : el.substr(0, 180)}</p>)))
        : "-"}</span>)
    },
    {
      name: 'Status',
      selector: 'running_status',
      width: "120px",
      sortable: true,
      cell: (row) => (
        row.running_status == 1 ?
          <span className={"badge badge-warning"}>Running</span> :
          (row.running_status == 2 ?
            <span className={"badge badge-danger"}>Aborted</span> :
            (row.running_status == 4 ?
              <span className={"badge badge-danger"}>Error</span> :
              <span className={"badge badge-success"}>Completed</span>
            )
          )
      )
    },
    {
      name: 'Action',
      selector: 'running_status',
      width: "180px",
      cell: (row) => (
        row.running_status == 1 ?
          <button className={"btn btn-danger"} onClick={() => handleForceStop(row.id)}>Force Stop</button>
          : "-"
      )
    }
  ];

  const handleForceStop = (id) => {
    if (confirm("Are you sure! you want to force stop the scheduler?")) {
      const body = {
        id: id
      }
      post('/admin/stop-cron-job', body, { headers }).then(async (response) => {
        if (response.status == 200) {
          toast.success(response.message);
          await reloadCronReport();
        }
        else {
          toast.error(response.message);
        }
      })
    }
  }

  useEffect(() => {
    retrieveUsers();
    retrieveAllCronReport();
  }, []);

  //meta title
  document.title = "Cron Report | ShipCheap";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" sourceItem="Report" breadcrumbItem="Cron Report" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody style={{ display: "flex" }}>
            <div className={"col-md-4"}>
              <label>Select User</label>
              <Select
                options={users}
                onChange={async (selectedGroup) => {
                  // console.log("selectedGroup",selectedGroup);
                  await retrieveCronReport(selectedGroup)
                }}
                className="select2-selection"
              />
              {/*<select className={"form-select"} onChange={retrieveCronReport}>*/}
              {/*  <option disabled={true} selected={true}>{users.length==0?"Loading users...":"Select a User"}</option>*/}
              {/*  {users.length>0?*/}
              {/*    users.map((part, id) => (*/}
              {/*      <option value={part.id} key={id}>{part.email}</option>*/}
              {/*    ))*/}
              {/*    :""}*/}
              {/*</select>*/}
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <button className={"btn btn-light"} style={{ marginBottom: "-44px" }} onClick={reloadCronReport}>Reload</button>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Cron Report...`}
            >
              <DataTable columns={columns} data={data} customStyles={customStyles} pagination={50} paginationPerPage={50} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationRowsPerPageOptions={[50, 100, 200, 500]}
                highlightOnHover={true} />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
CronReport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};
export default CronReport;