// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from 'prop-types';

import axios from 'axios'

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody, CardHeader
} from "reactstrap"
import ups_logo from "../../../assets/images/ups-logo.png"

import CoinbaseCommerceButton from 'react-coinbase-commerce';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';


import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PaypalButton from '../../../components/PaypalButton'
import coinbase from '../../../assets/images/Coinbase.svg.png'
import config from '../../../config';
import OrderViewModal from "components/OrderViewModal";
import payment_successful from '../../../assets/images/Successful Payment.gif'
import payment_failed from '../../../assets/images/Payment Error.gif'
import logo from '../../../assets/images/nullship-icon.png';
import { amountFormat } from "../../../assets/js/numberFormatter"
import customStyles from "../../../assets/css/customTableStyle"

function UserBalanceHistory(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [user, setUser]= useState(null);

  const [balance, setBalance] = useState("-");

  // const [coinbaseLogs, setCoinbaseLogs] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);

  const [inputAmount, setInputAmount] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const [coinbaseKey, setCoinbaseKey] = useState(null);
  const [coinbaseWebhookSecret, setCoinbaseWebhookSecret] = useState(null);

  const [product, setProduct] = useState({
    "name":"NullShip",
    "description":"Add balance to your account",
    "logo_url": logo,
    "pricing_type":"fixed_price",
    "local_price": {
      "amount": '',
      "currency": "USD"
    },
    "metadata": {
      "user_id": JSON.parse(localStorage.getItem("authUser")).id
    },
    "redirect_url": `${baseUrl()}/user/coinbase/redirect-to-wallet?status=Successful`,
    "cancel_url": `${baseUrl()}/user/coinbase/redirect-to-wallet?status=Failed`
  });

  const [rowId, setRowId] = useState();

  const [paymentStatus, setPaymentStatus] = useState(null);

  const [pending, setPending] = useState(true);


  // let product = {
  //   // price: 100,
  //   price: parseFloat(inputAmount),
  //   description: "checkout",
  // }
  // // console.log(product.price)

  //OLD WAY
  // const columns = [
  //   {
  //     name: 'Type',
  //     selector: 'type',
  //   //  sortable: true,
  //     cell: (row) => row.type === "credit" ? <span className={"badge badge-success"}>{row.type}</span> : <span className={"badge badge-danger"}>{row.type}</span>
  //   },
  //   {
  //     name: 'Balance',
  //     selector: 'balance',
  //   //  sortable: true,
  //     cell: row => row.balance ? "$" + amountFormat(row.balance) : "-"
  //   },
  //   {
  //     name: 'Date',
  //     selector: 'created_at',
  //    sortable: true,
  //     width:"230px",
  //     cell: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
  //   },
  //   {
  //     name: "Payment Mode",
  //     selector: 'payment_mode',
  //     // sortable: true,
  //     width:"140px",
  //     cell: (row) => row.payment_mode !== null ?row.payment_mode : "-"
  //   },
  //   {
  //     name: 'Description',
  //     selector: ['type', 'order_id'],
  //     // sortable: true,
  //     width:"140px",
  //     cell: row => row.type === "credit" ? row.order_id ? "Refund" : "-" : "-"
  //   },
  //   {
  //     name: 'Actions',
  //     selector: 'order_id',
  //     width:"120px",
  //     cell: (row) => row.order_id ? <><button className={"btn btn-success btn-sm"} onClick={() => {
  //       setRowId(row.order_id)
  //       setViewModal(true)
  //       // handleView(row.order_id)
  //     }}>View Order</button></> : ""
  //   }
  // ];


  // NEW WAY 1
  // const columns = [
  //   {
  //     name: "Batch ID",
  //     selector: 'batch_id',
  //     // sortable: true,
  //     width:"250px",
  //     cell: (row) => row.batch_id !== null ?row.batch_id : "-"
  //   },
  //   {
  //     name: "Order Count",
  //     selector: 'order_ids',
  //     // sortable: true,
  //     width: "110px",
  //     cell: (row) => row.order_ids ? row.order_ids.split(",").length : "-"
  //   },
  //   {
  //     name: 'Type',
  //     selector: 'type',
  //   //  sortable: true,
  //     width: "80px",
  //     cell: (row) => row.type === "credit" ? <span className={"badge badge-success"}>{row.type}</span> : <span className={"badge badge-danger"}>{row.type}</span>
  //   },
  //   {
  //     name: 'Balance',
  //     selector: 'total_amount',
  //   //  sortable: true,
  //     width: "110px",
  //     cell: row => row.total_amount ? "$" + amountFormat(row.total_amount) : "-"
  //   },
  //   {
  //     name: 'Date',
  //     selector: 'created_at',
  //    sortable: true,
  //     width:"230px",
  //     cell: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
  //   },
  //   {
  //     name: "Payment Mode",
  //     selector: 'payment_mode',
  //     // sortable: true,
  //     width:"140px",
  //     cell: (row) => row.payment_mode !== null ?row.payment_mode : "-"
  //   },
  //   {
  //     name: 'Description',
  //     selector: ['type', 'order_ids'],
  //     // sortable: true,
  //     width:"140px",
  //     cell: row => row.type === "credit" ? row.order_ids ? "Refund" : "-" : "-"
  //   }
  // ];


  // NEW WAY 2
  const columns = [
    {
      name: "Payment Mode",
      selector: 'payment_mode',
      // sortable: true,
      width:"130px",
      cell: (row) => row.payment_mode !== null ?row.payment_mode : "Wallet"
    },
    {
      name: "Batch ID",
      selector: ['batch_id', 'payment_mode'],
      // sortable: true,
      width:"230px",
      cell: (row) => row.batch_id? !row.payment_mode ? row.batch_id : "-" : "-"
    },
    {
      name: "Credit Amount",
      selector: 'credit_amount',
      // sortable: true,
      width: "140px",
      cell: row => row.credit_amount ? "$" + amountFormat(row.credit_amount) : "-"
    },
    {
      name: 'Debit Amount',
      selector: 'debit_amount',
      //  sortable: true,
      width: "140px",
      cell: row => row.debit_amount ? "$" + amountFormat(row.debit_amount) : "-"
    },
    {
      name: 'Success Orders',
      selector: 'success_orders',
      //  sortable: true,
      width: "150px",
      cell: row => row.success_orders ? row.success_orders : "-"
    },
    {
      name: 'Failed Orders',
      selector: 'failed_orders',
      //  sortable: true,
      width: "150px",
      cell: row => row.failed_orders ? row.failed_orders : "-"
    },
    {
      name: 'Date',
      selector: 'created_at',
      sortable: true,
      width:"230px",
      cell: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
  ];

  function getQueryParam(name) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(name);
  }


  const retrieveBalance = async () => {
    try {
      setPending(true);
      // let page = 1;
      // const fetchingOrders = await recursiveRetrieve(page);
      // // console.log("fetchingOrders",fetchingOrders);

      const body = {
        id: props.match.params.id
      };
      post('/user/get-wallet-history', body, { headers }).then((response) => {
        // console.log("response", response);
        // // Old Way
        //         // setFilteredData(response.data);
        //         // let data = response.data;
        //         // // console.log("table data" , { columns, data });
        //         // setTableData({ columns, data });
        let sorted = [];
        for (let i=0;i<response.data.length;i++) {
          if(response.data[i].batch_id) {
            let found = sorted.some(el => el. batch_id == response.data[i].batch_id);
            if(!found) {
              if(response.data[i].type == "credit") {
                sorted.push({
                  payment_mode: response.data[i].payment_mode,
                  batch_id: response.data[i].batch_id,
                  debit_amount: 0,
                  credit_amount: response.data[i].total_amount,
                  created_at: response.data[i].created_at,
                  success_orders: 0,
                  failed_orders: response.data[i].order_ids?response.data[i].order_ids.split(",").length:0
                });
              }
              else if(response.data[i].type == "debit") {
                sorted.push({
                  payment_mode: response.data[i].payment_mode,
                  batch_id: response.data[i].batch_id,
                  debit_amount: response.data[i].total_amount,
                  credit_amount: 0,
                  created_at: response.data[i].created_at,
                  success_orders: response.data[i].order_ids?response.data[i].order_ids.split(",").length:0,
                  failed_orders: 0
                });
              }
            }
            else {
              let index = sorted.findIndex(el => el. batch_id == response.data[i].batch_id);
              // console.log("found at index", index);
              if(response.data[i].type == "credit") {
                // console.log("credit ",sorted[index]);
                sorted[index].credit_amount = response.data[i].total_amount;
                sorted[index].debit_amount = parseFloat(sorted[index].debit_amount) - parseFloat(response.data[i].total_amount);
                sorted[index].failed_orders = (parseInt(sorted[index].success_orders) - response.data[i].order_ids.split(",").length);
              }
              if(response.data[i].type == "debit") {
                // console.log("debit ", sorted[index]);
                // console.log("response.data[i].total_amount",response.data[i].total_amount);
                // sorted[index].debit_amount = parseFloat(response.data[i].total_amount) - parseFloat(sorted[index].credit_amount);
                sorted[index].debit_amount = parseFloat(response.data[i].total_amount);
                sorted[index].success_orders = (response.data[i].order_ids.split(",").length - parseInt(sorted[index].failed_orders));
              }
            }
          }
        }

        // console.log("sorted", sorted);
        let data = sorted;
        setFilteredData(data);
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setUser(response.user);
        setPending(false);
      })
    }
    catch (e) {

    }
  }


  useEffect(() => {
    // console.log("product",product);
    retrieveBalance();
  }, []);



  //meta title
  document.title = "Balance History | ShipCheap";

  return (
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ShipCheap" breadcrumbItem="Balance History" />
          {/* <Table columns={columns} data={data} /> */}
          <Card>
            <CardHeader className={"bg-light"}>
              <h6>Name: {user?user.first_name + " " + user.last_name:""}</h6>
              <h6>Email: {user?user.email:""}</h6>
            </CardHeader>
            <CardBody>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white'
              }}>
              </div>
              <DataTableExtensions
                {...tableData}
                // exportHeaders={true}
                filterPlaceholder={`Filter Balance History...`}
              >
                <DataTable columns={columns} data={filteredData} customStyles={customStyles} pagination={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationPerPage={30}
                           paginationRowsPerPageOptions={[30, 50, 100, 200, 500]}
                           highlightOnHover={true} />
              </DataTableExtensions>
            </CardBody>
          </Card>


          <OrderViewModal
            orderId={rowId}
            viewModal={viewModal}
            showPrice={false}
            setViewModal={(data) => { setViewModal(data) }}
          />

        </div>
      </div>
  );
}
UserBalanceHistory.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default UserBalanceHistory;
