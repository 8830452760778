import PropTypes from "prop-types";
import React, { useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import LoginFooter from "components/LoginFooter";
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";
import '../../assets/css/loginfooter.css'
// import namelogo from "assets/images/nullship-logo.png";
import namelogo from "assets/images/nullship_christmas_logo_uncropped.gif";
import img11 from "assets/images/img11.png";
import img12 from "assets/images/img12.png";
// import header background img
import bgimg from "assets/images/bgimg.png";



// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
// import logo from "../../assets/images/logo.svg";
// import logo from "../../assets/images/nullship-icon.png";
import logo from '../../assets/images/ainimationlogo.gif'
import { post } from "../../helpers/api_helper"
import config from "../../config"
import { Toaster } from "react-hot-toast"
import {toast} from "react-hot-toast";


// Images
import Logo from '../../assets/images/shipcheap.svg'
import LiningBg from '../../assets/images/lining-bg.svg'
import USPS from '../../assets/images/usps-service.svg'
import DHL from '../../assets/images/dhl-service.svg'
import UPS from '../../assets/images/ups-service.svg'

const ForgetPasswordPage = props => {

  //meta title
  document.title = "Forget Password | ShipCheap";

  const dispatch = useDispatch();

  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      dispatch(handleForgetPassword(values));
    }
  });

  const handleForgetPassword = async (values) => {
    try {
      // console.log("values", values)
      const { email } = values;
      let loading = toast.loading("Sending password reset email...");
      await post('/user/forget-password', { email: email }).then((response) => {
        // console.log("response", response);
        toast.remove(loading);
        if (response.status === 200) {
          setResponseSuccess(true);
          setResponseMessage(response.message);
          // props.history.push('/dashboard');
        }
        else {
          setResponseSuccess(false);
          setResponseMessage(response.message);
        }
      })
    }
    catch (e) {
      // console.log(e);
    }
  }

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  return (
    <React.Fragment>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      {/* <div style={{ backgroundImage: `url(${bgimg})`, width: "100%", marginBottom: "20px", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "95px", position: "fixed", top: "0", left: "0", zIndex: "999" }} className="navbar-header">
        <a href={`${config.main_web.URL}`}><img className="mx-5" height={"120px"}
          // width="230px"
                                                src={namelogo} alt="" /></a>
      </div> */}
      <div className={"col-md-12"}>
        <div className="col-xl-12 mx-auto">
          {/* <Card>
            <CardBody> */}
              <Row>
                <Col xl={6} lg={6} className="d-none d-sm-none d-md-none d-lg-block">
                    <div className="relative overflow-hidden h-[100vh] md:flex flex-col bg-gradient-to-tr from-[#070A1F] to-[#070A1F] i justify-between">
                      <div className="absolute -top-36 -left-36 w-4/5 h-4/5 bg-[#2F3885] rounded-full blur-[250px] z-0"></div>
                      <div className="px-20 mt-10 w-11/12 z-[1]">
                        <img src={Logo} alt="Null Ship Logo" className="mt-20 mb-6" width={300} />
                        <h1 className="text-white font-bold text-4xl">Welcome to Simpler Shipping</h1>
                        <p className="text-white text-1xl mt-3">Where your packages are our top priority. We pride ourselves on delivering reliability, speed, and satisfaction. Our commitment extends beyond borders, ensuring your shipments reach their destination with precision and care. Trust us to connect you to a world of possibilities.</p>
                      </div>
                      <div className="absolute top-0 bottom-0 right-0">
                        <img src={LiningBg} alt="Lining bg animation" />
                      </div>
                      <div className="shipping-group relative self-end mr-24 mb-20 w-[570px] h-[375px] ">
                        <div className="absolute bottom-[125px] right-[370px] z-[3]">
                          <img src={USPS} alt="service bg animation" />
                        </div>
                        <div className="absolute bottom-0 right-[180px] z-[2]">
                          <img src={DHL} alt="service bg animation" />
                        </div>
                        <div className="absolute top-0 right-[0px] z-[1]">
                          <img src={UPS} alt="service bg animation" />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} className="flex items-center form_div">
                  <div className="w-[320px] my-6 m-auto">
                  <CardBody className="pt-0">
                      {/* <div> */}
                      {/*<Link to="/">*/}
                      {/* <div className="avatar-md profile-user-wid mb-4"> */}
                      {/* <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            // className="rounded-circle"
                            height="34"
                          />
                        </span> */}
                      {/* </div> */}
                      {/*</Link>*/}
                      {/* </div> */}
                      <div className="text-center">
                        <h2 className="mb-3 text-4xl font-extrabold text-dark-gray-900 text-[#000]">Reset your password</h2>
                        <p className="mb-2 text-gray-700">Send reset instructions to your email address.</p>
                      </div>
                      <div className="p-2">
                        {responseSuccess && !responseSuccess ? (
                          <Alert color="danger" style={{ marginTop: "13px" }}>
                            {responseMessage}
                          </Alert>
                        ) : null}
                        {responseSuccess && responseSuccess ? (
                          <Alert color="success" style={{ marginTop: "13px" }}>
                            {responseMessage}
                          </Alert>
                        ) : null}

                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="custom_input"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="m-auto text-center">
                            {/* <Col className="text-center"> */}
                              <button
                                className="btn custom_btn btn-dark w-full m-auto btn-block ntb"
                                type="submit"
                              >
                                Reset
                              </button>
                            {/* </Col> */}
                          </div>
                        </Form>
                      </div>
                      <div className="mt-2 text-center">
                          <p>
                            Go back to{" "}
                            <Link to="login" className="fw-medium text-danger">
                              Login
                            </Link>{" "}
                          </p>
                          {/*<p>*/}
                          {/*  © {new Date().getFullYear()} Skote. Crafted with{" "}*/}
                          {/*  <i className="mdi mdi-heart text-danger" /> by Themesbrand*/}
                          {/*</p>*/}
                        </div>
                    </CardBody>
                  </div>
                  {/* <hr/> */}
                  
                </Col>
              </Row>
            {/* </CardBody>
          </Card> */}
        </div>
      </div>
      {/* <LoginFooter /> */}
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
