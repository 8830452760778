import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, DropdownItem, ButtonDropdown } from "reactstrap"
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

import { Notification as Noti} from "iconsax-react"

//i18n
import { withTranslation } from "react-i18next";
import { baseUrl, get, post } from "../../../helpers/api_helper"
import moment from "moment-timezone"
import { toast } from "react-hot-toast"
import JSZip from "jszip"
import axios from "axios"
import config from "../../../config";
import io from 'socket.io-client';
import { PDFDocument } from "pdf-lib"
const notifiedIds = [];
import {socket} from "../../../assets/js/initializeSocket";

let pdfBlobs = [];

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(null);
  const [isConnected, setIsConnected] = useState(socket.connected);

  const [print_dropdown, setPrintDropdown] = useState(false)

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const retrieveNotifications = async () => {
    // console.log("heree");
    const body = {
      id: JSON.parse(localStorage.getItem("authUser")).id
    };
    await post('/user/get-notifications-limited', body, { headers }).then(async (response) => {
      // console.log("response", response);
      if (response.status === 200) {
        let arr = [];
        for (let i=0;i<response.data.length;i++) {
          let found = arr.some(el => el.title == response.data[i].title && el.batch_id && el.batch_id == response.data[i].batch_id);
          // console.log("ffff",found);
          if(!found) {
            arr.push(response.data[i]);
          }
        }

        if(arr.length > 0 && arr[0].isNotified == 0) {
          // if(!notifiedIds.includes(arr[0].id)) {
            // Create a new notification
            // notifiedIds.push(arr[0].id);
            await updateNotifiedStatus();
            const notification = new Notification(arr[0].title, {
              body: arr[0].description,
              tag: arr[0].id
            });

          // }
          // console.log("notifiedIds",notifiedIds);
        }

        setNotifications(arr);
        let count = arr.filter(el => el.isOpened == 0).length;
        setNotificationCount(count>99?"99+":count);
      }
      else {
        // setResponseSuccess(false);
        // setResponseMessage(response.message);
      }
    })
  }

  useEffect(() => {
    // Check if the browser supports notifications
    // if(JSON.parse(localStorage.getItem("authUser")).role_id == 2) {
      if ("Notification" in window) {
        // Check if permission has been granted for notifications
        if (Notification.permission !== "granted") {
          // If not, ask the user for permission to show notifications
          Notification.requestPermission();
        }
      }

    if(JSON.parse(localStorage.getItem("authUser")).role_id == 1 || JSON.parse(localStorage.getItem("authUser")).role_id == 2) {
      // console.log("config.site.BASE_URL",config.site.BASE_URL_FOR_SOCKET);
      retrieveNotifications();

      async function onConnect() {
        await retrieveNotifications();
        setIsConnected(true);
      }

      async function onDisconnect() {
        await retrieveNotifications();
        setIsConnected(false);
      }

      async function onNotification() {
        // console.log("emit")
        await retrieveNotifications();
      }

      // async function refreshOrders() {
      //   // console.log("emit")
      //   localStorage.setItem("reload","true");
      // }

      // console.log("emiting join")
      socket.emit('join', JSON.parse(localStorage.getItem("authUser")).id);
      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.on('newNotification', onNotification);
      // socket.on('refreshOrders', refreshOrders);

      return () => {
        socket.off('connect', onConnect);
        socket.off('disconnect', onDisconnect);
        socket.off('newNotification', onNotification);
        // socket.off('refreshOrders', refreshOrders);
      };

      //for login
      // setInterval(() => {
      //   retrieveNotifications();
      // }, 3000);
    }

    // }

  },[])

  const handleDownload = async (files) => {
    if(files) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading("Compressing orders...")

      try {
        const zip = new JSZip();
        let notExistingFilesCount = 0;
        let downloadFiles = files.split(",");
        let order_ids = [];
        for (let i = 0; i < downloadFiles.length; i++) {
          await axios.get( `${baseUrl()}/user/get-pdf/${downloadFiles[i]}`, { headers, responseType: "blob" })
            .then(async (response) => {
              if(response.headers["file-name"]) {
                // console.log("response.headers",response.headers);
                const blob = new Blob([response.data], { type: 'application/pdf' });
                // const url = URL.createObjectURL(blob);
                // window.open(url, '_blank');
                await zip.file(`${response.headers["file-name"]}.pdf`, blob)
                order_ids.push(response.headers["order-id"]);
              }
              else if(response.data && response.data.status == 500) {
                notExistingFilesCount++;
              }
            }).catch((err) => {
              // console.log("Something went wrong, Please try again",err)
              document.body.style.pointerEvents = "auto"
              toast.remove(loading)
              toast.error("Something went wrong");
            });
        }

        let payload = {
          order_ids: order_ids.toString()
        }
        console.log("payload",payload)
        post('/user/mark-pdf-printed', payload, {headers}).then(async (response) => {
          if(Object.keys(zip.files).length > 0 ){
            document.body.style.pointerEvents = "auto"
            toast.remove(loading)
            let loading1 = toast.loading("Downloading orders...")
            if(notExistingFilesCount > 0) {
              toast.error(`${notExistingFilesCount} files do not exist`);
            }
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, "orders.zip");
              toast.remove(loading1)
            })
          }
          else {
            document.body.style.pointerEvents = "auto"
            toast.remove(loading)
            toast.error("Files do not exist. Please share your order number to admin for the PDF Label");
          }
        }).catch(async (errr) => {
          if(Object.keys(zip.files).length > 0 ){
            document.body.style.pointerEvents = "auto"
            toast.remove(loading)
            let loading1 = toast.loading("Downloading orders...")
            if(notExistingFilesCount > 0) {
              toast.error(`${notExistingFilesCount} files do not exist`);
            }
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, "orders.zip");
              toast.remove(loading1)
            })
          }
          else {
            document.body.style.pointerEvents = "auto"
            toast.remove(loading)
            toast.error("Files do not exist. Please share your order number to admin for the PDF Label");
          }
        })
      }
      catch (e) {
        toast.remove(loading)
        toast.error("Something went wrong");
      }
    }
  }


  async function mergePDFs(pdfBlobs) {
    const mergedPdf = await PDFDocument.create();

    for (const pdfBlob of pdfBlobs) {
      const pdfBytes = await pdfBlob.arrayBuffer();
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const mergedPdfBlob = await mergedPdf.save();

    return mergedPdfBlob;
  }

  const handleDownloadMerged = async (files) => {
    let downloadFiles = files.split(",");
    pdfBlobs = [];
    let order_ids = [];
    document.body.style.pointerEvents = "none"
    let loading = toast.loading("Compressing orders...")
    try {
      let notExistingFilesCount = 0;

      await Promise.all(downloadFiles.map(async (el) => {
        await axios.get(`${baseUrl()}/user/get-pdf/${el}`, { headers, responseType: "blob" })
          .then(async (response) => {
            if (response.headers["file-name"]) {
            // console.log("response",response);
              const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
              order_ids.push(response.headers["order-id"]);
              pdfBlobs.push(pdfBlob);
            }
            else {
              notExistingFilesCount++;
            }
          }).catch((err) => {
          // console.log("err",err);
          });
      })).then(async ()=> {
        if(notExistingFilesCount>0) {
          toast.error(notExistingFilesCount+ " pdf file do not exits");
        }
        document.body.style.pointerEvents = "auto"
        toast.remove(loading)
        let loading1 = toast.loading("Downloading orders...")
        const mergedBlob = await mergePDFs(pdfBlobs);
        const blob = new Blob([mergedBlob], { type: 'application/pdf' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'merged-labels.pdf';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();

        let payload = {
          order_ids: order_ids.toString()
        }
        console.log("payload",payload)
        post('/user/mark-pdf-printed', payload, {headers}).then(async (response) => {
          window.URL.revokeObjectURL(url);
          toast.remove(loading1)
        }).catch(async (errr) => {
          window.URL.revokeObjectURL(url);
          toast.remove(loading1)
        })

        // Clean up

        // const zip = new JSZip();
        // await zip.file(`merge-labels.pdf`, mergedBlob)
        // zip.generateAsync({ type: "blob" }).then(function (content) {
        //   toast.remove(loading1)
        //   saveAs(content, "orders.zip");
        // });
      })
    }
    catch (e) {
    // console.log("e",e);
      toast.remove(loading)
      toast.error("Something went wrong");
    }
  }

  const updateOpenedStatus = async () => {
    const body = {
      id: JSON.parse(localStorage.getItem("authUser")).id
    };
    await post('/user/update-notifications-opened', body, { headers }).then((response) => {
      // console.log("response", response);
      if (response.status === 200) {
        setNotificationCount(null);
      }
    })
  }

  const updateNotifiedStatus = async () => {
    const body = {
      id: JSON.parse(localStorage.getItem("authUser")).id
    };
    await post('/user/update-notifications-notified', body, { headers }).then((response) => {
      // console.log("response", response);
      if (response.status === 200) {
        setNotificationCount(null);
      }
    })
  }

  const handleRetry = async (id, failed_order_ids, carrier) => {
    // console.log("failed_order_ids",failed_order_ids)
    // console.log("carrier",carrier)

    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: failed_order_ids
    }

    post("/user/calculate-order-amount", body, { headers }).then(
      async responseTotal => {
        if (responseTotal.status === 200) {
          let price = 0
          let above5lbsPrice = 0;
          let under1lbsPrice = 0;

          if (responseTotal.data.length > 0) {
            price = await responseTotal.data
              .map(item => item.price)
              .reduce((prev, next) => prev + next)
          }

          if (responseTotal.dataAboveFiveLbs.length > 0) {
            above5lbsPrice = await responseTotal.dataAboveFiveLbs
              .map(item => item.price)
              .reduce((prev, next) => prev + next)
          }

          if (responseTotal.dataUnderOneLbs.length > 0) {
            under1lbsPrice = await responseTotal.dataUnderOneLbs
              .map(item => item.price)
              .reduce((prev, next) => prev + next)
          }

          let totalAmount = parseFloat(price) + parseFloat(above5lbsPrice) + parseFloat(under1lbsPrice);

          post('/user/check-if-order-completed-or-deleted', body, {headers}).then(async (response) => {
            // console.log("chekcresponse",response);
            if(response.status == 200) {
              if (response.order_ids.length > 0) {
                if (failed_order_ids.split(",").length > 0) {
                  if (carrier === "FedEx") {
                    // //FedEx
                    // console.log("printing fedex orders");
                    await fedExPrint(id, response.order_ids.toString(), totalAmount);
                  } else if (carrier === "UPS") {
                    // //UPS
                    // console.log("printing ups orders");
                    await upsPrint(id, response.order_ids.toString(), totalAmount);
                  } else if (carrier === "UPSv2") {
                    // //UPS
                    // console.log("printing upsv2 orders");
                    await upsV2Print(id, response.order_ids.toString(), totalAmount);
                  }
                  else if (carrier === "USPS") {
                    // //USPS
                    // console.log("printing usps orders");
                    await uspsPrint(id, response.order_ids.toString(), totalAmount);
                  }
                }
              } else {
                toast.success("The order you are trying to process are already processed or deleted.")
                let noti = {
                  id: id
                }
                post('/user/update-notification-retried', noti, {headers}).then((response) => {
                  // console.log("resp", response);
                })
              }
            }
          }).catch((err) => {
            // console.log("e",err)
          })
        }
      }
    )



  }

  const upsPrint = async (id, failed_order_ids, totalAmount) => {
    document.body.style.pointerEvents = "none";
    let loading = toast.loading("Processing Retry Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: failed_order_ids,
      carrier: 'UPS',
      totalAmount: totalAmount
    };

    //print-orders-ups-updated - new process
    //print-orders-ups - old process

    get('/user/active-ups-api', {headers}).then((apiResponse) => {
      if (apiResponse.status == 200) {
        let url = '';
        if (apiResponse.active_api == 'easypost') {
          url = '/user/print-orders-ups-updated';
        } else if (apiResponse.active_api == 'shipengine') {
          url = '/shipengine/create-shipment';
        }
        if (url) {
          // console.log("url",url);
          post(url, body, { headers }).then(async (response) => {
            document.body.style.pointerEvents = "auto";
            if (response.status === 200) {
              toast.remove(loading);
              toast.success(response.message);
            }
            else {
              toast.remove(loading);
              toast.error(response.message);
            }
            let noti = {
              id: id
            }
            post('/user/update-notification-retried', noti, {headers}).then((response) => {
              // console.log("resp", response);
            })
          }).catch((err) => {
            document.body.style.pointerEvents = "auto";
          });
        }
      }
    });


  }

  const fedExPrint = async (id, failed_order_ids, totalAmount) => {
    document.body.style.pointerEvents = "none";
    let loading = toast.loading("Processing Retry Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: failed_order_ids,
      totalAmount: totalAmount
    };
    post('/user/print-orders-fedex', body, { headers }).then(async (response) => {
      document.body.style.pointerEvents = "auto";
      if (response.status === 200) {
        toast.remove(loading);
        toast.success(response.message);
      }
      else {
        toast.remove(loading);
        toast.error(response.message);
      }
      let noti = {
        id: id
      }
      post('/user/update-notification-retried', noti, {headers}).then((response) => {
        // console.log("resp", response);
      })
    });
  }

  const upsV2Print = async (id, failed_order_ids, totalAmount) => {
    let loading = toast.loading("Processing Retry Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: failed_order_ids,
      totalAmount
    };
    post('/user/print-orders-ups-v2', body, { headers }).then(async (response) => {
      document.body.style.pointerEvents = "auto";
      if (response.status === 200) {
        toast.remove(loading);
        toast.success(response.message);
      }
      else {
        toast.remove(loading);
        toast.error(response.message);
      }
      let noti = {
        id: id
      }
      post('/user/update-notification-retried', noti, {headers}).then((response) => {
        // console.log("resp", response);
      })
    });
  }

  const uspsPrint = async (id, failed_order_ids, totalAmount) => {
    document.body.style.pointerEvents = "none";
    let loading = toast.loading("Processing Retry Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: failed_order_ids,
      totalAmount
    };
//use labelaxxess for live
//use updated for beta
    post('/user/print-orders-usps-updated', body, { headers }).then(async (response) => {
      document.body.style.pointerEvents = "auto";
      if (response.status === 200) {git
        toast.remove(loading);
        toast.success(response.message);
      }
      else {
        toast.remove(loading);
        toast.error(response.message);
      }
      let noti = {
        id: id
      }
      post('/user/update-notification-retried', noti, {headers}).then((response) => {
        // console.log("resp", response);
      })
    }).catch((err) => {
      document.body.style.pointerEvents = "auto";
      // console.log("errrr",err)
    });
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={async () => {
          setMenu(!menu)
          if(menu == false) {
            await updateOpenedStatus();
          }
        }}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          {/*<i id="bell" className={"bx bx-bell "+ (menu?"bx-tada":"")} />*/}
          <i><Noti size={23}/></i>
          {notificationCount!=0?
            <span className="badge bg-danger rounded-pill">{notificationCount}</span>
            :""}

        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              {/*<div className="col-auto">*/}
              {/*  <a href="/notifications" className="small">*/}
              {/*    {" "}*/}
              {/*    View All*/}
              {/*  </a>*/}
              {/*</div>*/}
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notifications.length>0?
              notifications.map((el, id) => (
                <div className="text-reset notification-item" key={id}>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">
                        {el.title}
                      </h6>
                      <div className="font-size-12 text-secondary">
                        {el.batch_id?
                          <p className="mb-1">
                            #{el.batch_id}
                          </p>
                          :""}
                        <p className="mb-1" title={el.description}>
                          {el.description.substring(0, 37)}{el.description.length > 37?"...":""}
                        </p>
                        {el.failed_order_ids && el.is_retried == 0?
                          <p className="mb-1 anchor" onClick={() => {handleRetry(el.id, el.failed_order_ids, el.carrier)}}>
                            Retry Failed Orders
                            {/*({el.failed_order_ids.split(",").length} orders)*/}
                          </p>
                          :""
                        }
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          {moment(el.created_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}{" "}
                          {el.carrier=="Admin"?<span className={"badge badge-success"} style={{float:"right"}}>ShipCheap Support</span>:null}
                        </p>
                      </div>
                    </div>
                    {el.files?
                      <><table>
                        <tr>
                          <td>
                            <div className="avatar-xs me-3" style={{cursor:"pointer"}} title={"Download Labels"} onClick={() => handleDownload(el.files)}>
                        <span className="avatar-title bg-warning rounded-circle font-size-16">
                          <i className="bx bx-download" />
                        </span>
                            </div>
                          </td>
                        </tr>
                        {el.files.split(",").length > 1 ?<><tr>
                          <td><div className="avatar-xs me-3" style={{cursor:"pointer"}} title={"Download Merged Labels"} onClick={() => handleDownloadMerged(el.files)}>
                        <span className="avatar-title bg-warning rounded-circle font-size-16">
                          <i className="bx bx-select-multiple" />
                        </span>
                          </div></td>
                        </tr>
                          </>:""}
                      </table>
                      </>
                      :""}
                  </div>
                </div>
              ))
              :<p className={"text-center"}>There are no notifications to display.</p>}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" style={{color:"#ff6600"}} to="/notifications">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};