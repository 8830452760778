// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import customStyles from "../../../assets/css/customTableStyle"


function Subscribers(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const fileInput = useRef(null);
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [pending, setPending] = useState(true);



  const columns = [
    {
      name: 'Email',
      // selector: 'email',
      selector: row=>row.email,

    //  sortable: true,
    },
    // {
    //   name: 'Status',
    //   selector: 'status',
    //   sortable: true,
    //   width: "120px",
    //   cell: row => row.status === 1 ? <span className={"badge badge-success"}>Active</span>:<span className={"badge badge-danger"}>Inactive</span>
    // },
    {
      name: 'Created At',
      selector: 'created_at',
     sortable: true,
      width: "160px",
      cell: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    // {
    //   name: 'Actions',
    //   selector: 'id',
    //   width: "160px",
    //   cell: (row) =>
    //     <>
    //       <button className={"btn btn-warning btn-sm waves-effect waves-light"} onClick={() => handleEdit(row.id)}>Edit</button>
    //       <button className={"btn btn-danger btn-sm waves-effect waves-light"} onClick={() => handleDelete(row.id)} style={{marginLeft:"10px"}}>Delete</button>
    //     </>
    // },
  ];

  // const tableData = ;
  const retrieveSubscribers = async () => {
    try {
      get('/admin/get-subscribers', {headers}).then((response) => {
        // // console.log("response", response);
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }


  useEffect(() => {
    // // console.log("here");
    retrieveSubscribers();
  }, []);

  //meta title
  document.title = "Subscribers | ShipCheap";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" breadcrumbItem="Subscribers" />
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Subscribers...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
Subscribers.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Subscribers;