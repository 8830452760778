module.exports = [
  {
    id: 'InfoReceived',
    value: 'Info Received',
    color: "#2e4871",
    hasChild: true
  },
  {
    id: 'InfoReceived_001',
    parent_id: 'InfoReceived',
    value: 'Info Received',
  },
  {
    id: 'InTransit',
    value: 'In Transit',
    color: "#4598d6",
    hasChild: true
  },
  {
    id: 'InTransit_001',
    parent_id: 'InTransit',
    value: 'In Transit'
  },
  {
    id: 'InTransit_002',
    parent_id: 'InTransit',
    value: 'Acceptance scan',
  },
  {
    id: 'InTransit_003',
    parent_id: 'InTransit',
    value: 'Arrival scan',
  },
  {
    id: 'InTransit_004',
    parent_id: 'InTransit',
    value: 'Arrived at the destination country/region',
  },
  {
    id: 'InTransit_005',
    parent_id: 'InTransit',
    value: 'Customs clearance completed',
  },
  {
    id: 'InTransit_006',
    parent_id: 'InTransit',
    value: 'Customs clearance started',
  },
  {
    id: 'InTransit_007',
    parent_id: 'InTransit',
    value: 'Departure Scan',
  },
  {
    id: 'InTransit_008',
    parent_id: 'InTransit',
    value: 'Problem resolved',
  },
  {
    id: 'InTransit_009',
    parent_id: 'InTransit',
    value: 'Forwarded to a different delivery address',
  },
  {
    id: 'OutForDelivery',
    value: 'Out for Delivery',
    color: "#e77f12",
    hasChild: true
  },
  {
    id: 'OutForDelivery_001',
    parent_id: 'OutForDelivery',
    value: 'Out for Delivery',
  },
  {
    id: 'OutForDelivery_003',
    parent_id: 'OutForDelivery',
    value: 'Customer contacted',
  },
  {
    id: 'OutForDelivery_004',
    parent_id: 'OutForDelivery',
    value: 'Delivery appointment scheduled',
  },
  {
    id: 'AttemptFail',
    value: 'Failed Attempt',
    color: "#9b59b6",
    hasChild: true
  },
  {
    id: 'AttemptFail_001',
    parent_id: 'AttemptFail',
    value: 'Failed Attempt',
  },
  {
    id: 'AttemptFail_002',
    parent_id: 'AttemptFail',
    value: 'Addressee not available',
  },
  {
    id: 'AttemptFail_003',
    parent_id: 'AttemptFail',
    value: 'Business Closed',
  },
  {
    id: 'Delivered',
    value: 'Delivered',
    color: "#4baa67",
    hasChild: true
  },
  {
    id: 'Delivered_001',
    parent_id: 'Delivered',
    value: 'Delivered',
  },
  {
    id: 'Delivered_002',
    parent_id: 'Delivered',
    value: 'Picked up by the customer',
  },
  {
    id: 'Delivered_003',
    parent_id: 'Delivered',
    value: 'Sign by customer',
  },
  {
    id: 'Delivered_004',
    parent_id: 'Delivered',
    value: 'Delivered and received cash on delivery',
  },
  {
    id: 'AvailableForPickup',
    value: 'Available for pickup',
    color: "#ef7414",
    hasChild: true
  },
  {
    id: 'AvailableForPickup_001',
    parent_id: 'AvailableForPickup',
    value: 'Available for pickup',
  },
  {
    id: 'Exception',
    value: 'Exception',
    color: "#b74432",
    hasChild: true
  },
  {
    id: 'Exception_001',
    parent_id: 'Exception',
    value: 'Exception',
  },
  {
    id: 'Exception_002',
    parent_id: 'Exception',
    value: 'Customer moved',
  },
  {
    id: 'Exception_003',
    parent_id: 'Exception',
    value: 'Customer refused delivery',
  },
  {
    id: 'Exception_004',
    parent_id: 'Exception',
    value: 'Delayed (Customs clearance)',
  },
  {
    id: 'Exception_005',
    parent_id: 'Exception',
    value: 'Delayed (External factors)',
  },
  {
    id: 'Exception_006',
    parent_id: 'Exception',
    value: 'Held for payment',
  },
  {
    id: 'Exception_007',
    parent_id: 'Exception',
    value: 'Incorrect Address',
  },
  {
    id: 'Exception_008',
    parent_id: 'Exception',
    value: 'Pick up missed',
  },
  {
    id: 'Exception_009',
    parent_id: 'Exception',
    value: 'Rejected by carrier',
  },
  {
    id: 'Exception_010',
    parent_id: 'Exception',
    value: 'Returning to sender',
  },
  {
    id: 'Exception_011',
    parent_id: 'Exception',
    value: 'Returned to sender',
  },
  {
    id: 'Exception_012',
    parent_id: 'Exception',
    value: 'Shipment damage',
  },
  {
    id: 'Exception_013',
    parent_id: 'Exception',
    value: 'Shipment lost',
  },
  {
    id: 'Expired',
    value: 'Expired',
    color: "#616e7d",
    hasChild: true
  },
  {
    id: 'Expired_001',
    parent_id: 'Expired',
    value: 'Expired',
  },
  {
    id: 'Pending',
    value: 'Pending',
    color: "#cccccc",
    hasChild: true
  },
  {
    id: 'Pending_001',
    parent_id: 'Pending',
    value: 'Pending',
  },
  {
    id: 'Pending_002',
    parent_id: 'Pending',
    value: 'Carrier account not connected',
  },
  {
    id: 'Pending_003',
    parent_id: 'Pending',
    value: 'Label created, no updates yet',
  },
  {
    id: 'Pending_004',
    parent_id: 'Pending',
    value: 'Wrong carrier',
  },
  {
    id: 'Pending_005',
    parent_id: 'Pending',
    value: 'No recent updates',
  },
  {
    id: 'Pending_006',
    parent_id: 'Pending',
    value: 'Unrecognized carrier',
  }
];;