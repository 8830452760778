import PropTypes from "prop-types";
import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import config from '../../config';
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import logo from "assets/images/nullship-icon.png";
// import namelogo from "assets/images/nullship-logo.png";
import namelogo from "assets/images/nullship_christmas_logo_uncropped.gif";
import img11 from "assets/images/img11.png";
import img12 from "assets/images/img12.png";
import anim_logo from '../../assets/images/ainimationlogo.gif'
// import header background img
import bgimg from "assets/images/bgimg.png";
import Recaptcha from "components/Recaptcha";
import LoginFooter from "components/LoginFooter";
import '../../assets/css/loginfooter.css'
//Import config
import { facebook, google } from "../../config";
import { post } from "../../helpers/api_helper"
import { Toaster } from "react-hot-toast"
import {toast} from "react-hot-toast";
import loader from '../../assets/images/loader.gif';
import { GoogleTagManager, initGA } from "../../assets/js/gtag"

// Images
import Logo from '../../assets/images/shipcheap.svg'
import LiningBg from '../../assets/images/lining-bg.svg'
import USPS from '../../assets/images/usps-service.svg'
import DHL from '../../assets/images/dhl-service.svg'
import UPS from '../../assets/images/ups-service.svg'

const Login = props => {
  const [responseSuccess, setResponseSuccess] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isShow, setIsShow] = useState(true);

  //meta title
  document.title = "Login | ShipCheap";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password")
    }),
    onSubmit: (values) => {
      handleLogin(values);
    }
  });

  useEffect(() => {
    document.body.setAttribute("data-layout-mode", "light")
    const urlParams = new URLSearchParams(window.location.search);
    if(localStorage.getItem("authUser")) {
      if (localStorage.getItem("authUser") && JSON.parse(localStorage.getItem("authUser")).role_id == 2) {
        if (urlParams.get('auth') == "shopify" && urlParams.get('hmac')) {
          window.location = config.site.BASE_URL + "/shopify/auth?shop=" + urlParams.get('shop');
          return true;
        }
      }
      window.location = '/dashboard';
    }

    if(config.site.MODE == "production") {
      // initGA();
      GoogleTagManager('UA-254226105-1');
      GoogleTagManager('G-YQ0XXFE0NR');
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsShow(true)
    document.body.style.visibility = "hidden";
    document.body.style.overflowY = "hidden";
    document.body.style.overflowX = "hidden";
    setTimeout(() => {
      document.body.style.visibility = "visible";
      setTimeout(() => {
        setIsShow(false)
        document.body.style.overflowY = "visible";
      },600)
    },500)
  },[])

  const handleLogin = async (values) => {
    try {
      if(recaptchaToken) {

        const {email, password} = values;
        document.body.style.pointerEvents = "none";
        let loading = toast.loading("Processing your request...");
        let body = {
          email: email,
          password: password,
          token: recaptchaToken
        }
        setIsShow(true)
        await post('/user/login', body).then((response) => {
          // console.log("response", response);
          toast.remove(loading);
          document.body.style.pointerEvents = "auto";
          if(response.status === 200) {
            localStorage.removeItem("authUser");
            localStorage.removeItem("logout-event");
            localStorage.removeItem("token");
            localStorage.setItem("authUser", JSON.stringify(response.user));
            // localStorage.setItem("gapi", response.google_api);
            localStorage.setItem("token", response.token);
            localStorage.setItem("store", "0")
            localStorage.setItem("skt", "false");
            if(response.user.role_id === 2) {
              localStorage.setItem("balance", response.user.balance);
              //Store 0 = Manual Store
              localStorage.setItem("store", 0);
            }
            else {
              localStorage.setItem("theme","light");
            }


            const urlParams = new URLSearchParams(window.location.search);
            if(urlParams.get('auth') == "shopify" && urlParams.get('hmac')) {
              window.location = config.site.BASE_URL + "/shopify/auth?shop=" + urlParams.get('shop');
            }
            else {
              props.history.push('/dashboard');
            }
          }
          else if(response.status === 300) {
            props.history.push(response.url);
          }
          else {
            setIsShow(false)
            setResponseSuccess(false);
            setResponseError(true);
            setResponseMessage(response.message);
          }
        })
      }
      else {
        toast.error("Please verify that you are not a robot")
      }
    }
    catch (e) {
      // console.log(e);
    }
  }

  const handleUpdate = (newValue) => {
    setRecaptchaToken(newValue)
  }

  return (
    <React.Fragment>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerClassName={"zin"}
      />
      <div id='loader' className={"align-items-center justify-content-center"} style={{display:(isShow?"flex":"none"),alignItems:"center",margin:"auto", position: "fixed",width:"100%",height:"100vh",backgroundColor:"white",zIndex:"99999",top:"0",left:"0"}}>
        <img width={120} src={loader}/>
      </div>
      {/* <div style={{ backgroundImage: `url(${bgimg})`, width: "100%", marginBottom: "20px", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "95px", position: "fixed", top: "0", left: "0", zIndex: "999" }} className="navbar-header">
        <a href={`${config.main_web.URL}`}><img className="mx-5" height={"120px"}
                                                // width="230px"
                                                src={namelogo} alt="" /></a>
      </div> */}
      <div className={"col-md-12"}>
        <div className="col-xl-12 mx-auto">
          {/* <Card>
            <CardBody> */}
              <Row>
                <Col xl={6} lg={6} className="d-none d-sm-none d-md-none d-lg-block">
                  <div className="relative overflow-hidden h-[100vh] md:flex flex-col bg-gradient-to-tr from-[#070A1F] to-[#070A1F] i justify-between">
                    <div className="absolute -top-36 -left-36 w-4/5 h-4/5 bg-[#2F3885] rounded-full blur-[250px] z-0"></div>
                    <div className="px-20 mt-10 w-11/12 z-[1]">
                      <img src={Logo} alt="Null Ship Logo" className="mt-20 mb-6" width={300} />
                      <h1 className="text-white font-bold text-4xl">Welcome to Simpler Shipping</h1>
                      <p className="text-white text-1xl mt-3">Where your packages are our top priority. We pride ourselves on delivering reliability, speed, and satisfaction. Our commitment extends beyond borders, ensuring your shipments reach their destination with precision and care. Trust us to connect you to a world of possibilities.</p>
                    </div>
                    <div className="absolute top-0 bottom-0 right-0">
                      <img src={LiningBg} alt="Lining bg animation" />
                    </div>
                    <div className="shipping-group relative self-end mr-24 mb-20 w-[570px] h-[375px] ">
                      <div className="absolute bottom-[125px] right-[370px] z-[3]">
                        <img src={USPS} alt="service bg animation" />
                      </div>
                      <div className="absolute bottom-0 right-[180px] z-[2]">
                        <img src={DHL} alt="service bg animation" />
                      </div>
                      <div className="absolute top-0 right-[0px] z-[1]">
                        <img src={UPS} alt="service bg animation" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} className="flex items-center form_div">
                  <div className="w-[320px] my-6 m-auto">
                      <CardBody className="pt-0">
                              <div className="text-center">
                                <h2 className="mb-3 text-4xl font-extrabold text-dark-gray-900 text-[#000]">Login</h2>
                                <p className="mb-2 text-gray-700">Enter your email and password</p>
                              </div>
                              <div className="p-2">
                                <Form
                                  className="form-horizontal"
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                  }}
                                >
                                  {responseError ? <Alert color="danger">{responseMessage}</Alert> : null}

                                  <div className="mb-3">
                                    <Label className="form-label">Email</Label>
                                    <Input
                                      name="email"
                                      className="custom_input"
                                      placeholder="Enter email"
                                      type="email"
                                      required={true}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.email || ""}
                                      invalid={
                                        validation.touched.email && validation.errors.email ? true : false
                                      }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                      <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                    ) : null}
                                  </div>

                                  <div className="mb-3">
                                    <Label className="form-label">Password</Label>
                                    <Input
                                      name="password"
                                      value={validation.values.password || ""}
                                      type="password"
                                      className="custom_input"
                                      required={true}
                                      placeholder="Enter Password"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.password && validation.errors.password ? true : false
                                      }
                                    />
                                    {validation.touched.password && validation.errors.password ? (
                                      <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                    ) : null}
                                  </div>
                                  <Row >
                                    <Col>
                                      <div className="form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customControlInline"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="customControlInline"
                                        >
                                          Keep me logged in
                                        </label>
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="text-center">
                                        <Link to="/forgot-password" className="text-muted">
                                          <p className="text-danger">Forgot your password? </p>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                  {/* <div className="g-recaptcha" data-sitekey={config.recaptcha.SITE_KEY}></div> */}
                                  <Row className="rec">
                                    <Col>
                                      <Recaptcha onUpdate={handleUpdate} />
                                    </Col>
                                  </Row>
                                  <div className="mt-3 d-grid">
                                    <button
                                      className="btn custom_btn btn-dark w-full m-auto btn-block ntb"
                                      type="submit"
                                    >
                                      Log In
                                    </button>
                                  </div>
                                </Form>
                              </div>
                              <div className="mt-2 text-center">
                                <p>
                                  Don&#39;t have an account ?{" "}
                                  <Link to="/register" className="fw-medium text-danger">
                                    {" "}
                                    Signup now{" "}
                                  </Link>{" "}
                                </p>
                              </div>
                            </CardBody>
                  </div>
                  {/* <hr/> */}
                  
                </Col>
              </Row>
            {/* </CardBody>
          </Card> */}
        </div>
      </div>
      {/* <LoginFooter /> */}
    </React.Fragment>
  )

  // return (
  //   <React.Fragment>
  //     <div className="home-btn d-none d-sm-block">
  //       <Link to="/" className="text-dark">
  //         <i className="bx bx-home h2" />
  //       </Link>
  //     </div>
  //     <div className="account-pages my-5 pt-sm-5">
  //       <Container>
  //         <Row className="justify-content-center">
  //           <Col md={8} lg={6} xl={5}>
  //             <Card className="overflow-hidden">
  //               <div className="bg-primary bg-soft">
  //                 <Row>
  //                   <Col xs={7}>
  //                     <div className="text-primary p-4">
  //                       <h5 className="text-primary">Welcome Back !</h5>
  //                       <p>Sign in to continue to NullShip.</p>
  //                     </div>
  //                   </Col>
  //                   <Col className="col-5 align-self-end">
  //                     <img src={profile} alt="" className="img-fluid" />
  //                   </Col>
  //                 </Row>
  //               </div>
  //               <CardBody className="pt-0">
  //                 <div>
  //                   <Link to="/" className="auth-logo-light">
  //                     <div className="avatar-md profile-user-wid mb-4">
  //                       <span className="avatar-title rounded-circle bg-light">
  //                         <img
  //                           src={logo}
  //                           alt=""
  //                           // className="rounded-circle"
  //                           height="34"
  //                         />
  //                       </span>
  //                     </div>
  //                   </Link>
  //                 </div>
  //                 <div className="p-2">
  //                   <Form
  //                     className="form-horizontal"
  //                     onSubmit={(e) => {
  //                       e.preventDefault();
  //                       validation.handleSubmit();
  //                       return false;
  //                     }}
  //                   >
  //                     {responseError ? <Alert color="danger">{responseMessage}</Alert> : null}
  //
  //                     <div className="mb-3">
  //                       <Label className="form-label">Email</Label>
  //                       <Input
  //                         name="email"
  //                         className="custom_input"
  //                         placeholder="Enter email"
  //                         type="email"
  //                         onChange={validation.handleChange}
  //                         onBlur={validation.handleBlur}
  //                         value={validation.values.email || ""}
  //                         invalid={
  //                           validation.touched.email && validation.errors.email ? true : false
  //                         }
  //                       />
  //                       {validation.touched.email && validation.errors.email ? (
  //                         <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
  //                       ) : null}
  //                     </div>
  //
  //                     <div className="mb-3">
  //                       <Label className="form-label">Password</Label>
  //                       <Input
  //                         name="password"
  //                         value={validation.values.password || ""}
  //                         type="password"
  //                         placeholder="Enter Password"
  //                         onChange={validation.handleChange}
  //                         onBlur={validation.handleBlur}
  //                         invalid={
  //                           validation.touched.password && validation.errors.password ? true : false
  //                         }
  //                       />
  //                       {validation.touched.password && validation.errors.password ? (
  //                         <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
  //                       ) : null}
  //                     </div>
  //
  //                     <div className="form-check">
  //                       <input
  //                         type="checkbox"
  //                         className="form-check-input"
  //                         id="customControlInline"
  //                       />
  //                       <label
  //                         className="form-check-label"
  //                         htmlFor="customControlInline"
  //                       >
  //                         Remember me
  //                       </label>
  //                     </div>
  //                     <div className="g-recaptcha" data-sitekey={config.recaptcha.SITE_KEY}></div>
  //
  //                     <div className="mt-3 d-grid">
  //                       <button
  //                         className="btn btn-primary btn-block"
  //                         type="submit"
  //                       >
  //                         Log In
  //                       </button>
  //                     </div>
  //
  //                     {/*<div className="mt-4 text-center">*/}
  //                     {/*  <h5 className="font-size-14 mb-3">Sign in with</h5>*/}
  //
  //                     {/*  <ul className="list-inline">*/}
  //                     {/*    <li className="list-inline-item">*/}
  //                     {/*      <FacebookLogin*/}
  //                     {/*        appId={facebook.APP_ID}*/}
  //                     {/*        autoLoad={false}*/}
  //                     {/*        callback={facebookResponse}*/}
  //                     {/*        render={renderProps => (*/}
  //                     {/*          <Link*/}
  //                     {/*            to="#"*/}
  //                     {/*            className="social-list-item bg-primary text-white border-primary"*/}
  //                     {/*            onClick={renderProps.onClick}*/}
  //                     {/*          >*/}
  //                     {/*            <i className="mdi mdi-facebook" />*/}
  //                     {/*          </Link>*/}
  //                     {/*        )}*/}
  //                     {/*      />*/}
  //                     {/*    </li>*/}
  //                     {/*    /!*<li className="list-inline-item">*!/*/}
  //                     {/*    /!*  <TwitterLogin*!/*/}
  //                     {/*    /!*    loginUrl={*!/*/}
  //                     {/*    /!*      "http://localhost:4000/api/v1/auth/twitter"*!/*/}
  //                     {/*    /!*    }*!/*/}
  //                     {/*    /!*    onSuccess={this.twitterResponse}*!/*/}
  //                     {/*    /!*    onFailure={this.onFailure}*!/*/}
  //                     {/*    /!*    requestTokenUrl={*!/*/}
  //                     {/*    /!*      "http://localhost:4000/api/v1/auth/twitter/revers"*!/*/}
  //                     {/*    /!*    }*!/*/}
  //                     {/*    /!*    showIcon={false}*!/*/}
  //                     {/*    /!*    tag={"div"}*!/*/}
  //                     {/*    /!*  >*!/*/}
  //                     {/*    /!*    <a*!/*/}
  //                     {/*    /!*      href=""*!/*/}
  //                     {/*    /!*      className="social-list-item bg-info text-white border-info"*!/*/}
  //                     {/*    /!*    >*!/*/}
  //                     {/*    /!*      <i className="mdi mdi-twitter"/>*!/*/}
  //                     {/*    /!*    </a>*!/*/}
  //                     {/*    /!*  </TwitterLogin>*!/*/}
  //                     {/*    /!*</li>*!/*/}
  //                     {/*    <li className="list-inline-item">*/}
  //                     {/*      <GoogleLogin*/}
  //                     {/*        clientId={google.CLIENT_ID}*/}
  //                     {/*        render={renderProps => (*/}
  //                     {/*          <Link*/}
  //                     {/*            to="#"*/}
  //                     {/*            className="social-list-item bg-danger text-white border-danger"*/}
  //                     {/*            onClick={renderProps.onClick}*/}
  //                     {/*          >*/}
  //                     {/*            <i className="mdi mdi-google" />*/}
  //                     {/*          </Link>*/}
  //                     {/*        )}*/}
  //                     {/*        onSuccess={googleResponse}*/}
  //                     {/*        onFailure={() => { }}*/}
  //                     {/*      />*/}
  //                     {/*    </li>*/}
  //                     {/*  </ul>*/}
  //                     {/*</div>*/}
  //
  //                     <div className="mt-4 text-center">
  //                       <Link to="/forgot-password" className="text-muted">
  //                         <i className="mdi mdi-lock me-1" />
  //                         Forgot your password?
  //                       </Link>
  //                     </div>
  //                   </Form>
  //                 </div>
  //               </CardBody>
  //             </Card>
  //             <div className="mt-5 text-center">
  //               <p>
  //                 Don&#39;t have an account ?{" "}
  //                 <Link to="/register" className="fw-medium text-primary">
  //                   {" "}
  //                   Signup now{" "}
  //                 </Link>{" "}
  //               </p>
  //               {/*<p>*/}
  //               {/*  © {new Date().getFullYear()} Skote. Crafted with{" "}*/}
  //               {/*  <i className="mdi mdi-heart text-danger" /> by Themesbrand*/}
  //               {/*</p>*/}
  //             </div>
  //           </Col>
  //         </Row>
  //       </Container>
  //     </div>
  //   </React.Fragment>
  // );
};

export default withRouter(Login);

// Login.propTypes = {
//   history: PropTypes.object,
// };
