// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { post, get, postFile, baseUrl } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions"
import { toast } from "react-hot-toast"
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from "../../../assets/templete/Order bulk import template.xlsx"
import axios from "axios"
import ups_logo from "../../../assets/images/ups-logo.png"
import JSZip from "jszip"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import { saveAs } from "file-saver"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  CardHeader,
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal"
import config from "../../../config"
import fedex from "../../../assets/images/fedex.png"
import ups from "../../../assets/images/ups-logo.png"
import usps from "../../../assets/images/usps-logo.png"
import dhl from "../../../assets/images/dhl-logo.png"
import logo from "../../../assets/images/nullship-logo.png"
import { amountFormat } from "../../../assets/js/numberFormatter"
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Line,
  Image,
  StyleSheet,
} from "@react-pdf/renderer"
import defaultLoader from '../../../assets/images/default_loader.gif'
import { initGA } from "../../../assets/js/gtag"
import customStyles from "../../../assets/css/customTableStyle"
function RejectedCancelledOrders(props) {
  const fileInput = useRef(null)
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }

  const dispatch = useDispatch()

  const [filteredData, setFilteredData] = useState([])
  const [tableData, setTableData] = useState([])
  const [pending, setPending] = useState(true)
  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)

  const [viewModal, setViewModal] = useState(false)
  const [loadingOrders, setLoadingOrders] = useState(false);

  const [rowId, setRowId] = useState();

  const [filterType, setFilterType] = useState(
    localStorage.getItem("order-filter")
      ? localStorage.getItem("order-filter")
      : "FedEx"
  )

  const columns = [
    {
      name: 'User',
      selector: row => row.email,
      sortable: true,
      width: "250px",
      cell: row => row.email !== null ? row.email : "-"
    },
    {
      name: "Service Name",
      selector: row => row.service_name,
      //  sortable: true,
      width: "180px",
    },
    {
      name: "Price",
      selector: row => row.discounted_price,
      //  sortable: true,
      width: "150px",
    },
    {
      name: "To Name",
      // selector: "to_name",
      //  sortable: true,
      // width: "160px",
      selector: row => (row.to_name ? row.to_name : "-"),
    },
    {
      name: "Tracking Code",
      // selector: ["tracking_code"],
      sortable: false,
      width: "260px",
      selector: row =>
        row.tracking_code ? (
          <>
            {/*<a*/}
            {/*  target={"_blank"}*/}
            {/*  rel={"noreferrer"}*/}
            {/*  href={`/track/${row.tracking_code}`}*/}
            {/*>*/}
            {row.tracking_code}
            {/*</a>*/}
            <span style={{ color: "#e3e3e3", cursor: "pointer",marginLeft:"5px" }} className={"bx bx-clipboard"} title={"Copy Tracking Number"} onClick={() => {navigator.clipboard.writeText(row.tracking_code);toast.success("Copied!")}}></span>
          </>
        ) : (
          "-"
        ),
    },
    // {
    //   name: "Cancellation Status",
    //   selector: ["cancel_status"],
    //   sortable: false,
    //   width: "180px",
    //   cell: row =>
    //     row.cancel_status ? (
    //       <span className={"badge badge-success"}>{row.cancel_status}</span>
    //     ) : (
    //       "-"
    //     ),
    // },
    {
      name: "Rejected At",
      // selector: "cancellation_rejected_at",
      sortable: true,
      width: "180px",
      selector: row => row.cancellation_rejected_at ? moment(row.cancellation_rejected_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A") : "-",
    },
    {
      name: "Actions",
      // selector: ["id", "order_id", "discounted_price", "order_unique_id", "user_id", "email"],
      //  sortable: true,
      width: "150px",
      button: true,
      // cell: row => row.shipping_details!==null?<><button className={"btn btn-success btn-sm"} onClick={() => {handleView(row.order_id) }}>View Order</button><button className={"btn btn-warning btn-sm"} onClick={() => window.open(JSON.parse(row.shipping_details).public_url, '_blank')} style={{marginLeft:"10px"}}>Track Order</button></>:<button className={"btn btn-success btn-sm"} onClick={() => {handleView(row.order_id) }}>View Order</button>
      selector: row => (
        <>
          <button title={"View Order Details"} style={{ marginRight: "10px" }}
                  className={"btn btn-warning btn-sm"}
                  onClick={() => {
                    setRowId(row.order_id)
                    setViewModal(true)
                  }}
          >
            <i className={"bx bx-list-ul"}></i>
          </button>
        </>
      ),
    },
    {
      name: '',
      // selector: 'order_unique_id',
      selector: row=>row.order_unique_id,
      width: "0px"
    },
  ];

  const retrieveRejectedCancelledOrders = async (type) => {
    localStorage.setItem("order-filter", type)
    let data = [];
    setFilteredData(data);
    setTableData({columns, data});
    setPending(true);
    setLoadingOrders(true);
    try {
      let body = {
        carrier: type
      }
      post("/admin/get-all-rejected-order-cancelled-by-carrier", body, { headers }).then(
        response => {
          setLoadingOrders(false);
          if(response.status === 200) {
            let data = response.data;
            // console.log("data",data);

            setFilteredData(data);
            setTableData({columns, data});

            setPending(false);
          }
        }
      )
    } catch (e) {
    }
  }

  useEffect(() => {
    retrieveRejectedCancelledOrders(filterType);
  }, [])

  const handleFedExOrders = async () => {
    if(!loadingOrders) {
      setPending(true);
      document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("FedEx")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveRejectedCancelledOrders("FedEx")
    }
  }

  const handleUPSOrders = async () => {
    if(!loadingOrders) {
      setPending(true);
      document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("UPS")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveRejectedCancelledOrders("UPS")
    }
  }

  const handleUPSv2Orders = async () => {
    if(!loadingOrders) {
      setPending(true);
      document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("UPSv2")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveRejectedCancelledOrders("UPSv2")
    }
  }

  const handleDHLOrders = async () => {
    if(!loadingOrders) {
      setPending(true);
      document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("DHL")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveRejectedCancelledOrders("DHL")
    }
  }

  const handleUSPSOrders = async () => {
    if(!loadingOrders) {
      setPending(true);
      document.getElementsByClassName("filter-text")[0].value = ""
      setFilterType("USPS")
      setFilteredData([]);
      let data = [];
      setTableData({ columns, data });
      await retrieveRejectedCancelledOrders("USPS")
    }
  }

  //meta title
  document.title = "Cancellation Rejected | NullShip"

  return (
    <div className="page-content" style={{position:"relative"}}>
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Cancellation Rejected" />
        {/* <Table columns={columns} data={data} /> */}
        <div
          style={{ width: "fit-content", background: "#E1E5E8" }}
          className="rounded-top"
        >
          <button
            className={
              "btn btn-md rounded-top " +
              (filterType === "FedEx" ? " bg-white text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)" }}
            onClick={handleFedExOrders}
            disabled={pending}
          >
            FedEx
          </button>
          <button
            className={
              "btn btn-md rounded-top " +
              (filterType === "UPS" ? " bg-white text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)" }}
            onClick={handleUPSOrders}
            disabled={pending}
          >
            UPS
          </button>
          <button
            className={
              "btn btn-md rounded-top  " +
              (filterType === "UPSv2" ? " bg-white text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)" }}
            onClick={handleUPSv2Orders}
            disabled={pending}
          >
            UPSv2
          </button>
          <button
            className={
              "btn btn-md rounded-top  " +
              (filterType === "DHL" ? " bg-white text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)" }}
            onClick={handleDHLOrders}
            disabled={pending}
          >
            DHL
          </button>
          <button
            className={
              "btn btn-md  " +
              (filterType === "USPS" ? " bg-white text-dark" : " text-secondary")
            }
            // disabled={loadingOrders}
            style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)" }}
            onClick={handleUSPSOrders}
            disabled={pending}
          >
            USPS
          </button>
        </div>
        <Card style={{borderRadius: "0px 3px 3px 3px"}}>
          <CardBody>
            {/*<h1>page {page}</h1>*/}
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Cancellation Rejected...`}
            >
              <DataTable
                columns={columns}
                data={filteredData} customStyles={customStyles}
                pagination={50}
                progressPending={pending}
                progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                paginationPerPage={50}
                paginationRowsPerPageOptions={[50, 100, 200, 500]}
                highlightOnHover={true}
                className={"order-table"}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>

        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          showPrice={false}
          setViewModal={data => {
            setViewModal(data)
          }}
        />

      </div>
    </div>
  )
}
RejectedCancelledOrders.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default RejectedCancelledOrders
