import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import config from '../config';
const SITE_KEY = config.recaptcha.V2_SITE_KEY;
const DELAY = 1200;

const Recaptcha = ({onUpdate}) => {
  const [callback, setCallback] = useState("not fired");
  const [value, setValue] = useState("[empty]");
  const [load, setLoad] = useState(false);
  const [expired, setExpired] = useState("false");
  const reCaptchaRef = React.createRef();

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, DELAY);
  // console.log("didMount - reCaptcha Ref-", reCaptchaRef);
  }, []);

  const handleChange = (value) => {
    // console.log("Captcha value:", value);
    setValue(value);
    onUpdate(value);
    // if value is null recaptcha expired
    if (value === null) setExpired("true");
  };

  const asyncScriptOnLoad = () => {
    setCallback("called!");
    // console.log("scriptLoad - reCaptcha Ref-", reCaptchaRef);
  };

  return (
    <div className="App" style={{  
      fontFamily: "sans-serif",
      textAlign: "center"}}>
      {load && (
        <ReCAPTCHA
          style={{ display: "inline-block" }}
          theme="black"
          ref={reCaptchaRef}
          sitekey={SITE_KEY}
          onChange={handleChange}
          asyncScriptOnLoad={asyncScriptOnLoad}
        />
      )}
    </div>
  );
};

export default Recaptcha;
