// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"
import Select from "react-select"


function AddDiscount(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [amount, setAmount] = useState(null);
  const [discountType, setDiscountType] = useState("Flat");
  const [amountAboveFive, setAmountAboveFive] = useState(null);
  const [amountUnderOne, setAmountUnderOne] = useState(null);
  const [discountTypeAboveFive, setDiscountTypeAboveFive] = useState("Flat");

  const [user_id, setUserId] = useState(null);
  const [data, setData] = useState([]);

  const [services, setServices] = useState([]);
  const [service_id, setServiceId] = useState(0);

  // const validation = useFormik({
  //   // enableReinitialize : use this flag when initial values needs to be changed
  //   enableReinitialize: true,
  //
  //   initialValues: {
  //     amount: '',
  //     user_id: ''
  //   },
  //   validationSchema: Yup.object({
  //     // carrierId: Yup.string().required("Please Enter Service Id"),
  //     // carrierName: Yup.string().required("Please Enter Service Name"),
  //     // serviceKey: Yup.string().required("Please Enter Service Key"),
  //     // price: Yup.string().required("Please Enter Price"),
  //     // description: Yup.string().required("Please Enter Description"),
  //     amount: Yup.string().required("Please Enter Amount"),
  //   }),
  //   onSubmit: (values) => {
  //     dispatch(handleAddDiscount(values));
  //   }
  // });

  const handleDiscountType = (e) => {
    // console.log(e.target.value);
    setDiscountType(e.target.value);
  }

  const retrieveUsers = async () => {
    try {
      get('/admin/getActiveUsers', {headers}).then((response) => {
        if(response.data.length > 0) {
          response.data.sort((a, b) => {
            const nameA = a.email.toLowerCase();
            const nameB = b.email.toLowerCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        }

        let users = [];
        for (let i=0;i<response.data.length;i++) {
          users.push({
            label: response.data[i].email,
            value: response.data[i].id
          })
        }

        setData(users);
        // console.log("response.data",response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveServices = async () => {
    try {
      get('/admin/get-services', {headers}).then((response) => {
        // // console.log("response", response);
        let arr = [];
        for (let i=0;i<response.data.length;i++) {
          if(response.data[i].status == 1) {
            arr.push(response.data[i]);
          }
        }
        setServices(arr);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }



  useEffect(() => {
    retrieveUsers();
    retrieveServices();
  }, []);


  // const tableData = ;
  const handleAddDiscount = async (values) => {
    try {
      // const {amount, user_id} = values;

      // // console.log("amount", amount, "user", user_id);

      if(user_id && amount && discountType && amountAboveFive && amountUnderOne) {
        if(discountType !== "None" && amount == "" || amount == "0") {
          toast.error("Discount must be greater than 0");
        }
        else {
          if(discountType === "Percent" && amount > 100) {
            toast.error("Discount percentage must be lesser than 100");
            return true;
          }

          const body = {
            amount: amount,
            discountType: discountType,
            amountAboveFive: amountAboveFive,
            amountUnderOne: amountUnderOne,
            // discountTypeAboveFive: discountTypeAboveFive,
            user_id: user_id.value,
            service_id: service_id,
            type: "User"
          };

          post('/admin/add-discount', body, {headers}).then((response) => {
            if(response.status === 200) {
              toast.success(response.message);
              setTimeout(() => {
                props.history.push("/discounts");
              },2000)
            }
            else {
              toast.error(response.message);
            }
          })
        }
      }
      else {
        toast.error("Please select all required fields");
      }

    }
    catch (e) {
      // console.log(e)
    }
  }


  //meta title
  document.title = "Add Discount | ShipCheap";


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" sourceItem="Discounts" breadcrumbItem="Add Discount" />
        {/* <Table columns={columns} data={data} /> */}
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody>
                  <CardTitle className="mb-4">Discount Information</CardTitle>
                  <div className="">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleAddDiscount();
                        // validation.handleSubmit();
                        // return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">User</Label>
                        <Select
                          options={data}
                          onChange={(selectedGroup) => {
                          // console.log("selectedGroup",selectedGroup);
                            setUserId(selectedGroup)
                          }}
                          className="select2-selection"
                        />
                        {/*<select*/}
                        {/*  name={"user_id"}*/}
                        {/*  className="form-select"*/}
                        {/*  onChange={(e) => setUserId(e.target.value)}*/}
                        {/*  required={true}*/}
                        {/*>*/}
                        {/*  <option disabled={true} selected={true} value={""}>Select a user</option>*/}
                        {/*  {data.map((part, id) => {*/}
                        {/*    return (<option key={id} value={part.id}>ID #{part.id} - {part.first_name + " " + part.last_name} ({part.email})</option>);*/}
                        {/*  })};*/}
                        {/*</select>*/}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Service</Label>
                        <select
                          name={"service_id"}
                          className="form-select"
                          onChange={(e) => setServiceId(e.target.value)}
                          required={true}
                        >
                          {/*<option selected={true} value={0}>All Services</option>*/}
                          <option selected={true} value={""}>Select a services</option>
                          {services.sort((a, b) => {
                            const nameA = a.name.toLowerCase();
                            const nameB = b.name.toLowerCase();

                            if (nameA < nameB) {
                              return -1;
                            }
                            if (nameA > nameB) {
                              return 1;
                            }
                            return 0;
                          }).map((part, id) => {
                            return (<option key={id} value={part.id}>{part.name}</option>);
                          })};
                        </select>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Discount Type</Label>
                        <select
                          className="form-select"
                          name="discountType"
                          // onChange={validation.handleChange}
                          onChange={handleDiscountType}
                          required={true}
                        >
                          {/*<option>None</option>*/}
                          <option value={"Flat"}>Flat</option>
                          <option value={"Percent"}>Percent</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Amount</Label>
                        <Input
                          name="amount"
                          className="form-control non-arrow-number"
                          placeholder="Enter Discount Amount"
                          type="number"
                          step="any"
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) => setAmount(e.target.value)}
                          max={discountType==="Percent"?100:""}
                          required={true}
                        />
                      </div>
                      {/*<div className="mb-3">*/}
                      {/*  <Label className="form-label">Discount Type (Above 5 lbs)</Label>*/}
                      {/*  <select*/}
                      {/*    className="form-select"*/}
                      {/*    name="discountType"*/}
                      {/*    // onChange={validation.handleChange}*/}
                      {/*    onChange={(e) => setDiscountTypeAboveFive(e.target.value)}*/}
                      {/*    required={true}*/}
                      {/*  >*/}
                      {/*    /!*<option>None</option>*!/*/}
                      {/*    <option value={"Flat"}>Flat</option>*/}
                      {/*    <option value={"Percent"}>Percent</option>*/}
                      {/*  </select>*/}
                      {/*</div>*/}
                      <div className="mb-3">
                        <Label className="form-label">Amount (Above 5+ lbs)</Label>
                        <Input
                          name="abovefive"
                          className="form-control non-arrow-number"
                          placeholder="Enter Discount Amount (Above 5+ lbs)"
                          type="number"
                          step="any"
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) => setAmountAboveFive(e.target.value)}
                          max={discountType==="Percent"?100:""}
                          required={true}
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Amount (Above 1- lbs)</Label>
                        <Input
                          name="underone"
                          className="form-control non-arrow-number"
                          placeholder="Enter Discount Amount (Above 1- lbs)"
                          type="number"
                          step="any"
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) => setAmountUnderOne(e.target.value)}
                          max={discountType==="Percent"?100:""}
                          required={true}
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <Button type="button" onClick={() => props.history.push('/discounts') } color="danger" style={{marginRight:"10px"}}>
                            Cancel
                          </Button>
                          <button
                            className="btn btn-success w-md "
                            type="submit"
                          >
                            Save
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
      </div>
    </div>
  );
}
AddDiscount.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default AddDiscount;