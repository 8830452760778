// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { post, get, postFile, baseUrl } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions"
import { toast } from "react-hot-toast"
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from "../../../assets/templete/Order bulk import template.csv"
import templateItemCsv from "../../../assets/templete/Order with items bulk import template.csv"
import templateXlsx from "../../../assets/templete/Order bulk import template.xlsx"
import templateXls from "../../../assets/templete/Order bulk import xls template.xls"
import axios from "axios"
import ups_logo from "../../../assets/images/ups-logo.png"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import customStyles from "../../../assets/css/customTableStyle";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  CardHeader,
  ButtonDropdown,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal"
import fedex from "../../../assets/images/fedex.png"
import ups from "../../../assets/images/ups-logo.png"
import usps from "../../../assets/images/usps-logo.png"
import dhl from "../../../assets/images/dhl-logo.png"
import { amountFormat } from "../../../assets/js/numberFormatter"
import CustomFilters from "../../../components/CustomFilters/index";
import CustomMultiselectFilters from "../../../components/CustomMultiselectFilters/index";

import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Line,
  Image,
  StyleSheet,
} from "@react-pdf/renderer"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"
import config from "../../../config"
import io from "socket.io-client"

let selectedOrders = [];
let selectedOrderJson = [];

let selectedTags = [];

let hideShowItemName = false;
let hideShowServiceName = false;
let hideShowFromName = false;
let hideShowToName = false;
let hideShowNotes = false;
let hideShowTags = false;
let hideShowOrderNumber = false;
let hideShowPackageNumber = false;
let hideShowOrderDate = false;
let hideShowUpdatedAt = false;
let hideShowDimension = false;
let hideShowWeight = false;
let hideShowStatus = false;
let hideShowItemNames = false;
let hideShowProductIds = false;

let hideShowZenventory = true;

let hideShowPicklists = false;

let hideShowChannelNumber = false;

let tempFilterData = [];

let sideBarData = [];

let clickedSideBarJson = [];

let selectedGroupBy = "";

let validationTimeout = 3;

let picklistFilter = [];

import {socket} from "../../../assets/js/initializeSocket";

function AwaitingShipment(props) {
  let fileInput = useRef(null)
  let fileInputItems = useRef(null)
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }

  const dispatch = useDispatch()

  const [mergedPackingSlip, setMergedPackingSlip] = useState([]);

  const [responseSuccess, setResponseSuccess] = useState(null)
  const [responseMessage, setResponseMessage] = useState("")

  const [failedOrders, setFailedOrders] = useState([])
  const [isRetry, setIsRetry] = useState(false)

  const [allData, setAllData] = useState([])

  const [selectedOrdersCount, setSelectedOrdersCount] = useState(0);

  const [filteredData, setFilteredData] = useState([])
  const [tableData, setTableData] = useState(null)
  const [modal, setModal] = useState(false)
  const [modalItems, setModalItems] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [orderDetailsModal, setOrderDetailsModal] = useState(false)

  const [services, setServices] = useState([])
  const [selectedService, setSelectedService] = useState("")
  const [selectedServiceName, setSelectedServiceName] = useState(null)
  const [selectAvailableService, setSelectAvailableService] = useState(false)

  const [carriers, setCarriers] = useState([])
  const [selectedCarrier, setSelectedCarrier] = useState(null)
  const [selectedCarrierName, setSelectedCarrierName] = useState(null)
  const [selectAvailableCarrier, setSelectAvailableCarrier] = useState(false)

  const [selectedMeasurement, setSelectedMeasurement] = useState("pounds");

  const [balance, setBalance] = useState("-")

  const [calculatedAmounts, setCalculatedAmounts] = useState([])
  const [calculatedAmountsAboveFiveLbs, setCalculatedAmountsAboveFiveLbs] = useState([])
  const [calculatedAmountsUnderOneLbs, setCalculatedAmountsUnderOneLbs] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  const [filterType, setFilterType] = useState(localStorage.getItem("order-filter")?localStorage.getItem("order-filter"):"UPS")
  const [pending, setPending] = useState(true)

  const [drp_link, setdrp_link] = useState(false)
  const [print_dropdown, setPrintDropdown] = useState(false)

  const [verificationModal, setVerificationModal] = useState(false)
  const [customVerificationModal, setCustomVerificationModal] = useState(false)

  const [orderErrors, setOrderErrors] = useState([])
  const [orderErrorModal, setOrderErrorModal] = useState(false)

  const [storeModal, setStoreModal] = useState(false)

  const [tagsModal, setTagsModal] = useState(false)
  const [tags, setTags] = useState([]);
  const [tagsForFilter, setTagsForFilter] = useState([]);
  const [picklists, setPicklists] = useState([]);
  const [tag, setTag] = useState("")
  const [tagColor, setTagColor] = useState("#e3e3e3")

  const [rowId, setRowId] = useState()

  const [stores, setStores] = useState([])
  const [selectedStore, setSelectedStore] = useState(null)
  const [clickedJson, setClickedJson] = useState([]);
  const [aboveFiveLbsOrders, setAboveFiveLbsOrders] = useState([]);
  const [belowFiveLbsOrders, setBelowFiveLbsOrders] = useState([]);
  const [underOneLbsOrders, setUnderOneLbsOrders] = useState([]);
  // const [clickedSideBarJson, setClickedSideBarJson] = useState([]);
  const [singleOrderWeight, setSingleOrderWeight] = useState("")
  const [SingleOrderServiceId, setSingleOrderServiceId] = useState("")
  const [singleOrderWidth, setSingleOrderWidth] = useState("")
  const [singleOrderHeight, setSingleOrderHeight] = useState("")
  const [singleOrderLength, setSingleOrderLength] = useState("")
  const [singleOrderRate, setSingleOrderRate] = useState("")
  const [singleIsDimenssion, setSingleIsDimenssion] = useState(false)
  const [orderUniqueId, setOrderUniqueId] = useState(null)

  const [serviceRateBelowFiveLbs, setServiceRateBelowFiveLbs] = useState("")
  const [serviceRateAboveFiveLbs, setServiceRateAboveFiveLbs] = useState("")
  const [serviceRateUnderOneLbs, setServiceRateUnderOneLbs] = useState("")

  const [underOneLbsService, setUnderOneLbsService] = useState("")
  const [belowFiveLbsService, setBelowFiveLbsService] = useState("")
  const [aboveFiveLbsService, setAboveFiveLbsService] = useState("")

  const [bulkUpdateServiceId, setBulkUpdateServiceId] = useState("");
  const [enableBulkUpdateToPhone, setEnableBulkUpdateToPhone] = useState(false);
  const [bulkUpdateToPhone, setBulkUpdateToPhone] = useState("");
  const [clickedBulkUpdateJson, setClickedBulkUpdateJson] = useState([]);

  const [showFilters, setShowFilters] = useState(false);
  //Sort states
  const [nameSort, setNameSort] = useState("");
  const [weightSort, setWeightSort] = useState("");
  const [quantitySort, setQuantitySort] = useState("");
  const [filterShipment, setFilterShipment] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [tagFilter, setTagFilter] = useState("");
  // const [picklistFilter, setPicklistFilter] = useState("");
  const [validationFilter, setValidationFilter] = useState("");

  //Count states
  const [unassignedOrderCount, setUnassignedOrderCount] = useState("-");
  const [fedexOrderCount, setFedexOrderCount] = useState("-");
  const [upsOrderCount, setUpsOrder] = useState("-");
  const [upsv2OrderCount, setUpsv2Order] = useState("-");
  const [dhlOrderCount, setDhlOrder] = useState("-");
  const [uspsOrderCount, setUspsOrder] = useState("-");

  const [fromBuyerNotesModal, setFromBuyerNotesModal] = useState(false);
  const [toBuyerNotesModal, setToBuyerNotesModal] = useState(false);
  const [internalNotesModal, setInternalBuyerNotesModal] = useState(false);

  const [orderItemInfoModal, setOrderItemInfoModal] = useState(false);
  const [orderItemType, setOrderItemType] = useState("");
  const [orderItemData, setOrderItemData] = useState("");

  const [notesOrderId, setNotesOrderId] = useState("");
  const [fromBuyerNotes, setFromBuyerNotes] = useState(false);
  const [toBuyerNotes, setToBuyerNotes] = useState(false);
  const [internalNotes, setInternalBuyerNotes] = useState(false);

  const [hideShowColumns, setHideShowColumns] = useState(false);

  const [isFullTextVisible, setIsFullTextVisible] = useState(false);
  const [isFullTextVisibleBelow, setIsFullTextVisibleBelow] = useState(false);
  const [isFullTextVisibleUnderOne, setIsFullTextVisibleUnderOne] = useState(false);
  const [isFullTextVisible1, setIsFullTextVisible1] = useState(false);

  const [hideShowGroupBy, setHideShowGroupBy] = useState(false);
  // const [selectedGroupBy, setSelectedGroupBy] = useState("");
  const [groupByDataTables, setGroupByDataTables] = useState([]);
  const [groupByDataTablesAll, setGroupByDataTablesAll] = useState([]);
  const [pendingGroupBy, setPendingGroupBy] = useState(false);

  // const [hideShowServiceName, setHideShowServiceName] = useState(false);
  // const [hideShowFromName, setHideShowFromName] = useState(false);
  // const [hideShowToName, setHideShowToName] = useState(false);
  // const [hideShowNotes, setHideShowNotes] = useState(false);
  // const [hideShowTags, setHideShowTags] = useState(false);
  // const [hideShowOrderNumber, setHideShowOrderNumber] = useState(false);
  // const [hideShowUpdatedAt, setHideShowUpdatedAt] = useState(false);
  // const [hideShowStatus, setHideShowStatus] = useState(false);

  const [showSideBar, setShowSideBar] = useState(false);

  const [valTime, setValTime] = useState(3);

  const columns = [
    {
      name: <input type={"checkbox"} title={"Select All"} className={"select-all-checkbox"}
                   id={"select-all-checkbox"} onChange={checkAll}/>,
      selector: ["id", "name"],
      // sortable: true,
      width: "48px",
      cell: row => <input type={"checkbox"} className={"order-checkbox"} id={`order-${row.id}`} value={row.id}
                          name="order_id" onChange={handleOrderCheck}/>
    },
    {
      name: <span style={{visibility:"hidden"}}>...</span>,
      selector: "created_at",
      sortable: true,
      width: "100px",
      minWidth: "80px",
      maxWidth: "140px",
      reorder: true,
      style: {
        color: "#FF6600", fontWeight: "500", cursor: "pointer"
      },
      cell: (row) => {
        const date1 = moment(row.created_at.toString().substring(0,10)).tz(moment.tz.guess()).toDate();
        const date2 = moment(new Date());
        // console.log("date1",date1);
        // console.log("date2",date2);
        // console.log("")
        return (date2.diff(date1, 'days') + " days")
      }
    },
    {
      name: "Items",
      selector: ["store_type", "custom_items_count"],
      sortable: true,
      width: "100px",
      reorder: true,
      cell: row => row.custom_items_count?row.custom_items_count + (parseInt(row.custom_items_count)>1?" Items":" Item"):"0 Items",
      omit: hideShowItemName
    },
    {
      name: "Item Names",
      selector: ["store_type", "custom_items_data"],
      sortable: true,
      width: "200px",
      reorder: true,
      cell: row => {
        if(row.custom_items_data && JSON.parse(row.store_type == "ebay" ?JSON.parse(row.custom_items_data):row.custom_items_data).length > 0) {
          if(row.store_type == null) {
            let data = JSON.parse(row.custom_items_data);
            let finalData = data.map((el, id) => el.description + (id != (data.length - 1) ? ", ": ""));
            return <span onClick={() => {
              setOrderItemType("Item Names" + (row.order_number?" of " + row.order_number:""));
              setOrderItemData(finalData);
              setOrderItemInfoModal(true);
            }} style={{cursor:"pointer"}}>{finalData}</span>
            // width:"180px",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis",
          }
          else if(row.store_type == "shopify") {
            let data = JSON.parse(row.custom_items_data);
            let finalData = data.map((el, id) => el.name + (id != (data.length - 1) ? ", ": ""))
            return <span onClick={() => {
              setOrderItemType("Item Names" + (row.order_number?" of " + row.order_number:""));
              setOrderItemData(finalData);
              setOrderItemInfoModal(true);
            }} style={{cursor:"pointer"}}>{finalData}</span>
          }
          else if(row.store_type == "ebay") {
            let data = JSON.parse(JSON.parse(row.custom_items_data));
            let finalData = data.map((el, id) => el.title + (id != (data.length - 1) ? ", ": ""))
            return <span onClick={() => {
              setOrderItemType("Item Names" + (row.order_number?" of " + row.order_number:""));
              setOrderItemData(finalData);
              setOrderItemInfoModal(true);
            }} style={{cursor:"pointer"}}>{finalData}</span>
          }
          else if(row.store_type == "walmart") {
            let data = JSON.parse(row.custom_items_data);
            let finalData = data.map((el, id) => el.item.productName + (id != (data.length - 1) ? ", ": ""))
            return <span onClick={() => {
              setOrderItemType("Item Names" + (row.order_number?" of " + row.order_number:""));
              setOrderItemData(finalData);
              setOrderItemInfoModal(true);
            }} style={{cursor:"pointer"}}>{finalData}</span>
          }
          else if(row.store_type == "zenventory") {
            let data = JSON.parse(row.custom_items_data);
            let finalData = data.map((el, id) => el.itemmaster.description + (id != (data.length - 1) ? ", ": ""))
            return <span onClick={() => {
              setOrderItemType("Item Names" + (row.order_number?" of " + row.order_number:""));
              setOrderItemData(finalData);
              setOrderItemInfoModal(true);
            }} style={{cursor:"pointer"}}>{finalData}</span>
          }
          else if(row.store_type == "sellercloud") {
            let data = JSON.parse(row.custom_items_data);
            let finalData = data.map((el, id) => el.DisplayName + (id != (data.length - 1) ? ", ": ""))
            return <span onClick={() => {
              setOrderItemType("Item Names" + (row.order_number?" of " + row.order_number:""));
              setOrderItemData(finalData);
              setOrderItemInfoModal(true);
            }} style={{cursor:"pointer"}}>{finalData}</span>
          }
          else if(row.store_type == "shipstation") {
            let data = JSON.parse(row.custom_items_data);
            let finalData = data.map((el, id) => el.name + (id != (data.length - 1) ? ", ": ""))
            return <span onClick={() => {
              setOrderItemType("Item Names" + (row.order_number?" of " + row.order_number:""));
              setOrderItemData(finalData);
              setOrderItemInfoModal(true);
            }} style={{cursor:"pointer"}}>{finalData}</span>
          }
        }
        else {
          return "-"
        }
      },
      omit: hideShowItemNames
    },
    {
      name: "Product IDs",
      selector: ["store_type", "custom_items_data"],
      sortable: true,
      width: "200px",
      reorder: true,
      cell: row => {
        if(row.custom_items_data && JSON.parse(row.store_type == "ebay" ?JSON.parse(row.custom_items_data):row.custom_items_data).length > 0) {
          if(row.store_type == null) {
            let data = JSON.parse(row.custom_items_data);
            let finalData = data.map((el, id) => el.product_identifier + (id != (data.length - 1) ? ", ": ""));
            return <span onClick={() => {
              setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
              setOrderItemData(finalData);
              setOrderItemInfoModal(true);
            }} style={{cursor:"pointer"}}>{finalData}</span>
          }
          else if(row.store_type == "shopify") {
            let data = JSON.parse(row.custom_items_data);
            let finalData = data.map((el, id) => el.id + (id != (data.length - 1) ? ", ": ""))
            return <span onClick={() => {
              setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
              setOrderItemData(finalData);
              setOrderItemInfoModal(true);
            }} style={{cursor:"pointer"}}>{finalData}</span>
          }
          else if(row.store_type == "ebay") {
            let data = JSON.parse(JSON.parse(row.custom_items_data));
            let finalData = data.map((el, id) => el.lineItemId + (id != (data.length - 1) ? ", ": ""))
            return <span onClick={() => {
              setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
              setOrderItemData(finalData);
              setOrderItemInfoModal(true);
            }} style={{cursor:"pointer"}}>{finalData}</span>
          }
          else if(row.store_type == "walmart") {
            let data = JSON.parse(row.custom_items_data);
            let finalData = data.map((el, id) => el.item.sku + (id != (data.length - 1) ? ", ": ""))
            return <span onClick={() => {
              setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
              setOrderItemData(finalData);
              setOrderItemInfoModal(true);
            }} style={{cursor:"pointer"}}>{finalData}</span>
          }
          else if(row.store_type == "zenventory") {
            let data = JSON.parse(row.custom_items_data);
            let finalData = data.map((el, id) => el.customerorderitemid + (id != (data.length - 1) ? ", ": ""))
            return <span onClick={() => {
              setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
              setOrderItemData(finalData);
              setOrderItemInfoModal(true);
            }} style={{cursor:"pointer"}}>{finalData}</span>
          }
          else if(row.store_type == "sellercloud") {
            let data = JSON.parse(row.custom_items_data);
            let finalData = data.map((el, id) => el.ProductID + (id != (data.length - 1) ? ", ": ""))
            return <span onClick={() => {
              setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
              setOrderItemData(finalData);
              setOrderItemInfoModal(true);
            }} style={{cursor:"pointer"}}>{finalData}</span>
          }
          else if(row.store_type == "shipstation") {
            let data = JSON.parse(row.custom_items_data);
            let finalData = data.map((el, id) => el.orderItemId + (id != (data.length - 1) ? ", ": ""))
            return <span onClick={() => {
              setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
              setOrderItemData(finalData);
              setOrderItemInfoModal(true);
            }} style={{cursor:"pointer"}}>{finalData}</span>
          }
        }
        else {
          return "-"
        }
      },
      omit: hideShowProductIds
    },
    {
      name: "Service Name",
      selector: "name",
      sortable: true,
      width: "170px",
      reorder: true,
      cell: row => row.name ? row.name : <span style={{ color: "red" }}>Not Assigned</span>,
      omit: hideShowServiceName
    },
    {
      name: "From Name",
      selector: ["from_name","is_from_address_verified"],
      sortable: true,
      width: "170px",
      reorder: true,
      cell: row => row.from_name ? <>{row.is_from_address_verified==0?
        <i title={"Address Validation Failed"} className={"bx bx-info-circle"} style={{color:"red",marginRight:"5px",cursor:"pointer"}}/>
        :(row.is_from_address_verified==3?
            null
          // <i title={"Validation In Progress"} className={"bx bx-loader-alt spin"} style={{marginRight:"5px",cursor:"pointer"}}/>
          :(row.is_from_address_verified==1?
              <i title={"Address Validation Passed"} className={"bx bx-check-circle"} style={{color:"#02ad30",marginRight:"5px",cursor:"pointer"}}/>:
              null)
        )
      } {row.from_name}</> : "-",
      omit: hideShowFromName
    },
    {
      name: "To Name",
      selector: ["to_name", "is_to_address_verified"],
      sortable: true,
      width: "170px",
      reorder: true,
      cell: row => row.to_name ? <>{row.is_to_address_verified==0?
        <i title={"Address Validation Failed"} className={"bx bx-info-circle"} style={{color:"red",marginRight:"5px",cursor:"pointer"}}/>
        :(row.is_to_address_verified==3?
            null
          // <i title={"Validation In Progress"} className={"bx bx-loader-alt spin"} style={{marginRight:"5px",cursor:"pointer"}}/>
            :(row.is_to_address_verified==1?
              <i title={"Address Validation Passed"} className={"bx bx-check-circle"} style={{color:"#02ad30",marginRight:"5px",cursor:"pointer"}}/>:
              null)
        )
      } {row.to_name}</> : "-",
      omit: hideShowToName
    },
    {
      name: "Notes",
      selector: ["id", "from_buyer_notes", "to_buyer_notes", "internal_notes"],
      //  sortable: true,
      width: "100px",
      reorder: true,
      cell: row => (
        <div style={{ width: "100%", display: "flex" }}>
          <span title={"From Buyer Notes"}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
               style={{ cursor: "pointer" }} onClick={() => {
            setNotesOrderId(row.id)
            setFromBuyerNotes(row.from_buyer_notes)
            setFromBuyerNotesModal(true)
            setToBuyerNotesModal(false)
            setInternalBuyerNotesModal(false)
          }}>
            <g opacity={row.from_buyer_notes ? "1" : "0.2"}>
            <path
              d="M15.4166 3.425L11.2582 1.86667C10.5666 1.60834 9.43322 1.60834 8.74155 1.86667L4.58322 3.425C3.62489 3.78334 2.84155 4.91667 2.84155 5.93334V12.125C2.84155 13.1083 3.49155 14.4 4.28322 14.9917L7.86655 17.6667C9.04155 18.55 10.9749 18.55 12.1499 17.6667L15.7332 14.9917C16.5249 14.4 17.1749 13.1083 17.1749 12.125V5.93334C17.1582 4.91667 16.3749 3.78334 15.4166 3.425ZM9.94155 5.85834C10.9249 5.85834 11.7249 6.65834 11.7249 7.64167C11.7249 8.60834 10.9666 9.38334 10.0082 9.41667H9.92489C8.92489 9.38334 8.17489 8.60834 8.17489 7.64167C8.16655 6.65834 8.96655 5.85834 9.94155 5.85834ZM11.8249 13.6333C11.3166 13.9667 10.6582 14.1417 9.99989 14.1417C9.34155 14.1417 8.67489 13.975 8.17489 13.6333C7.69989 13.3167 7.44155 12.8833 7.43322 12.4083C7.43322 11.9417 7.69989 11.4917 8.17489 11.175C9.18322 10.5083 10.8249 10.5083 11.8332 11.175C12.3082 11.4917 12.5749 11.925 12.5749 12.4C12.5666 12.8667 12.2999 13.3167 11.8249 13.6333Z"
              fill={localStorage.getItem("theme")=="dark"?"white":"#393E46"}/>
            </g>
          </svg>
          </span>
          <span title={"To Buyer Notes"}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
               style={{ cursor: "pointer", marginLeft: "2px" }} onClick={() => {
            setNotesOrderId(row.id)
            setToBuyerNotes(row.to_buyer_notes)
            setFromBuyerNotesModal(false)
            setToBuyerNotesModal(true)
            setInternalBuyerNotesModal(false)
          }}>
            <g opacity={row.to_buyer_notes ? "1" : "0.2"}>
              <path
                d="M14.1667 1.66666H5.83341C3.53341 1.66666 1.66675 3.525 1.66675 5.81666V11.6333C1.66675 13.925 3.53341 15.7833 5.83341 15.7833H7.08341C7.30841 15.7833 7.60841 15.9333 7.75008 16.1167L9.00008 17.775C9.55008 18.5083 10.4501 18.5083 11.0001 17.775L12.2501 16.1167C12.4084 15.9083 12.6584 15.7833 12.9167 15.7833H14.1667C16.4667 15.7833 18.3334 13.925 18.3334 11.6333V5.81666C18.3334 3.525 16.4667 1.66666 14.1667 1.66666ZM6.66675 10C6.20008 10 5.83341 9.625 5.83341 9.16666C5.83341 8.70833 6.20841 8.33333 6.66675 8.33333C7.12508 8.33333 7.50008 8.70833 7.50008 9.16666C7.50008 9.625 7.13341 10 6.66675 10ZM10.0001 10C9.53341 10 9.16675 9.625 9.16675 9.16666C9.16675 8.70833 9.54175 8.33333 10.0001 8.33333C10.4584 8.33333 10.8334 8.70833 10.8334 9.16666C10.8334 9.625 10.4667 10 10.0001 10ZM13.3334 10C12.8667 10 12.5001 9.625 12.5001 9.16666C12.5001 8.70833 12.8751 8.33333 13.3334 8.33333C13.7917 8.33333 14.1667 8.70833 14.1667 9.16666C14.1667 9.625 13.8001 10 13.3334 10Z"
                fill={localStorage.getItem("theme")=="dark"?"white":"#393E46"}/>
            </g>
          </svg>
          </span>
          <span title={"Internal Notes"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
                 style={{ cursor: "pointer", marginLeft: "2px" }} onClick={() => {
              setNotesOrderId(row.id)
              setInternalBuyerNotes(row.internal_notes)
              setFromBuyerNotesModal(false)
              setToBuyerNotesModal(false)
              setInternalBuyerNotesModal(true)
            }}>
            <g opacity={row.internal_notes ? "1" : "0.2"}>
              <path
                d="M10.5633 16.6325C10.7925 16.6858 10.8133 16.9858 10.59 17.06L9.27333 17.4933C5.965 18.56 4.22333 17.6683 3.14833 14.36L2.08166 11.0683C1.015 7.76 1.89833 6.01 5.20666 4.94334L5.64333 4.79834C5.97916 4.6875 6.30583 5.02417 6.21 5.365C6.16333 5.53334 6.11833 5.71 6.07333 5.89334L5.25666 9.385C4.34 13.31 5.68166 15.4767 9.60666 16.41L10.5633 16.6325Z"
                fill={localStorage.getItem("theme")=="dark"?"white":"#393E46"}/>
              <path
                d="M14.3083 2.67417L12.9167 2.34917C10.1333 1.69084 8.47499 2.2325 7.49999 4.24917C7.24999 4.7575 7.04999 5.37417 6.88332 6.0825L6.06665 9.57417C5.24999 13.0575 6.32499 14.7742 9.79999 15.5992L11.2 15.9325C11.6833 16.0492 12.1333 16.1242 12.55 16.1575C15.15 16.4075 16.5333 15.1908 17.2333 12.1825L18.05 8.69917C18.8667 5.21584 17.8 3.49084 14.3083 2.67417ZM12.7417 11.1075C12.6667 11.3908 12.4167 11.5742 12.1333 11.5742C12.0833 11.5742 12.0333 11.5658 11.975 11.5575L9.54999 10.9408C9.39962 10.8913 9.27367 10.7864 9.19759 10.6476C9.12152 10.5087 9.10098 10.3462 9.14013 10.1927C9.17927 10.0393 9.27519 9.90649 9.4085 9.82106C9.54181 9.73563 9.70258 9.70398 9.85832 9.7325L12.2833 10.3492C12.625 10.4325 12.825 10.7742 12.7417 11.1075ZM15.1833 8.29084C15.1083 8.57417 14.8583 8.7575 14.575 8.7575C14.525 8.7575 14.475 8.74917 14.4167 8.74084L10.375 7.71584C10.2246 7.66625 10.0987 7.56144 10.0226 7.42258C9.94652 7.28372 9.92598 7.12116 9.96513 6.96775C10.0043 6.81433 10.1002 6.68149 10.2335 6.59606C10.3668 6.51063 10.5276 6.47898 10.6833 6.5075L14.725 7.5325C15.0667 7.6075 15.2667 7.94917 15.1833 8.29084Z"
                fill={localStorage.getItem("theme")=="dark"?"white":"#393E46"}/>
            </g>
          </svg>
          </span>
        </div>
      ),
      omit: hideShowNotes
    },
    {
      name: "Tags",
      selector: "tags",
      //  sortable: true,
      minWidth: "90px",
      maxWidth: "90px",
      reorder: true,
      cell: row => row.tags.length > 0? row.tags.map((el,index) => (
        <span key={index} title={el.tag} style={{backgroundColor: el.color,width:"8px",height:"20px",display:"block",marginLeft:(index>0?"5px":"0px"),borderRadius:"5px"}}></span>
      )) : "-",
      omit: hideShowTags
    },
    {
      name: "Order #",
      selector: "order_number",
      sortable: true,
      width: "140px",
      conditionalCellStyles: [
        {
          when: row => row.order_number,
          style: {
            color: "#FF6600", fontWeight: "500", cursor: "pointer"
          }
        }],
      button: true,
      reorder: true,
      cell: row => row.order_number ? <span onClick={() => {
        setRowId(row.id)
        setViewModal(true)
      }}>{row.order_number}</span> : "-",
      omit: hideShowOrderNumber
    },
    {
      name: "Package #",
      selector: "package_number",
      sortable: true,
      width: "120px",
      // conditionalCellStyles: [
      //   {
      //     when: row => row.package_number,
      //     style: {
      //       color: "#FF6600", fontWeight: "500", cursor: "pointer"
      //     }
      //   }],
      // button: true,
      reorder: true,
      cell: row => row.package_number ? <span>{row.package_number + (row.package_index?" (" + row.package_index + ")":"")}</span> : "-",
      omit: hideShowPackageNumber
    },
    {
      name:  "Zenventory Order #",
      selector: ["store_type", "store_order_json"],
      sortable: true,
      reorder: true,
      width:"200px",
      cell: row => row.store_type == "zenventory"?(row.store_order_json?JSON.parse(row.store_order_json)?.customerorder?.ordernumber:"-"):"-",
      omit: hideShowZenventory
    },
    {
      name:  "Picklist",
      selector: "picklists",
      sortable: true,
      reorder: true,
      width:"130px",
      button: true,
      cell: row => row.store_type == "sellercloud"?(row.picklists?
        <div style={{width:"170px",display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
          {row.picklists.split(",").map((el, index) => (
        <span key={index} className={"badge border border-dark text-dark mt-1"} style={{ marginLeft: "5px" }}>{el}</span>
      ))}
        </div>:"-"):"-",
      omit: hideShowPicklists
    },
    {
      name:  "Channel Order #",
      selector: "store_order_json",
      sortable: true,
      reorder: true,
      width:"240px",
      cell: row => row.store_type == "sellercloud"?(JSON.parse(row.store_order_json).OrderSourceOrderID?JSON.parse(row.store_order_json).OrderSourceOrderID:"-"):"-",
      omit: hideShowChannelNumber
    },
    // {
    //   name: "Store Order Date",
    //   selector: "store_order_created_at",
    //   sortable: true,
    //   minWidth: "180px",
    //   reorder: true,
    //   cell: (row) => {
    //     if(row.store_order_created_at) {
    //       let order = JSON.parse(row.store_order_json);
    //       // if(row.store_type == "shopify") {
    //       //   return (<span>{moment(order.created_at).format("MMM DD, YYYY")}</span>);
    //       // }
    //       // else if(row.store_type == "ebay") {
    //       //   return (<span>{moment(order.updated_at).format("MMM DD, YYYY")}</span>);
    //       // }
    //       // else if(row.store_type == "walmart") {
    //       //   return (<span>{moment(order.orderDate).format("MMM DD, YYYY")}</span>);
    //       // }
    //       // else if(row.store_type == "zenventory") {
    //       //   return (<span>{moment(order.ordereddate).format("MMM DD, YYYY")}</span>);
    //       // }
    //       // else
    //         if(row.store_type == "sellercloud") {
    //         return (<span>{moment(row.store_order_created_at).format("MMM DD, YYYY hh:mm A")}</span>);
    //       }
    //     }
    //     else {
    //       return ("-")
    //     }
    //   },
    //   omit: localStorage.getItem("store")==0
    // },
    {
      name: "Store Order Date",
      selector: "store_order_json",
      sortable: true,
      minWidth: "180px",
      reorder: true,
      cell: (row) => {
        if(row.store_order_json) {
          let order = JSON.parse(row.store_order_json);
          if(row.store_type == "shopify") {
            return (<span>{moment(order.created_at).format("MMM DD, YYYY")}</span>);
          }
          else if(row.store_type == "ebay") {
            return (<span>{moment(order.updated_at).format("MMM DD, YYYY")}</span>);
          }
          else if(row.store_type == "walmart") {
            return (<span>{moment(order.orderDate).format("MMM DD, YYYY")}</span>);
          }
          else if(row.store_type == "zenventory") {
            return (<span>{moment(order.ordereddate).format("MMM DD, YYYY")}</span>);
          }
          else if(row.store_type == "sellercloud") {
            return (<span>{moment(order.TimeOfOrder).format("MMM DD, YYYY hh:mm A")}</span>);
          }
          else if(row.store_type == "shipstation") {
            return (<span>{moment(order.createDate).format("MMM DD, YYYY hh:mm A")}</span>);
          }
        }
        else {
          return ("-")
        }
      },
      omit: localStorage.getItem("store")==0
    },
    {
      name: "Order Date",
      selector: "created_at",
      sortable: true,
      minWidth: "180px",
      reorder: true,
      cell: (row) => moment(row.created_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A"),
      omit: hideShowOrderDate
    },
    {
      name: "Updated At",
      selector: "updated_at",
      sortable: true,
      minWidth: "180px",
      reorder: true,
      cell: (row) => moment(row.updated_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A"),
      omit: hideShowUpdatedAt
    },
    {
      name: "Dimension (L x W x H)",
      selector: ["length", "width", "height"],
      // sortable: true,
      minWidth: "180px",
      reorder: true,
      cell: (row) => (
        row.length || row.width || row.height ? <span>{row.length?row.length:"-"} x {row.width?row.width:"-"} x {row.height?row.height:"-"}</span> :"-"
      ),
      omit: hideShowDimension
    },
    {
      name: "Weight",
      selector: "weight",
      sortable: true,
      minWidth: "150px",
      reorder: true,
      cell: (row) => (
        row.weight ? row.weight + " lb.":"-"
      ),
      omit: hideShowWeight
    },
    {
      name: "Status",
      selector: ["id", "error_message", "is_verified"],
      //  sortable: true,
      width: "100px",
      button: true,
      reorder: true,
      // cell: (row) => row.error_message ? <span className={"badge badge-danger"}>{row.error_message}</span> : "-"
      cell: (row) => row.is_verified == 1 && row.error_message != "Try Again" ?
        <span className={"badge badge-success"}>Passed</span> :
        (row.error_message ?
          (row.is_verified == 1 && row.error_message == "Try Again"?<span className={"badge badge-warning"}>Try Again</span>:
          <>
                  <span className={"badge badge-danger"} style={{ cursor: "pointer" }} onClick={() => {
                    handleFailedOrderErrorMessage(row.id)
                  }}>Failed</span>
            {/*<span className={"text-danger fw-medium text-center"} title={row.error_message.toString().replaceAll("null,","")}><i style={{cursor:"pointer",fontSize:"15px"}} className={"bx bx-error"}></i></span>*/}
          </>) : "-"),
      omit: hideShowStatus
    },
    {
      name: "Actions",
      selector: "id",
      width: "170px",
      button: true,
      cell: (row) =>
        <>
          <button
            className={"btn btn-success btn-sm"}
            onClick={() => {
            setRowId(row.id)
            setViewModal(true)
          }} style={{ marginRight: "10px" }}
            title="View Order Details">
            <i className={"bx bx-list-ul"}></i>
          </button>
          <button
            className={"btn btn-light btn-sm"}
            data-id={row["id"]}
            onClick={() => {
              props.history.push("/order/edit/" + row["id"])
            }}
            style={{ marginRight: "10px" }}
            title="Edit Order">
            <i className={"bx bx-edit"}></i>
          </button>
          {localStorage.getItem("store") == 0 ?
            <><button className={"btn btn-danger btn-sm"}
                      onClick={() => {
                        handleDelete(row.id)
                      }} title="Delete Order">
              <i className={"bx bx-trash"}></i>
            </button>
              <button
                title={"Duplicate Order"}
                className={"btn btn-info btn-sm"}
                onClick={() => handleDuplicate(row.id)}
                style={{ marginLeft: "10px" }}
              >
                <i className={"bx bx-duplicate"}></i>
              </button>
            </> : ""}
        </>
    },
    {
      name: "",
      // selector: "order_unique_id",
      selector: row=>row.order_unique_id,

      omit: true
    },
    {
      name: "",
      // selector: "reference_number",
      selector: row=>row.reference_number,

      omit: true
    },
    {
      name: "",
      // selector: "custom_reference_1",
      selector: row=>row.custom_reference_1,

      omit: true
    },
    {
      name: "",
      // selector: "custom_reference_2",
      selector: row=>row.custom_reference_2,

      omit: true
    }
  ];

  const handleDuplicate = async (order_id) => {
    try {
      if(confirm("Are you sure! you want to duplicate the order?")) {
        let loading = toast.loading("Duplicating Order...");

        let body = {
          id: JSON.parse(localStorage.getItem("authUser")).id,
          order_id: order_id,
          type: filterType,
          order_count: 1
        }

        post('/user/duplicate-order', body, {headers}).then(async (response) => {
          toast.remove(loading);
          if(response.status == 200) {
            toast.success(response.message);
            await retrieveOrdersByFilterType();
          }
          else {
            toast.error(response.message);
          }
        })
      }
    }
    catch (e) {

    }
  }

  // Calculate brightness
  const getBrightness = (color) => {
    const hexColor = color.replace('#', '');
    const r = parseInt(hexColor.slice(0, 2), 16);
    const g = parseInt(hexColor.slice(2, 4), 16);
    const b = parseInt(hexColor.slice(4, 6), 16);
    // console.log("ddd", 0.299 * r + 0.587 * g + 0.114 * b)
    return 0.299 * r + 0.587 * g + 0.114 * b;
  };

  const handleFailedOrderErrorMessage = async id => {
    try {
      get(`/user/get-order-errors/${id}`, { headers }).then(response => {
        // console.log("get-order-errors", response)
        if (response.status === 200) {
          // console.log(response.data[0].error_message.split(","))
          setOrderErrors(response.data[0].error_message)
          setOrderErrorModal(true)
        } else {
          toast.error(response.message)
        }
      })
    } catch (e) {
      // console.log(e)
    }
  }

  const handleOrderCheck = async e => {
    const { value, checked } = e.target
    // console.log("value",value);
    // console.log("allData[index]",sideBarData);
    let arr = clickedSideBarJson;
    if (checked) {
      // console.log("value",JSON.parse(value));
      if(!selectedOrders.includes(value)) {
        selectedOrders.push(value);
        let index = sideBarData.findIndex(el => el.id == value);
        arr.push(sideBarData[index]);
        clickedSideBarJson = arr;
      }

      // // console.log("handleOrderCheck selectedOrders", selectedOrders);
      // // console.log("data",data);
      // selectedOrderJson.push(JSON.parse(value));
    } else {
      let index = selectedOrders.findIndex(el => el == value);
      selectedOrders.splice(index, 1);

      let indexToRemove = arr.findIndex(el => el.id == value);
      arr.splice(indexToRemove, 1);
      clickedSideBarJson = arr;
      // let i = selectedOrderJson.findIndex(el => el.id == parseInt(JSON.parse(value).id));
      // selectedOrderJson.splice(i, 1);
    }
    // console.log("selectedOrders",selectedOrders);
    setSelectedOrdersCount(selectedOrders.length);

    setSingleOrderRate("");
    setSingleOrderWeight("")
    setSingleOrderServiceId("")
    setSingleOrderWidth("")
    setSingleOrderHeight("")
    setSingleOrderLength("")

    // // console.log("selectedOrderJson", selectedOrderJson);
    // await setClickedJson(selectedOrderJson);
    // if(selectedOrders.length == 1) {
    //   retrieveOrder(parseInt(JSON.parse(value).id));
    // }
    // else {
    //   setSingleOrderWeight("")
    //   setSingleOrderServiceId("")
    //   setSingleOrderWidth("")
    //   setSingleOrderHeight("")
    //   setSingleOrderLength("")
    // }
    // document.body.click();
    document.getElementsByClassName("right-bar-enabled")[0].click();
  }


  const handleTagCheck = async e => {
    const { value, checked } = e.target
    if (checked) {
      selectedTags.push(value);
    } else {
      let index = selectedTags.findIndex(el => el == value);
      selectedTags.splice(index, 1);
    }
    document.getElementsByClassName("right-bar-enabled")[0].click();
  }

  const handleBulKRate = async () => {
    document.body.style.pointerEvents = "none";
    setClickedJson([]);
    setAboveFiveLbsOrders([]);
    setBelowFiveLbsOrders([]);
    setUnderOneLbsOrders([]);
    setAboveFiveLbsService("")
    setBelowFiveLbsService("")
    setUnderOneLbsService("")
    setServiceRateAboveFiveLbs("")
    setServiceRateBelowFiveLbs("")
    setServiceRateUnderOneLbs("")

    if (selectedOrders.length > 0) {
      let underOneLbs = [];
      let belowFiveLbs = [];
      let aboveFiveLbs = [];
      let arr = [];
      for (let i=0;i<selectedOrders.length;i++) {
        let index = allData.findIndex(el => el.id == selectedOrders[i]);
        arr.push(allData[index]);
        selectedOrderJson.push(allData[index]);
        // const order = await retrieveOrderWeight(selectedOrders[i]);
        const order = allData[index];
        if(order.weight) {
          if(parseFloat(order.weight) > 5) {
            aboveFiveLbs.push(order);
          }
          else if(parseFloat(order.weight) < 1) {
            underOneLbs.push(order);
          }
          else {
            belowFiveLbs.push(order);
          }
        }
        else {
          underOneLbs.push(order);
        }
      }

      // console.log("belowFiveLbs",belowFiveLbs);
      // console.log("aboveFiveLbs",aboveFiveLbs);
      setClickedJson(arr);
      setAboveFiveLbsOrders(aboveFiveLbs);
      setBelowFiveLbsOrders(belowFiveLbs);
      setUnderOneLbsOrders(underOneLbs);
      setIsFullTextVisible(false);
      setIsFullTextVisibleBelow(false);
      setIsFullTextVisibleUnderOne(false);
      document.body.style.pointerEvents = "auto";


    //   setSingleOrderRate("");
    //   let arr = [];
    //   for (let i=0;i<selectedOrders.length;i++) {
    //     let index = allData.findIndex(el => el.id == selectedOrders[i]);
    //     arr.push(allData[index]);
    //     selectedOrderJson.push(allData[index]);
    //   }
    // // console.log("setClickedJson",arr)
    //   setClickedJson(arr);
    //
    //   if(selectedOrders.length == 1) {
    //     retrieveOrder(selectedOrders[0]);
    //   }
    //   else {
    //     setSingleOrderWeight("")
    //     setSingleOrderServiceId("")
    //     setSingleOrderWidth("")
    //     setSingleOrderHeight("")
    //     setSingleOrderLength("")
    //   }

    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleBulkUpdate = () => {
    if (selectedOrders.length > 0) {
      let arr = [];
      for (let i=0;i<selectedOrders.length;i++) {
        let index = allData.findIndex(el => el.id == selectedOrders[i]);
        arr.push(allData[index]);
      }
      // console.log("setClickedJson",arr)
      setClickedBulkUpdateJson(arr);

    } else {
      toast.error("Please select at least one order")
    }
  }

  const retrieveTags = async () => {
    let id = JSON.parse(localStorage.getItem("authUser")).id;
    get(`/user/get-tags/${id}`, { headers }).then(response => {
      // console.log("get-order-errors", response)
      if (response.status === 200) {
        // console.log(response.data[0].error_message.split(","))
        let arr = [{"name":"Remove Filter","value":"none"}];
        for (let i=0;i<response.data.length;i++) {
          arr.push({"name":response.data[i].tag,"value":response.data[i].id});
        }
        setTagsForFilter(arr);
        setTags(response.data)
      } else {
        toast.error(response.message)
      }
    })
  }

  const retrievePicklist = async () => {
    let id = JSON.parse(localStorage.getItem("authUser")).id;
    const body = {
      user_id: id,
      store_id: localStorage.getItem("store")
    }
    post(`/user/get-picklists`, body, { headers }).then(response => {
      // console.log("get-order-picklists", response)
      if (response.status === 200) {
        // console.log(response.data[0].error_message.split(","))
        let arr = [{"name":"Remove Filter","value":"none"}];
        for (let i=0;i<response.data.length;i++) {
          arr.push({"name":response.data[i].picklists,"value":response.data[i].picklists});
        }
        setPicklists(arr);
      } else {
        toast.error(response.message)
      }
    })
  }

  const handleTags = async () => {
    if (selectedOrders.length > 0) {
      await retrieveTags();
      setTag("");
      setTagColor("#e3e3e3");
      setTagsModal(true);
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleUpdateTag = () => {
    if (selectedOrders.length > 0) {
      if(selectedTags.length > 0) {
        let body = {
          id: JSON.parse(localStorage.getItem("authUser")).id,
          order_ids: selectedOrders.toString(),
          tag: selectedTags.toString()
        }
        post('/user/update-order-tag', body, {headers}).then(async (response) => {
          uncheckAll();
          selectedOrders = [];
          setSelectedOrdersCount(selectedOrders.length);
          clickedSideBarJson = [];
          selectedOrderJson = [];
          setBulkUpdateServiceId("");
          setBulkUpdateToPhone("");
          setEnableBulkUpdateToPhone(false);
          selectedTags = [];
          uncheckAllTags();
          if(selectedGroupBy) {
            await handleGroupByOrders(selectedGroupBy);
          }
          else {
            await retrieveOrdersByFilterType();
          }

          if(response.status == 200) {
            toast.success(response.message);
            setTag("");
            setTagColor("#e3e3e3");
            setTagsModal(false);
          }
          else {
            toast.error(response.message);
          }
        })
      } else {
        toast.error("Please select at least one tag")
      }
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleCreateTag = () => {
    // console.log("getBrightness(tagColor)",getBrightness(tagColor))
    if(parseFloat(getBrightness(tagColor)) < 250) {
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
        tag: tag,
        tagColor: tagColor
      }
      post('/user/create-tag', body, {headers}).then(async (response) => {
        if(response.status == 200) {
          await retrieveTags();
          setTag("");
          setTagColor("#e3e3e3");
          toast.success(response.message);
        }
        else {
          toast.error(response.message);
        }
      });
    }
    else {
      toast.error("Tag color must be brighter than white");
    }
  }

  const handleDeleteTag = async (id) => {
    if(confirm("Are you sure! you want to delete this tag?")) {
      let body = {
        id: id
      }
      post('/user/delete-tag', body, {headers}).then(async (response) => {
        if(response.status == 200) {
          await retrieveTags();
          toast.success(response.message);
        }
        else {
          toast.error(response.message);
        }
      });
    }
  }

  // const handleOrderCheck = async e => {
  //   const { value, checked } = e.target
  //   if (checked) {
  //     // console.log("value",JSON.parse(value));
  //     selectedOrders.push(parseInt(JSON.parse(value).id));
  //     // console.log("handleOrderCheck selectedOrders", selectedOrders);
  //     // console.log("data",data);
  //     selectedOrderJson.push(JSON.parse(value));
  //   } else {
  //     let index = selectedOrders.findIndex(el => el == parseInt(JSON.parse(value).id));
  //     selectedOrders.splice(index, 1);
  //     let i = selectedOrderJson.findIndex(el => el.id == parseInt(JSON.parse(value).id));
  //     selectedOrderJson.splice(i, 1);
  //     // setClickedJson(selectedOrderJson);
  //   }
  //   // console.log("selectedOrderJson", selectedOrderJson);
  //   await setClickedJson(selectedOrderJson);
  //   if(selectedOrders.length == 1) {
  //     retrieveOrder(parseInt(JSON.parse(value).id));
  //   }
  //   else {
  //     setSingleOrderWeight("")
  //     setSingleOrderServiceId("")
  //     setSingleOrderWidth("")
  //     setSingleOrderHeight("")
  //     setSingleOrderLength("")
  //   }
  //   document.body.click();
  // }

  const retrieveServices = async () => {
    try {
      get("/admin/get-services", { headers }).then(response => {
        // // console.log("response", response);
        // console.log("data", response.data)
        setServices(response.data)
      })
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveCarriers = async () => {
    try {
      get("/admin/get-carriers", { headers }).then(response => {
        // console.log("carriers", response)
        setCarriers(response.data)
      })
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveOrderCounts = async () => {
    setUnassignedOrderCount("-");
    setFedexOrderCount("-");
    setUpsOrder("-");
    setUpsv2Order("-");
    setDhlOrder("-");
    setUspsOrder("-");
    let body = {
      id: JSON.parse(localStorage.getItem("authUser")).id,
      store_id: localStorage.getItem("store")
    }
    post("/user/get-order-counts", body, { headers }).then((response) => {
      if(response.status == 200) {
        setUnassignedOrderCount(response.data.unsassigned_order_count);
        setFedexOrderCount(response.data.fedex_order_count);
        setUpsOrder(response.data.ups_order_count);
        setUpsv2Order(response.data.upsv2_order_count);
        setDhlOrder(response.data.dhl_order_count);
        setUspsOrder(response.data.usps_order_count);
      }
    });
  }

  const handleTableData = async (responseData) => {
    if(selectedGroupBy) {
      await handleSort(nameSort, weightSort, quantitySort, orderDate, tagFilter, filterShipment, validationFilter, picklistFilter, responseData);
      setGroupByDataTablesAll(responseData);
      // console.log("responseData",responseData);

      let mergedArray = [];
      for (let i=0;i<responseData.length;i++) {
        mergedArray = mergedArray.concat(responseData[i].data);
      }
      // console.log("after concat",mergedArray);
      setAllData(mergedArray)
      sideBarData = mergedArray;
    }
    else {

      await handleSort(nameSort, weightSort, quantitySort, orderDate, tagFilter, filterShipment, validationFilter, picklistFilter, responseData);
      setAllData(responseData)
      sideBarData = responseData;
    }
  }

  const retrieveUnassignedOrders = async () => {
    try {
      // refresh filter on carrier change
      // setNameSort("");
      // setWeightSort("");
      // setQuantitySort("");
      // setFilterShipment("All");

      localStorage.setItem("order-filter", "Unassigned")
      setPending(true)
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: localStorage.getItem("store")
      }
      post("/user/get-unassigned-orders", body, { headers }).then(
        async (response) => {
          // // console.log("response", response);
          // if(response.data.length > 0) {
          await handleTableData(response.data.filter(el => el.error_message != "In Process"));
          // setFilteredData(response.data.filter(el => el.error_message != "In Process"))
          // data = response.data.filter(el => el.error_message != "In Process")
          // }

          // setTableData({ columns, data })
        }
      )

      await retrieveOrderCounts();
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveFedexOrders = async () => {
    try {
      // refresh filter on carrier change
      // setNameSort("");
      // setWeightSort("");
      // setQuantitySort("");
      // setFilterShipment("All");

      localStorage.setItem("order-filter", "FedEx")
      setPending(true)
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: localStorage.getItem("store")
      }
      post("/user/get-fedex-orders", body, { headers }).then(
        async (response) => {
          // // console.log("response", response);
          // if(response.data.length > 0) {
          await handleTableData(response.data.filter(el => el.error_message != "In Process"));
          // setFilteredData(response.data.filter(el => el.error_message != "In Process"))
          // data = response.data.filter(el => el.error_message != "In Process")
          // }

          // setTableData({ columns, data })
          setPending(false)
        }
      )
      await retrieveOrderCounts();
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveUpsOrders = async () => {
    try {
      // refresh filter on carrier change
      // setNameSort("");
      // setWeightSort("");
      // setQuantitySort("");
      // setFilterShipment("All");


      localStorage.setItem("order-filter", "UPS")
      setPending(true)
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: localStorage.getItem("store")
      }
      post("/user/get-ups-orders", body, { headers }).then(
        async (response) => {
          // // console.log("response", response);
          // if(response.data.length > 0) {
          await handleTableData(response.data.filter(el => el.error_message != "In Process"));
          // setFilteredData(response.data.filter(el => el.error_message != "In Process"))
          // data = response.data.filter(el => el.error_message != "In Process")
          // }

          // setTableData({ columns, data })
          setPending(false)
        }
      );
      await retrieveOrderCounts();
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveUpsv2Orders = async () => {
    try {
      // refresh filter on carrier change
      // setNameSort("");
      // setWeightSort("");
      // setQuantitySort("");
      // setFilterShipment("All");


      localStorage.setItem("order-filter", "UPSv2")
      setPending(true)
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: localStorage.getItem("store")
      }
      post("/user/get-ups-v2-orders", body, { headers }).then(
        async (response) => {
          // // console.log("response", response);
          // if(response.data.length > 0) {
          await handleTableData(response.data.filter(el => el.error_message != "In Process"));
          // setFilteredData(response.data.filter(el => el.error_message != "In Process"))
          // data = response.data.filter(el => el.error_message != "In Process")
          // }

          // setTableData({ columns, data })
          setPending(false)
        }
      );
      await retrieveOrderCounts();
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveDhlOrders = async () => {
    try {
      // refresh filter on carrier change
      // setNameSort("");
      // setWeightSort("");
      // setQuantitySort("");
      // setFilterShipment("All");


      localStorage.setItem("order-filter", "DHL")
      setPending(true)
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: localStorage.getItem("store")
      }
      post("/user/get-dhl-orders", body, { headers }).then(
        async (response) => {
          // // console.log("response", response);
          // if(response.data.length > 0) {
          await handleTableData(response.data.filter(el => el.error_message != "In Process"));
          // setFilteredData(response.data.filter(el => el.error_message != "In Process"))
          // data = response.data.filter(el => el.error_message != "In Process")
          // }

          // setTableData({ columns, data })
          setPending(false)
        }
      );
      await retrieveOrderCounts();
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveUspsOrders = async () => {
    try {
      // refresh filter on carrier change
      // setNameSort("");
      // setWeightSort("");
      // setQuantitySort("");
      // setFilterShipment("All");


      localStorage.setItem("order-filter", "USPS")
      setPending(true)
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: localStorage.getItem("store")
      }
      post("/user/get-usps-orders", body, { headers }).then(
        async (response) => {
          // // console.log("response", response);
          // if(response.data.length > 0) {
          await handleTableData(response.data.filter(el => el.error_message != "In Process"));
          // setFilteredData(response.data.filter(el => el.error_message != "In Process"))
          // data = response.data.filter(el => el.error_message != "In Process")
          // }

          // setTableData({ columns, data })
          setPending(false)
        }
      );
      await retrieveOrderCounts();
    } catch (e) {
      // console.log(e)
    }
  }

  const getUserBalance = async () => {
    setBalance("-");
    const body = {
      id: JSON.parse(localStorage.getItem("authUser")).id,
    }
    await post("/user/get-balance", body, { headers }).then(response => {
      // console.log("response", response)
      if (response.status === 200) {
        localStorage.setItem("balance", response.balance)
        setBalance(response.balance)
      } else {
        // setResponseSuccess(false);
        // setResponseMessage(response.message);
      }
    })
  }

  const getUserPreferences = async () => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: selectedStore,
        type: 'awaiting-shipment-columns'
      }
      // console.log("update-user-preferences body", body)

      post('/user/get-user-preferences', body, {headers}).then((response) => {
        // console.log("get-user-preferences response", response)
        if(response.status == 200) {
          if(response.data.length > 0) {
            let data = JSON.parse(response.data[0].data)

            hideShowItemName = data.hideShowItemName;
            hideShowServiceName = data.hideShowServiceName;
            hideShowFromName = data.hideShowFromName;
            hideShowToName = data.hideShowToName;
            hideShowNotes = data.hideShowNotes;
            hideShowTags = data.hideShowTags;
            hideShowOrderNumber = data.hideShowOrderNumber;
            hideShowPackageNumber = data.hideShowPackageNumber;
            hideShowOrderDate = data.hideShowOrderDate;
            hideShowUpdatedAt = data.hideShowUpdatedAt;
            hideShowDimension = data.hideShowDimension;
            hideShowWeight = data.hideShowWeight;
            hideShowStatus = data.hideShowStatus;
            hideShowItemNames = data.hideShowItemNames;
            hideShowProductIds = data.hideShowProductIds;
            hideShowPicklists = data.hideShowPicklists;

            columns[2].omit = hideShowItemName;
            columns[3].omit = hideShowItemNames;
            columns[4].omit = hideShowProductIds;
            columns[5].omit = hideShowServiceName;
            columns[6].omit = hideShowFromName;
            columns[7].omit = hideShowToName;
            columns[8].omit = hideShowNotes;
            columns[9].omit = hideShowTags;
            columns[10].omit = hideShowOrderNumber;
            columns[11].omit = hideShowPackageNumber;
            columns[13].omit = hideShowPicklists;
            columns[16].omit = hideShowOrderDate;
            columns[17].omit = hideShowUpdatedAt;
            columns[18].omit = hideShowDimension;
            columns[19].omit = hideShowWeight;
            columns[20].omit = hideShowStatus;

            setTableData({columns, data: []});
          }
        }
      });
    }
    catch (e) {

    }
  }

  useEffect(() => {
    // // console.log("here");
    getUserPreferences();
    selectedGroupBy = "";
    setGroupByDataTablesAll([]);
    setGroupByDataTables([]);
    setIsRetry(false)
    setFailedOrders([])
    setResponseSuccess(null)
    selectedOrders = []
    getUserBalance()
    retrieveOrdersByFilterType()
    retrieveStores()
    retrieveTags();
    retrievePicklist();
    // retrieveOrders();
    retrieveCarriers().then(() => {
      retrieveServices()
    });

    // setInterval(() => {
    //   if(responseSuccess == true || responseSuccess == false) {
    //     setTimeout(() => {
    //       setResponseSuccess(null)
    //       setResponseMessage("")
    //     }, 2000);
    //   }
    // },1000);
  }, []);

  useEffect(() => {
    if(responseSuccess == true || responseSuccess == false) {
      setTimeout(() => {
        setResponseSuccess(null)
        setResponseMessage("")
      }, 5000);
    }
  }, [responseSuccess])

  useEffect(() => {
    // async function onConnect() {
    //   console.log("connected")
    // }
    //
    // async function onDisconnect() {}

    async function refreshOrders() {
      console.log("emit called")
      retrieveOrdersByFilterType(localStorage.getItem("order-filter"));
    }

    // socket.emit('join', JSON.parse(localStorage.getItem("authUser")).id);
    // socket.on('connect', onConnect);
    // socket.on('disconnect', onDisconnect);
    socket.on('refreshOrders', refreshOrders);

    return () => {
      // socket.off('connect', onConnect);
      // socket.off('disconnect', onDisconnect);
      socket.off('refreshOrders', refreshOrders);
    };
  },[]);

  const handleChange = event => {
    // console.log(fileInput.current.files)
  }

  // useEffect(() => {
  //  if(localStorage.getItem("reload") == "true") {
  //    console.log("localStorage.getItem(reload)",localStorage.getItem("reload"))
  //    retrieveOrdersByFilterType();
  //    localStorage.setItem("reload","false");
  //  }
  // });

  const retrieveOrdersByFilterType = async (filter = filterType) => {
    if (filter == "Unassigned") {
      await retrieveUnassignedOrders()
    }
    if (filter == "FedEx") {
      await retrieveFedexOrders()
    } else if (filter == "UPS") {
      await retrieveUpsOrders()
    } else if (filter == "UPSv2") {
      await retrieveUpsv2Orders()
    } else if (filter == "DHL") {
      await retrieveDhlOrders()
    } else if (filter == "USPS") {
      await retrieveUspsOrders()
    }

    const checkCheckboxExist = setInterval(() => {
      if(document.getElementById("select-all-checkbox")) {
        for(let i=0;i<document.getElementsByClassName("select-all-checkbox").length;i++) {
          document.getElementsByClassName("select-all-checkbox")[i].style.display = "block";
        }
        clearInterval(checkCheckboxExist);
      }
    }, 100)
  }

  const handleImportSubmit = event => {
    event.preventDefault();
    setModal(false);
    uncheckAll();
    selectedOrders = [];
    setSelectedOrdersCount(selectedOrders.length);
    clickedSideBarJson = [];
    selectedOrderJson = [];
    setBulkUpdateServiceId("");
    setBulkUpdateToPhone("");
    setEnableBulkUpdateToPhone(false);

    document.body.style.pointerEvents = "none";
    let loading = toast.loading("Importing orders...");
    // if(selectedService !== "") {
    const formData = new FormData();
    formData.append('file', fileInput.current.files[0]);
    formData.append('id', JSON.parse(localStorage.getItem("authUser")).id);
    formData.append('service_id', selectedService);
    formData.append('measurement', selectedMeasurement);

    postFile("/user/import-orders", formData).then((response) => {
      // // console.log("response", response);
      toast.remove(loading);
      if (response.status === 200) {
        setSelectedMeasurement("pounds");
        if(selectedGroupBy) {
          handleGroupByOrders(selectedGroupBy).then(() => {
            document.body.style.pointerEvents = "auto";
            toast.success(response.message);
          });
        }
        else {
          retrieveOrdersByFilterType().then(() => {
            document.body.style.pointerEvents = "auto";
            toast.success(response.message);
          });
        }
      }
      else {
        toast.error(response.message);
      }
    })
  };

  const handleImportWithItemsSubmit = event => {
    event.preventDefault();
    setModalItems(false);
    uncheckAll();
    selectedOrders = [];
    setSelectedOrdersCount(selectedOrders.length);
    clickedSideBarJson = [];
    selectedOrderJson = [];
    setBulkUpdateServiceId("");
    setBulkUpdateToPhone("");
    setEnableBulkUpdateToPhone(false);

    document.body.style.pointerEvents = "none";
    let loading = toast.loading("Importing orders...");
    // if(selectedService !== "") {
    const formData = new FormData();
    formData.append('file', fileInputItems.current.files[0]);
    formData.append('id', JSON.parse(localStorage.getItem("authUser")).id);
    formData.append('service_id', selectedService);
    formData.append('measurement', selectedMeasurement);

    postFile("/user/import-orders-with-items", formData).then((response) => {
      // // console.log("response", response);
      toast.remove(loading);
      if (response.status === 200) {
        setSelectedMeasurement("pounds");
        if(selectedGroupBy) {
          handleGroupByOrders(selectedGroupBy).then(() => {
            document.body.style.pointerEvents = "auto";
            toast.success(response.message);
          });
        }
        else {
          retrieveOrdersByFilterType().then(() => {
            document.body.style.pointerEvents = "auto";
            toast.success(response.message);
          });
        }
      }
      else {
        toast.error(response.message);
      }
    })
  };

  const handleDelete = async id => {
    // const id = e.target.getAttribute("data-id");
    let confrm = confirm("Are you sure! you want to delete this order?")
    if (confrm) {
      const body = {
        id: id,
      }
      let loading = toast.loading("Deleting Order...")
      document.body.style.pointerEvents = "none"
      post("/user/delete-order", body, { headers }).then(response => {
        // // console.log("delete-order", response);
        document.body.style.pointerEvents = "auto"
        uncheckAll();
        selectedOrders = [];
        setSelectedOrdersCount(selectedOrders.length);
        clickedSideBarJson = [];
        selectedOrderJson = [];
        setBulkUpdateServiceId("");
        setBulkUpdateToPhone("");
        setEnableBulkUpdateToPhone(false);
        if (response.status === 200) {
          toast.remove(loading)
          if(selectedGroupBy) {
            handleGroupByOrders(selectedGroupBy)
          }
          else {
            retrieveOrdersByFilterType();
          }
          // toast.success(response.message);
          // retrieveOrders();
        } else {
          toast.remove(loading)
          toast.success(response.message)
        }
      })
    }
  }

  function uncheckAll() {
    let checkboxes = document.getElementsByClassName("order-checkbox");
    selectedOrders = [];
    let selectAllCheckbox = document.getElementsByClassName(
      "select-all-checkbox"
    )
    // console.log("checkboxes",checkboxes);
    if (checkboxes.length > 0) {
      selectAllCheckbox[0].checked = false
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false
      }
    }
  }

  function uncheckAllTags() {
    let checkboxes = document.getElementsByClassName("tag");
    selectedTags = [];
    if (checkboxes.length > 0) {
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false
      }
    }
  }

  function checkAll(e) {
    const { checked } = e.target;
    let checkboxes = document.getElementsByClassName('order-checkbox');
    selectedOrders = [];
    let arr = clickedSideBarJson;
    for (let i = 0; i < checkboxes.length; i++) {
      if (!checked) {
        checkboxes[i].checked = false;
        selectedOrders = [];
        arr = [];
      }
      else {
        checkboxes[i].checked = true;
        if(!selectedOrders.includes(checkboxes[i].value)) {
          selectedOrders.push(checkboxes[i].value);
        }
        let index = sideBarData.findIndex(el => el.id == checkboxes[i].value);
        arr.push(sideBarData[index]);
      }
    }

    clickedSideBarJson = arr;

    setSelectedOrdersCount(selectedOrders.length);
    // console.log("selectedOrders all",selectedOrders)
  }

  const handleCalculatedPrice = async () => {
    document.body.style.pointerEvents = "none";
    if(selectedGroupBy) {
      handleGroupByOrders(selectedGroupBy, false).then(() => {
        setVerificationModal(false)
        let body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).id,
          ids: selectedOrders.toString(),
        }
        post("/user/calculate-order-amount", body, { headers }).then(
          async response => {
            if (response.status === 200) {
              console.log("calculate-order-amount data", response);
              setCalculatedAmounts(response.data)
              setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)
              setCalculatedAmountsUnderOneLbs(response.dataUnderOneLbs)

              let price = 0
              if (response.data.length > 0) {
                price = await response.data
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              let above5lbsPrice = 0;
              let under1lbsPrice = 0;
              if (response.dataAboveFiveLbs.length > 0) {
                above5lbsPrice = await response.dataAboveFiveLbs
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              if (response.dataUnderOneLbs.length > 0) {
                under1lbsPrice = await response.dataUnderOneLbs
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              // console.log("price", price)
              // console.log("above5lbsPrice", above5lbsPrice)

              setTotalAmount(parseFloat(price) + parseFloat(above5lbsPrice) + parseFloat(under1lbsPrice))
              setOrderDetailsModal(!orderDetailsModal)
            }
          }
        )
        document.body.style.pointerEvents = "auto"
      })
    }
    else {
      retrieveOrdersByFilterType().then(() => {
        setVerificationModal(false)
        let body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).id,
          ids: selectedOrders.toString(),
        }
        post("/user/calculate-order-amount", body, { headers }).then(
          async response => {
            if (response.status === 200) {
              console.log("calculate-order-amount data", response);
              setCalculatedAmounts(response.data)
              setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)
              setCalculatedAmountsUnderOneLbs(response.dataUnderOneLbs)

              let price = 0
              if (response.data.length > 0) {
                price = await response.data
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              let above5lbsPrice = 0;
              let under1lbsPrice = 0;
              if (response.dataAboveFiveLbs.length > 0) {
                above5lbsPrice = await response.dataAboveFiveLbs
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              if (response.dataUnderOneLbs.length > 0) {
                under1lbsPrice = await response.dataUnderOneLbs
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              // console.log("price", price)
              // console.log("above5lbsPrice", above5lbsPrice)

              setTotalAmount(parseFloat(price) + parseFloat(above5lbsPrice) + parseFloat(under1lbsPrice))
              setOrderDetailsModal(!orderDetailsModal)
            }
          }
        )

        document.body.style.pointerEvents = "auto"
      })
    }
  }

  const handleBulkDelete = async () => {
    if (selectedOrders.length > 0) {
      let confirmation = confirm(
        "Are you sure! you want to delete these orders?"
      )
      if (confirmation) {
        let loading = toast.loading("Deleting Orders...")
        document.body.style.pointerEvents = "none"
        let body = {
          ids: selectedOrders.toString(),
        }
        post("/user/delete-bulk-orders", body, { headers }).then(
          async response => {
            if (response.status === 200) {
              toast.remove(loading)
              document.body.style.pointerEvents = "auto"
              uncheckAll();
              selectedOrders = [];
              setSelectedOrdersCount(selectedOrders.length);
              clickedSideBarJson = [];
              selectedOrderJson = [];
              setBulkUpdateServiceId("");
              setBulkUpdateToPhone("");
              setEnableBulkUpdateToPhone(false);

              if(selectedGroupBy) {
                handleGroupByOrders().then(() => {
                  toast.success(response.message)
                })
              }
              else {
                retrieveOrdersByFilterType().then(() => {
                  toast.success(response.message)
                })
              }

            } else {
              toast.remove(loading)
              toast.error(response.message)
            }
          }
        )
      }
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleExport = async () => {
    if (selectedOrders.length > 0) {
      let loading = toast.loading("Exporting orders...")
      document.body.style.pointerEvents = "none"
      const header =
        "FromCountry,FromName,FromCompany,FromPhone,FromStreet1,FromStreet2,FromCity,FromZip,FromState,ToCountry,ToName,ToCompany,ToPhone,ToStreet1,ToStreet2,ToCity,ToZip,ToState,Length,Height,Width,Weight"
      let orders = ""

      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/get-bulk-orders-by-ids", body, { headers })
        .then(response => {
          // console.log("bulk orders export", response)
          if (response.status === 200) {
            let ordersByApi = response.data
            for (let i = 0; i < ordersByApi.length; i++) {
              let order = ordersByApi[i]
              orders =
                orders +
                `${order.from_country},${order.from_name},${order.from_company},${order.to_phone},${order.from_street1},${order.from_street2},${order.from_city},${order.from_zip},${order.from_state},${order.to_country},${order.to_name},${order.to_company},${order.to_phone},${order.to_street1},${order.to_street2},${order.to_city},${order.to_zip},${order.to_state},${order.length},${order.height},${order.width},${order.weight}\n`
            }
          }
          // // console.log("orders",orders);
          return response
        })
        .then(response => {
          if (response.status === 200) {
            let data = header + "\n" + orders
            const blob = new Blob([data], { type: "text/csv;charset=utf-8" })
            saveAs(
              blob,
              "exported-order-" +
              moment(new Date()).format("DD-MMM-YYYY") +
              ".csv"
            )
            uncheckAll();
            selectedOrders = [];
            setSelectedOrdersCount(selectedOrders.length);
            clickedSideBarJson = [];
            selectedOrderJson = [];
            setBulkUpdateServiceId("");
            setBulkUpdateToPhone("");
            setEnableBulkUpdateToPhone(false);
            toast.success("Orders exported successfully")
            document.body.style.pointerEvents = "auto"
            toast.remove(loading)
          } else {
            document.body.style.pointerEvents = "auto"
            toast.remove(loading)
            toast.error(response.message)
          }
        })
        .catch((err) => {
          selectedOrders = []
          toast.error("Something went wrong")
          document.body.style.pointerEvents = "auto"
          toast.remove(loading)
        })
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleRetry = async () => {
    if (failedOrders.length > 0) {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        ids: failedOrders.toString(),
      }
      post("/user/calculate-order-amount", body, { headers }).then(
        async response => {
          if (response.status === 200) {
            // // console.log("data", response);
            setCalculatedAmounts(response.data)
            setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)
            setOrderDetailsModal(!orderDetailsModal)
          }
        }
      )
    }
  }

  const upsPrintBulk = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let loading = toast.loading("Processing bulk Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString()
    };
    post('/user/print-batch-orders-ups', body, { headers }).then(async (response) => {
      // toast.remove(loading);
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length);
      clickedSideBarJson = [];
      await getUserBalance();
      if(selectedGroupBy) {
        await handleGroupByOrders(selectedGroupBy);
      }
      else {
        await retrieveOrdersByFilterType();
      }
      uncheckAll();
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length);
      clickedSideBarJson = [];
      selectedOrderJson = [];
      setBulkUpdateServiceId("");
      setBulkUpdateToPhone("");
      setEnableBulkUpdateToPhone(false);
      document.body.style.pointerEvents = "auto";
      if (response.status === 200) {
        toast.remove(loading);
        setResponseSuccess(true);
        setResponseMessage(response.message);
      }
      else {
        toast.remove(loading);
        setResponseSuccess(false);
        setResponseMessage(response.message);
      }
    });
  }

  const upsPrint = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let loading = toast.loading("Processing Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString(),
      totalAmount: parseFloat(totalAmount),
      carrier: 'UPS'
    };
    get('/user/active-service-provider', {headers}).then((apiResponse) => {
      if(apiResponse.status == 200) {
        let url = '';
        if(apiResponse.active_api == 'easypost') {
          url = '/user/print-orders-ups-updated';
        }
        else if(apiResponse.active_api == 'shipengine') {
          url = '/shipengine/create-shipment';
        }
        else if(apiResponse.active_api == 'aftership-label') {
          url = '/aftership/create-shipment';
        }
        if(url) {
          console.log("url",url);
          post(url, body, { headers }).then(async (response) => {
            selectedOrders = [];
            setSelectedOrdersCount(selectedOrders.length);
            clickedSideBarJson = [];
            await getUserBalance();
            if(selectedGroupBy) {
              await handleGroupByOrders(selectedGroupBy);
            }
            else {
              await retrieveOrdersByFilterType();
            }
            uncheckAll();
            document.body.style.pointerEvents = "auto";
            if (response.status === 200) {
              toast.remove(loading);
              setResponseSuccess(true);
              setResponseMessage(response.message);
            }
            else {
              toast.remove(loading);
              setResponseSuccess(false);
              setResponseMessage(response.message);
            }
          });
        }
      }
      else {
        toast.error(apiResponse.message);
      }
    });
  }

  const upsV2Print = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString(),
      totalAmount: parseFloat(totalAmount)
    };
    post('/user/print-orders-ups-v2', body, { headers }).then(async (response) => {
      // if (response.pdf && response.pdf.length > 0) {
      //   const pdfUrls = response.pdf;
      //   const zip = new JSZip();
      //   let loadingComp = toast.loading("Creating compressed file...");
      //   new Promise(async function(resolve, reject) {
      //     for (let i = 0; i < pdfUrls.length; i++) {
      //       await axios.get(`${baseUrl()}/user/get-pdf/${pdfUrls[i]}`, { headers, responseType: "blob" })
      //         .then(async (response) => {
      //           // console.log(response.headers["file-name"]);
      //           const blob = new Blob([response.data], { type: 'application/pdf' });
      //           await zip.file(`${response.headers["file-name"]}.pdf`, blob)
      //
      //           if(i === (pdfUrls.length - 1)) {
      //             resolve(zip);
      //           }
      //         });
      //     }
      //   }).then(async () => {
      //     await zip.generateAsync({ type: "blob" })
      //       .then(function (content) {
      //         toast.remove(loadingComp);
      //         saveAs(content, "orders.zip");
      //       });
      //   })
      // }
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length);
      clickedSideBarJson = [];
      await getUserBalance();
      if(selectedGroupBy) {
        await handleGroupByOrders(selectedGroupBy);
      }
      else {
        await retrieveUpsv2Orders();
      }
      uncheckAll();
      document.body.style.pointerEvents = "auto";
      if (response.status === 200) {
        toast.remove(loading);
        setResponseSuccess(true);
        setResponseMessage(response.message);
      }
      else {
        toast.remove(loading);
        setResponseSuccess(false);
        setResponseMessage(response.message);
      }
      // if(response.success_orders.length > 0 ) {
      //   let body = {
      //     data: response.success_orders
      //   };
      //   // console.log("generate tracking body", body);
      //   await post('/user/generate-tracking', body, {headers});
      // }
    });
  }

  const fedExPrintBulk = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let loading = toast.loading("Processing bulk Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: isRetry ? failedOrders.toString() : selectedOrders.toString()
    };
    post('/user/print-batch-orders-fedex', body, { headers }).then(async (response) => {
      // toast.remove(loading);
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length);
      clickedSideBarJson = [];
      await getUserBalance();
      if(selectedGroupBy) {
        await handleGroupByOrders(selectedGroupBy);
      }
      else {
        await retrieveUpsOrders();
      }
      uncheckAll();
      document.body.style.pointerEvents = "auto";
      if (response.status === 200) {
        toast.remove(loading);
        setResponseSuccess(true);
        setResponseMessage(response.message);
      }
      else {
        toast.remove(loading);
        setResponseSuccess(false);
        setResponseMessage(response.message);
      }
    });
  }

  const fedExPrint = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let loading = toast.loading("Processing Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString(),
      totalAmount: parseFloat(totalAmount),
      carrier: 'FedEx'
    };
    get('/user/active-service-provider', {headers}).then((apiResponse) => {
      if(apiResponse.status == 200) {
        let url = '';
        if(apiResponse.active_api == 'easypost') {
          url = '/user/print-orders-fedex-updated';
        }
        else if(apiResponse.active_api == 'shipengine') {
          url = '/shipengine/create-shipment';
        }
        else if(apiResponse.active_api == 'aftership-label') {
          url = '/aftership/create-shipment';
        }
        if(url) {
          console.log("url",url);
          post(url, body, { headers }).then(async (response) => {
            selectedOrders = [];
            setSelectedOrdersCount(selectedOrders.length);
            clickedSideBarJson = [];
            await getUserBalance();
            if(selectedGroupBy) {
              await handleGroupByOrders(selectedGroupBy);
            }
            else {
              await retrieveOrdersByFilterType();
            }
            uncheckAll();
            document.body.style.pointerEvents = "auto";
            if (response.status === 200) {
              toast.remove(loading);
              setResponseSuccess(true);
              setResponseMessage(response.message);
            }
            else {
              toast.remove(loading);
              setResponseSuccess(false);
              setResponseMessage(response.message);
            }
          });
        }
      }
      else {
        toast.error(apiResponse.message);
      }
    });


    // document.body.style.pointerEvents = "none";
    // setOrderDetailsModal(!orderDetailsModal);
    // let loading = toast.loading("Processing Request...");
    // let body = {
    //   user_id: JSON.parse(localStorage.getItem("authUser")).id,
    //   ids: isRetry ? failedOrders.toString() : selectedOrders.toString(),
    //   totalAmount: parseFloat(totalAmount)
    // };
    // // post('/user/print-batch-orders-fedex', body, { headers }).then(async (response) => {
    // //   // toast.remove(loading);
    // //   selectedOrders = [];
    // //   setSelectedOrdersCount(selectedOrders.length);
    // //   await getUserBalance();
    // //   await retrieveUpsOrders();
    // //   uncheckAll();
    // //   document.body.style.pointerEvents = "auto";
    // //   if (response.status === 200) {
    // //     toast.remove(loading);
    // //     setResponseSuccess(true);
    // //     setResponseMessage(response.message);
    // //   }
    // //   else {
    // //     toast.remove(loading);
    // //     setResponseSuccess(false);
    // //     setResponseMessage(response.message);
    // //   }
    // // });
    //
    // post('/user/print-orders-fedex', body, { headers }).then(async (response) => {
    //   // // console.log("print-orders response", response);
    //   toast.remove(loading);
    //   // if (response.failedOrders.length > 0) {
    //   //   let fo = [];
    //   //   for (let i=0;i<failedOrders.length;i++) {
    //   //     fo.push(failedOrders[i].order_id);
    //   //   }
    //   //   setFailedOrders(fo);
    //   //   setIsRetry(true);
    //   // }
    //   // else {
    //   //   setFailedOrders([]);
    //   //   setIsRetry(false);
    //   // }
    //   // if (response.pdf && response.pdf.length > 0) {
    //   //   const pdfUrls = response.pdf;
    //   //   const zip = new JSZip();
    //   //
    //   //   let loadingComp = toast.loading("Creating compressed file...");
    //   //   new Promise(async function(resolve, reject) {
    //   //     for (let i = 0; i < pdfUrls.length; i++) {
    //   //       await axios.get(`${baseUrl()}/user/get-pdf/${pdfUrls[i]}`, { headers })
    //   //         .then(async (response) => {
    //   //           // console.log(response.headers["file-name"]);
    //   //           await zip.file(`${response.headers["file-name"]}.pdf`, response.data)
    //   //           if(i === (pdfUrls.length - 1)) {
    //   //             resolve(zip);
    //   //           }
    //   //         });
    //   //     }
    //   //   }).then(async () => {
    //   //     await zip.generateAsync({ type: "blob" })
    //   //       .then(function (content) {
    //   //         toast.remove(loadingComp);
    //   //         saveAs(content, "orders.zip");
    //   //       });
    //   //   })
    //   // }
    //   selectedOrders = [];
    //   setSelectedOrdersCount(selectedOrders.length);
    //   clickedSideBarJson = [];
    //   if(selectedGroupBy) {
    //     await handleGroupByOrders(selectedGroupBy);
    //   }
    //   else {
    //     await retrieveFedexOrders();
    //   }
    //   uncheckAll();
    //   document.body.style.pointerEvents = "auto";
    //   if (response.status === 200) {
    //     toast.remove(loading);
    //     setResponseSuccess(true);
    //     setResponseMessage(response.message);
    //   }
    //   else {
    //     toast.remove(loading);
    //     setResponseSuccess(false);
    //     setResponseMessage(response.message);
    //   }
    //
    //   // if(response.success_orders.length > 0 ) {
    //   //   let body = {
    //   //     data: response.success_orders
    //   //   };
    //   //   // console.log("generate tracking body", body);
    //   //   await post('/user/generate-tracking', body, {headers});
    //   // }
    // });
  }

  const dhlPrint = async () => {
    document.body.style.pointerEvents = "none"
    setOrderDetailsModal(!orderDetailsModal)
    let loading = toast.loading("Processing Request...")
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString(),
      totalAmount: parseFloat(totalAmount)
    }
    post("/user/submit-orders-dhl-usps", body, { headers }).then(
      async response => {
        // // console.log("print-orders response", response);
        setTimeout(async () => {
          toast.remove(loading)
          await getUserBalance()
          if(selectedGroupBy) {
            await handleGroupByOrders(selectedGroupBy);
          }
          else {
            await retrieveDhlOrders()
          }
          uncheckAll();
          selectedOrders = [];
          setSelectedOrdersCount(selectedOrders.length);
          clickedSideBarJson = [];
          document.body.style.pointerEvents = "auto"
          if (response.status === 200) {
            setResponseSuccess(true)
            setResponseMessage(response.message)
          } else {
            toast.remove(loading)
            setResponseSuccess(false)
            setResponseMessage(response.message)
          }
        }, 3000)
      }
    )
  }

  const uspsPrint = async () => {
    document.body.style.pointerEvents = "none";
    setOrderDetailsModal(!orderDetailsModal);
    let loading = toast.loading("Processing Request...");
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString(),
      totalAmount: parseFloat(totalAmount)
    };
    // post('/user/print-batch-orders-ups', body, { headers }).then(async (response) => {
    //   // toast.remove(loading);
    //   selectedOrders = [];
    //   setSelectedOrdersCount(selectedOrders.length);
    //   await getUserBalance();
    //   await retrieveUpsOrders();
    //   uncheckAll();
    //   document.body.style.pointerEvents = "auto";
    //   if (response.status === 200) {
    //     toast.remove(loading);
    //     setResponseSuccess(true);
    //     setResponseMessage(response.message);
    //   }
    //   else {
    //     toast.remove(loading);
    //     setResponseSuccess(false);
    //     setResponseMessage(response.message);
    //   }
    // });

    //use labelaxxess for live
    //use updated for beta
    post('/user/print-orders-usps-updated', body, { headers }).then(async (response) => {
      // // console.log("response", response);
      // if (response.failedOrders.length > 0) {
      //   let fo = [];
      //   for (let i=0;i<failedOrders.length;i++) {
      //     fo.push(failedOrders[i].order_id);
      //   }
      //   setFailedOrders(fo);
      //   setIsRetry(true);
      // }
      // else {
      //   setFailedOrders([]);
      //   setIsRetry(false);
      // }
      // toast.remove(loading);
      // if (response.pdf && response.pdf.length > 0) {
      //   const pdfUrls = response.pdf;
      //   const zip = new JSZip();
      //   let loadingComp = toast.loading("Creating compressed file...");
      //   new Promise(async function(resolve, reject) {
      //     for (let i = 0; i < pdfUrls.length; i++) {
      //       await axios.get(`${baseUrl()}/user/get-pdf/${pdfUrls[i]}`, { headers })
      //         .then(async (response) => {
      //           // console.log(response.headers["file-name"]);
      //           await zip.file(`${response.headers["file-name"]}.pdf`, response.data)
      //
      //           if(i === (pdfUrls.length - 1)) {
      //             resolve(zip);
      //           }
      //         });
      //     }
      //   }).then(async () => {
      //     await zip.generateAsync({ type: "blob" })
      //       .then(function (content) {
      //         toast.remove(loadingComp);
      //         saveAs(content, "orders.zip");
      //       });
      //   })
      // }
      uncheckAll();
      selectedOrders = [];
      setSelectedOrdersCount(selectedOrders.length);
      clickedSideBarJson = [];
      await getUserBalance();
      if(selectedGroupBy) {
        await handleGroupByOrders(selectedGroupBy);
      }
      else {
        await retrieveUspsOrders();
      }

      document.body.style.pointerEvents = "auto";
      if (response.status === 200) {
        toast.remove(loading);
        setResponseSuccess(true);
        setResponseMessage(response.message);
      }
      else {
        toast.remove(loading);
        setResponseSuccess(false);
        setResponseMessage(response.message);
      }

      // if(response.success_orders.length > 0 ) {
      //   let body = {
      //     data: response.success_orders
      //   };
      //   // console.log("generate tracking body", body);
      //   await post('/user/generate-tracking', body, {headers});
      // }
    });
  }

  const handleBulkProceed = async () => {
    if (selectedOrders.length > 0 || failedOrders.length > 0) {
      if (filterType === "FedEx") {
        // //FedEx
        // console.log("printing fedex orders");
        await fedExPrintBulk();
      }
      else if (filterType === "UPS") {
        // //UPS
        // console.log("printing ups orders");
        await upsPrintBulk();
      }
    }
    else {
      toast.error("Please select at least one order");
    }
  }

  const handleProceed = async () => {
    if (selectedOrders.length > 0 || failedOrders.length > 0) {
      if (filterType === "FedEx") {
        // //FedEx
        // console.log("printing fedex orders")
        await fedExPrint()
      } else if (filterType === "UPS") {
        // //UPS
        // console.log("printing ups orders")
        await upsPrint()
      } else if (filterType === "UPSv2") {
        // //UPS
        // console.log("printing ups orders")
        await upsV2Print()
      } else if (filterType === "DHL") {
        // //DHL
        // console.log("printing dhl orders")
        await dhlPrint()
      } else if (filterType === "USPS") {
        // //DHL
        // console.log("printing usps orders")
        await uspsPrint()
      }
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleService = async e => {
    const { value } = e.target
    // console.log("selected service", selectedService)
    setSelectedService(value)
    setSelectedServiceName(e.target.getAttribute("data-name"))
  }

  const handleNoServiceOrders = async () => {
    // document.getElementsByClassName("filter-text")[0].value = ""
    setFilterType("Unassigned")
    selectedGroupBy = "";
    setGroupByDataTables([])
    setGroupByDataTablesAll([]);
    selectedOrders = []
    selectedOrderJson = [];
    setSelectedOrdersCount(selectedOrders.length);
    clickedSideBarJson = [];
    uncheckAll()
    await retrieveUnassignedOrders()
  }

  const handleFedExOrders = async () => {
    // document.getElementsByClassName("filter-text")[0].value = ""
    selectedGroupBy = "";
    setGroupByDataTables([]);
    setGroupByDataTablesAll([]);
    setFilteredData([]);
    setAllData([]);
    setFilterType("FedEx")
    selectedOrders = []
    selectedOrderJson = [];
    setSelectedOrdersCount(selectedOrders.length);
    clickedSideBarJson = [];
    uncheckAll()
    await retrieveFedexOrders()
  }

  const handleUPSOrders = async () => {
    // document.getElementsByClassName("filter-text")[0].value = ""
    selectedGroupBy = "";
    setGroupByDataTables([]);
    setGroupByDataTablesAll([]);
    setFilteredData([]);
    setAllData([]);
    setFilterType("UPS")
    selectedOrders = []
    selectedOrderJson = [];
    setSelectedOrdersCount(selectedOrders.length);
    clickedSideBarJson = [];
    uncheckAll()
    await retrieveUpsOrders()
  }

  const handleUPSv2Orders = async () => {
    // document.getElementsByClassName("filter-text")[0].value = ""
    selectedGroupBy = "";
    setGroupByDataTables([]);
    setGroupByDataTablesAll([]);
    setFilteredData([]);
    setAllData([]);
    setFilterType("UPSv2")
    selectedOrders = []
    selectedOrderJson = [];
    setSelectedOrdersCount(selectedOrders.length);
    clickedSideBarJson = [];
    uncheckAll()
    await retrieveUpsv2Orders()
  }

  const handleDHLOrders = async () => {
    // document.getElementsByClassName("filter-text")[0].value = ""
    selectedGroupBy = "";
    setGroupByDataTables([]);
    setGroupByDataTablesAll([]);
    setFilteredData([]);
    setAllData([]);
    setFilterType("DHL")
    selectedOrders = []
    selectedOrderJson = [];
    setSelectedOrdersCount(selectedOrders.length);
    clickedSideBarJson = [];
    uncheckAll()
    await retrieveDhlOrders()
  }

  const handleUSPSOrders = async () => {
    // document.getElementsByClassName("filter-text")[0].value = ""
    selectedGroupBy = "";
    setGroupByDataTables([]);
    setGroupByDataTablesAll([]);
    setFilteredData([]);
    setAllData([]);
    setFilterType("USPS")
    selectedOrders = []
    selectedOrderJson = [];
    setSelectedOrdersCount(selectedOrders.length);
    clickedSideBarJson = [];
    uncheckAll()
    retrieveUspsOrders()
  }

  const handleCarrier = async e => {
    setSelectedCarrier(e.target.value)
    setSelectedCarrierName(e.target.getAttribute("data-name"))
    setSelectedService("")
    setSelectedServiceName(null)
    setSelectAvailableService(false)
    setSelectAvailableCarrier(false)
    const ele = document.getElementsByName("service_id")
    for (let i = 0; i < ele.length; i++) {
      ele[i].checked = false
    }
  }

  const handleImport = () => {
    setModal(!modal)
    setSelectedMeasurement("pounds");
    setSelectedCarrier("")
    setSelectedCarrierName(null)
    setSelectedService("")
    setSelectedServiceName(null)
    setSelectAvailableService(false)
    setSelectAvailableCarrier(false)
    const ele = document.getElementsByName("service_id")
    for (let i = 0; i < ele.length; i++) {
      ele[i].checked = false
    }
  }

  const handleImportWithItems = () => {
    setModalItems(!modalItems)
    setSelectedMeasurement("pounds");
    setSelectedCarrier("")
    setSelectedCarrierName(null)
    setSelectedService("")
    setSelectedServiceName(null)
    setSelectAvailableService(false)
    setSelectAvailableCarrier(false)
    const ele = document.getElementsByName("service_id")
    for (let i = 0; i < ele.length; i++) {
      ele[i].checked = false
    }
  }
  const [currentStore, setCurrentStore] = useState([]);
  const retrieveStores = async () => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      };
      post('/user/getStores', body, {headers}).then((response) => {
        // console.log("stores in nav", response);
        let arr = [];
        arr.push({
          "name": "Manual Store",
          "value": "0",
          "type": "manual"
        })
        for (let i=0;i<response.data.length;i++) {
          arr.push({
            "name": response.data[i].name,
            "value": response.data[i].id.toString(),
            "type": response.data[i].store_type
          })
        }

      // console.log("user stores",arr);
        setStores(arr);
        setCurrentStore(arr.filter(el => el.value == localStorage.getItem("store"))[0]);

        if(arr.filter(el => el.value == localStorage.getItem("store"))[0].type == "zenventory") {
          hideShowZenventory = false;
        }
        else {
          hideShowZenventory = true;
        }

        columns[12].omit = hideShowZenventory;

        if(arr.filter(el => el.value == localStorage.getItem("store"))[0].type == "sellercloud") {
          // hideShowPicklists = false;
          hideShowChannelNumber = false;
        }
        else {
          // hideShowPicklists = true;
          hideShowChannelNumber = true;
        }

        // columns[13].omit = hideShowPicklists;
        columns[14].omit = hideShowChannelNumber;
      })
    }
    catch (e) {
    // console.log(e)
    }
  }


  document.title = "Awaiting Shipment | ShipCheap";

  const retrieveOrder = async oid => {
    try {
      let body = {
        id: oid,
      }
      post("/user/get-order", body, { headers }).then(async response => {
        // console.log("get order", response)
        let data = response.data[0]
        setSingleOrderWeight(data.weight)
        setSingleOrderServiceId(data.service_id != null ? data.service_id : "")
        setSingleOrderWidth(data.width != null ? data.width : "")
        setSingleOrderHeight(data.height != null ? data.height : "")
        setSingleOrderLength(data.length != null ? data.length : "")
        setSingleOrderRate("")
        if (data.weight && data.service_id != null) {
          const body = {
            user_id: JSON.parse(localStorage.getItem("authUser")).id,
            service_id: data.service_id,
            weight: data.weight,
          }
          await post("/shopify/get-service-rates-by-weight-and-service", body, {
            headers,
          }).then(response => {
            // console.log("get rate response", response)
            if (response.status === 200) {
              setSingleOrderRate(response.data.servicePrice)
            } else {
              // setResponseSuccess(false);
              // setResponseMessage(response.message);
            }
          })
        }
      })
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveOrderWeight = async oid => {
    return new Promise((resolve) => {
      try {
        let body = {
          id: oid,
        }
        post("/user/get-order-weight", body, { headers }).then(async response => {
          // console.log("response",response)
          let data = response.data[0];
          console.log("data",data)
          return resolve(data);
        })
      } catch (e) {
        // console.log(e)
      }
    })
  }

  const handleRowClick = row => {
    // Handle row click event here
    // let data = row
    // // console.log("data", JSON.parse(data.custom_items_data))
    // // // console.log("data.id", data.id)
    // // // console.log("tableData", tableData)
    // let index = tableData.data.findIndex(el => el.id == data.id)
    // setOrderUniqueId(tableData.data[index].order_unique_id)
    //
    // retrieveOrder(data.id)
    // uncheckAll();
    // document.getElementById(`order-${data.id}`).checked = true;
    // selectedOrders = [data.id];
    // // console.log("handleRowClick selectedOrders", selectedOrders);
    // selectedOrderJson.push(data);
    // // console.log("selectedOrderJson", selectedOrderJson);
    // setClickedJson(selectedOrderJson);
    // console.log(`order-${row.id}`)
    document.getElementById(`order-${row.id}`).click();
  }

  const handleOrderWeight = async e => {
    setSingleOrderWeight(e.target.value)
    if (e.target.value && parseFloat(e.target.value) > 0 && SingleOrderServiceId) {
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        service_id: SingleOrderServiceId,
        weight: e.target.value,
      }
      await post("/shopify/get-service-rates-by-weight-and-service", body, {
        headers,
      }).then(response => {
        // console.log("get rate response", response)
        if (response.status === 200) {
          setSingleOrderRate(response.data.servicePrice)
        } else {
          // setResponseSuccess(false);
          // setResponseMessage(response.message);
        }
      })
    }
  }

  const handleServiceOrder = async e => {
    setSingleOrderServiceId(e.target.value)
    let index = services.findIndex(el => el.id == e.target.value)
    if (
      services[index].carrier_name == "DHL" ||
      services[index].carrier_name == "USPS"
    ) {
      setSingleIsDimenssion(true)
    } else {
      setSingleIsDimenssion(false)
    }
    if (e.target.value && parseFloat(singleOrderWeight) > 0 && singleOrderWeight) {
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        service_id: e.target.value,
        weight: singleOrderWeight,
      }
      await post("/shopify/get-service-rates-by-weight-and-service", body, {
        headers,
      }).then(response => {
        // console.log("get rate response", response)
        if (response.status === 200) {
          setSingleOrderRate(response.data.servicePrice)
        } else {
          // setResponseSuccess(false);
          // setResponseMessage(response.message);
        }
      })
    } else {
      toast.error("Please select an service")
    }
  }

  const handleServiceOrderBelowFiveLbs = async (e) => {
    if (e.target.value) {
      setBelowFiveLbsService(e.target.value);
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        service_id: e.target.value,
        weight: 1,
      }
      await post("/shopify/get-service-rates-by-weight-and-service", body, {
        headers,
      }).then(response => {
        // console.log("get rate response", response)
        if (response.status === 200) {
          setServiceRateBelowFiveLbs(response.data.servicePrice)
        } else {
          toast.error(response.message);
        }
      })
    }
  }

  const handleServiceOrderAboveFiveLbs = async (e) => {
    if (e.target.value) {
      setAboveFiveLbsService(e.target.value);
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        service_id: e.target.value,
        weight: 6,
      }
      await post("/shopify/get-service-rates-by-weight-and-service", body, {
        headers,
      }).then(response => {
        // console.log("get rate response", response)
        if (response.status === 200) {
          setServiceRateAboveFiveLbs(response.data.servicePrice)
        } else {
          toast.error(response.message);
        }
      })
    }
  }

  const handleServiceOrderUnderOneLbs = async (e) => {
    if (e.target.value) {
      setUnderOneLbsService(e.target.value);
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        service_id: e.target.value,
        weight: 0.5,
      }
      await post("/shopify/get-service-rates-by-weight-and-service", body, {
        headers,
      }).then(response => {
        // console.log("get rate response", response)
        if (response.status === 200) {
          setServiceRateUnderOneLbs(response.data.servicePrice)
        } else {
          toast.error(response.message);
        }
      })
    }
  }

  const singleOrderUpdate = async () => {
    // console.log("working...")
    if (singleOrderWeight && SingleOrderServiceId) {
      if(singleOrderWeight == 0) {
        toast.error("Shipment weight must be greater than 0")
        return true;
      }
      if(clickedJson.length == 1) {
        const body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).id,
          service_id: SingleOrderServiceId,
          weight: singleOrderWeight,
          order_id: selectedOrders[0],
          length: singleOrderLength,
          width: singleOrderWidth,
          height: singleOrderHeight,
        }
        await post("/shopify/update-single-order", body, {
          headers,
        }).then(async response => {
          // console.log("get rate response", response)
          if(selectedGroupBy) {
            await handleGroupByOrders(selectedGroupBy);
          }
          else {
            await retrieveOrdersByFilterType();
          }

          if (response.status === 200) {
            toast.success(response.message)
            document.body.style.overflow = "visible"
            setClickedJson([])
            let x = [];
            clickedSideBarJson = x;
            uncheckAll();
            selectedOrders = [];
            setSelectedOrdersCount(selectedOrders.length);
            clickedSideBarJson = [];
            selectedOrderJson = [];
            setSingleOrderWeight("")
            setSingleOrderServiceId("")
            setSingleOrderWidth("")
            setSingleOrderHeight("")
            setSingleOrderLength("")
            setIsFullTextVisible(false);

            // setShowSideBar(false);

          } else {
            toast.error(response.message)
          }
        })
      }
      else {
        const body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).id,
          service_id: SingleOrderServiceId,
          weight: singleOrderWeight,
          order_ids: selectedOrders.toString(),
          length: singleOrderLength,
          width: singleOrderWidth,
          height: singleOrderHeight,
        }
        await post("/shopify/update-bulk-orders", body, {
          headers,
        }).then(async response => {
          // console.log("get rate response", response)
          if(selectedGroupBy) {
            await handleGroupByOrders(selectedGroupBy);
          }
          else {
            await retrieveOrdersByFilterType();
          }
          if (response.status === 200) {
            toast.success(response.message)
            document.body.style.overflow = "visible"
            setClickedJson([])
            uncheckAll();
            selectedOrders = [];
            setSelectedOrdersCount(selectedOrders.length);
            clickedSideBarJson = [];
            selectedOrderJson = [];
            setSingleOrderWeight("")
            setSingleOrderServiceId("")
            setSingleOrderWidth("")
            setSingleOrderHeight("")
            setSingleOrderLength("")
          } else {
            toast.error(response.message)
          }
        })
      }
    } else {
      toast.error("Please fill required fields")
    }
  }

  const updateBulkOrders = async () => {
    if(bulkUpdateServiceId) {
      if(enableBulkUpdateToPhone) {
        if(bulkUpdateToPhone.length < 10) {
          toast.error("Ship to phone must contain 10 or more digits")
          return false;
        }
      }

      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        service_id: bulkUpdateServiceId,
        order_ids: selectedOrders.toString(),
        update_ship_to_phone: enableBulkUpdateToPhone,
        ship_to_phone: bulkUpdateToPhone
      }

      await post("/user/update-bulk-orders-service", body, {
        headers,
      }).then(async response => {
        if(selectedGroupBy) {
          await handleGroupByOrders(selectedGroupBy);
        }
        else {
          await retrieveOrdersByFilterType();
        }
        if (response.status === 200) {
          toast.success(response.message)
          setClickedBulkUpdateJson([])
          uncheckAll();
          selectedOrders = [];
          setSelectedOrdersCount(selectedOrders.length);
          clickedSideBarJson = [];
          selectedOrderJson = [];
          setBulkUpdateServiceId("");
          setBulkUpdateToPhone("");
          setEnableBulkUpdateToPhone(false);
          document.getElementById("bulk-update-enable-to-phone").checked = false;
          setIsFullTextVisible1(false);
        } else {
          toast.error(response.message)
        }
      });
    } else {
      toast.error("Please fill required fields")
    }
  }

  const fedexValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-fedex-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          if(response.status == 200) {
            resolve(response)
          }
          else{
            toast.error(response.message)
          }
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const upsValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    // console.log("selectedOrders",selectedOrders);
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-ups-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          if(response.status == 200) {
            resolve(response)
          }
          else{
            toast.error(response.message)
          }
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const upsV2Validation = async () => {
    let loading = toast.loading("Validating Orders...");
    return new Promise(function(resolve, reject) {
      let body = {
        ids: selectedOrders.toString()
      }
      post('/user/validate-ups-v2-orders', body, {headers}).then((response) => {
        toast.remove(loading);
        document.body.style.pointerEvents = "auto";
        if(response.status == 200) {
          resolve(response)
        }
        else{
          toast.error(response.message)
        }
      }).catch((err) => {
        resolve(err);
        // console.log("validation error",err)
        toast.remove(loading);
        document.body.style.pointerEvents = "auto";
        toast.error("Your request was not processed completely, Please try again")
      });
    })
  }

  const dhlValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-dhl-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          if(response.status == 200) {
            resolve(response)
          }
          else{
            toast.error(response.message)
          }
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const uspsValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-usps-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          if(response.status == 200) {
            resolve(response)
          }
          else{
            toast.error(response.message)
          }
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const noServiceValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-shopify-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          if(response.status == 200) {
            resolve(response)
          }
          else{
            toast.error(response.message)
          }
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const handleValidate = async () => {
    document.body.style.pointerEvents = "none"
    if (filterType == "FedEx") {
      let response = await fedexValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (filterType == "UPS") {
      let response = await upsValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (filterType == "UPSv2") {
      let response = await upsV2Validation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (filterType == "DHL") {
      let response = await dhlValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (filterType == "USPS") {
      let response = await uspsValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    }
    else if(filterType == "Unassigned") {
      let response = await noServiceValidation();
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    }
  }

  const handleVerification = async () => {
    if (selectedOrders.length > 0) {
      if(!selectedGroupBy) {
        for (let i=0;i<selectedOrders.length;i++) {
          // console.log("selectedOrders[i]",selectedOrders[i]);
          let index = filteredData.findIndex(el => el.id == selectedOrders[i]);
          // console.log("index",index, "data[index]",filteredData[index]);
          if(filteredData[index].service_id == null) {
            toast.error("Selected orders do not have any associated service")
            return false;
          }
        }
      }

      handleValidate().then(data => {
        if(data.orders.length == 0) {
          uncheckAll();
          selectedOrders = [];
          setSelectedOrdersCount(selectedOrders.length);
          clickedSideBarJson = [];
          selectedOrderJson = [];
          setBulkUpdateServiceId("");
          setBulkUpdateToPhone("");
          setEnableBulkUpdateToPhone(false);
        }
        document.body.style.pointerEvents = "auto"
        setVerificationModal(true)
        setValTime(3);
        let x = 0;
        const intrvl = setInterval(() => {
          setValTime(parseInt(validationTimeout) - parseInt(x));
          x++;
          if((parseInt(validationTimeout) - parseInt(x)) == -1) {
          // console.log("click validate-btn");
            document.getElementById("validate-btn").click();
            clearInterval(intrvl);
          }
        },1000);
      })
    } else {
      toast.error("Please select at least one order")
    }
  }

  // customValidation
  const handleCustomCalculatedPrice = async () => {
    if(selectedGroupBy) {
      await handleGroupByOrders(selectedGroupBy).then(() => {
        setCustomVerificationModal(false)
        let body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).id,
          ids: selectedOrders.toString(),
        }
        post("/user/calculate-order-amount", body, { headers }).then(
          async response => {
            if (response.status === 200) {
              // // console.log("data", response);
              setCalculatedAmounts(response.data)
              setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)

              let price = 0
              if (response.data.length > 0) {
                price = await response.data
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              let above5lbsPrice = 0
              if (response.dataAboveFiveLbs.length > 0) {
                above5lbsPrice = await response.dataAboveFiveLbs
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              // console.log("price", price)
              // console.log("above5lbsPrice", above5lbsPrice)

              setTotalAmount(parseFloat(price) + parseFloat(above5lbsPrice))
              setOrderDetailsModal(!orderDetailsModal)
            }
          }
        )
      });
    }
    else {
      retrieveOrdersByFilterType().then(() => {
        setCustomVerificationModal(false)
        let body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).id,
          ids: selectedOrders.toString(),
        }
        post("/user/calculate-order-amount", body, { headers }).then(
          async response => {
            if (response.status === 200) {
              // // console.log("data", response);
              setCalculatedAmounts(response.data)
              setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)

              let price = 0
              if (response.data.length > 0) {
                price = await response.data
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              let above5lbsPrice = 0
              if (response.dataAboveFiveLbs.length > 0) {
                above5lbsPrice = await response.dataAboveFiveLbs
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              // console.log("price", price)
              // console.log("above5lbsPrice", above5lbsPrice)

              setTotalAmount(parseFloat(price) + parseFloat(above5lbsPrice))
              setOrderDetailsModal(!orderDetailsModal)
            }
          }
        )
      })
    }
  }

  const fedexCustomValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-fedex-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const upsCustomValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-ups-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const dhlCustomValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-dhl-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const uspsCustomValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-usps-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const handleCustomOrderValidate = async () => {
    document.body.style.pointerEvents = "none"
    let index = services.findIndex(el => el.id == SingleOrderServiceId)
    if (services[index].carrier_name == "FedEx") {
      let response = await fedexCustomValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (services[index].carrier_name == "UPS") {
      let response = await upsCustomValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (services[index].carrier_name == "DHL") {
      let response = await dhlCustomValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (services[index].carrier_name == "USPS") {
      let response = await uspsCustomValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    }
  }

  const handleCustomOrderVerification = async () => {
    if (selectedOrders.length > 0) {
      handleCustomOrderValidate().then(data => {
        // console.log("handleCustomOrderValidate", data)
        document.body.style.pointerEvents = "auto"
        setCustomVerificationModal(!customVerificationModal)
        setClickedJson([])
        document.body.style.overflow = "visible"
      })
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleSort = (name_sort, weight_sort, quantity_sort, order_date, tag_filter, destination, validation, picklist, filtered_Data) => {
    if(selectedGroupBy != "") {
      // let first_data = [...filtered_Data];
      let finalData = [...filtered_Data];
      let data = [];

      // console.log("filtered_Data",finalData)
     if(destination) {
     // console.log("destinationdestinationdestination",destination)
       for (let i = 0;i<finalData.length;i++) {
         if (destination == "International") {
           data.push({"name":finalData[i].name, "data": finalData[i].data.filter(el => el.from_country != el.to_country)});
         }
         else if(destination == "Domestic") {
           data.push({"name":finalData[i].name, "data": finalData[i].data.filter(el => el.from_country == el.to_country)});
         }
         else {
           data.push({"name":finalData[i].name, "data": finalData[i].data});
         }
       }
     }
     else {
       for (let i = 0;i<finalData.length;i++) {
         data.push({"name":finalData[i].name,"data": finalData[i].data});
       }
     }

      for (let i = 0;i<data.length;i++) {
        if(name_sort) {
          if(name_sort == "ASC") {
            data[i].data.sort((a, b) => a.to_name.localeCompare(b.to_name));
          }
          else if (name_sort == "DESC") {
            data[i].data.sort((a, b) => b.to_name.localeCompare(a.to_name));
          }
        }

        if(weight_sort) {
          if(weight_sort == "ASC") {
            data[i].data.sort((a, b) => parseFloat(a.weight) - parseFloat(b.weight));
          }
          else if (weight_sort == "DESC") {
            data[i].data.sort((a, b) => parseFloat(b.weight) - parseFloat(a.weight));
          }
        }

        if(order_date) {
          // if(order_date == "ASC") {
          //   data.sort((a, b) => a.updated_at.localeCompare(b.updated_at));
          // }
          // else if (order_date == "DESC") {
          //   data.sort((a, b) => b.updated_at.localeCompare(a.updated_at));
          // }
          if(order_date == "Today") {
            data[i].data = data[i].data.filter((el) => moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") == moment(new Date()).tz(moment.tz.guess()).format("YYYY-MM-DD"));
          }
          else if(order_date == "Yesterday") {
          // console.log("moment(new Date()).tz(moment.tz.guess()).subtract(1, 'day').format(\"YYYY-MM-DD\")",moment(new Date()).tz(moment.tz.guess()).subtract(1, 'day').format("YYYY-MM-DD"));
            data[i].data = data[i].data.filter((el) => moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") >= moment(new Date()).tz(moment.tz.guess()).subtract(1, 'day').format("YYYY-MM-DD"));
          }
          else if(order_date == "Last Week") {
          // console.log("fff",moment(new Date()).tz(moment.tz.guess()).subtract(1, 'week').format("YYYY-MM-DD"));
            data[i].data = data[i].data.filter((el) => moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") >= moment(new Date()).tz(moment.tz.guess()).subtract(1, 'week').format("YYYY-MM-DD"));
          }
          else if(order_date == "Last Month") {
            data[i].data = data[i].data.filter((el) => moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") >= moment(new Date()).tz(moment.tz.guess()).subtract(1, 'month').format("YYYY-MM-DD"));
          }
          else if(order_date == "Last Year") {
            data[i].data = data[i].data.filter((el) => moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") >= moment(new Date()).tz(moment.tz.guess()).subtract(1, 'year').format("YYYY-MM-DD"));
          }
        }

        if(quantity_sort) {
          if(quantity_sort == "ASC") {
            data[i].data.sort((a, b) => {
              if (a.custom_items_data && b.custom_items_data) {
                return JSON.parse(a.custom_items_data).length - JSON.parse(b.custom_items_data).length;
              }
            });
          }
          else if (quantity_sort == "DESC") {
            data[i].data.sort((a, b) => {
              if (a.custom_items_data && b.custom_items_data) {
                return JSON.parse(b.custom_items_data).length - JSON.parse(a.custom_items_data).length; // Compare normal ages
              }
            });
          }
        }

        if(tag_filter) {
          if(tag_filter != "none") {
          // console.log("tag_filter",tag_filter);
            // console.log("data.filter((el) => el.tag != null)",data.filter((el) => el.tag != null));
            data[i].data = data[i].data.filter((el) => el.tag && el.tag?.toString().includes(tag_filter));
          }
        }

        if(picklist.length > 0) {
          // console.log("tag_filter",tag_filter);
          // console.log("data.filter((el) => el.tag != null)",data.filter((el) => el.tag != null));
          let picklistFilteredData = [];
          data[i].data.map((d) => {
            for (let p=0;p<picklist.length;p++) {
              if (d.picklists && d.picklists?.toString().includes(picklist[p])) {
                picklistFilteredData.push(d);
              }
            }
          });
          data[i].data = picklistFilteredData;
        }

        // if(picklist) {
        //   if(picklist != "none") {
        //     // console.log("tag_filter",tag_filter);
        //     // console.log("data.filter((el) => el.tag != null)",data.filter((el) => el.tag != null));
        //     data[i].data = data[i].data.filter((el) => el.picklists && el.picklists?.toString().includes(picklist));
        //   }
        // }
      }

      console.log("data",data);
      let final = [];
      for (let i=0;i<data.length;i++) {
        if (data[i].data.length > 0) {
          final.push(data[i]);
        }
      }

      if(final.length == 0) {
        final.push({"name":"-","data":[]});
      }

    // console.log("first_data",final);
      setGroupByDataTables(final);
      document.getElementsByClassName("right-bar-enabled")[0].click();
    }
    else {
      let data1 = [...filtered_Data];
      let data = [];
      if(destination) {
        if (destination == "All") {
          data = data1;
        }
        if (destination == "International") {
          data1.map(el => {
            if(el.from_country != el.to_country) {
              data.push(el);
            }
          });
        }
        else if (destination == "Domestic") {
          data1.map(el => {
            if(el.from_country == el.to_country) {
              data.push(el);
            }
          });
        }
      }
      else {
        data = data1;
      }


    // console.log("data after",data);

      // let data = [...filtered_Data];

      if(name_sort) {
        if(name_sort == "ASC") {
          data.sort((a, b) => a.to_name.localeCompare(b.to_name));
        }
        else if (name_sort == "DESC") {
          data.sort((a, b) => b.to_name.localeCompare(a.to_name));
        }
      }

      if(weight_sort) {
        if(weight_sort == "ASC") {
          data.sort((a, b) => parseFloat(a.weight) - parseFloat(b.weight));
        }
        else if (weight_sort == "DESC") {
          data.sort((a, b) => parseFloat(b.weight) - parseFloat(a.weight));
        }
      }

      if(order_date) {
        // if(order_date == "ASC") {
        //   data.sort((a, b) => a.updated_at.localeCompare(b.updated_at));
        // }
        // else if (order_date == "DESC") {
        //   data.sort((a, b) => b.updated_at.localeCompare(a.updated_at));
        // }
        if(order_date == "Today") {
          data = data.filter((el) => moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") == moment(new Date()).tz(moment.tz.guess()).format("YYYY-MM-DD"));
        }
        else if(order_date == "Yesterday") {
        // console.log("moment(new Date()).tz(moment.tz.guess()).subtract(1, 'day').format(\"YYYY-MM-DD\")",moment(new Date()).tz(moment.tz.guess()).subtract(1, 'day').format("YYYY-MM-DD"));
          data = data.filter((el) => moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") >= moment(new Date()).tz(moment.tz.guess()).subtract(1, 'day').format("YYYY-MM-DD"));
        }
        else if(order_date == "Last Week") {
        // console.log("fff",moment(new Date()).tz(moment.tz.guess()).subtract(1, 'week').format("YYYY-MM-DD"));
          data = data.filter((el) => moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") >= moment(new Date()).tz(moment.tz.guess()).subtract(1, 'week').format("YYYY-MM-DD"));
        }
        else if(order_date == "Last Month") {
          data = data.filter((el) => moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") >= moment(new Date()).tz(moment.tz.guess()).subtract(1, 'month').format("YYYY-MM-DD"));
        }
        else if(order_date == "Last Year") {
          data = data.filter((el) => moment(el.created_at).tz(moment.tz.guess()).format("YYYY-MM-DD") >= moment(new Date()).tz(moment.tz.guess()).subtract(1, 'year').format("YYYY-MM-DD"));
        }
      }

      if(quantity_sort) {
        if(quantity_sort == "ASC") {
          data.sort((a, b) => {
            if (a.custom_items_data && b.custom_items_data) {
              return JSON.parse(a.custom_items_data).length - JSON.parse(b.custom_items_data).length;
            }
          });
        }
        else if (quantity_sort == "DESC") {
          data.sort((a, b) => {
            if (a.custom_items_data && b.custom_items_data) {
              return JSON.parse(b.custom_items_data).length - JSON.parse(a.custom_items_data).length; // Compare normal ages
            }
          });
        }
      }

      if(tag_filter) {
        if(tag_filter != "none") {
          // console.log("tag_filter",tag_filter);
          // console.log("data.filter((el) => el.tag != null)",data.filter((el) => el.tag != null));
          data = data.filter((el) => el.tag && el.tag?.toString().includes(tag_filter));
        }
      }

      // if(picklist != "none") {
        if(picklist.length > 0) {
          // console.log("tag_filter",tag_filter);
          // console.log("data.filter((el) => el.tag != null)",data.filter((el) => el.tag != null));
          let picklistFilteredData = [];
          data.map((d) => {
            for (let p=0;p<picklist.length;p++) {
              if (d.picklists && d.picklists?.toString().includes(picklist[p])) {
                picklistFilteredData.push(d);
              }
            }
          });
          data = picklistFilteredData;
        }
      // }

      if(validation) {
      // console.log("validation",validation);
        if(validation == "Passed") {
          data = data.filter((el) => el.is_verified == 1);
        }
        else if(validation == "Failed") {
          data = data.filter((el) => el.is_verified == 0 && el.error_message);
        }
      }

      // console.log("data.filter((el) => el.tag != null) 111",data);

      setTableData({ columns, data });
      setFilteredData(data);
      setPending(false)
    }
  }

  //Filter and sortings
  const handleSortName = (e) => {
    setNameSort(e.target.value);
    handleSort(e.target.value, weightSort, quantitySort, orderDate, tagFilter, filterShipment, validationFilter, picklistFilter, filteredData);
  }

  const handleSortWeight = (e) => {
    setWeightSort(e.target.value);
    handleSort(nameSort, e.target.value, quantitySort, orderDate, tagFilter, filterShipment, validationFilter, picklistFilter, filteredData);
  }

  const handleOrderDate = (e) => {
    setOrderDate(e.target.value);

    if(selectedGroupBy) {
      let fdata = groupByDataTablesAll;
      handleSort(nameSort, weightSort, quantitySort, e.target.value, tagFilter, filterShipment, validationFilter, picklistFilter, fdata);
    }
    else {
      let fdata = allData;
      handleSort(nameSort, weightSort, quantitySort, e.target.value, tagFilter, filterShipment, validationFilter, picklistFilter, fdata);
    }

  }

  const handleValidationFilter = (e) => {
    setValidationFilter(e.target.value);
    if(selectedGroupBy) {
      let fdata = groupByDataTablesAll;
      handleSort(nameSort, weightSort, quantitySort, orderDate, tagFilter, filterShipment, e.target.value, picklistFilter, fdata);
    }
    else {
      let fdata = allData;
      handleSort(nameSort, weightSort, quantitySort, orderDate, tagFilter, filterShipment, e.target.value, picklistFilter, fdata);
    }

  }

  const handleSortQuantity = (e) => {
    setQuantitySort(e.target.value);
    handleSort(nameSort, weightSort, e.target.value, orderDate, tagFilter, filterShipment, validationFilter, picklistFilter, filteredData);
  }

  const handleSortTag = (e) => {
    setTagFilter(e.target.value);
    if(selectedGroupBy) {
      let fdata = groupByDataTablesAll;
      handleSort(nameSort, weightSort, quantitySort, orderDate, e.target.value, filterShipment, validationFilter, picklistFilter, fdata);
    }
    else {
      let fdata = allData;
      handleSort(nameSort, weightSort, quantitySort, orderDate, e.target.value, filterShipment, validationFilter, picklistFilter, fdata);
    }

  }

  const handleSortPicklist = (e) => {
    picklistFilter = e.target;
    if(selectedGroupBy) {
      let fdata = groupByDataTablesAll;
      handleSort(nameSort, weightSort, quantitySort, orderDate, tagFilter, filterShipment, validationFilter, e.target, fdata);
    }
    else {
      let fdata = allData;
      handleSort(nameSort, weightSort, quantitySort, orderDate, tagFilter, filterShipment, validationFilter, e.target, fdata);
    }

  }

  const handleAllocation = (e) => {
    // setAllocation(e.target.value);
    handleSort(nameSort, weightSort, quantitySort, orderDate, tagFilter, filterShipment, validationFilter, picklistFilter, filteredData);
  }

  const handleStoreSelect = async (e) => {
    // console.log("value",e);
    setCurrentStore(e.target.value);
    localStorage.setItem("store", e.target.value);
    if (e.target.value == 0) {
      columns[16].omit = true;
      columns[15].omit = true;
    }

    if(stores.filter(el => el.value == e.target.value)[0].type == "zenventory") {
      hideShowZenventory = false;
    }
    else {
      hideShowZenventory = true;
    }

    columns[12].omit = hideShowZenventory;

    if(stores.filter(el => el.value == e.target.value)[0].type == "sellercloud") {
      // hideShowPicklists = false;
      hideShowChannelNumber = false;
    }
    else {
      // hideShowPicklists = true;
      hideShowChannelNumber = true;
    }

    // columns[13].omit = hideShowPicklists;
    columns[14].omit = hideShowChannelNumber;

    let xx =[];
    setAllData(xx);
    setFilteredData(xx);
    setGroupByDataTables(xx);
    setGroupByDataTablesAll(xx);
    let x = "";
    selectedGroupBy = "";
    sideBarData = [];
    clickedSideBarJson = [];
    await retrievePicklist();
    // const picklistResetFilter = "";
    picklistFilter = [];
    if(localStorage.getItem("order-filter") == "Unassigned") {
      if(e.target.value == "0") {
        const activeCarrier = carriers.filter(el => el.is_active == 1)[0].name;
        localStorage.setItem("order-filter", activeCarrier);
        setFilterType(localStorage.getItem("order-filter"));
        if(selectedGroupBy) {
          await handleGroupByOrders(selectedGroupBy);
        }
        else {
          await retrieveOrdersByFilterType(localStorage.getItem("order-filter"));
        }
      }
      else {
        if(selectedGroupBy) {
          await handleGroupByOrders(selectedGroupBy);
        }
        else {
          await retrieveOrdersByFilterType();
        }
      }
    }
    else {
      if(selectedGroupBy) {
        await handleGroupByOrders(selectedGroupBy);
      }
      else {
        await retrieveOrdersByFilterType();
      }
    }
  }

  const handleFilterShipment = async (e) => {
    setFilterShipment(e.target.value);
    if(selectedGroupBy) {
      let finalGroupData = groupByDataTablesAll;
      await handleSort(nameSort, weightSort, quantitySort, orderDate, tagFilter, e.target.value, validationFilter, picklistFilter, finalGroupData);
    }
    else {
      let data1 = allData;
      await handleSort(nameSort, weightSort, quantitySort, orderDate, tagFilter, e.target.value, validationFilter, picklistFilter, data1);
    }
  }

  const handleAssignee = async (e) => {
    // setAssignee(e.target.value)
    handleSort(nameSort, weightSort, quantitySort, orderDate, tagFilter, filterShipment, validationFilter, picklistFilter, filteredData);
  }

  const expandableRows = (row) => {
    // console.log("row",row);
    return (
      <>
        <div className={"col-md-12 mt-2 mb-2 row p-2"} style={{backgroundColor:localStorage.getItem("theme") == "dark"?"#222224":"#f7f5f5"}}>
          <h5>Item Details</h5>
          <div style={{display:"flex", justifyContent:"start", alignItems:"center", flexWrap:"wrap"}}>
            {row.data.is_custom_form >= 0 ?
              (row.data.custom_items_data?.length > 0 ?
                JSON.parse(row.data.store_type=="ebay"?JSON.parse(row.data.custom_items_data):row.data.custom_items_data).map((el, id) => {
                  if(row.data.store_type == null) {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.product_identifier}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.description ? el.description : "-"}</div></h5>
                        {/*total value is unit price*/}
                        <p>{el.total_value ? el.total_value : "-"} USD</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'shopify') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.id}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.name ? el.name : "-"}</div></h5>
                        <p>{el.price ? el.price : "-"} {el.price_set.shop_money.currency_code}</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'ebay') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.lineItemId}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.title ? el.title : "-"}</div></h5>
                        <p>{el.lineItemCost.value} {el.lineItemCost.currency}</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'walmart') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>SKU: {el.item.sku}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                        <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'zenventory') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.customerorderitemid}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.itemmaster.description ? el.itemmaster.description : "-"}</div></h5>
                        <p>${el.customerprice}</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'sellercloud') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.ProductID}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.Qty ? el.Qty : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{(el.DisplayName ? el.DisplayName : "-")}</div></h5>
                        <p>{el.DisplayDescription?el.DisplayDescription:"-"}</p>
                        <div style={{display:"flex"}}>
                          <p style={{width:"50%"}}>${el.AdjustedSitePrice}</p>
                          <p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {el.Weight?el.Weight + "lb.":"-"}</p>
                        </div>
                        <p><span style={{fontWeight:"500"}}>L x W x H:</span> {el.Length?el.Length:"-"} x {el.Width?el.Width:"-"} x {el.Height?el.Height:"-"}</p>
                      </div>
                    )
                  }
                  else if(row.data.store_type == 'shipstation') {
                    return (
                      <div key={id} className={"border rounded shadow"} style={{width:"300px", height:"fit-content", padding:"10px 10px 0px 10px",margin:"10px", backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                        <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {el.orderItemId}</h6>
                        <hr/>
                        <h5><span style={{float:"right"}}>{el.quantity ? el.quantity : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{(el.name ? el.name : "-")}</div></h5>
                        {/*<p>{el.name?el.name:"-"}</p>*/}
                        <div style={{display:"flex"}}>
                          <p style={{width:"50%"}}>${el.unitPrice}</p>
                          {/*<p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {el.Weight?el.Weight + "lb.":"-"}</p>*/}
                        </div>
                        {/*<p><span style={{fontWeight:"500"}}>L x W x H:</span> {el.Length?el.Length:"-"} x {el.Width?el.Width:"-"} x {el.Height?el.Height:"-"}</p>*/}
                      </div>
                    )
                  }
                })
                : "")
              : <h6 style={{textAlign:"center"}}>No Item Found</h6>}
          </div>
        </div>
      </>
    )
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // Function to update the window width state
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  // Add an event listener to the window when the component mounts
  useEffect(() => {

    window.addEventListener('resize', updateWindowWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  const handleReloadPage = async () => {
    // console.log("hit")
    // setNameSort("");
    // setWeightSort("");
    // setQuantitySort("");
    // setOrderDate("");
    // setFilterShipment("All");

    if(selectedGroupBy) {
      await handleGroupByOrders(selectedGroupBy);
    }
    else {
      await retrieveOrdersByFilterType();
    }

    getUserBalance();
    retrieveTags();
  }

  const handleUpdateNotes = () => {
    try {
      let body = {
        order_id: notesOrderId,
        type:fromBuyerNotesModal?"from_buyer_notes":(toBuyerNotesModal?"to_buyer_notes":"internal_notes"),
        notes: fromBuyerNotesModal?fromBuyerNotes:(toBuyerNotesModal?toBuyerNotes:internalNotes)
      }
      post('/user/update-order-notes', body, {headers}).then(async (response) => {
        if(response.status == 200) {
          toast.success(response.message);
          setFromBuyerNotesModal(false);
          setToBuyerNotesModal(false);
          setInternalBuyerNotesModal(false);

          uncheckAll();
          selectedOrders = [];
          setSelectedOrdersCount(selectedOrders.length);
          clickedSideBarJson = [];
          selectedOrderJson = [];
          setBulkUpdateServiceId("");
          setBulkUpdateToPhone("");
          setEnableBulkUpdateToPhone(false);
          if(selectedGroupBy) {
            await handleGroupByOrders(selectedGroupBy);
          }
          else {
            await retrieveOrdersByFilterType();
          }
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {

    }
  }
  const componentColumnsRef = useRef(null)
  const componentGroupByRef = useRef(null)

  const handleColumnsClickOutside = (event) => {
    if (componentColumnsRef.current && !componentColumnsRef.current.contains(event.target)) {
      setHideShowColumns(false);
    }
  };

  const handleGroupByClickOutside = (event) => {
    if (componentGroupByRef.current && !componentGroupByRef.current.contains(event.target)) {
      setHideShowGroupBy(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleColumnsClickOutside);
    document.addEventListener('mousedown', handleGroupByClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleColumnsClickOutside);
      document.removeEventListener('mousedown', handleGroupByClickOutside);
    };
  }, []);

  const handleGroupByOrders = async (type, clear = true) => {
      if(clear) {
        clickedSideBarJson = [];
        uncheckAll();
        selectedOrders = [];
        setSelectedOrdersCount(selectedOrders.length);
        clickedSideBarJson = [];
        selectedOrderJson = [];
        setBulkUpdateServiceId("");
        setBulkUpdateToPhone("");
        setEnableBulkUpdateToPhone(false);
        setFilteredData([]);
        setAllData([]);
      }

      setPendingGroupBy(true);
      const body = {
        id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: parseInt(localStorage.getItem("store")),
        carrier: filterType,
        type: type
      }
    // console.log("body", body);
      post('/user/get-group-by-orders', body, {headers}).then(async (response) => {
      console.log("get-group-by-orders", response.data);

        await handleTableData(response.data);
        const checkCheckboxExist = setInterval(() => {
          if(document.getElementById("select-all-checkbox")) {
            for(let i=0;i<document.getElementsByClassName("select-all-checkbox").length;i++) {
              document.getElementsByClassName("select-all-checkbox")[i].style.display = "none";
            }
            clearInterval(checkCheckboxExist);
          }
        }, 100)

        setPendingGroupBy(false);
      })

    return true;

  }


  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "white",
    },
    section: {
      margin: 20,
      padding: 20,
      flexGrow: 1,
    },
  })

  const [slip_name, setSlipName] = useState("")
  const [pc_to_street1, setPCToStreet1] = useState("")
  const [pc_to_street2, setPCToStreet2] = useState("")
  const [pc_to_city, setPCToCity] = useState("")
  const [pc_to_state, setPCToState] = useState("")
  const [pc_to_zip, setPCToZip] = useState("")
  const [pc_to_country, setPCToCountry] = useState("")
  const [pc_to_name, setPCToName] = useState("");

  const [pc_from_street1, setPCFromStreet1] = useState("")
  const [pc_from_street2, setPCFromStreet2] = useState("")
  const [pc_from_city, setPCFromCity] = useState("")
  const [pc_from_state, setPCFromState] = useState("")
  const [pc_from_zip, setPCFromZip] = useState("")
  const [pc_from_country, setPCFromCountry] = useState("")

  const [pc_order_number, setPCOrderNumber] = useState("");
  const [pc_order_date, setPCOrderDate] = useState("");
  const [pc_shipment_date, setPCShipmentDate] = useState("");

  const [pc_items, setPcItems] = useState([]);
  const [pc_sub_total, setPcSubTotal] = useState("");
  const [pc_total, setPcTotal] = useState("");

  const PackingSlip = () => (
    <Document>
      <Page size={[384, 520]} style={styles.page}>
        <View>
          <View>
            <Text
              style={{
                left: "150px",
                fontSize: "12px",
                marginTop: "10px",
                fontWeight: "bold",
                // fontFamily: "Georgia",
                color: "black",
              }}
            >
              Packing Slip
            </Text>
          </View>
          <View
            style={{
              marginTop: "15px",
            }}
          >
            <Text
              style={{
                left: "20px",
                fontSize: "9px",
                fontWeight: "hairline",
                paddingTop: "3px",
                color: "gray",
              }}
            >
              {pc_from_street1}
            </Text>
            {pc_from_street2?<Text
              style={{
                left: "20px",
                fontSize: "9px",
                paddingTop: "3px",
                fontWeight: "hairline",
                color: "gray",
              }}
            >
              {pc_from_street2}
            </Text>:null}
            <Text
              style={{
                left: "20px",
                fontSize: "9px",
                paddingTop: "3px",
                fontWeight: "hairline",
                color: "gray",
              }}
            >
              {pc_from_city},
            </Text>
            <Text
              style={{
                left: "20px",
                fontSize: "9px",
                paddingTop: "3px",
                fontWeight: "hairline",
                color: "gray",
              }}
            >
              {pc_from_state} {pc_from_zip} {pc_from_country}
            </Text>
          </View>
          {/* header detail section */}
          <View
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                left: "20px",
              }}
            >
              <Text
                style={{
                  fontSize: "9px",
                  fontWeight: "bold ",
                  color: "black",
                  marginRight : '5px',
                }}
              >
                Ship To:
              </Text>
              <Text
                style={{
                  fontSize: "9px",
                  fontWeight: "hairline",
                  marginLeft: "10px",
                  width: "117px",
                  color: "gray",
                }}
              >
                {pc_to_street1}{pc_to_street2?" " + pc_to_street2:null} {pc_to_city}, {pc_to_state} {pc_to_zip} {pc_to_country}
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                // left: "20px",
                marginLeft: "80px",
                gap: "6px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View>
                <Text
                  style={{
                    fontSize: "9px",
                    color: "black",
                    fontWeight: "bold",
                    direction: "rtl",
                    // marginLeft: 110,
                    // marginTop : '10px'
                  }}
                >
                  Order #
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "bold",
                    color: "black",
                    marginTop: "10px",
                    direction: "rtl",
                    // marginLeft: 125,
                  }}
                >
                  Date
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "bold",
                    color: "black",
                    marginTop: "13px",
                    direction: "rtl",
                    // marginLeft: 125,
                  }}
                >
                  User
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                    color: "black",
                    fontWeight: "bold",
                    marginTop: "13px",
                    direction: "rtl",
                    // marginLeft: 100,
                  }}
                >
                  Ship Date
                </Text>
              </View>
              <Line
                style={{
                  width: "2px",
                  height: "80px",
                  // right: "10px",
                  // marginLeft: 350,
                  // marginTop: -80,
                  borderLeftWidth: 1,
                  borderLeftColor: "gray",
                }}
              ></Line>
              <View
                style={
                  {
                    width : '140px'
                  }
                }
              >
                <Text
                  style={{
                    fontSize: "9px",
                    color: "gray",
                  }}
                >
                  {pc_order_number}
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                    color: "gray",
                    marginTop: "10px",
                  }}
                >
                  {pc_order_date}
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                    color: "gray",
                    marginTop: "13px",
                  }}
                >
                  {pc_to_name}
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                    color: "gray",
                    marginTop: "13px",
                  }}
                >
                  -
                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: "98%", marginHorizontal: "auto" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                // border: "1px solid black",
                padding: "10px",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <View
                style={{
                  // borderLeft: "1px solid black",
                  padding : "6px 0px 0px 5px",
                  border: "1px 0px 1px 1px solid black",
                  width: "25%",
                }}
              >
                <Text
                  style={{
                    fontSize: 9,
                  }}
                >
                  Item
                </Text>
              </View>
              <View
                style={{
                  // borderLeft: "1px solid black",
                  padding : "6px 0px 0px 5px",
                  border: "1px 0px 1px 1px solid black",
                  width: "30%",
                }}
              >
                <Text
                  style={{
                    fontSize: 9,
                  }}
                >
                  Description
                </Text>
              </View>
              <View
                style={{
                  // borderLeft: "1px solid black",
                  padding : "6px 0px 0px 5px",
                  border: "1px 0px 1px 1px solid black",
                  width: "15%",
                }}
              >
                <Text
                  style={{
                    fontSize: 9,
                  }}
                >
                  Price
                </Text>
              </View>
              <View
                style={{
                  // borderLeft: "1px solid black",
                  padding : "6px 0px 0px 5px",
                  border: "1px 0px 1px 1px solid black",
                  width: "10%",
                }}
              >
                <Text
                  style={{
                    fontSize: 9,
                  }}
                >
                  Qty
                </Text>
              </View>
              <View
                style={{
                  // borderLeft: "1px solid black",
                  padding : "6px 0px 0px 5px",
                  border: "1px 1px 1px 1px solid black",
                  width: "20%",
                }}
              >
                <Text
                  style={{
                    fontSize: 9,
                  }}
                >
                  Ext. Price
                </Text>
              </View>
            </View>
            {pc_items.length > 0 ? pc_items.map((el,index) => (
                <View
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    padding: "5px",
                    width: "100%",
                    marginTop: "3px",
                  }}
                >
                  <View
                    style={{
                      // borderLeft: "1px solid black",
                      width: "25%",
                      paddingLeft:"10px"
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 9,
                        color: "gray",
                      }}
                    >
                      {el.product_identifier}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "30%",
                      paddingLeft:"10px",
                      // borderLeft: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 9,
                        padding: 2
                      }}
                    >
                      {el.description}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "15%",
                      // borderLeft: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 9,
                        textAlign: "center",
                        color: "gray",
                        padding: 2
                      }}
                    >
                      ${el.price}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "10%",
                      // borderLeft: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 9,
                        textAlign: "center",
                        padding: 2
                      }}
                    >
                      {el.quantity}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "20%", // borderLeft: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 9,
                        textAlign: "center",
                        color: "gray",
                        padding: 2
                      }}
                    >
                      ${el.total_price}
                    </Text>
                  </View>
                </View>
              ))
              :null}
            <Line
              style={{
                width: "100%",
                borderBottom: "1px solid gray",
              }}
            ></Line>
          </View>
          <View
            style={{
              display: "flex",
              // justifyContent : 'flex-end',
              marginLeft: "60%",
              marginTop: "15px",
              width: "40%",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                fontSize: "9px",
              }}
            >
              <View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ fontWeight: "bold" }}>Sub Total:</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ fontWeight: "bold" }}>Tax:</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ fontWeight: "bold" }}>Shipping:</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ fontWeight: "bold" }}>Total:</Text>
                </View>
              </View>
              <View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ color: "gray" }}>${pc_sub_total}</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ color: "gray" }}>$0.00</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ color: "gray" }}>$0.00</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ color: "gray" }}>${pc_total}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )

  const MergedPackingSlip = () => (
    <Document>
      {mergedPackingSlip.map((el, i) => (
        <Page size={[384, 520]} style={styles.page} key={i}>
          <View>
            <View>
              <Text
                style={{
                  left: "150px",
                  fontSize: "12px",
                  marginTop: "10px",
                  fontWeight: "bold",
                  // fontFamily: "Georgia",
                  color: "black",
                }}
              >
                Packing Slip
              </Text>
            </View>
            <View
              style={{
                marginTop: "15px",
              }}
            >
              <Text
                style={{
                  left: "20px",
                  fontSize: "9px",
                  fontWeight: "hairline",
                  paddingTop: "3px",
                  color: "gray",
                }}
              >
                {el.from_street1}
              </Text>
              {el.from_street2?<Text
                style={{
                  left: "20px",
                  fontSize: "9px",
                  paddingTop: "3px",
                  fontWeight: "hairline",
                  color: "gray",
                }}
              >
                {el.from_street2}
              </Text>:null}
              <Text
                style={{
                  left: "20px",
                  fontSize: "9px",
                  paddingTop: "3px",
                  fontWeight: "hairline",
                  color: "gray",
                }}
              >
                {el.from_city},
              </Text>
              <Text
                style={{
                  left: "20px",
                  fontSize: "9px",
                  paddingTop: "3px",
                  fontWeight: "hairline",
                  color: "gray",
                }}
              >
                {el.from_state} {el.from_zip} {el.from_country}
              </Text>
            </View>
            {/* header detail section */}
            <View
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "50%",
                  left: "20px",
                }}
              >
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "bold ",
                    color: "black",
                    marginRight : '5px',
                  }}
                >
                  Ship To:
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "hairline",
                    marginLeft: "10px",
                    width: "117px",
                    color: "gray",
                  }}
                >
                  {el.to_street1}{el.to_street2?" " + el.to_street2:null} {el.to_city}, {el.to_state} {el.to_zip} {el.to_country}
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  // left: "20px",
                  marginLeft: "80px",
                  gap: "6px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View>
                  <Text
                    style={{
                      fontSize: "9px",
                      color: "black",
                      fontWeight: "bold",
                      direction: "rtl",
                      // marginLeft: 110,
                      // marginTop : '10px'
                    }}
                  >
                    Order #
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      fontWeight: "bold",
                      color: "black",
                      marginTop: "10px",
                      direction: "rtl",
                      // marginLeft: 125,
                    }}
                  >
                    Date
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      fontWeight: "bold",
                      color: "black",
                      marginTop: "13px",
                      direction: "rtl",
                      // marginLeft: 125,
                    }}
                  >
                    User
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      color: "black",
                      fontWeight: "bold",
                      marginTop: "13px",
                      direction: "rtl",
                      // marginLeft: 100,
                    }}
                  >
                    Ship Date
                  </Text>
                </View>
                <Line
                  style={{
                    width: "2px",
                    height: "80px",
                    // right: "10px",
                    // marginLeft: 350,
                    // marginTop: -80,
                    borderLeftWidth: 1,
                    borderLeftColor: "gray",
                  }}
                ></Line>
                <View
                  style={
                    {
                      width : '140px'
                    }
                  }
                >
                  <Text
                    style={{
                      fontSize: "9px",
                      color: "gray",
                    }}
                  >
                    {el.order_number}
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      color: "gray",
                      marginTop: "10px",
                    }}
                  >
                    {el.order_date}
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      color: "gray",
                      marginTop: "13px",
                    }}
                  >
                    {el.to_name}
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      color: "gray",
                      marginTop: "13px",
                    }}
                  >
                    {el.shipment_date}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ width: "98%", marginHorizontal: "auto" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  // border: "1px solid black",
                  padding: "10px",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <View
                  style={{
                    // borderLeft: "1px solid black",
                    padding : "6px 0px 0px 5px",
                    border: "1px 0px 1px 1px solid black",
                    width: "25%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                    }}
                  >
                    Item
                  </Text>
                </View>
                <View
                  style={{
                    // borderLeft: "1px solid black",
                    padding : "6px 0px 0px 5px",
                    border: "1px 0px 1px 1px solid black",
                    width: "30%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                    }}
                  >
                    Description
                  </Text>
                </View>
                <View
                  style={{
                    // borderLeft: "1px solid black",
                    padding : "6px 0px 0px 5px",
                    border: "1px 0px 1px 1px solid black",
                    width: "15%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                    }}
                  >
                    Price
                  </Text>
                </View>
                <View
                  style={{
                    // borderLeft: "1px solid black",
                    padding : "6px 0px 0px 5px",
                    border: "1px 0px 1px 1px solid black",
                    width: "10%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                    }}
                  >
                    Qty
                  </Text>
                </View>
                <View
                  style={{
                    // borderLeft: "1px solid black",
                    padding : "6px 0px 0px 5px",
                    border: "1px 1px 1px 1px solid black",
                    width: "20%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                    }}
                  >
                    Ext. Price
                  </Text>
                </View>
              </View>
              {el.items.length > 0 ?el.items.map((ell,index) => (
                  <View
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      padding: "5px",
                      width: "100%",
                      marginTop: "3px",
                    }}
                  >
                    <View
                      style={{
                        // borderLeft: "1px solid black",
                        width: "25%",
                        paddingLeft:"10px"
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 9,
                          color: "gray",
                        }}
                      >
                        {ell.product_identifier}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "30%",
                        paddingLeft:"10px",
                        // borderLeft: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 9,
                          padding: 2
                        }}
                      >
                        {ell.description}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "15%",
                        // borderLeft: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 9,
                          textAlign: "center",
                          color: "gray",
                          padding: 2
                        }}
                      >
                        ${ell.price}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "10%",
                        // borderLeft: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 9,
                          textAlign: "center",
                          padding: 2
                        }}
                      >
                        {ell.quantity}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "20%", // borderLeft: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 9,
                          textAlign: "center",
                          color: "gray",
                          padding: 2
                        }}
                      >
                        ${ell.total_price}
                      </Text>
                    </View>
                  </View>
                ))
                :null}
              <Line
                style={{
                  width: "100%",
                  borderBottom: "1px solid gray",
                }}
              ></Line>
            </View>
            <View
              style={{
                display: "flex",
                // justifyContent : 'flex-end',
                marginLeft: "60%",
                marginTop: "15px",
                width: "40%",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  fontSize: "9px",
                }}
              >
                <View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ fontWeight: "bold" }}>Sub Total:</Text>
                  </View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ fontWeight: "bold" }}>Tax:</Text>
                  </View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ fontWeight: "bold" }}>Shipping:</Text>
                  </View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ fontWeight: "bold" }}>Total:</Text>
                  </View>
                </View>
                <View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ color: "gray" }}>${el.sub_total}</Text>
                  </View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ color: "gray" }}>$0.00</Text>
                  </View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ color: "gray" }}>$0.00</Text>
                  </View>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={{ color: "gray" }}>${el.total}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  )

  const handlePackingSlip = async () => {
    // console.log("orders", clickedSideBarJson)
    if(clickedSideBarJson.length > 0) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading(`Creating Packing ${clickedSideBarJson.length>1?"Slips":"Slip"}...`);

      await Promise.all(clickedSideBarJson.map(async (el, i) => {
        setTimeout(async () => {
          // setSlipName("")
          //
          // setPCToStreet1("")
          // setPCToStreet2("")
          // setPCToCity("")
          // setPCToState("")
          // setPCToZip("")
          // setPCToCountry("")
          // setPCToName("")
          //
          // setPCFromStreet1("")
          // setPCFromStreet2("")
          // setPCFromCity("")
          // setPCFromState("")
          // setPCFromZip("")
          // setPCFromCountry("")
          //
          // setPCOrderNumber("")
          // setPCOrderDate("")
          // setPcItems([]);
          // setPcSubTotal("0.00")
          // setPcTotal("0.00")

          setSlipName(el.order_unique_id)
          // console.log("el",el);
          setPCToStreet1(el.to_street1)
          setPCToStreet2(el.to_street2)
          setPCToCity(el.to_city)
          setPCToState(el.to_state)
          setPCToZip(el.to_zip)
          setPCToCountry(el.to_country)
          setPCToName(el.to_name)

          setPCFromStreet1(el.from_street1)
          setPCFromStreet2(el.from_street2)
          setPCFromCity(el.from_city)
          setPCFromState(el.from_state)
          setPCFromZip(el.from_zip)
          setPCFromCountry(el.from_country)
          // console.log("eeeeee",el.package_number);

          setPCOrderNumber(el.order_number?el.order_number+ (el.package_number?"-" + el.package_number + (el.package_index?" (" + el.package_index + ")":""):""):"-")

          let storeOrderData = "";
          if(el.store_type) {
            let StoreOrder = JSON.parse(el.store_order_json);
            if(el.store_type == "shopify") {
              storeOrderData = moment(StoreOrder.created_at).format("YYYY-MM-DD")
            }
            else if(el.store_type == "ebay") {
              storeOrderData = moment(StoreOrder.updated_at).format("YYYY-MM-DD")
            }
            else if(el.store_type == "walmart") {
              storeOrderData = moment(StoreOrder.orderDate).format("YYYY-MM-DD")
            }
            else if(el.store_type == "zenventory") {
              storeOrderData = moment(StoreOrder.ordereddate).format("YYYY-MM-DD")
            }
            else if(el.store_type == "sellercloud") {
              // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
              storeOrderData = moment(StoreOrder.TimeOfOrder).format("YYYY-MM-DD")
              // console.log("storeOrderData",storeOrderData)
            }
            else if(el.store_type == "sellercloud") {
              // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
              storeOrderData = moment(StoreOrder.createDate).format("YYYY-MM-DD")
              // console.log("storeOrderData",storeOrderData)
            }
          }
          else {
            storeOrderData = moment(el.order_date).tz(moment.tz.guess()).format("YYYY-MM-DD")
          }

          // setPCOrderDate(el.store_type?(el.store_type=="walmart"?moment(el.store_order_updated_at).format("YYYY-MM-DD"):el.store_order_updated_at.toString().substring(0,10)):el.created_at.toString().substring(0,10))
          setPCOrderDate(storeOrderData);

          if(el.custom_items_data && JSON.parse(el.store_type=="ebay"?JSON.parse(el.custom_items_data):el.custom_items_data).length > 0) {
            let items = JSON.parse(el.store_type=="ebay"?JSON.parse(el.custom_items_data):el.custom_items_data);
            let itemArray = [];
            // console.log("items",items);
            await Promise.all(items.map((item) => {
              if(el.store_type == null) {
                itemArray.push({
                  'product_identifier': item.product_identifier,
                  'description': item.description,
                  'quantity': item.quantity,
                  'price': parseFloat(item.total_value).toFixed(2),
                  'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.total_value)).toFixed(2)
                });
              }
              else if(el.store_type == "shopify") {
                itemArray.push({
                  'product_identifier': item.id,
                  'description': item.name,
                  'quantity': item.quantity,
                  'price': parseFloat(item.price).toFixed(2),
                  'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.price)).toFixed(2)
                });
              }
              else if(el.store_type == "ebay") {
                itemArray.push({
                  'product_identifier': item.lineItemId,
                  'description': item.title,
                  'quantity': item.quantity,
                  'price': parseFloat(item.lineItemCost.value).toFixed(2),
                  'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.lineItemCost.value)).toFixed(2)
                });
              }
              else if(el.store_type == "walmart") {
                itemArray.push({
                  'product_identifier': item.id,
                  'description': item.name,
                  'quantity': item.orderLineQuantity.amount,
                  'price': parseFloat(item.charges.charge[0].chargeAmount.amount).toFixed(2),
                  'total_price': parseFloat(parseInt(item.orderLineQuantity.amount) * parseFloat(item.charges.charge[0].chargeAmount.amount)).toFixed(2)
                });
              }
              else if(el.store_type == "zenventory") {
                itemArray.push({
                  'product_identifier': item.customerorderitemid,
                  'description': item.itemmaster.description,
                  'quantity': item.quantity,
                  'price': parseFloat(item.customerprice).toFixed(2),
                  'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.customerprice)).toFixed(2)
                });
              }
              else if(el.store_type == "sellercloud") {
                // itemArray.push({
                //   'product_identifier': item.ProductID,
                //   'description': el.is_package==0?item.DisplayName:item.ItemName,
                //   'quantity': item.Qty,
                //   'price': parseFloat(el.is_package==0?item.LineTotal:0).toFixed(2),
                //   'total_price': parseFloat(parseInt(item.Qty) * parseFloat(el.is_package==0?item.LineTotal:0)).toFixed(2)
                // });

                itemArray.push({
                  'product_identifier': item.ProductID,
                  'description': item.DisplayName,
                  'quantity': item.Qty,
                  'price': parseFloat(item.AdjustedSitePrice).toFixed(2),
                  'total_price': parseFloat(parseInt(item.Qty) * parseFloat(item.AdjustedSitePrice)).toFixed(2)
                });
              }
              else if(el.store_type == "shipstation") {
                itemArray.push({
                  'product_identifier': item.orderItemId,
                  'description': item.name,
                  'quantity': item.quantity,
                  'price': parseFloat(item.unitPrice).toFixed(2),
                  'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.unitPrice)).toFixed(2)
                });
              }
            }))
            // console.log("itemArray",itemArray);
            setPcItems(itemArray);
            setPcSubTotal(parseFloat(itemArray.reduce((accumulator, currentObject) => {
              return accumulator + parseFloat(currentObject.total_price);
            }, 0)).toFixed(2));
            setPcTotal(parseFloat(itemArray.reduce((accumulator, currentObject) => {
              return accumulator + parseFloat(currentObject.total_price);
            }, 0)).toFixed(2));
          }
          else {
            setPcItems([]);
            setPcSubTotal("0.00");
            setPcTotal("0.00");
          }

          setTimeout(() => {
            document.getElementsByClassName("pdf-downloader")[0].click();
          },1000)

          if(clickedSideBarJson.length == (i+1)) {
            toast.remove(loading);
            toast.success(clickedSideBarJson.length + ` packing ${clickedSideBarJson.length>1?"slips":"slip"} generated`);
            clickedSideBarJson = [];
            uncheckAll();
            selectedOrders = [];
            setSelectedOrdersCount(selectedOrders.length);
            clickedSideBarJson = [];
            selectedOrderJson = [];
            setBulkUpdateServiceId("");
            setBulkUpdateToPhone("");
            setEnableBulkUpdateToPhone(false);
            document.body.style.pointerEvents = "auto"
          }
        }, (i+1) * 2000);
      }));
    }
    else {
      toast.error("Please select at least one order")
    }
  }

  const downloadMergedPackingSlip = async () => {
    if(clickedSideBarJson.length > 0) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading(`Creating Packing ${clickedSideBarJson.length>1?"Slips":"Slip"}...`);

      let dataArray = []

      await clickedSideBarJson.map(async (el, i) => {
        let itemArray = [];
        if(el.custom_items_data && JSON.parse(el.store_type=="ebay"?JSON.parse(el.custom_items_data):el.custom_items_data).length > 0) {
          let items = JSON.parse(el.store_type=="ebay"?JSON.parse(el.custom_items_data):el.custom_items_data);
          items.map((item) => {
            if(el.store_type == null) {
              itemArray.push({
                'product_identifier': item.product_identifier,
                'description': item.description,
                'quantity': item.quantity,
                'price': parseFloat(item.total_value).toFixed(2),
                'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.total_value)).toFixed(2)
              });
            }
            else if(el.store_type == "shopify") {
              itemArray.push({
                'product_identifier': item.id,
                'description': item.name,
                'quantity': item.quantity,
                'price': parseFloat(item.price).toFixed(2),
                'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.price)).toFixed(2)
              });
            }
            else if(el.store_type == "ebay") {
              itemArray.push({
                'product_identifier': item.lineItemId,
                'description': item.title,
                'quantity': item.quantity,
                'price': parseFloat(item.lineItemCost.value).toFixed(2),
                'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.lineItemCost.value)).toFixed(2)
              });
            }
            else if(el.store_type == "walmart") {
              itemArray.push({
                'product_identifier': item.id,
                'description': item.name,
                'quantity': item.orderLineQuantity.amount,
                'price': parseFloat(item.charges.charge[0].chargeAmount.amount).toFixed(2),
                'total_price': parseFloat(parseInt(item.orderLineQuantity.amount) * parseFloat(item.charges.charge[0].chargeAmount.amount)).toFixed(2)
              });
            }
            else if(el.store_type == "zenventory") {
              itemArray.push({
                'product_identifier': item.customerorderitemid,
                'description': item.itemmaster.description,
                'quantity': item.quantity,
                'price': parseFloat(item.customerprice).toFixed(2),
                'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.customerprice)).toFixed(2)
              });
            }
            else if(el.store_type == "sellercloud") {
              // itemArray.push({
              //   'product_identifier': item.ProductID,
              //   'description': el.is_package==0?item.DisplayName:item.ItemName,
              //   'quantity': item.Qty,
              //   'price': parseFloat(el.is_package==0?item.LineTotal:0).toFixed(2),
              //   'total_price': parseFloat(parseInt(item.Qty) * parseFloat(el.is_package==0?item.LineTotal:0)).toFixed(2)
              // });

              itemArray.push({
                'product_identifier': item.ProductID,
                'description': item.DisplayName,
                'quantity': item.Qty,
                'price': parseFloat(item.AdjustedSitePrice).toFixed(2),
                'total_price': parseFloat(parseInt(item.Qty) * parseFloat(item.AdjustedSitePrice)).toFixed(2)
              });
            }
            else if(el.store_type == "shipstation") {
              itemArray.push({
                'product_identifier': item.orderItemId,
                'description': item.name,
                'quantity': item.quantity,
                'price': parseFloat(item.unitPrice).toFixed(2),
                'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.unitPrice)).toFixed(2)
              });
            }
          })
        }

        let storeOrderData = "";
        if(el.store_type) {
          let StoreOrder = JSON.parse(el.store_order_json);
          if(el.store_type == "shopify") {
            storeOrderData = moment(StoreOrder.created_at).format("YYYY-MM-DD")
          }
          else if(el.store_type == "ebay") {
            storeOrderData = moment(StoreOrder.updated_at).format("YYYY-MM-DD")
          }
          else if(el.store_type == "walmart") {
            storeOrderData = moment(StoreOrder.orderDate).format("YYYY-MM-DD")
          }
          else if(el.store_type == "zenventory") {
            storeOrderData = moment(StoreOrder.ordereddate).format("YYYY-MM-DD")
          }
          else if(el.store_type == "sellercloud") {
            // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
            storeOrderData = moment(StoreOrder.TimeOfOrder).format("YYYY-MM-DD")
            // console.log("storeOrderData",storeOrderData)
          }
          else if(el.store_type == "shipstation") {
            // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
            storeOrderData = moment(StoreOrder.createDate).format("YYYY-MM-DD")
            // console.log("storeOrderData",storeOrderData)
          }
        }
        else {
          storeOrderData = moment(el.order_date).tz(moment.tz.guess()).format("YYYY-MM-DD")
        }

        // console.log("pushing index",i);
        dataArray.push({
          to_street1: el.to_street1,
          to_street2: el.to_street2,
          to_city: el.to_city,
          to_state: el.to_state,
          to_zip: el.to_zip,
          to_country: el.to_country,
          to_name: el.to_name,
          from_street1: el.from_street1,
          from_street2: el.from_street2,
          from_city: el.from_city,
          from_state: el.from_state,
          from_zip: el.from_zip,
          from_country: el.from_country,
          from_name: el.from_name,
          order_number: el.order_number?el.order_number + (el.package_number?"-" + el.package_number + (el.package_index?" (" + el.package_index + ")":""):""):"-",
          // order_date: el.store_type?(el.store_type=="walmart"?moment(el.store_order_updated_at).format("YYYY-MM-DD"):el.store_order_updated_at.toString().substring(0,10)):el.order_date.toString().substring(0,10),
          order_date: storeOrderData,
          shipment_date: "-",
          items: itemArray,
          sub_total: parseFloat(itemArray.reduce((accumulator, currentObject) => {
            return accumulator + parseFloat(currentObject.total_price);
          }, 0)).toFixed(2),
          total: parseFloat(itemArray.reduce((accumulator, currentObject) => {
            return accumulator + parseFloat(currentObject.total_price);
          }, 0)).toFixed(2)
        })
      })

      let x = dataArray;
      setMergedPackingSlip([...x]);
      document.body.click();
      console.log("x",x.length);
      setTimeout(() => {
        document.body.style.pointerEvents = "auto"
        toast.remove(loading);
        toast.success(x.length + ` packing ${x.length>1?"slips":"slip"} generated`);

        document.getElementsByClassName("merged-packing-slip-downloader")[0].click();
        clickedSideBarJson = [];
        uncheckAll();
        selectedOrders = [];
        setSelectedOrdersCount(selectedOrders.length);
        setMergedPackingSlip([]);
      },(x.length * 200))
    }
    else {
      toast.error("Please select at least one order")
    }
  }

  createTheme('solarized', {
    text: {
      primary: '#fffff',
      secondary: '#fffff',
    },
    background: {
      default: '#0f0f0f',
    },
    context: {
      background: '#cb4b16',
      text: '#0f0f0f',
    },
    divider: {
      default: '#282828',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const handleUpdateBelowAndAboveFiveLbs = () => {
    if(aboveFiveLbsOrders.length > 0 || belowFiveLbsOrders.length > 0 || underOneLbsOrders.length > 0) {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        aboveFiveLbsOrders: aboveFiveLbsOrders.length>0?aboveFiveLbsOrders.map(el => el.id).toString():"",
        aboveFiveLbsService: aboveFiveLbsService,
        belowFiveLbsOrders: belowFiveLbsOrders.length>0?belowFiveLbsOrders.map(el => el.id).toString():"",
        belowFiveLbsService: belowFiveLbsService,
        underOneLbsOrders: underOneLbsOrders.length>0?underOneLbsOrders.map(el => el.id).toString():"",
        underOneLbsService: underOneLbsService
      }

      console.log("body",body);
      post('/user/update-orders-above-below', body, {headers}).then(async (response) => {
        if(response.status == 200) {
          retrieveOrdersByFilterType();
          toast.success(response.message);
          setClickedJson([])
          uncheckAll();
          selectedOrders = [];
          setSelectedOrdersCount(selectedOrders.length);
          clickedSideBarJson = [];
          selectedOrderJson = [];
          selectedOrderJson = [];
          setSingleOrderWeight("")
          setSingleOrderServiceId("")
          setSingleOrderWidth("")
          setSingleOrderHeight("")
          setSingleOrderLength("")
          setIsFullTextVisible(false);
          setIsFullTextVisibleBelow(false);
          setIsFullTextVisibleUnderOne(false);
          setServiceRateAboveFiveLbs("");
          setServiceRateBelowFiveLbs("");
          setServiceRateUnderOneLbs("");
          setBelowFiveLbsOrders([])
          setAboveFiveLbsOrders([])
          setUnderOneLbsOrders([]);
        }
        else {
          toast.error(response.message);
        }
      });
    }
  }

  const handleUpdateUserPreferences = () => {
    try {
      let columnPreferences = {
        hideShowItemName: hideShowItemName,
        hideShowServiceName: hideShowServiceName,
        hideShowFromName: hideShowFromName,
        hideShowToName: hideShowToName,
        hideShowNotes: hideShowNotes,
        hideShowTags: hideShowTags,
        hideShowOrderNumber: hideShowOrderNumber,
        hideShowPackageNumber: hideShowPackageNumber,
        hideShowOrderDate: hideShowOrderDate,
        hideShowUpdatedAt: hideShowUpdatedAt,
        hideShowDimension: hideShowDimension,
        hideShowWeight: hideShowWeight,
        hideShowStatus: hideShowStatus,
        hideShowItemNames: hideShowItemNames,
        hideShowProductIds: hideShowProductIds,
        hideShowPicklists: hideShowPicklists,
      }

      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        store_id: selectedStore,
        type: 'awaiting-shipment-columns',
        data: JSON.stringify(columnPreferences)
      }

      // console.log("update-user-preferences body", body)

      post('/user/update-user-preferences', body, {headers}).then((response) => {
        // console.log("response", response)
      });
    }
    catch (e) {

    }
  }

  return (
    <div className="page-content" style={{position:"relative"}}>
      <span style={{fontSize:"12px",fontWeight:"normal",position:"fixed", bottom:"75px",boxShadow:"0px 0px 4px 5px rgba(0,0,0,0.05)", right:"0px",padding:"5px", backgroundColor:(localStorage.getItem("theme") == "dark"?"#333":"white"),zIndex:"999", display: (selectedOrdersCount>0?"block":"none")}}>{selectedOrdersCount} {selectedOrdersCount>1?"Orders":"Order"} Selected </span>
      {/*Right Get Rate Modal*/}
      <div className={(clickedJson.length>0 ? "modal-background" : "")} onClick={() => {
        setClickedJson([])
        uncheckAll();
        selectedOrders = [];
        setSelectedOrdersCount(selectedOrders.length);
        clickedSideBarJson = [];
        selectedOrderJson = [];
        setSingleOrderWeight("")
        setSingleOrderServiceId("")
        setSingleOrderWidth("")
        setSingleOrderHeight("")
        setSingleOrderLength("")
        setIsFullTextVisible(false);
        setIsFullTextVisibleBelow(false);
        setIsFullTextVisibleUnderOne(false);
        setServiceRateAboveFiveLbs("");
        setServiceRateBelowFiveLbs("");
        setServiceRateUnderOneLbs("");
        setBelowFiveLbsOrders([])
        setAboveFiveLbsOrders([])
        setUnderOneLbsOrders([]);
      }}>
      </div>
      <div
        className={
          "order-info " + (clickedJson.length>0 ? "order-active" : "order-inactive")
        }
        style={{backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleUpdateBelowAndAboveFiveLbs();
          }}
        >
          <div className={"head my-2"}>
            <span
              className={"close-btn"}
              onClick={() => {
                document.body.style.overflow = "visible"
                setClickedJson([])
                uncheckAll();
                selectedOrders = [];
                setSelectedOrdersCount(selectedOrders.length);
                clickedSideBarJson = [];
                selectedOrderJson = [];
                setSingleOrderWeight("")
                setSingleOrderServiceId("")
                setSingleOrderWidth("")
                setSingleOrderHeight("")
                setSingleOrderLength("")
                setIsFullTextVisible(false);
                setIsFullTextVisibleBelow(false);
                setIsFullTextVisibleUnderOne(false);
                setServiceRateAboveFiveLbs("");
                setServiceRateBelowFiveLbs("");
                setServiceRateUnderOneLbs("");
                setBelowFiveLbsOrders([])
                setAboveFiveLbsOrders([])
                setUnderOneLbsOrders([]);
              }}
            >
              ⨉
            </span>
            <h4 onClick={() => {}} style={{ width: "fit-content" }}>
              Get Rate
              {/*Update {clickedJson.length>1?"Shipments":"Shipment"}*/}
              {/*{orderUniqueId != null ? orderUniqueId : ""}*!/*/}
            </h4>
          </div>
          <div className={"body"}>
            {belowFiveLbsOrders.length > 0?
              <Row>
                <fieldset>
                  <legend>Below 5 lb. ({belowFiveLbsOrders.length})</legend>
                  <p className={`read-more-text ${isFullTextVisibleBelow ? 'expanded' : ''}`} style={{fontSize:"12px",marginBottom:"0px"}}>{belowFiveLbsOrders.length > 0
                    ? belowFiveLbsOrders.map((part, id) => {
                      if(id == (belowFiveLbsOrders.length - 1)) {
                        return (<span key={id} title={part.order_number}>Shipment #{part.order_unique_id}</span>)
                      }
                      else {
                        return (<span key={id} title={part.order_number}>Shipment #{part.order_unique_id}, </span>)
                        // return ("Shipment #"+part.order_unique_id+", ")
                      }
                    })
                    :null}</p>
                  {belowFiveLbsOrders.length>2?<p className={"text-primary"} style={{fontSize:"12px",fontWeight:"500",cursor:"pointer"}} type={"button"} onClick={()=> {
                    console.log("setIsFullTextVisibleBelow 1",!isFullTextVisibleBelow);
                    setIsFullTextVisibleBelow(!isFullTextVisibleBelow)
                  }}>
                    {isFullTextVisibleBelow ? 'View Less' : 'View More'}
                  </p>:null}
                  <Row className={"mt-3"}>
                    <label className="col-md-3 col-form-label">
                      Service <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className={"col-md-9"}>
                      <select
                        name="serviceName"
                        className="form-control"
                        onChange={handleServiceOrderBelowFiveLbs}
                      >
                        <option selected="true" value={""} disabled="true">
                          Select a service
                        </option>
                        {services.map((part, ind) => {
                          return (
                            part.is_active == 1?
                              <option key={ind} value={part.id}>
                                {part.carrier_name} - {part.name}
                              </option>:null
                          )
                        })}
                      </select>
                    </div>
                  </Row>
                  <div>
                    <Row className="mb-12 py-3">
                      <div className="col">
                        <h4
                          className="text-center"
                          onClick={() => {}}
                          // style={{ textAlign: "center" }}
                        >
                          Rate $
                          {serviceRateBelowFiveLbs
                            ? parseFloat(serviceRateBelowFiveLbs).toFixed(2)
                            : "0.00"} <span className={"text-secondary"} style={{fontSize:"12px",fontWeight:"400"}}>per shipment</span>
                        </h4>
                      </div>
                    </Row>
                  </div>
                </fieldset>
              </Row>
            :null}
            {aboveFiveLbsOrders.length > 0?
              <Row>
                <fieldset>
                  <legend>Above 5 lb. ({aboveFiveLbsOrders.length})</legend>
                  <p className={`read-more-text ${isFullTextVisible ? 'expanded' : ''}`} style={{fontSize:"12px",marginBottom:"0px"}}>{aboveFiveLbsOrders.length > 0
                    ? aboveFiveLbsOrders.map((part, id) => {
                      if(id == (aboveFiveLbsOrders.length - 1)) {
                        return ("Shipment #"+part.order_unique_id)
                      }
                      else {
                        return ("Shipment #"+part.order_unique_id+", ")
                      }
                    })
                    :null}</p>
                  {aboveFiveLbsOrders.length>2?<p className={"text-primary"} style={{fontSize:"12px",fontWeight:"500",cursor:"pointer"}} type={"button"} onClick={()=> {
                    console.log("isFullTextVisible 1",!isFullTextVisible);
                    setIsFullTextVisible(!isFullTextVisible)
                  }}>
                    {isFullTextVisible ? 'View Less' : 'View More'}
                  </p>:null}
                  <Row className={"mt-3"}>
                    <label className="col-md-3 col-form-label">
                      Service <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className={"col-md-9"}>
                      <select
                        name="serviceName"
                        className="form-control"
                        onChange={handleServiceOrderAboveFiveLbs}
                      >
                        <option selected="true" value={""} disabled="true">
                          Select a service
                        </option>
                        {services.map((part, ind) => {
                          return (
                            part.is_active == 1?
                              <option key={ind} value={part.id}>
                                {part.carrier_name} - {part.name}
                              </option>:null
                          )
                        })}
                      </select>
                    </div>
                  </Row>
                  <div>
                    <Row className="mb-12 py-3">
                      <div className="col">
                        <h4
                          className="text-center"
                          onClick={() => {}}
                          // style={{ textAlign: "center" }}
                        >
                          Rate $
                          {serviceRateAboveFiveLbs
                            ? parseFloat(serviceRateAboveFiveLbs).toFixed(2)
                            : "0.00"} <span className={"text-secondary"} style={{fontSize:"12px",fontWeight:"400"}}>per shipment</span>
                        </h4>
                      </div>
                    </Row>
                  </div>
                </fieldset>
              </Row>
            :null}
            {underOneLbsOrders.length > 0?
              <Row>
                <fieldset>
                  <legend>Under 1 lb. ({underOneLbsOrders.length})</legend>
                  <p className={`read-more-text ${isFullTextVisibleUnderOne ? 'expanded' : ''}`} style={{fontSize:"12px",marginBottom:"0px"}}>{underOneLbsOrders.length > 0
                    ? underOneLbsOrders.map((part, id) => {
                      if(id == (underOneLbsOrders.length - 1)) {
                        return ("Shipment #"+part.order_unique_id)
                      }
                      else {
                        return ("Shipment #"+part.order_unique_id+", ")
                      }
                    })
                    :null}</p>
                  {underOneLbsOrders.length>2?<p className={"text-primary"} style={{fontSize:"12px",fontWeight:"500",cursor:"pointer"}} type={"button"} onClick={()=> {
                    console.log("isFullTextVisible 1",!isFullTextVisibleUnderOne);
                    setIsFullTextVisibleUnderOne(!isFullTextVisibleUnderOne)
                  }}>
                    {isFullTextVisibleUnderOne ? 'View Less' : 'View More'}
                  </p>:null}
                  <Row className={"mt-3"}>
                    <label className="col-md-3 col-form-label">
                      Service <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className={"col-md-9"}>
                      <select
                        name="serviceName"
                        className="form-control"
                        onChange={handleServiceOrderUnderOneLbs}
                      >
                        <option selected="true" value={""} disabled="true">
                          Select a service
                        </option>
                        {services.map((part, ind) => {
                          return (
                            part.is_active == 1?
                              <option key={ind} value={part.id}>
                                {part.carrier_name} - {part.name}
                              </option>:null
                          )
                        })}
                      </select>
                    </div>
                  </Row>
                  <div>
                    <Row className="mb-12 py-3">
                      <div className="col">
                        <h4
                          className="text-center"
                          onClick={() => {}}
                          // style={{ textAlign: "center" }}
                        >
                          Rate $
                          {serviceRateUnderOneLbs
                            ? parseFloat(serviceRateUnderOneLbs).toFixed(2)
                            : "0.00"} <span className={"text-secondary"} style={{fontSize:"12px",fontWeight:"400"}}>per shipment</span>
                        </h4>
                      </div>
                    </Row>
                  </div>
                </fieldset>
              </Row>
              :null}

            <Row className="d-flex" style={{ justifyContent: "space-evenly" }}>
              <button
                type="submit"
                className="w-25 btn btn-sm btn-success waves-effect waves-light"
              >
                Update Orders
              </button>
            </Row>
            <hr />
            <div className={"my-2"}>
              <h4 onClick={() => {}} style={{ width: "fit-content" }}>
                Items
              </h4>
            </div>
            <hr />
            {clickedJson.length > 0
              ? clickedJson.map((part, id) => {
                if(part.store_type == null) {
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {part.custom_items_data && JSON.parse(part.custom_items_data).length>0?
                        JSON.parse(part.custom_items_data).map((val, ind) => {
                          return (
                            <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                              <Col sm="12">
                                <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {val.product_identifier}</h6>
                                <hr/>
                                <h5>
                                  <span style={{ float: "right" }}>
                                  {val.quantity} x
                                  </span>
                                  <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                    {val.description}{" "}
                                  </div>
                                </h5>
                                <p>
                                  {val.total_value} USD
                                </p>
                              </Col>
                            </Row>
                          )
                        }):<h6 style={{textAlign:"center"}}>No Item Found</h6>
                      }
                      <hr/>
                    </>
                  )
                }
                else if (part.store_type == "shopify") {
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {
                        JSON.parse(part.custom_items_data).map((val, ind) => {
                          return (
                            <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                              <Col md="12">
                                <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {val.id}</h6>
                                <hr/>
                              </Col>
                              <Col sm="2">
                                <img width="100%" src={val.image_url} />
                              </Col>
                              <Col sm="10">
                                <h5>
                                  <span style={{ float: "right" }}>
                              {val.quantity} x
                            </span>
                                  <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                    {val.name}{" "}
                                  </div>
                                </h5>
                                <p>
                                  {val.price} {val.price_set.shop_money.currency_code}
                                </p>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      <hr/>
                    </>
                  )
                }
                else if (part.store_type == "ebay") {
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {
                        JSON.parse(JSON.parse(part.custom_items_data)).map((val, ind) => {
                          return (
                            <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                              <Col sm="12">
                                <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {val.lineItemId}</h6>
                                <hr/>
                                <h5>
                                    <span style={{ float: "right" }}>
                              {val.quantity} x
                            </span>
                                  <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                    {val.title}{" "}
                                  </div>
                                </h5>
                                <p>
                                  {val.total.value} {val.total.currency}
                                </p>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      <hr/>
                    </>
                  )
                }
                else if (part.store_type == "walmart") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {
                        JSON.parse(part.custom_items_data).map((val, ind) => {
                          return (
                            <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                              <Col sm="12">
                                <h6 className={"bold"} style={{marginBottom:"-10px"}}>SKU: {val.item.sku}</h6>
                                <hr/>
                                <h5>
                                    <span style={{ float: "right" }}>
                              {val.orderLineQuantity.amount} x
                            </span>
                                  <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                    {val.item.productName ? val.item.productName : "-"}{" "}
                                  </div>
                                </h5>
                                <p>
                                  {val.charges.charge[0].chargeAmount.amount} {val.charges.charge[0].chargeAmount.currency}
                                </p>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      <hr/>
                    </>
                  )
                }
                else if (part.store_type == "zenventory") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {
                        JSON.parse(part.custom_items_data).map((val, ind) => {
                          return (
                            <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                              <Col sm="12">
                                <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {val.customerorderitemid}</h6>
                                <hr/>
                                <h5>
                                    <span style={{ float: "right" }}>
                              {val.quantity} x
                            </span>
                                  <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                    {val.itemmaster.description ? val.itemmaster.description : "-"}{" "}
                                  </div>
                                </h5>
                                <p>
                                  ${val.customerprice}
                                </p>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      <hr/>
                    </>
                  )
                }
                else if (part.store_type == "sellercloud") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {
                        JSON.parse(part.custom_items_data).map((val, ind) => {
                          return (
                            <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                              <Col sm="12">
                                <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {val.ProductID}</h6>
                                <hr/>
                                <h5>
                                    <span style={{ float: "right" }}>
                              {val.Qty} x
                            </span>
                                  <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                    {(val.DisplayName ? val.DisplayName : "-")}{" "}
                                  </div>
                                </h5>
                                <p>{val.DisplayDescription?val.DisplayDescription:"-"}</p>
                                <div style={{display:"flex"}}>
                                  <p style={{width:"50%"}}>${val.AdjustedSitePrice}</p>
                                  <p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {val.Weight?val.Weight + "lb.":"-"}</p>
                                </div>
                                <p><span style={{fontWeight:"500"}}>L x W x H:</span> {val.Length?val.Length:"-"} x {val.Width?val.Width:"-"} x {val.Height?val.Height:"-"}</p>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      <hr/>
                    </>
                  )
                }
                else if (part.store_type == "shipstation") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {
                        JSON.parse(part.custom_items_data).map((val, ind) => {
                          return (
                            <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                              <Col sm="12">
                                <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {val.orderItemId}</h6>
                                <hr/>
                                <h5>
                                    <span style={{ float: "right" }}>
                              {val.quantity} x
                            </span>
                                  <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                    {val.name ? val.name : "-"}{" "}
                                  </div>
                                </h5>
                                <p>
                                  ${val.unitPrice}
                                </p>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      <hr/>
                    </>
                  )
                }
              })
              : ""}
          </div>
        </Form>
      </div>
      {/*Right Get Rate Modal*/}

      {/*Right Bulk Update Modal*/}
      <div className={(clickedBulkUpdateJson.length>0 ? "modal-background" : "")} onClick={() => {
        setClickedBulkUpdateJson([])
        uncheckAll();
        selectedOrders = [];
        setSelectedOrdersCount(selectedOrders.length);
        clickedSideBarJson = [];
        selectedOrderJson = [];
        setBulkUpdateServiceId("");
        setBulkUpdateToPhone("");
        setEnableBulkUpdateToPhone(false);
        document.getElementById("bulk-update-enable-to-phone").checked = false;
        setIsFullTextVisible1(false);
      }}>
      </div>
      <div
        className={
          "order-info " + (clickedBulkUpdateJson.length>0 ? "order-active" : "order-inactive")
        }
        style={{backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
            updateBulkOrders()
          }}
        >
          <div className={"head my-2"}>
            <span
              className={"close-btn"}
              onClick={() => {
                document.body.style.overflow = "visible"
                clickedSideBarJson = [];
                setClickedBulkUpdateJson([])
                uncheckAll();
                selectedOrders = [];
                setSelectedOrdersCount(selectedOrders.length);
                clickedSideBarJson = [];
                selectedOrderJson = [];
                setBulkUpdateServiceId("");
                setBulkUpdateToPhone("");
                setEnableBulkUpdateToPhone(false);
                document.getElementById("bulk-update-enable-to-phone").checked = false;
                setIsFullTextVisible1(false);
              }}
            >
              ⨉
            </span>
            <h4 onClick={() => {}} style={{ width: "fit-content" }}>
              Update {clickedBulkUpdateJson.length>1?"Shipments":"Shipment"}
              {/*{orderUniqueId != null ? orderUniqueId : ""}*!/*/}
            </h4>
            <p className={`read-more-text ${isFullTextVisible1 ? 'expanded' : ''}`} style={{fontSize:"12px",marginBottom:"0px"}}>{clickedBulkUpdateJson.length > 0
              ? clickedBulkUpdateJson.map((part, id) => {
                if(id == (clickedBulkUpdateJson.length - 1)) {
                  return ("Shipment #"+part.order_unique_id)
                }
                else {
                  return ("Shipment #"+part.order_unique_id+", ")
                }
              })
              :null}</p>
            {clickedBulkUpdateJson.length>2?<p className={"text-primary"} style={{fontSize:"12px",fontWeight:"500",cursor:"pointer"}} type={"button"} onClick={()=>setIsFullTextVisible1(!isFullTextVisible1)}>
              {isFullTextVisible1 ? 'View Less' : 'View More'}
            </p>:null}
          </div>
          <div className={"body"}>
            <Row className="mb-3 mt-3">
              <label className="col-md-4 col-form-label">
                Service <span style={{ color: "red" }}> *</span>
              </label>
              <div className="col-md-8">
                <select
                  name="serviceName"
                  className="form-control"
                  value={bulkUpdateServiceId}
                  onChange={(e) => setBulkUpdateServiceId(e.target.value)}
                  required={true}
                >
                  <option selected="true" value={""} disabled="true">
                    Select a service
                  </option>
                  {services.map((part, ind) => {
                    return (
                      part.is_active == 1?
                        <option key={ind} value={part.id}>
                          {part.carrier_name} - {part.name}
                        </option>:null
                    )
                  })}
                </select>
              </div>
            </Row>
            <Row className="mb-3 mt-3">
              <label className="col-md-4 col-form-label" style={{color:(enableBulkUpdateToPhone? localStorage.getItem("theme") == "dark" ?"#a6b0c4":"black":"gray")}}>
                <input type={"checkbox"} id={"bulk-update-enable-to-phone"} onChange={(e) => {
                  setBulkUpdateToPhone("");
                  setEnableBulkUpdateToPhone(e.target.checked)
                }}/> Ship To Phone {enableBulkUpdateToPhone?<span style={{ color: "red" }}> *</span>:null}
              </label>
              <div className="col-md-8">
                <input
                  name="to_phone"
                  // value={name}
                  className="form-control non-arrow-number"
                  placeholder="Enter Ship To Phone"
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  value={bulkUpdateToPhone}
                  disabled={!enableBulkUpdateToPhone}
                  onChange={(e) => setBulkUpdateToPhone(e.target.value)}
                  required={enableBulkUpdateToPhone}
                />
              </div>
            </Row>
            <Row className="d-flex" style={{ justifyContent: "space-evenly" }}>
              <button
                type="submit"
                className="w-25 btn btn-sm btn-success waves-effect waves-light"
              >
                Update Orders
              </button>
            </Row>
            <hr />
            <div className={"my-2"}>
              <h4 onClick={() => {}} style={{ width: "fit-content" }}>
                Items
              </h4>
            </div>
            <hr />
            {clickedBulkUpdateJson.length > 0
              ? clickedBulkUpdateJson.map((part, id) => {
                if(part.store_type == null) {
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {part.custom_items_data && JSON.parse(part.custom_items_data).length>0?
                        JSON.parse(part.custom_items_data).map((val, ind) => {
                          return (
                            <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                              <Col sm="12">
                                <h5>
                                  <span style={{ float: "right" }}>
                                  {val.quantity} x
                                  </span>
                                  <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                    {val.description}{" "}
                                  </div>
                                </h5>
                                <p>
                                  {val.total_value} USD
                                </p>
                              </Col>
                            </Row>
                          )
                        }):<h6 style={{textAlign:"center"}}>No Item Found</h6>
                      }
                      <hr/>
                    </>
                  )
                }
                else if (part.store_type == "shopify") {
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {
                        JSON.parse(part.custom_items_data).map((val, ind) => {
                          return (
                            <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                              <Col sm="2">
                                <img width="100%" src={val.image_url} />
                              </Col>
                              <Col sm="10">
                                <h5>
                                  <span style={{ float: "right" }}>
                              {val.quantity} x
                            </span>
                                  <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                    {val.name}{" "}
                                  </div>
                                </h5>
                                <p>
                                  {val.price} {val.price_set.shop_money.currency_code}
                                </p>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      <hr/>
                    </>
                  )
                }
                else if (part.store_type == "ebay") {
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {
                        JSON.parse(JSON.parse(part.custom_items_data)).map((val, ind) => {
                          return (
                            <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                              <Col sm="12">
                                <h5>
                                    <span style={{ float: "right" }}>
                              {val.quantity} x
                            </span>
                                  <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                    {val.title}{" "}
                                  </div>
                                </h5>
                                <p>
                                  {val.total.value} {val.total.currency}
                                </p>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      <hr/>
                    </>
                  )
                }
                else if (part.store_type == "walmart") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {
                        JSON.parse(part.custom_items_data).map((val, ind) => {
                          return (
                            <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                              <Col sm="12">
                                <h5>
                                    <span style={{ float: "right" }}>
                              {val.orderLineQuantity.amount} x
                            </span>
                                  <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                    {val.item.productName ? val.item.productName : "-"}{" "}
                                  </div>
                                </h5>
                                <p>
                                  {val.charges.charge[0].chargeAmount.amount} {val.charges.charge[0].chargeAmount.currency}
                                </p>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      <hr/>
                    </>
                  )
                }
                else if (part.store_type == "zenventory") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {
                        JSON.parse(part.custom_items_data).map((val, ind) => {
                          return (
                            <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                              <Col sm="12">
                                <h5>
                                    <span style={{ float: "right" }}>
                              {val.quantity} x
                            </span>
                                  <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                    {val.itemmaster.description ? val.itemmaster.description : "-"}{" "}
                                  </div>
                                </h5>
                                <p>
                                  ${val.customerprice}
                                </p>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      <hr/>
                    </>
                  )
                }
                else if (part.store_type == "sellercloud") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {
                        JSON.parse(part.custom_items_data).map((val, ind) => {
                          return (
                            <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                              <Col sm="12">
                                <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {val.ProductID}</h6>
                                <hr/>
                                <h5>
                                    <span style={{ float: "right" }}>
                              {val.Qty} x
                            </span>
                                  <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                    {(val.DisplayName ? val.DisplayName : "-")}{" "}
                                  </div>
                                </h5>
                                <p>{val.DisplayDescription?val.DisplayDescription:"-"}</p>
                                <div style={{display:"flex"}}>
                                  <p style={{width:"50%"}}>${val.AdjustedSitePrice}</p>
                                  <p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {val.Weight?val.Weight + "lb.":"-"}</p>
                                </div>
                                <p><span style={{fontWeight:"500"}}>L x W x H:</span> {val.Length?val.Length:"-"} x {val.Width?val.Width:"-"} x {val.Height?val.Height:"-"}</p>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      <hr/>
                    </>
                  )
                }
                else if (part.store_type == "shipstation") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {
                        JSON.parse(part.custom_items_data).map((val, ind) => {
                          return (
                            <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                              <Col sm="12">
                                <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {val.orderItemId}</h6>
                                <hr/>
                                <h5>
                                    <span style={{ float: "right" }}>
                              {val.quantity} x
                            </span>
                                  <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                    {val.name ? val.name : "-"}{" "}
                                  </div>
                                </h5>
                                <p>
                                  ${val.unitPrice}
                                </p>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      <hr/>
                    </>
                  )
                }

              })
              : ""}
          </div>
        </Form>
      </div>
      {/*Right Bulk Update Modal*/}

      <div className="container-fluid">
        <span className={"group-by"} ref={componentGroupByRef} onClick={() => {
          // filterType!="Unassigned"?
            setHideShowGroupBy(!hideShowGroupBy)
            // :null
        }} ><span style={{color:selectedGroupBy?"#ff4800":
            // (filterType!="Unassigned"?
              localStorage.getItem("theme") == "dark"?"#a6b0c4":"black"
              // :"gray")
        }}>Group By</span> <i className={`bx bx-${hideShowGroupBy?"chevron-up":"chevron-down"} font-size-16`}></i>
                <ul style={{display: (hideShowGroupBy? "block": "none"),backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"}}>
                    <li onClick={async () => {
                      clickedSideBarJson = [];
                      uncheckAll();
                      selectedOrders = [];
                      setSelectedOrdersCount(selectedOrders.length);
                      selectedGroupBy = "";
                      await retrieveOrdersByFilterType();
                    }} style={{color:(selectedGroupBy?"#FF6969":"gray")}}>Remove Group By</li>
                    <li onClick={async () => {
                      selectedGroupBy = "Line Items Count";
                      await handleGroupByOrders( "Line Items Count");
                      // setSelectedGroupBy( "Line Items Count")
                      // handleGroupByOrders( "Line Items Count");
                    }}><i className={"bx bx-check"} style={{fontSize:"16px", marginTop:"0px",marginRight:"5px",float:"right", display: (selectedGroupBy== "Line Items Count"?"block":"none")}}></i> Line Items Count</li>
                    <li onClick={async () => {
                      selectedGroupBy = "Weight";
                      await handleGroupByOrders("Weight");
                      // setSelectedGroupBy( "Weight")
                      // handleGroupByOrders( "Weight");
                    }}><i className={"bx bx-check"} style={{fontSize:"16px", marginTop:"0px",marginRight:"5px",float:"right", display: (selectedGroupBy== "Weight"?"block":"none")}}></i> Weight</li>
                    <li onClick={async () => {
                      selectedGroupBy = "Total Quantity";
                      await handleGroupByOrders("Total Quantity");
                    }}><i className={"bx bx-check"} style={{fontSize:"16px", marginTop:"0px",marginRight:"5px",float:"right", display: (selectedGroupBy=="Total Quantity"?"block":"none")}}></i> Total Quantity</li>
                   <li onClick={async () => {
                     selectedGroupBy = "Country";
                     await handleGroupByOrders("Country");
                   }}><i className={"bx bx-check"} style={{fontSize:"16px", marginTop:"0px",marginRight:"5px",float:"right", display: (selectedGroupBy=="Country"?"block":"none")}}></i> Country</li>
                  {filterType!="Unassigned"?
                    <>
                    <li onClick={async () => {
                      selectedGroupBy = "Service";
                      await handleGroupByOrders("Service");
                    }}><i className={"bx bx-check"} style={{fontSize:"16px", marginTop:"0px",marginRight:"5px",float:"right", display: (selectedGroupBy=="Service"?"block":"none")}}></i> Service</li></>:null
                  }
                </ul>
        </span>
        <span className={"columns"} ref={componentColumnsRef} onClick={() => setHideShowColumns(!hideShowColumns)} style={{color:localStorage.getItem("theme") == "dark"?"#a6b0c4":"black"}}>Columns <i className={`bx bx-${hideShowColumns?"chevron-up":"chevron-down"} font-size-16`}></i>
                <ul style={{display: (hideShowColumns? "block": "none"),backgroundColor:localStorage.getItem("theme") == "dark"?"#0f0f0f":"white",height:"350px",overflow:"auto"}}>
                    <li style={{backgroundColor:(!hideShowItemName?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                      hideShowItemName = !hideShowItemName;
                      columns[2].omit = hideShowItemName;
                      setTableData({columns, data: allData})
                      handleUpdateUserPreferences();
                    }}><i className={"bx " + (hideShowItemName?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> Items</li>
                    <li style={{backgroundColor:(!hideShowItemNames?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                      hideShowItemNames = !hideShowItemNames;
                      columns[3].omit = hideShowItemNames;
                      setTableData({columns, data: allData})
                      handleUpdateUserPreferences();
                    }}><i className={"bx " + (hideShowItemNames?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> Item Names</li>
                    <li style={{backgroundColor:(!hideShowProductIds?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                      hideShowProductIds = !hideShowProductIds;
                      columns[4].omit = hideShowProductIds;
                      setTableData({columns, data: allData})
                      handleUpdateUserPreferences();
                    }}><i className={"bx " + (hideShowProductIds?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> Product IDs</li>
                    <li style={{backgroundColor:(!hideShowServiceName?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                      hideShowServiceName = !hideShowServiceName;
                      columns[5].omit = hideShowServiceName;
                      setTableData({columns, data: allData})
                      handleUpdateUserPreferences();
                    }}><i className={"bx " + (hideShowServiceName?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> Service Name</li>
                    <li style={{backgroundColor:(!hideShowFromName?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                      hideShowFromName = !hideShowFromName;
                      columns[6].omit = hideShowFromName;
                      setTableData({columns, data: allData})
                      handleUpdateUserPreferences();
                    }}><i className={"bx " + (hideShowFromName?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> From Name</li>
                    <li style={{backgroundColor:(!hideShowToName?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                      hideShowToName = !hideShowToName;
                      columns[7].omit = hideShowToName;
                      setTableData({columns, data: allData})
                      handleUpdateUserPreferences();
                    }}><i className={"bx " + (hideShowToName?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> To Name</li>
                    <li style={{backgroundColor:(!hideShowNotes?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                      hideShowNotes = !hideShowNotes;
                      columns[8].omit = hideShowNotes;
                      setTableData({columns, data: allData})
                      handleUpdateUserPreferences();
                    }}><i className={"bx " + (hideShowNotes?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> Notes</li>
                    <li style={{backgroundColor:(!hideShowTags?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                      hideShowTags = !hideShowTags;
                      columns[9].omit = hideShowTags;
                      setTableData({columns, data: allData})
                      handleUpdateUserPreferences();
                    }}><i className={"bx " + (hideShowTags?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> Tags</li>
                    <li style={{backgroundColor:(!hideShowOrderNumber?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                      hideShowOrderNumber = !hideShowOrderNumber;
                      columns[10].omit = hideShowOrderNumber;
                      setTableData({columns, data: allData})
                      handleUpdateUserPreferences();
                    }}><i className={"bx " + (hideShowOrderNumber?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> Order #</li>
                    <li style={{backgroundColor:(!hideShowPackageNumber?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                      hideShowPackageNumber = !hideShowPackageNumber;
                      columns[11].omit = hideShowPackageNumber;
                      setTableData({columns, data: allData})
                      handleUpdateUserPreferences();
                    }}><i className={"bx " + (hideShowPackageNumber?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> Package #</li>
                    <li style={{backgroundColor:(!hideShowPicklists?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                      hideShowPicklists = !hideShowPicklists;
                      columns[13].omit = hideShowPicklists;
                      setTableData({columns, data: allData})
                      handleUpdateUserPreferences();
                    }}><i className={"bx " + (hideShowPicklists?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> Picklist</li>
                    <li style={{backgroundColor:(!hideShowOrderDate?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                      hideShowOrderDate = !hideShowOrderDate;
                      columns[16].omit = hideShowOrderDate;
                      setTableData({columns, data: allData})
                      handleUpdateUserPreferences();
                    }}><i className={"bx " + (hideShowOrderDate?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> Order Date</li>
                  <li style={{backgroundColor:(!hideShowUpdatedAt?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                    hideShowUpdatedAt = !hideShowUpdatedAt;
                    columns[17].omit = hideShowUpdatedAt;
                    setTableData({columns, data: allData})
                    handleUpdateUserPreferences();
                  }}><i className={"bx " + (hideShowUpdatedAt?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> Updated At</li>
                  <li style={{backgroundColor:(!hideShowDimension?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                    hideShowDimension = !hideShowDimension;
                    columns[18].omit = hideShowDimension;
                    setTableData({columns, data: allData})
                    handleUpdateUserPreferences();
                  }}><i className={"bx " + (hideShowDimension?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> Dimension</li>
                  <li style={{backgroundColor:(!hideShowWeight?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                    hideShowWeight = !hideShowWeight;
                    columns[19].omit = hideShowWeight;
                    setTableData({columns, data: allData})
                    handleUpdateUserPreferences();
                  }}><i className={"bx " + (hideShowWeight?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> Weight</li>
                    <li style={{backgroundColor:(!hideShowStatus?(localStorage.getItem("theme") == "dark"?"#0f0f0f":"white"):(localStorage.getItem("theme") == "dark"?"#1c1c1c":"#efefef"))}} onClick={() => {
                      hideShowStatus = !hideShowStatus;
                      columns[20].omit = hideShowStatus;
                      setTableData({columns, data: allData})
                      handleUpdateUserPreferences();
                    }}><i className={"bx " + (hideShowStatus?"bx-x":"bx-check")} style={{fontSize:"16px", marginTop:"0px",marginRight:"10px",float:"left"}}></i> Status</li>
                </ul>
        </span>

        <Breadcrumbs
          title="ShipCheap"
          sourceItem="Orders"
          breadcrumbItem="Awaiting Shipment"
          reloadPage={handleReloadPage}
          isOrder={true}
        />
        <Card>
          <CardBody>
              <PDFDownloadLink
              className={"pdf-downloader"}
              document={<PackingSlip />}
              style={{display:"none"}}
              fileName={`${slip_name}-packing-slip.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>
            <PDFDownloadLink
              className={"merged-packing-slip-downloader"}
              document={<MergedPackingSlip />}
              style={{display:"none"}}
              fileName={`merged-packing-slips.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>
            {filterType!="Unassigned" ? <button
              className={"btn btn-primary waves-effect waves-light m-1"}
              onClick={handleVerification}
              disabled={selectedOrders.length==0}
            >
              Create + Print Labels
            </button>:null}
            <button
              className={"btn btn-light waves-effect waves-light m-1"}
              onClick={handleBulKRate}
              disabled={showSideBar || selectedOrders.length == 0}
            >
              Get Rate
            </button>
            <ButtonDropdown
              isOpen={print_dropdown}
              toggle={() => {
                setPrintDropdown(!print_dropdown);
              }}
              disabled={clickedSideBarJson.length==0}
              style={{opacity:(clickedSideBarJson.length==0?'0.7':'1')}}
            >
              <DropdownToggle className={"btn btn-light waves-effect waves-light m-1"} caret color="light">
                Print <i className="bx bx-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={handlePackingSlip}>Packing Slip</DropdownItem>
                <DropdownItem onClick={downloadMergedPackingSlip}>Merged Packing Slips</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <button
              className={"btn btn-light waves-effect waves-light m-1"}
              disabled={true}
              // onClick={handleBulkUpdate}
            >
              Assign To
            </button>
            <button
              className={"btn btn-light waves-effect waves-light m-1"}
              onClick={handleTags}
              disabled={selectedOrders.length==0}
            >
              Tag
            </button>
            <button
              onClick={() => props.history.push("/order/create")}
              className={"btn btn-light waves-effect waves-light m-1"}
              disabled={localStorage.getItem("store")!=0}
            >New Order</button>
            <button
              className={"btn btn-light waves-effect waves-light m-1"}
              onClick={handleBulkUpdate}
              disabled={showSideBar || selectedOrders.length == 0}
            >
              Bulk Update
            </button>
            <button
              className={"btn btn-light waves-effect waves-light m-1"}
              disabled={true}
              // onClick={handleBulkUpdate}
            >
              Allocate
            </button>
            <ButtonDropdown
              isOpen={drp_link}
              toggle={() => {
                setdrp_link(!drp_link);
              }}
            >
              <DropdownToggle className={"btn btn-light waves-effect waves-light m-1"} caret color="light">
                Other Actions <i className="bx bx-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem disabled={localStorage.getItem("store") == 0 ?false:true} onClick={handleImport}>Import Orders</DropdownItem>
                <DropdownItem disabled={localStorage.getItem("store") == 0 ?false:true} onClick={handleImportWithItems}>Import Orders With Items</DropdownItem>
                <DropdownItem onClick={handleBulkDelete} disabled={selectedOrders.length==0}>Bulk Delete</DropdownItem>
                <DropdownItem onClick={handleExport}>Export Orders</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>

            <div className={"col-md-1 col-lg-1 col-xl-2 col-xxl-4 m-1 mt-2"} style={{ position: "relative",float:"right",width: "fit-content"}}>
              {
                JSON.parse(localStorage.getItem('authUser')).role_id === 2 ?
                  <div className={"order-desktop-balance"} style={{ right: '10px', top: '-10px', position: "absolute" }}>
                    <h6>Balance<br /><span style={{ fontSize: "1.5rem" }}>${balance!="-"?amountFormat(parseFloat(balance).toFixed(2)):"..."}</span></h6>
                  </div> : null
              }
            </div>
          </CardBody>
        </Card>
        <Card className={"order-mobile-balance"}>
          <CardBody>
            {
              JSON.parse(localStorage.getItem('authUser')).role_id === 2 ?
                <div>
                  <h6 style={{textAlign:"right"}}>Balance: <span style={{ fontSize: "1.5em" }}>${balance!="-"?amountFormat(parseFloat(balance).toFixed(2)):"..."}</span></h6>
                </div> : null
            }
          </CardBody>
        </Card>
        {carriers.length == 0?<button
          style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)" }}
          disabled={pending}
        >
          Loading...
        </button>:null}
        {localStorage.getItem("store") != 0 ?<button
          className={
            "btn btn-md rounded-top " +
            (filterType === "Unassigned" ? " text-dark" : " text-secondary")
          }
          // disabled={loadingOrders}
          style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == "Unassigned" ? (localStorage.getItem("theme") == "dark"?"#0f0f0f":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
          onClick={handleNoServiceOrders}
          disabled={pending}
        >
          Unassigned ({unassignedOrderCount})
        </button>:null}
        {carriers.length > 0 && carriers.filter(el => el.name == "FedEx")[0].is_active == 1 ?<button
          className={
            "btn btn-md rounded-top " +
            (filterType === "FedEx" ? " text-dark" : " text-secondary")
          }
          // disabled={loadingOrders}
          style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == "FedEx" ? (localStorage.getItem("theme") == "dark"?"#0f0f0f":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
          onClick={handleFedExOrders}
          disabled={pending}
        >
          FedEx ({fedexOrderCount})
        </button>:null}
        {carriers.length > 0 && carriers.filter(el => el.name == "UPS")[0].is_active == 1 ?<button
          className={
            "btn btn-md rounded-top " +
            (filterType === "UPS" ? " text-dark" : " text-secondary")
          }
          // disabled={loadingOrders}
          style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == "UPS" ? (localStorage.getItem("theme") == "dark"?"#0f0f0f":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
          onClick={handleUPSOrders}
          disabled={pending}
        >
          UPS ({upsOrderCount})
        </button>:null}
        {carriers.length > 0 && carriers.filter(el => el.name == "UPSv2")[0].is_active == 1 ?<button
          className={
            "btn btn-md rounded-top  " +
            (filterType === "UPSv2" ? " text-dark" : " text-secondary")
          }
          // disabled={loadingOrders}
          style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == "UPSv2" ? (localStorage.getItem("theme") == "dark"?"#0f0f0f":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
          onClick={handleUPSv2Orders}
          disabled={pending}
        >
          UPSv2 ({upsv2OrderCount})
        </button>:null}
        {carriers.length > 0 && carriers.filter(el => el.name == "DHL")[0].is_active == 1 ?<button
          className={
            "btn btn-md rounded-top  " +
            (filterType === "DHL" ? " text-dark" : " text-secondary")
          }
          // disabled={loadingOrders}
          style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == "DHL" ? (localStorage.getItem("theme") == "dark"?"#0f0f0f":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
          onClick={handleDHLOrders}
          disabled={pending}
        >
          DHL ({dhlOrderCount})
        </button>:null}
        {carriers.length > 0 && carriers.filter(el => el.name == "USPS")[0].is_active == 1 ?<button
          className={
            "btn btn-md  " +
            (filterType === "USPS" ? " text-dark" : " text-secondary")
          }
          // disabled={loadingOrders}
          style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == "USPS" ? (localStorage.getItem("theme") == "dark"?"#0f0f0f":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
          onClick={handleUSPSOrders}
          disabled={pending}
        >
          USPS ({uspsOrderCount})
        </button>:null}
        <div className={"row"}>
          <div className={showSideBar?"col-md-8":"col-md-12"} style={{position:"relative"}}>
            <label htmlFor={"showsidebar"} className={"showsidebar"} style={{position:"absolute",right:"12px",top:"-20px",fontWeight:"500",fontSize:"12px"}}>
              <input type={"checkbox"} id={"showsidebar"} onClick={(e) => {
                let {checked} = e.target;
                if(checked) {
                  setShowSideBar(true);
                }
                else {
                  setShowSideBar(false);
                  document.body.style.overflow = "visible"
                  let x = [];
                  clickedSideBarJson = x;
                  uncheckAll();
                  selectedOrders = [];
                  setSelectedOrdersCount(selectedOrders.length);
                  clickedSideBarJson = [];
                  selectedOrderJson = [];
                  setSingleOrderWeight("")
                  setSingleOrderServiceId("")
                  setSingleOrderWidth("")
                  setSingleOrderHeight("")
                  setSingleOrderLength("")
                  setIsFullTextVisible(false)
                }
              }} /> <span>Show Sidebar</span></label>
            <Card>
              <CardBody>
                {responseSuccess === false ? (
                  <>
                    <br/>
                    <Alert color="danger">
                      {responseMessage}
                      <span className={"alert-loading"}></span>
                    </Alert>
                    <br/>
                  </>
                ) : null}
                {responseSuccess === true ? (
                  <>
                    <br/>
                    <Alert color="success">
                      {responseMessage} {isRetry ? <span>{failedOrders.length} orders failed due to excessive resource consumption, Please <span style={{ fontWeight: "bold", textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={handleRetry}>Try Again</span></span> : ""}
                      <span className={"alert-loading"}></span>
                    </Alert>
                    <br/>
                  </>
                ) : null}
                <div className={"row"} style={{ marginBottom: (windowWidth>1500?"-46px":"10px"),display:"flex"}}>
                  <div className={"custom-filter"} style={{fontSize:"14px",color:localStorage.getItem("theme") == "dark"?"#a6b0c4":"black"}}>Filter By:</div>
                  {stores.length>0?<CustomFilters
                    title={"Store"}
                    options={stores}
                    change={handleStoreSelect}
                  />:null}
                  <CustomFilters
                    title={"Destination"}
                    options={[{ "name": "All","value": "All" },{ "name": "International","value": "International" },{ "name": "Domestic","value": "Domestic" }]}
                    change={handleFilterShipment}
                  />
                  <CustomFilters
                    title={"Assignee"}
                    options={[{"name":"Remove Filter","value":"none"},{ "name": "Unassigned","value": "Unassigned" }]}
                    change={handleAssignee}
                  />
                  {tagsForFilter.length>1?<CustomFilters
                    title={"Tag"}
                    options={tagsForFilter}
                    change={handleSortTag}
                  />:null}
                  {picklists.length>1?<CustomMultiselectFilters
                    title={"Picklist"}
                    options={picklists}
                    change={handleSortPicklist}
                  />:null}
                  <CustomFilters
                    title={"Allocation"}
                    options={[{"name":"Remove Filter","value":"none"},{ "name": "Unallocated","value": "Unallocated" }]}
                    change={handleAllocation}
                  />
                  <CustomFilters
                    title={"Validation"}
                    options={[{"name":"Remove Filter","value":"none"},{ "name": "Passed","value": "Passed" },{ "name": "Failed","value": "Failed" }]}
                    change={handleValidationFilter}
                  />
                  <CustomFilters
                    title={"Order Date"}
                    options={[{"name":"Remove Filter","value":"none"},{ "name": "Today","value": "Today" },{ "name": "Yesterday","value": "Yesterday" },{ "name": "Last Week","value": "Last Week" },{ "name": "Last Month","value": "Last Month" },{ "name": "Last Year","value": "Last Year" }]}
                    change={handleOrderDate}
                  />
                  {/*<CustomFilters*/}
                  {/*  title={"Ship To Name"}*/}
                  {/*  options={[{"name":"None","value":null},{ "name": "Ascending","value": "ASC" },{ "name": "Descending","value": "DESC" }]}*/}
                  {/*  change={handleSortName}*/}
                  {/*/>*/}
                  {/*<CustomFilters*/}
                  {/*  title={"Weight"}*/}
                  {/*  options={[{"name":"Remove Filter","value":"none"},{ "name": "Ascending","value": "ASC" },{ "name": "Descending","value": "DESC" }]}*/}
                  {/*  change={handleSortWeight}*/}
                  {/*/>*/}
                  <CustomFilters
                    title={"Other"}
                    options={[{"name":"Remove Filter","value":"none"},{ "name": "Other","value": "Other" }]}
                    change={handleAllocation}
                  />
                  <CustomFilters
                    title={"Save Filter"}
                    options={[{"name":"Remove Filter","value":"none"},{ "name": "Save Filter","value": "Save Filter" }]}
                    change={handleAllocation}
                  />

                  {/*<CustomFilters*/}
                  {/*  title={"Item Quantity"}*/}
                  {/*  options={[{"name":"None","value":"none"},{ "name": "Ascending","value": "ASC" },{ "name": "Descending","value": "DESC" }]}*/}
                  {/*  change={handleSortQuantity}*/}
                  {/*/>*/}

                  {/*<div className={"col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 mt-2"}>*/}
                  {/*  <label>*/}
                  {/*    Destination*/}
                  {/*  </label>*/}
                  {/*  <select className={"form-select"} value={filterShipment} onChange={handleFilterShipment}>*/}
                  {/*    /!*<option disabled={true} selected={true}>Select Filter</option>*!/*/}
                  {/*    <option value="All">All</option>*/}
                  {/*    <option value="International">International</option>*/}
                  {/*    <option value="Domestic">Domestic</option>*/}
                  {/*  </select>*/}
                  {/*</div>*/}
                  {/*<div className={"col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 mt-2"}>*/}
                  {/*  <label>*/}
                  {/*    Ship To Name*/}
                  {/*  </label>*/}
                  {/*  <select className={"form-select"} value={nameSort} onChange={handleSortName}>*/}
                  {/*    <option selected={true} value={""}>None</option>*/}
                  {/*    <option value="ASC">Ascending</option>*/}
                  {/*    <option value="DESC">Descending</option>*/}
                  {/*  </select>*/}
                  {/*</div>*/}
                  {/*<div className={"col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 mt-2"}>*/}
                  {/*  <label>*/}
                  {/*    Shipment Weight*/}
                  {/*  </label>*/}
                  {/*  <select className={"form-select"} value={weightSort} onChange={handleSortWeight}>*/}
                  {/*    <option selected={true} value={""}>None</option>*/}
                  {/*    <option value="ASC">Ascending</option>*/}
                  {/*    <option value="DESC">Descending</option>*/}
                  {/*  </select>*/}
                  {/*</div>*/}
                  {/*<div className={"col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 mt-2"}>*/}
                  {/*  <label>*/}
                  {/*    Items Quantity*/}
                  {/*  </label>*/}
                  {/*  <select className={"form-select"} value={quantitySort} onChange={handleSortQuantity}>*/}
                  {/*    <option selected={true} value={""}>None</option>*/}
                  {/*    <option value="ASC">Ascending</option>*/}
                  {/*    <option value="DESC">Descending</option>*/}
                  {/*  </select>*/}
                  {/*</div>*/}
                </div>
                {showSideBar?<><br/><br/><br/></>:null}
                {/*<h5 style={{ marginBottom: "-50px" }}>{filterType} Orders<br/>*/}
                {/*  <span style={{fontSize:"12px",fontWeight:"normal",marginTop:"-10px !important"}}>{selectedOrdersCount} {selectedOrdersCount>1?"Orders":"Order"} Selected </span>*/}
                {/*</h5>*/}
                {/*<h4 style={{ marginBottom: "-40px" }}>{filterType} Orders</h4>*/}

                {!selectedGroupBy || (groupByDataTables.length == 0 && !pendingGroupBy)?<DataTableExtensions
                  {...tableData}
                  // exportHeaders={true}
                  filterPlaceholder={`Filter Order With Detail or Item Name...`}
                >
                  <DataTable
                    keyField={"id"}
                    columns={columns}
                    data={filteredData}
                    pagination={50}
                    progressPending={pending}
                    progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                    paginationPerPage={50}
                    paginationRowsPerPageOptions={[50, 100, 200, 500]}
                    highlightOnHover={true}
                    onRowClicked={handleRowClick}
                    theme={localStorage.getItem("theme")=="dark"?"solarized":null} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}
                    expandableRows={true} expandableRowsComponent={expandableRows}
                    expandableRowDisabled={row => row.custom_items_data && JSON.parse(row.custom_items_data).length > 0?false:true}
                    className={"order-table"}
                    // expandableIcon={(row) => {if(row.is_custom_form==0) { return false;}}}
                  />
                </DataTableExtensions>:groupByDataTables.length>0?!pendingGroupBy?groupByDataTables.map((item, index) => (
                  <>
                    <h5 style={{marginTop:"60px",marginBottom:"-40px"}}>{item.name !="-"?selectedGroupBy + ":":null} {item.name!="-"?item.name + (selectedGroupBy=="Weight"?" lb.":""):null}</h5>
                    <DataTableExtensions
                      {...{columns, data: item.data}}
                      // exportHeaders={true}
                      filterPlaceholder={`Filter Order With Detail or Item Name...`}
                    >
<DataTable
                      key={index}
                      keyField={"id"}
                      columns={columns}
                      data={item.data}
                      pagination={50}
                      progressPending={pending}
                      progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                      paginationPerPage={50}
                      paginationRowsPerPageOptions={[50, 100, 200, 500]}
                      highlightOnHover={true}
                      onRowClicked={handleRowClick}
                      theme={localStorage.getItem("theme")=="dark"?"solarized":null} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}
                      expandableRows={true} expandableRowsComponent={expandableRows}
                      expandableRowDisabled={row => row.custom_items_data && JSON.parse(row.custom_items_data).length > 0?false:true}
                      className={"order-table"}
                      // expandableIcon={(row) => {if(row.is_custom_form==0) { return false;}}}
/></DataTableExtensions></>
                )):<h4 style={{marginTop:"60px",marginBottom:"0px"}}>Loading...</h4>:pendingGroupBy?<h4 style={{marginTop:"60px",marginBottom:"0px"}}>Loading...</h4>:null}

              </CardBody>
            </Card>
          </div>
          <div style={{display:showSideBar?"block":"none"}} className={showSideBar?"col-md-4 sticky-sidebar":null}>
            <Card>
              <CardBody style={{height:"590px",overflow:"auto",padding:"0px"}}>
                {clickedSideBarJson.length==0?<h5 className={"text-center p-2"}>No Rows Selected</h5>:
                     <>
                       <h5 className={"bg-light p-2"}>Update {clickedSideBarJson.length>1?"Shipments":"Shipment"} ({selectedOrdersCount} Rows Selected)</h5><br/>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      singleOrderUpdate()
                    }}
                    className={"px-4"}
                    style={{marginTop:"-20px"}}
                  >
                    <div className={"head my-2"}>
                      {/*<span*/}
                      {/*  className={"close-btn"}*/}
                      {/*  onClick={() => {*/}
                      {/*    document.body.style.overflow = "visible"*/}
                      {/*    setClickedSideBarJson([])*/}
                      {/*    uncheckAll();*/}
                      {/*    selectedOrders = [];*/}
                      {/*    setSelectedOrdersCount(selectedOrders.length);*/}
                      {/*    selectedOrderJson = [];*/}
                      {/*    setSingleOrderWeight("")*/}
                      {/*    setSingleOrderServiceId("")*/}
                      {/*    setSingleOrderWidth("")*/}
                      {/*    setSingleOrderHeight("")*/}
                      {/*    setSingleOrderLength("")*/}
                      {/*    setIsFullTextVisible(false);*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  ⨉*/}
                      {/*</span>*/}
                      {/*<h4 onClick={() => {}} style={{ width: "fit-content" }}>*/}
                      {/*  Update {clickedSideBarJson.length>1?"Shipments":"Shipment"}*/}
                      {/*  /!*{orderUniqueId != null ? orderUniqueId : ""}*!/*!/*/}
                      {/*</h4>*/}
                      <p className={`read-more-text ${isFullTextVisible ? 'expanded' : ''}`} style={{fontSize:"12px",marginBottom:"0px"}}>{clickedSideBarJson.length > 0
                        ? clickedSideBarJson.map((part, id) => {
                          if(id == (clickedSideBarJson.length - 1)) {
                            return ("Shipment #"+part.order_unique_id)
                          }
                          else {
                            return ("Shipment #"+part.order_unique_id+", ")
                          }
                        })
                        :null}</p>
                      {clickedSideBarJson.length>1?<p className={"text-primary"} style={{fontSize:"12px",fontWeight:"500",cursor:"pointer"}} type={"button"} onClick={()=>setIsFullTextVisible(!isFullTextVisible)}>
                        {isFullTextVisible ? 'View Less' : 'View More'}
                      </p>:null}
                    </div>
                    <div className={"body"}>
                      <Row className="mb-3 mt-3">
                        <label htmlFor="weight_Input" className="col-md-3 col-form-label">
                          Weight <span style={{ color: "red" }}> *</span>
                        </label>
                        <div className="col-md-9">
                          <input
                            onChange={handleOrderWeight}
                            value={singleOrderWeight}
                            name="weight"
                            placeholder="Weight in (lb)"
                            className="form-control"
                            type="number"
                            step="any"
                            min={0}
                            required={true}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 mt-3">
                        <label className="col-md-3 col-form-label">
                          Service <span style={{ color: "red" }}> *</span>
                        </label>
                        <div className="col-md-9">
                          <select
                            name="serviceName"
                            className="form-control"
                            value={SingleOrderServiceId}
                            onChange={handleServiceOrder}
                          >
                            <option selected="true" value={""} disabled="true">
                              Select a service
                            </option>
                            {services.map((part, ind) => {
                              return (
                                part.is_active == 1?
                                  <option key={ind} value={part.id}>
                                    {part.carrier_name} - {part.name}
                                  </option>:null
                              )
                            })}
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3 mt-3">
                        <fieldset>
                          <legend>Dimensions</legend>
                          <Row style={{ justifyContent: "center" }}>
                            <div className="col-md-4 mb-2">
                              <label>
                                Width{" "}
                                {singleIsDimenssion && (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </label>
                              <input
                                onChange={e => setSingleOrderWidth(e.target.value)}
                                placeholder="Enter width"
                                className="form-control"
                                type="number"
                                step="any"
                                min={0}
                                value={singleOrderWidth}
                                required={singleIsDimenssion}
                              />
                            </div>
                            <div className="col-md-4 mb-2">
                              <label>
                                Height{" "}
                                {singleIsDimenssion && (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </label>
                              <input
                                value={singleOrderHeight}
                                onChange={e => setSingleOrderHeight(e.target.value)}
                                placeholder="Enter height"
                                className="form-control"
                                type="number"
                                step="any"
                                min={0}
                                required={singleIsDimenssion}
                              />
                            </div>
                            <div className="col-md-4 mb-2">
                              <label>
                                Length{" "}
                                {singleIsDimenssion && (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </label>
                              <input
                                value={singleOrderLength}
                                onChange={e => setSingleOrderLength(e.target.value)}
                                placeholder="Enter length"
                                className="form-control"
                                type="number"
                                step="any"
                                min={0}
                                required={singleIsDimenssion}
                              />
                            </div>
                          </Row>
                        </fieldset>
                      </Row>
                      <Row className="mb-12 py-3">
                        <div className="col">
                          <h4
                            className="text-center"
                            onClick={() => {}}
                            // style={{ textAlign: "center" }}
                          >
                            Rate $
                            {singleOrderRate
                              ? parseFloat(singleOrderRate).toFixed(2)
                              : "0.00"} <span className={"text-secondary"} style={{fontSize:"12px",fontWeight:"400"}}>per shipment</span>
                          </h4>
                        </div>
                      </Row>
                      <Row className="d-flex" style={{ justifyContent: "space-evenly" }}>
                        <button
                          type="submit"
                          className="w-50 btn btn-sm btn-success waves-effect waves-light"
                        >
                          Update Orders
                        </button>
                      </Row>
                      <hr />
                      <div className={"my-2"}>
                        <h4 onClick={() => {}} style={{ width: "fit-content" }}>
                          Items
                        </h4>
                      </div>
                      <hr />
                      {clickedSideBarJson.length > 0
                        ? clickedSideBarJson.map((part, id) => {
                          if(part.store_type == null) {
                            return (
                              <>
                                <h6>Shipment #{part.order_unique_id}</h6>
                                {part.custom_items_data && JSON.parse(part.custom_items_data).length>0?
                                  JSON.parse(part.custom_items_data).map((val, ind) => {
                                    return (
                                      <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                                        <Col sm="12">
                                          <h5>
                                  <span style={{ float: "right" }}>
                                  {val.quantity} x
                                  </span>
                                            <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                              {val.description}{" "}
                                            </div>
                                          </h5>
                                          <p>
                                            {val.total_value} USD
                                          </p>
                                        </Col>
                                      </Row>
                                    )
                                  }):<h6 style={{textAlign:"center"}}>No Item Found</h6>
                                }
                                <hr/>
                              </>
                            )
                          }
                          else if (part.store_type == "shopify") {
                            return (
                              <>
                                <h6>Shipment #{part.order_unique_id}</h6>
                                {
                                  JSON.parse(part.custom_items_data).map((val, ind) => {
                                    return (
                                      <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                                        <Col sm="2">
                                          <img width="100%" src={val.image_url} />
                                        </Col>
                                        <Col sm="10">
                                          <h5>
                                  <span style={{ float: "right" }}>
                              {val.quantity} x
                            </span>
                                            <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                              {val.name}{" "}
                                            </div>
                                          </h5>
                                          <p>
                                            {val.price} {val.price_set.shop_money.currency_code}
                                          </p>
                                        </Col>
                                      </Row>
                                    )
                                  })
                                }
                                <hr/>
                              </>
                            )
                          }
                          else if (part.store_type == "ebay") {
                            return (
                              <>
                                <h6>Shipment #{part.order_unique_id}</h6>
                                {
                                  JSON.parse(JSON.parse(part.custom_items_data)).map((val, ind) => {
                                    return (
                                      <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                                        <Col sm="12">
                                          <h5>
                                    <span style={{ float: "right" }}>
                              {val.quantity} x
                            </span>
                                            <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                              {val.title}{" "}
                                            </div>
                                          </h5>
                                          <p>
                                            {val.total.value} {val.total.currency}
                                          </p>
                                        </Col>
                                      </Row>
                                    )
                                  })
                                }
                                <hr/>
                              </>
                            )
                          }
                          else if (part.store_type == "walmart") {
                            // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                            // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                            return (
                              <>
                                <h6>Shipment #{part.order_unique_id}</h6>
                                {
                                  JSON.parse(part.custom_items_data).map((val, ind) => {
                                    return (
                                      <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                                        <Col sm="12">
                                          <h5>
                                    <span style={{ float: "right" }}>
                              {val.orderLineQuantity.amount} x
                            </span>
                                            <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                              {val.item.productName ? val.item.productName : "-"}{" "}
                                            </div>
                                          </h5>
                                          <p>
                                            {val.charges.charge[0].chargeAmount.amount} {val.charges.charge[0].chargeAmount.currency}
                                          </p>
                                        </Col>
                                      </Row>
                                    )
                                  })
                                }
                                <hr/>
                              </>
                            )
                          }
                          else if (part.store_type == "zenventory") {
                            // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                            // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                            return (
                              <>
                                <h6>Shipment #{part.order_unique_id}</h6>
                                {
                                  JSON.parse(part.custom_items_data).map((val, ind) => {
                                    return (
                                      <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                                        <Col sm="12">
                                          <h5>
                                    <span style={{ float: "right" }}>
                              {val.quantity} x
                            </span>
                                            <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                              {val.itemmaster.description ? val.itemmaster.description : "-"}{" "}
                                            </div>
                                          </h5>
                                          <p>
                                            ${val.customerprice}
                                          </p>
                                        </Col>
                                      </Row>
                                    )
                                  })
                                }
                                <hr/>
                              </>
                            )
                          }
                          else if (part.store_type == "sellercloud") {
                            // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                            // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                            return (
                              <>
                                <h6>Shipment #{part.order_unique_id}</h6>
                                {
                                  JSON.parse(part.custom_items_data).map((val, ind) => {
                                    return (
                                      <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                                        <Col sm="12">
                                          <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {val.ProductID}</h6>
                                          <hr/>
                                          <h5>
                                    <span style={{ float: "right" }}>
                              {val.Qty} x
                            </span>
                                            <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                              {(val.DisplayName ? val.DisplayName : "-")}{" "}
                                            </div>
                                          </h5>
                                          <p>{val.DisplayDescription?val.DisplayDescription:"-"}</p>
                                          <div style={{display:"flex"}}>
                                            <p style={{width:"50%"}}>${val.AdjustedSitePrice}</p>
                                            <p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {val.Weight?val.Weight + "lb.":"-"}</p>
                                          </div>
                                          <p><span style={{fontWeight:"500"}}>L x W x H:</span> {val.Length?val.Length:"-"} x {val.Width?val.Width:"-"} x {val.Height?val.Height:"-"}</p>
                                        </Col>
                                      </Row>
                                    )
                                  })
                                }
                                <hr/>
                              </>
                            )
                          }
                          else if (part.store_type == "shipstation") {
                            // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                            // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                            return (
                              <>
                                <h6>Shipment #{part.order_unique_id}</h6>
                                {
                                  JSON.parse(part.custom_items_data).map((val, ind) => {
                                    return (
                                      <Row key={ind} className={"border rounded mt-2"} style={{padding:"10px 10px 0px 10px"}}>
                                        <Col sm="12">
                                          <h6 className={"bold"} style={{marginBottom:"-10px"}}>ID: {val.orderItemId}</h6>
                                          <hr/>
                                          <h5>
                                    <span style={{ float: "right" }}>
                              {val.quantity} x
                            </span>
                                            <div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>
                                              {val.name ? val.name : "-"}{" "}
                                            </div>
                                          </h5>
                                          <p>
                                            ${val.unitPrice}
                                          </p>
                                        </Col>
                                      </Row>
                                    )
                                  })
                                }
                                <hr/>
                              </>
                            )
                          }

                        })
                        : ""}
                    </div>
                  </Form>
                </>}
              </CardBody>
            </Card>
          </div>
        </div>


        {/*Modal*/}
        <Modal
          isOpen={orderDetailsModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal-xl"
          tabIndex="-1"
          // toggle={() => {
          //   setOrderDetailsModal(!orderDetailsModal)
          //   selectedOrders = []
          // }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setOrderDetailsModal(!orderDetailsModal)
                uncheckAll();
                selectedOrders = [];
                setSelectedOrdersCount(selectedOrders.length);
                clickedSideBarJson = [];
                selectedOrderJson = [];
                setBulkUpdateServiceId("");
                setBulkUpdateToPhone("");
                setEnableBulkUpdateToPhone(false);
                setTotalAmount(0);
                setCalculatedAmounts([]);
                setCalculatedAmountsAboveFiveLbs([]);
              }}
            >
              Order Cost Calculation
            </ModalHeader>
            <ModalBody>
              <table className={"table table-bordered"}>
                <tbody>
                <tr style={{ border: "0px" }}>
                  <th style={{ border: "0px" }}>
                    {/* <img
                        src={
                          services.length > 0
                            ? services[
                                services.findIndex(
                                  el => el.id == SingleOrderServiceId
                                )
                              ].carrier_name == "FedEx"
                              ? fedex
                              : filterType == "UPS"
                              ? ups
                              : filterType == "USPS"
                              ? usps
                              : dhl
                            : ""
                        }
                        height={"50px"}
                      /> */}
                  </th>
                  <th style={{ textAlign: "right", border: "0px" }}>
                    Current Balance
                    <br />
                    <h4>${amountFormat(parseFloat(balance).toFixed(2))}</h4>
                  </th>
                </tr>
                </tbody>
              </table>
              <br />
              <table className={"table table-bordered"}>
                <thead>
                <tr>
                  <th>Service</th>
                  <th>Cost</th>
                  <th>Quantity</th>
                  <th>Discount Type</th>
                  <th>Discount</th>
                  <th>Final Cost</th>
                </tr>
                </thead>
                <tbody>
                {calculatedAmounts.length > 0 ? (
                  calculatedAmounts.map((el, id) => {
                    return (
                      <tr key={id}>
                        <td>{el.service_name}</td>
                        <td style={{ textAlign: "right" }}>
                          ${amountFormat(el.actual_service_price)}
                        </td>
                        <td style={{ textAlign: "right" }}>{el.quantity}</td>
                        <td>
                          {el.applied_discount !== "None"
                            ? el.applied_discount
                            : "-"}
                        </td>
                        <td>
                          {el.discount_type !== "None"
                            ? el.discounted_value + " " + el.discount_type
                            : "-"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          ${amountFormat(el.price)}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>-</td>
                  </tr>
                )}
                <tr>
                  <th className={"bg-light"} colSpan={6}>
                    Above 5 lb. Orders
                  </th>
                </tr>
                {calculatedAmountsAboveFiveLbs.length > 0 ? (
                  calculatedAmountsAboveFiveLbs.map((el, id) => {
                    return (
                      <tr key={id}>
                        <td>{el.service_name}</td>
                        <td style={{ textAlign: "right" }}>
                          ${amountFormat(el.actual_service_price)}
                        </td>
                        <td style={{ textAlign: "right" }}>{el.quantity}</td>
                        <td>
                          {el.applied_discount !== "None"
                            ? el.applied_discount
                            : "-"}
                        </td>
                        <td>
                          {el.discount_type !== "None"
                            ? el.discounted_value + " " + el.discount_type
                            : "-"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          ${amountFormat(el.price)}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>-</td>
                  </tr>
                )}
                <tr>
                  <th className={"bg-light"} colSpan={6}>
                    Under 1 lb. Orders
                  </th>
                </tr>
                {calculatedAmountsUnderOneLbs.length > 0 ? (
                  calculatedAmountsUnderOneLbs.map((el, id) => {
                    return (
                      <tr key={id}>
                        <td>{el.service_name}</td>
                        <td style={{ textAlign: "right" }}>
                          ${amountFormat(el.actual_service_price)}
                        </td>
                        <td style={{ textAlign: "right" }}>{el.quantity}</td>
                        <td>
                          {el.applied_discount !== "None"
                            ? el.applied_discount
                            : "-"}
                        </td>
                        <td>
                          {el.discount_type !== "None"
                            ? el.discounted_value + " " + el.discount_type
                            : "-"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          ${amountFormat(el.price)}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>-</td>
                  </tr>
                )}
                </tbody>
                <tfoot>
                <tr>
                  <th style={{ textAlign: "right" }} colSpan={5}>
                    Total
                  </th>
                  {/*<th style={{textAlign:"right"}}>{calculatedAmounts.length>0?calculatedAmounts.map(item => item.price).reduce((prev, next) => prev + next):0}</th>*/}
                  <th style={{ textAlign: "right" }}>
                    ${amountFormat(totalAmount)}
                  </th>
                </tr>
                </tfoot>
              </table>
              {/*<br/>*/}
              {totalAmount <= parseFloat(balance) ?
                (filterType == "UPS" || filterType == "FedEx" ? <>
                    {/*<button style={{ float: "right",marginLeft:"10px" }} className={"btn btn-warning float-right"} title={"Click to proceed the order"} onClick={handleBulkProceed}>Bulk Proceed</button>*/}
                    <button style={{ float: "right" }} className={"btn btn-success float-right"} title={"Click to proceed the order"} onClick={handleProceed}>Proceed</button>
                  </>:
                  <button style={{ float: "right" }} className={"btn btn-success float-right"} title={"Click to proceed the order"} onClick={handleProceed}>Proceed</button>)
                :
                <button style={{ float: "right" }} className={"btn btn-danger float-right"} title={"You have insufficient balance to proceed this order."}>Insufficient Balance</button>
              }
              <br />
              <br />
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Import bulk orders modal*/}
        <Modal
          isOpen={modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal-lg"
          tabIndex="-1"
          toggle={() => {
            setModal(!modal);
          }}
          onClosed={() => {
            setSelectedMeasurement("pounds");
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModal(!modal);
              }}
            >
              Import Bulk Orders
            </ModalHeader>
            <ModalBody>
              <a href={localStorage.getItem("store") == 0 ?templateCsv:"#"} style={{cursor: (localStorage.getItem("store") == 0 ? "pointer":"default")}} download={localStorage.getItem("store") == 0 ?true:false}><button disabled={localStorage.getItem("store") == 0 ?false:true} className={"btn btn-light waves-effect waves-light m-1"}>Download Template <i className={"bx bx-file"}></i></button></a>
              <hr/>
              <form onSubmit={handleImportSubmit}>
                <Label className="form-label">Select A File (.xlsx / .csv / .xls)</Label>
                <input type="file" required={true} className="form-control mb-3" ref={fileInput} onChange={handleChange}
                       accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
                       // accept={".csv"}
                       />
                <Label className="form-label">Unit of Measurement</Label>
                <select required={true} className="form-control mb-3" onChange={(e) => setSelectedMeasurement(e.target.value)}>
                  <option value={"pounds"} selected={selectedMeasurement=="pounds"}>Pounds</option>
                  <option value={"ounces"} selected={selectedMeasurement=="ounces"}>Ounces</option>
                </select>

                <label>Select Carrier <span style={{ color: "red" }}>*</span></label>
                <div className={"col-md-12 rounded p-2"} style={{ border: "1px solid #ced4da",cursor:"pointer" }} onClick={() => {
                  setSelectAvailableCarrier(!selectAvailableCarrier)
                }}>
                  <h5 className={"mt-2"}>{selectedCarrierName?selectedCarrierName.toString().replace(" ",""):"Select Available Carrier"}</h5>
                </div>
                <div className={"row"} style={{ display: (selectAvailableCarrier ? "block" : "none") }}>
                  <div className={"col-md-12"}>
                    <table className={"table table-hover"}>
                      <tbody>
                      {carriers.map((part, id) => {
                        return (
                          part.is_active == 1?
                            <tr key={id} style={{ cursor: "pointer" }} onClick={() => {
                              document.getElementById(`carrier-${part.id}`).click();
                            }}>
                              <td style={{ width: "10px" }}>
                                <input
                                  // className="order-checkbox"
                                  type="radio"
                                  name="carrier"
                                  id={`carrier-${part.id}`}
                                  data-name={part.name}
                                  style={{ marginRight: '20px' }}
                                  onChange={handleCarrier}
                                  value={part.id}
                                  required={true}
                                />
                              </td>
                              <td style={{ width: "30px" }}>
                                <img src={part.name == "FedEx" ? fedex : (part.name == "UPS" || part.name == "UPSv2" ? ups: (part.name == "USPS" ? usps : dhl))} height={"30px"} />
                              </td>
                              <td>{part.name.toString().replace(" ","")}</td>
                            </tr>:null
                        );
                      })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <br/>
                <label>Service <span style={{ color: "red" }}>*</span></label>
                <div className={"col-md-12 rounded p-2"} style={{ border: "1px solid #ced4da",cursor:"pointer" }} onClick={() => {
                  if(selectedCarrier) {
                    setSelectAvailableService(!selectAvailableService)
                  }
                  else {
                    toast.error("Please select a carrier")
                  }
                }}>
                  <h5 className={"mt-2"}>{selectedServiceName?selectedServiceName:"Select Available Service"}</h5>
                </div>
                <div className={"row"} style={{ display: (selectAvailableService ? "block" : "none") }}>
                  <div className={"col-md-12"}>
                    <table className={"table table-hover"}>
                      <thead>
                      <tr>
                        <th colSpan={3}>Services</th>
                        <th style={{ textAlign: "right" }}>Basic Cost</th>
                        <th style={{ textAlign: "right" }}>Above 5 lb. Cost</th>
                      </tr>
                      </thead>
                      <tbody>
                      {services.filter(el => el.carrier_id == selectedCarrier).map((part, id) => {
                        return (
                          <tr key={id} style={{ cursor: "pointer" }} onClick={() => {
                            document.getElementById(`service-${part.id}`).click();
                          }}>
                            <td style={{ width: "10px" }}>
                              <input
                                // className="order-checkbox"
                                type="radio"
                                name="service_id"
                                style={{ marginRight: '20px' }}
                                id={`service-${part.id}`}
                                data-name={part.name}
                                onClick={handleService}
                                value={part.id}
                                required={true}
                              />
                            </td>
                            <td style={{ width: "30px" }}>
                              <img src={`data:image/jpeg;base64,${part.service_image}`} height={"30px"} />
                            </td>
                            <td>{part.name} - {part.description}</td>
                            <td style={{ textAlign: "right" }}>
                              ${part.discount_type === "None" ? part.price : (part.discount_type === "Flat" ? part.price - part.discount : part.price - (part.discount / 100 * part.price))}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              ${part.discount_type === "None" ? part.above_five_lbs_price : (part.discount_type === "Flat" ? part.above_five_lbs_price - part.discount : part.above_five_lbs_price - (part.discount / 100 * part.above_five_lbs_price))}
                            </td>
                          </tr>
                        );
                      })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <button className="btn btn-primary btn-sm w-md mt-3" type="submit">Import</button>
              </form>
            </ModalBody>
          </div>
        </Modal>
        {/*Import bulk orders modal*/}

        {/*Import bulk orders with items modal*/}
        <Modal
          isOpen={modalItems}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal-lg"
          tabIndex="-1"
          toggle={() => {
            setModalItems(!modalItems);
          }}
          onClosed={() => {
            setSelectedMeasurement("pounds");
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModalItems(!modalItems);
              }}
            >
              Import Bulk Orders With Items
            </ModalHeader>
            <ModalBody>
              <a href={localStorage.getItem("store") == 0 ?templateItemCsv:"#"} style={{cursor: (localStorage.getItem("store") == 0 ? "pointer":"default")}} download={localStorage.getItem("store") == 0 ?true:false}><button disabled={localStorage.getItem("store") == 0 ?false:true} className={"btn btn-light waves-effect waves-light m-1"}>Download Template <i className={"bx bx-file"}></i></button></a>
              <hr/>
              <form onSubmit={handleImportWithItemsSubmit}>
                <Label className="form-label">Select A File ( .csv )</Label>
                <input type="file" required={true} className="form-control mb-3" ref={fileInputItems} onChange={handleChange}
                       // accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
                       accept={".csv"}
                       />
                <Label className="form-label">Unit of Measurement</Label>
                <select required={true} className="form-control mb-3" onChange={(e) => setSelectedMeasurement(e.target.value)}>
                  <option value={"pounds"} selected={selectedMeasurement=="pounds"}>Pounds</option>
                  <option value={"ounces"} selected={selectedMeasurement=="ounces"}>Ounces</option>
                </select>

                <label>Select Carrier <span style={{ color: "red" }}>*</span></label>
                <div className={"col-md-12 rounded p-2"} style={{ border: "1px solid #ced4da",cursor:"pointer" }} onClick={() => {
                  setSelectAvailableCarrier(!selectAvailableCarrier)
                }}>
                  <h5 className={"mt-2"}>{selectedCarrierName?selectedCarrierName.toString().replace(" ",""):"Select Available Carrier"}</h5>
                </div>
                <div className={"row"} style={{ display: (selectAvailableCarrier ? "block" : "none") }}>
                  <div className={"col-md-12"}>
                    <table className={"table table-hover"}>
                      <tbody>
                      {carriers.map((part, id) => {
                        return (
                          part.is_active == 1?
                            <tr key={id} style={{ cursor: "pointer" }} onClick={() => {
                              document.getElementById(`carrier-${part.id}`).click();
                            }}>
                              <td style={{ width: "10px" }}>
                                <input
                                  // className="order-checkbox"
                                  type="radio"
                                  name="carrier"
                                  id={`carrier-${part.id}`}
                                  data-name={part.name}
                                  style={{ marginRight: '20px' }}
                                  onChange={handleCarrier}
                                  value={part.id}
                                  required={true}
                                />
                              </td>
                              <td style={{ width: "30px" }}>
                                <img src={part.name == "FedEx" ? fedex : (part.name == "UPS" || part.name == "UPSv2" ? ups: (part.name == "USPS" ? usps : dhl))} height={"30px"} />
                              </td>
                              <td>{part.name.toString().replace(" ","")}</td>
                            </tr>:null
                        );
                      })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <br/>
                <label>Service <span style={{ color: "red" }}>*</span></label>
                <div className={"col-md-12 rounded p-2"} style={{ border: "1px solid #ced4da",cursor:"pointer" }} onClick={() => {
                  if(selectedCarrier) {
                    setSelectAvailableService(!selectAvailableService)
                  }
                  else {
                    toast.error("Please select a carrier")
                  }
                }}>
                  <h5 className={"mt-2"}>{selectedServiceName?selectedServiceName:"Select Available Service"}</h5>
                </div>
                <div className={"row"} style={{ display: (selectAvailableService ? "block" : "none") }}>
                  <div className={"col-md-12"}>
                    <table className={"table table-hover"}>
                      <thead>
                      <tr>
                        <th colSpan={3}>Services</th>
                        <th style={{ textAlign: "right" }}>Basic Cost</th>
                        <th style={{ textAlign: "right" }}>Above 5 lb. Cost</th>
                      </tr>
                      </thead>
                      <tbody>
                      {services.filter(el => el.carrier_id == selectedCarrier).map((part, id) => {
                        return (
                          <tr key={id} style={{ cursor: "pointer" }} onClick={() => {
                            document.getElementById(`service-${part.id}`).click();
                          }}>
                            <td style={{ width: "10px" }}>
                              <input
                                // className="order-checkbox"
                                type="radio"
                                name="service_id"
                                style={{ marginRight: '20px' }}
                                id={`service-${part.id}`}
                                data-name={part.name}
                                onClick={handleService}
                                value={part.id}
                                required={true}
                              />
                            </td>
                            <td style={{ width: "30px" }}>
                              <img src={`data:image/jpeg;base64,${part.service_image}`} height={"30px"} />
                            </td>
                            <td>{part.name} - {part.description}</td>
                            <td style={{ textAlign: "right" }}>
                              ${part.discount_type === "None" ? part.price : (part.discount_type === "Flat" ? part.price - part.discount : part.price - (part.discount / 100 * part.price))}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              ${part.discount_type === "None" ? part.above_five_lbs_price : (part.discount_type === "Flat" ? part.above_five_lbs_price - part.discount : part.above_five_lbs_price - (part.discount / 100 * part.above_five_lbs_price))}
                            </td>
                          </tr>
                        );
                      })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <button className="btn btn-primary btn-sm w-md mt-3" type="submit">Import</button>
              </form>
            </ModalBody>
          </div>
        </Modal>
        {/*Import bulk orders with items modal*/}

        {/*Verify orders modal*/}
        <Modal
          isOpen={verificationModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          // toggle={() => {
          //   if(selectedGroupBy) {
          //     handleGroupByOrders(selectedGroupBy).then(() => {
          //       setVerificationModal(!verificationModal)
          //       selectedOrders = []
          //     });
          //   }
          //   else {
          //     retrieveOrdersByFilterType().then(() => {
          //       setVerificationModal(!verificationModal)
          //       selectedOrders = []
          //     })
          //     }
          // }}
        >
          <div>
            <ModalHeader
              // toggle={() => {
              //   if(selectedGroupBy) {
              //     handleGroupByOrders(selectedGroupBy).then(() => {
              //       setVerificationModal(!verificationModal)
              //       selectedOrders = []
              //     });
              //   }
              //   else {
              //     retrieveOrdersByFilterType().then(() => {
              //       setVerificationModal(!verificationModal)
              //       selectedOrders = []
              //     })
              //     }
              // }}
            >
              Order Validation Report
            </ModalHeader>
            <ModalBody>
              <Alert color={"success"}>
                {selectedOrders.length} orders validation passed.
              </Alert>
              <hr />
              <h5 style={{textAlign:"right"}}>Auto skip in <span className='text-success'>{valTime>0?"0"+valTime.toString() :"..."}</span></h5>
              <button
                className={"btn btn-warning"}
                id={"validate-btn"}
                style={{ float: "right",display:"none" }}
                // disabled={selectedOrders.length > 0 ? (valTime>0?true:false) : true}
                onClick={handleCalculatedPrice}
              >
                {/*{valTime>0?"Skip in " + valTime.toString():"Proceed To Checkout"}*/}
              </button>
              {/*<br />*/}
              {/*<br />*/}
            </ModalBody>
          </div>
        </Modal>
        {/*Verify orders modal*/}

        {/*Verify orders modal*/}
        <Modal
          isOpen={customVerificationModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            if(selectedGroupBy) {
              handleGroupByOrders(selectedGroupBy).then(() => {
                setCustomVerificationModal(!customVerificationModal)
                selectedOrders = []
              })
            }
            else {
              retrieveOrdersByFilterType().then(() => {
                setCustomVerificationModal(!customVerificationModal)
                selectedOrders = []
              })
              }
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                if(selectedGroupBy) {
                  handleGroupByOrders(selectedGroupBy).then(() => {
                    setCustomVerificationModal(!customVerificationModal)
                    selectedOrders = []
                  })
                }
                else {
                  retrieveOrdersByFilterType().then(() => {
                    setCustomVerificationModal(!customVerificationModal)
                    selectedOrders = []
                  })
                }
              }}
            >
              Order Validation Report
            </ModalHeader>
            <ModalBody>
              <Alert color={"success"}>
                {selectedOrders.length} orders validation passed.
              </Alert>
              <hr />
              <button
                className={"btn btn-warning"}
                style={{ float: "right" }}
                disabled={selectedOrders.length > 0 ? false : true}
                onClick={handleCustomCalculatedPrice}
              >
                Proceed To Checkout
              </button>
              <br />
              <br />
            </ModalBody>
          </div>
        </Modal>
        {/*Verify orders modal*/}

        {/*orders error modal*/}
        <Modal
          isOpen={orderErrorModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setOrderErrorModal(!orderErrorModal)
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setOrderErrorModal(!orderErrorModal)
              }}
            >
              Order Errors
            </ModalHeader>
            <ModalBody>
              <ul>
                {orderErrors != ""
                  ? orderErrors.split(",").map((part, id) => {
                    if (part && part != "null") {
                      return (
                        <li className={"text-danger"} key={id}>
                          {part}
                        </li>
                      )
                    }
                  })
                  : ""}
              </ul>
            </ModalBody>
          </div>
        </Modal>
        {/*orders error modal*/}

        {/*tag modal*/}
        <Modal
          isOpen={tagsModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setTagsModal(!tagsModal)
          }}
          onClosed={() => {
            setTag("")
            setTagColor("#e3e3e3");
            uncheckAllTags();
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setTagsModal(!tagsModal)
              }}
            >
              Manage Tag
            </ModalHeader>
            <ModalBody>
              <fieldset className={"mt-2"}>
                <legend>Add New Tag</legend>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    handleCreateTag();
                  }}
                  className={"mb-2"}
                >
                  {/*<label>Tag Name</label>*/}
                  <div className={"row px-2"} style={{justifyContent:"space-around"}}>
                    <input type={"text"} value={tag} required={true} placeholder={"Enter Tag Name"} maxLength={"30"} style={{width:"60%"}} onChange={(e) => setTag(e.target.value)} className={"form-control"}/>
                    <input type={"color"} value={tagColor} required={true} title={"Set Tag Background Color"} onChange={(e)=> {
                      setTagColor(e.target.value)
                    }} className={"form-control form-control-color"} style={{width:"15%",height:"40px"}}/>
                    <button
                      className={"btn btn-sm btn-primary"} style={{width:"15%",height:"40px"}}
                      type="submit"
                    >
                      Add Tag
                    </button>
                  </div>
                </Form>
              </fieldset>
              <h5>Tags<span style={{float:"right",fontWeight:"400",fontSize:"12px"}}>{selectedTags.length>0?selectedTags.length + " selected" :null}</span></h5>
              <div style={{width:"100%",height:"300px",overflow:"auto"}}>
                <table className={"table table-hover"}>
                  <thead>
                  <tr>
                    <th style={{backgroundColor:localStorage.getItem("theme") =="dark"?"#212124":"#F6F6FA"}}></th>
                    <th style={{backgroundColor:localStorage.getItem("theme") =="dark"?"#212124":"#F6F6FA"}}>Tag</th>
                    <th style={{backgroundColor:localStorage.getItem("theme") =="dark"?"#212124":"#F6F6FA"}}>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  {tags.length > 0 ?
                    tags.map((el, index) => (
                      <tr key={index}>
                        <td style={{verticalAlign:"middle",width:"10%",textAlign:"center"}}><input type={"checkbox"} onClick={handleTagCheck} className={"tag"} id={"tag-"+el.id} value={el.id}/></td>
                        <td style={{verticalAlign:"middle"}} onClick={() => {document.getElementById("tag-"+el.id).click()}}><span className={"badge"} style={{backgroundColor: el.color, color: (parseFloat(getBrightness(el.color)) > 128 ? "black" : "white"),padding:"5px" }}>{el.tag}</span></td>
                        <td style={{width:"10%",textAlign:"center"}}><button className={"btn btn-danger btn-sm"} onClick={()=>handleDeleteTag(el.id)}><i className={"bx bx-trash"}></i></button></td>
                      </tr>
                    ))
                    :null}
                  </tbody>
                </table>
              </div>
              <br/>
              <table className={"table"}>
                <tbody>
                <tr>
                  <td style={{textAlign:"right",border:"none", width:"100%"}}>
                    <button className={"btn btn-success btn-sm"} onClick={handleUpdateTag}>Apply Tags</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </ModalBody>
          </div>
        </Modal>
        {/*tag modal*/}

        {/*from buyer note modal*/}
        <Modal
          isOpen={fromBuyerNotesModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setFromBuyerNotesModal(!fromBuyerNotesModal)
          }}
          onClosed={() => {
            setNotesOrderId("");
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setFromBuyerNotesModal(!fromBuyerNotesModal)
              }}
            >
              From Buyer Notes
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  handleUpdateNotes();
                }}
              >
                <label>Notes</label>
                <textarea type={"text"} required={true} readOnly={localStorage.getItem("store")!="0"} placeholder={"Enter From Buyer Notes"} onChange={(e) => setFromBuyerNotes(e.target.value)} className={"form-control"}>{fromBuyerNotes}</textarea>
                <br />
                {localStorage.getItem("store")=="0"?<button
                  className={"btn btn-sm btn-success"}
                  type="submit"
                  style={{ float: "right" }}
                >
                  Save
                </button>:null}
              </Form>
              <br />
              <br />
            </ModalBody>
          </div>
        </Modal>
        {/*from buyer note modal*/}

        {/*to buyer note modal*/}
        <Modal
          isOpen={toBuyerNotesModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setToBuyerNotesModal(!toBuyerNotesModal)
          }}
          onClosed={() => {
            setNotesOrderId("");
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setToBuyerNotesModal(!toBuyerNotesModal)
              }}
            >
              To Buyer Notes
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  handleUpdateNotes();
                }}
              >
                <label>Notes</label>
                <textarea type={"text"} required={true} readOnly={localStorage.getItem("store")!="0"} placeholder={"Enter To Buyer Notes"} onChange={(e) => setToBuyerNotes(e.target.value)} className={"form-control"}>{toBuyerNotes}</textarea>
                <br />
                {localStorage.getItem("store")=="0"?<button
                  className={"btn btn-sm btn-success"}
                  type="submit"
                  style={{ float: "right" }}
                >
                  Save
                </button>:null}
              </Form>
              <br />
              <br />
            </ModalBody>
          </div>
        </Modal>
        {/*to buyer note modal*/}

        {/*to buyer note modal*/}
        <Modal
          isOpen={internalNotesModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setInternalBuyerNotesModal(!internalNotesModal)
          }}
          onClosed={() => {
            setNotesOrderId("");
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setInternalBuyerNotesModal(!internalNotesModal)
              }}
            >
              Internal Notes
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  handleUpdateNotes();
                }}
              >
                <label>Notes</label>
                <textarea type={"text"} required={true} readOnly={localStorage.getItem("store")!="0"} placeholder={"Enter Internal Notes"} onChange={(e) => setInternalBuyerNotes(e.target.value)} className={"form-control"}>{internalNotes}</textarea>
                <br />
                {localStorage.getItem("store")=="0"?<button
                  className={"btn btn-sm btn-success"}
                  type="submit"
                  style={{ float: "right" }}
                >
                  Save
                </button>:null}
              </Form>
              <br />
              <br />
            </ModalBody>
          </div>
        </Modal>
        {/*to buyer note modal*/}

        {/*Item name - product ids modal*/}
        <Modal
          isOpen={orderItemInfoModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setOrderItemInfoModal(!orderItemInfoModal)
            setOrderItemType("")
            setOrderItemData("")
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setOrderItemInfoModal(!orderItemInfoModal)
                setOrderItemType("")
                setOrderItemData("")
              }}
            >
              {orderItemType}
            </ModalHeader>
            <ModalBody>
              {orderItemData}
            </ModalBody>
          </div>
        </Modal>
        {/*Item name - product ids modal*/}

        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          setViewModal={data => {
            setViewModal(data)
          }}
        />
      </div>
    </div>
  )
}
AwaitingShipment.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default AwaitingShipment;