import PropTypes from "prop-types";
import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/nullship-icon.png";
import { post } from "../../helpers/api_helper"
// import namelogo from "assets/images/nullship-logo.png";
import namelogo from "assets/images/nullship_christmas_logo_uncropped.gif";
import img11 from "assets/images/img11.png";
import img12 from "assets/images/img12.png";
// import header background img
import bgimg from "assets/images/bgimg.png";
import LoginFooter from "components/LoginFooter";
import anim_logo from '../../assets/images/ainimationlogo.gif'
import config from "../../config"
import { Toaster } from "react-hot-toast"
import {toast} from "react-hot-toast";

// Images
import Logo from '../../assets/images/shipcheap.svg'
import LiningBg from '../../assets/images/lining-bg.svg'
import USPS from '../../assets/images/usps-service.svg'
import DHL from '../../assets/images/dhl-service.svg'
import UPS from '../../assets/images/ups-service.svg'

const ResetPassword = props => {

  //meta title
  document.title="Reset Password | ShipCheap";

  const dispatch = useDispatch();
  const {
    match: { params },
  } = props

  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [email, setEmail] = useState(params.email);
  const [token, setToken] = useState(params.token);

  const [PWFocus, setPWFocus] = useState(false);
  const [passValid, setPassValid] = useState(null);
  const [confirmValid, setConfirmValid] = useState(true);
  const [firstCondition, setFirstCondition] = useState(false);
  const [secondCondition, setSecondCondition] = useState(false);
  const [thirdCondition, setThirdCondition] = useState(false);
  const [forthCondition, setForthCondition] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    const body = {
      email: email,
      token: token
    }
    post('/user/check-reset-password',body)
      .then(async (res) => {
        // // console.log(res.data);
        if (res.status !== 200) {
          toast.error(res.message);
          props.history.push('/');
        }
      });
  },[]);


  const handleResetPassword = async (values) => {
    try {
      if(password == confirmPassword) {
        const body = {
          email: email,
          password:password
        }
        // console.log("body",body);
        await post('/user/reset-password', body).then((response) => {
          // console.log("response", response);
          if(response.status === 200) {
            setResponseSuccess(true);
            setResponseMessage(response.message);
            props.history.push('/');
          }
          else {
            setResponseSuccess(false);
            setResponseMessage(response.message);
          }
        })
      }
      else {
        toast.error("Password and confirm password do not match");
      }
    }
    catch (e) {
      // console.log(e);
    }
  }

  function hasLowerCase(str) {
    return (/[a-z]/.test(str));
  }

  function hasUpperCase(str) {
    return (/[A-Z]/.test(str));
  }

  function hasNumber(str) {
    return (/[1-9]/.test(str));
  }

  function hasSpecial(str) {
    let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return (format.test(str));
  }

  const handleFocus = () => {
    // console.log("setPWFocus true")
    setPWFocus(true);
  }

  const handleFocusOut = () => {
    // console.log("setPWFocus false")
    setPWFocus(false);
  }

  const handlePassword = (e) => {
    setPassword(e.target.value);
    //// console.log("e.target.value.length",e.target.value.length);
    if(e.target.value.length >= 8) {
      setFirstCondition(true);
      // //// console.log(firstCondition)
    }
    else {
      setFirstCondition(false);
    }

    if(hasUpperCase(e.target.value) && hasLowerCase(e.target.value)) {
      setSecondCondition(true);
    }
    else {
      setSecondCondition(false);
    }

    if(hasNumber(e.target.value)) {
      setThirdCondition(true);
    }
    else {
      setThirdCondition(false);
    }


    if(hasSpecial(e.target.value)) {
      setForthCondition(true);
    }
    else {
      setForthCondition(false);
    }

    if(e.target.value.length >= 7 && hasUpperCase(e.target.value) && hasLowerCase(e.target.value) && hasNumber(e.target.value) && hasSpecial(e.target.value)) {
      setPassValid(true);
    }
    else{
      setPassValid(false);
    }

    if(confirmPassword === e.target.value) {
      setConfirmValid(true);
    }
    else {
      setConfirmValid(false);
    }
  }

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    if(password === e.target.value) {
      setConfirmValid(true);
    }
    else {
      setConfirmValid(false);
    }
  }

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div> */}
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      {/* <div style={{ backgroundImage: `url(${bgimg})`, width: "100%", marginBottom: "20px", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "95px", position: "fixed", top: "0", left: "0", zIndex: "999" }} className="navbar-header">
        <a href={`${config.main_web.URL}`}><img className="mx-5" height={"120px"}
          // width="230px"
                                                src={namelogo} alt="" /></a>
      </div> */}
      <div className={"col-md-12"}>
        <div className="col-xl-12 mx-auto">
          {/* <Card>
            <CardBody> */}
            <Row >
                <Col xl={6} lg={6} className="d-none d-sm-none d-md-none d-lg-block">
                  <div className="relative overflow-hidden h-[100vh] md:flex flex-col bg-gradient-to-tr from-[#070A1F] to-[#070A1F] i justify-between">
                    <div className="absolute -top-36 -left-36 w-4/5 h-4/5 bg-[#2F3885] rounded-full blur-[250px] z-0"></div>
                    <div className="px-20 mt-10 w-11/12 z-[1]">
                      <img src={Logo} alt="Null Ship Logo" className="mt-20 mb-6" width={300} />
                      <h1 className="text-white font-bold text-4xl">Welcome to Simpler Shipping</h1>
                      <p className="text-white text-1xl mt-3">Where your packages are our top priority. We pride ourselves on delivering reliability, speed, and satisfaction. Our commitment extends beyond borders, ensuring your shipments reach their destination with precision and care. Trust us to connect you to a world of possibilities.</p>
                    </div>
                    <div className="absolute top-0 bottom-0 right-0">
                      <img src={LiningBg} alt="Lining bg animation" />
                    </div>
                    <div className="shipping-group relative self-end mr-24 mb-20 w-[570px] h-[375px] ">
                      <div className="absolute bottom-[125px] right-[370px] z-[3]">
                        <img src={USPS} alt="service bg animation" />
                      </div>
                      <div className="absolute bottom-0 right-[180px] z-[2]">
                        <img src={DHL} alt="service bg animation" />
                      </div>
                      <div className="absolute top-0 right-[0px] z-[1]">
                        <img src={UPS} alt="service bg animation" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} className="flex items-center form_div">
                  <div className="w-[320px] my-6 m-auto">
                    <CardBody className="pt-0">
                      <div className="text-center">
                        <h2 className="mb-3 text-4xl font-extrabold text-dark-gray-900 text-[#000]">Reset Password</h2>
                        {/* <p className="mb-2 text-gray-700">Get started in seconds</p> */}
                      </div>
                      <div className="p-2">
                        {responseSuccess && !responseSuccess ? (
                          <Alert color="danger" style={{ marginTop: "13px" }}>
                            {responseMessage}
                          </Alert>
                        ) : null}
                        {responseSuccess && responseSuccess ? (
                          <Alert color="success" style={{ marginTop: "13px" }}>
                            {responseMessage}
                          </Alert>
                        ) : null}
                        <Form
                          className="form-horizontal"
                          onSubmit={async (e) => {
                            e.preventDefault();
                            await handleResetPassword();
                            return false;
                          }}
                        >
                          <div className="mb-3" style={{position:"relative"}}>
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              className="custom_input"
                              placeholder="Enter Password"
                              type="password"
                              value={password}
                              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{7,20}$"
                              onChange={handlePassword}
                              onFocus={handleFocus}
                              onBlur={handleFocusOut}
                              required={true}
                            />
                            <span style={{color:"red",fontSize:"11px"}}>{passValid == null || passValid == true ? "" : "Please fulfill the password requirements"}</span>
                            <div className={"bg-white"} style={{display:(PWFocus?"block":"none"),position:"absolute",bottom:"-130px",backgroundColor:"red",right:"0",paddingRight:"30px",paddingLeft:"30px",borderRadius:"10px",border:"1px solid #333",zIndex:"99999"}}>
                              <p style={{fontWeight:"600"}}>Password Requirements</p>
                              <ul id="ul" style={{fontSize:"12px",listStyleType:"none",marginLeft:"-50px"}}>
                                <li className={firstCondition?"conditionPass":"conditionFail"}>At least 8 characters.</li>
                                <li className={secondCondition?"conditionPass":"conditionFail"}>Contains uppercase and lowercase letters.</li>
                                <li className={thirdCondition?"conditionPass":"conditionFail"}>Contains numbers.</li>
                                <li className={forthCondition?"conditionPass":"conditionFail"}>Contains at least one special character, <br/>e.g. _ ! @ # ? ]</li>
                              </ul>
                            </div>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Confirm Password</Label>
                            <Input
                              name="confirmPassword"
                              className="custom_input"
                              placeholder="Enter Confirm Password"
                              type="password"
                              onChange={handleConfirmPassword}
                              value={confirmPassword}
                              // onClick={() => {setConfirmValid(false)}}
                              required={true}
                              disabled={password?false:true}
                            />
                            <span style={{color:"red",fontSize:"11px"}}>{confirmValid ? "" : "Password do not match"}</span>
                          </div>
                          <div className="text-center">
                            <button
                              className="btn custom_btn btn-secondary w-full m-auto btn-block ntb"
                              type="submit"
                              disabled={
                                (confirmValid
                                  ? (passValid ?
                                    false :
                                    true)
                                  : true)}
                            >
                              Reset Password
                            </button>
                          </div>
                        </Form>
                      </div>
                      <div className="mt-2 text-center">
                        <p>
                          Already have an account ?{" "}
                          <Link to="/login" className="fw-medium text-danger">
                            {" "}
                            Login
                          </Link>{" "}
                        </p>
                      </div>
                    </CardBody>
                  </div>
                  {/* <hr/> */}
                  
                </Col>
              </Row>
            {/* </CardBody>
          </Card> */}
        </div>
      </div>
      {/* <LoginFooter/> */}
    </React.Fragment>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ResetPassword);
