import React, { useState, useEffect } from "react";
import Breadcrumbs from "components/Common/Breadcrumb";
import PropTypes from 'prop-types';
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable, { createTheme } from "react-data-table-component"
import { post, get } from "../../../helpers/api_helper"
import customStyles from "../../../assets/css/customTableStyle"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"
import Select from 'react-select';
import { Link } from 'react-router-dom'
import {
   Container,
   Row,
   Col,
   Card,
   Alert,
   CardBody,
   Button,
   Label,
   Input,
   FormFeedback,
   Form,
   Modal,
   ModalHeader,
   ModalBody,
   CardHeader, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
 } from "reactstrap"
 import moment from "moment"

function ReferralAdmin() {
   // Page Title
   document.title = "Referral || ShipCheap"

   const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("token")}`,
   };

   const [pending, setPending] = useState(true)
   const [filterType, setFilterType] = useState(localStorage.getItem("referral-filter") ? localStorage.getItem("referral-filter") : "referralData")
   const [filterData, setFilterData] = useState(null)
   const [tableData, setTableData] = useState([]);
   const [paymentData, setPaymentData] = useState([]);
   const [paymentGroupData, setPaymentGroupData] = useState([]);
   const [searchTerm, setSearchTerm] = useState('');
   const [from, setFrom] = useState('');
   const [to, setTo] = useState('');
   const [emailList, setEmailList] = useState([]);
   const [userEmails, setUserEmails] = useState("");
   const [filterChecks, setFilterChecks] = useState("");


   // Tabs Buttons
   const buttonTypes = [
      {value: 'Referral Data', key: 'referralData'},
      {value: 'Payment History', key: 'paymentHistory'}
   ]

   // Get Users Email from API
   const retrieveUsers = () => {
      try {
         get('/admin/getUsers', {headers}).then((response) => {
            console.log(response)
            let arr = [] 
            const getUserEmails = response.data;
            getUserEmails.forEach(item => {
               arr.push({
                  value: item.id,
                  label: item.email
               })
            })
            console.log(arr)
            setEmailList(arr)
         })
      } catch (error) {

      }
   }

   // Group By Reference Email Filter
   const groupedData = paymentData.reduce((groups, item) => {
      const { user_email } = item;
      if (!groups[user_email]) {
         groups[user_email] = [];
      }
      groups[user_email].push(item);
      return groups;
   }, {})

   // Get Filter Data From API
   const retrieveFilteredData = (search, from, to, user_id, group_by_referrer) => {
      try {
         let body = {
            search: search,
            from: from,
            to: to,
            refer_by: user_id,
            group_by_referrer: group_by_referrer
         }
         console.log("Filter Data", body)
         post('/admin/get-filtered-referrers', body, {headers}).then((response) => {
            if(response.status === 200) {
               if (group_by_referrer) {
                  setPaymentGroupData(groupedData)
                  console.log("Payment Grouped Data", groupedData)
               }
               setFilterData(response.data)
            }
         })
      } catch (error) {

      }
   }
   
   // Get Referred Data from API
   const retrieveUsersData = () => {
      try {
         get('/admin/get-referrer', {headers}).then((response) => {
            if(response.status === 200) {
               console.log("Referrer List", response)
               setTableData(response.data)
               console.log("Table Data", tableData)
            }
         })
      } catch (error) {

      }
   }

   // Get Payment Data from API
   const retrievePaymentData = () => {
      try {
         get('/admin/get-referrer-payments', {headers}).then((response) => {
            if(response.status === 200) {
               console.log("Payment List", response)
               setPaymentData(response.data)
               console.log("PaymentData", paymentData)
            }
         })
      } catch (error) {

      }
   }

   // Resuable handle for filter state update
   const handleToggleData = (type) => {
      setFilterType(type)
      setFilterChecks(false)
      localStorage.setItem('referral-filter', type)
   } 
   

   const dataToDisplay = filterData ? filterData : tableData;
   
   useEffect(() => {
      retrieveUsers()
      retrieveFilteredData()
      retrieveUsersData()
      retrievePaymentData()
      setPending(false)
      console.log(filterType) 
   }, [filterType])

   // Referral Data Columns
   const referral_data_columns = [
      {
         name: "User Id",
         // selector: "refer_by_user_id",
         selector: row=>row.refer_by_user_id,

         width: "250px", 
      },
      {
         name: "Email Referred By",
         // selector: "refer_by_email",
         selector: row=>row.refer_by_email,

         //   width: "300px",
      },
      {
         name: "Referred To",
         // selector: "refer_to_email",
         selector: row=>row.refer_to_email,

         //   width: "300px", 
      },
      {
         name: "Total Labels",
         // selector: "completed_order_count",
         selector: row=>row.completed_order_count,

           width: "250px", 
      },
      {
         name: "Labels Refunded",
         // selector: "refunded_order_count",
         selector: row=>row.refunded_order_count,

           width: "250px", 
      },
      {
         name: "Created At",
         // selector: "created_at",
         selector: row=> row.created_at,
           width: "250px", 
         format: (row) => {
         const formattedDate = moment(row.created_at).utc(false).utcOffset('-07:00').format("MMM DD, YYYY hh:mm A")
         return formattedDate;
         },
      }
   ]

   
   // Payment History Columns
   const payment_history_columns = [
      {
         name: "Email Referred By",
         // selector: "user_email",
         selector: row=>row.user_email,

      },
      {
         name: "Payment Date Time",
         // selector: "created_at",
         selector: row=>row.created_at,

         format: (row) => {
            const formattedDate = moment(row.created_at).utc(false).utcOffset('-07:00').format("MMM DD, YYYY hh:mm A")
            return formattedDate;
          },
      },
      {
         name: "Payment Mode",
         // selector: "payment_mode",
         selector: row=>row.payment_mode,

         
      },
      {
         name: "Amount (USD)",
         // selector: "amount",
         selector: row=>row.amount,

           width: "250px",
      },
      {
         name: "Total Labels",
         // selector: "order_count",
         selector: row=>row.order_count,

           width: "250px",
      }
   ]

  return (
   <div className="page-content">
      <div className="container-fluid">
         <Breadcrumbs title="ShipCheap" breadcrumbItem="Referral" />
         
         <Card>
          <CardBody className="flex gap-3">
            <Link to={"/add-user"}><button className={"btn btn-primary btn-sm waves-effect waves-light"}>Add User</button></Link>
            <Link to={"/add-payment"}><button className={"btn btn-primary btn-sm waves-effect waves-light"}>Add Payment</button></Link>
          </CardBody>
        </Card>
         
         {/* Tabs Section */}
        <div
          style={{ width: "fit-content", background: localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8" }}
          className="rounded-top"
        >
         {buttonTypes.map((button, index) => {
            return (
               <button 
                     key={index}
                     className={
                        "btn btn-md rounded-top " +
                        (filterType === "Reports" ? " text-dark" : " text-secondary")
                      }
                     style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == button?.key ? (localStorage.getItem("theme") == "dark"?"#2a3042":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
                     onClick={() => handleToggleData(button?.key)}
                     disabled={pending}
                     >{button?.value}</button>
                  )
         })}
        </div>

        {/* Filter and Data Content Section */}
        <Card style={{borderRadius: "0px 3px 3px 3px"}}>
            <CardBody>
               <Form  className="form-horizontal">
                  <Row>
                  {filterType === 'referralData' && <>
                        <div className={"col-xl-3 col-lg-3 col-md-3 p-1"}>
                           <div className="mb-3">
                              <Label className="form-label">Filter</Label>
                              <Input
                              type="text"
                              className="form-control"
                              placeholder={"Filter Data..."}
                              value={searchTerm}
                              onChange={async (e) => {
                                 setSearchTerm(e.target.value)
                                 await retrieveFilteredData(e.target.value, from, to, userEmails.value, filterChecks);
                              }}
                              />
                           </div>
                        </div>
                        <div className={"col-xl-2 col-lg-3 col-md-3 p-1"}>
                           <div className="mb-3">
                              <Label className="form-label">From</Label>
                              <Input
                              name="from"
                              className="form-control"
                              type="date"
                              max={to}
                              value={from}
                              onChange={async (e) => {
                                 setFrom(e.target.value)
                                 await retrieveFilteredData(searchTerm, e.target.value, to, userEmails.value, filterChecks)
                              }}
                              />
                           </div>
                        </div>
                        <div className={"col-xl-2 col-lg-3 col-md-3 p-1"}>
                           <div className="mb-3">
                              <Label className="form-label">To</Label>
                              <Input
                              name="to"
                              className="form-control"
                              type="date"
                              disabled={from == "" ? true : false}
                              title={from == "" ? "Please select from date" : ""}
                              min={from}
                              value={from == "" ? "" : to}
                              onChange={async (e) => {
                                 setTo(e.target.value)
                                 await retrieveFilteredData(searchTerm, from, e.target.value, userEmails.value, filterChecks)
                              }}
                              />
                           </div>
                        </div>
                     {(JSON.parse(localStorage.getItem('authUser')).role_id == 1) && 
                        (
                           <div className={"col-xl-3 col-lg-3 col-md-3 p-1"}>
                              <div className="mb-3">
                                 <Label htmlFor="referredby" className="form-label">Referred By</Label>
                                 <Select
                                    options={emailList}
                                    placeholder="Select Email"
                                    value={userEmails}
                                    // onChange={value => handleEmailFilter(value)}
                                    onChange={async (value) => {
                                       setUserEmails(value)
                                       await retrieveFilteredData(searchTerm, from, to, (value.value ? value.value : ""), filterChecks)
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                 />
                              </div>
                           </div>)}
                  </>}
                  {filterType === 'paymentHistory' && 
                     <div style={{position:"relative"}}>
                           <label htmlFor={"group-reference-email"} style={{fontWeight:"500",fontSize:"12px",zIndex:"99", marginRight: "30px"}} >
                               <input 
                                 type={"checkbox"} 
                                 id={"group-reference-email"} 
                                 name="reference" 
                                 value={filterChecks}
                                 onChange={async (e) => {
                                    setFilterChecks(e.target.checked)
                                    await retrieveFilteredData(searchTerm, from, to, userEmails.value, e.target.checked)
                                 }}
                                 /> 
                                 <span style={{marginLeft: "10px"}}>Group By Reference Email</span>
                           </label>
                            {/* <label htmlFor={"group-payment-date"} style={{fontWeight:"500",fontSize:"12px",zIndex:"99"}} onClick={(e) => {handleCheckbox('payment')}}>
                               <input type={"checkbox"} id={"group-payment-dates"} name="payment" checked={selectedCheck === 'payment' ? true : ""}/> <span>Group By Payment Date</span>
                            </label> */}
                        </div>
                     }
                  </Row>
               </Form>
               {filterType == 'paymentHistory' && filterChecks == true ? (Object.entries(paymentGroupData).map(([user_email, items], i) => {
                  return (
                     <div style={{ paddingTop: "10px" }} key={i}>
                        {/* <DataTableExtensions
                      {...{payment_history_columns, items}}
                      // exportHeaders={true}
                      filterPlaceholder={`Filter...`}
                    > */}
                        <h5>{user_email}</h5>
                        <DataTable
                           columns={payment_history_columns}
                           data={items}
                           // data={filteredData}
                           pagination={50}
                           // searchable
                           // progressPending={pending}
                           progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                           paginationPerPage={50}
                           paginationRowsPerPageOptions={[50, 100, 200, 500]}
                           highlightOnHover={true}
                           // onChangePage={handleOnPageChange}
                           // onRowClicked={handleRowClick}
                           // expandableRows={true} expandableRowsComponent={expandableRows}
                           // expandableRowDisabled={row => row.custom_items_data && JSON.parse(row.custom_items_data).length > 0?false:true}
                           className={"order-table"}
                           theme={localStorage.getItem("theme")=="dark"?"solarized":null} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}
                        />
                     {/* </DataTableExtensions> */}
                     </div>
                  )})) : (<DataTable
                  columns={filterType == 'paymentHistory' ? payment_history_columns :  referral_data_columns}
                  data={ filterType == 'paymentHistory' ? paymentData : dataToDisplay}
                  // data={filteredData}
                  pagination={50}
                  // searchable
                  // progressPending={pending}
                  progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                  paginationPerPage={50}
                  paginationRowsPerPageOptions={[50, 100, 200, 500]}
                  highlightOnHover={true}
                  // onChangePage={handleOnPageChange}
                  // onRowClicked={handleRowClick}
                  // expandableRows={true} expandableRowsComponent={expandableRows}
                  // expandableRowDisabled={row => row.custom_items_data && JSON.parse(row.custom_items_data).length > 0?false:true}
                  className={"order-table"}
                  theme={localStorage.getItem("theme")=="dark"?"solarized":null} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}
               />)}
            </CardBody>
         </Card>
      </div>
   </div>

  )
}

export default ReferralAdmin