// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions"
import { toast } from "react-hot-toast"
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from "../../../assets/templete/Order bulk import template.xlsx"
import axios from "axios"
import ups_logo from "../../../assets/images/ups-logo.png"
import JSZip from "jszip"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import { saveAs } from "file-saver"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  CardHeader,
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal"
import config from "../../../config"
import fedex from "../../../assets/images/fedex.png"
import ups from "../../../assets/images/ups-logo.png"
import usps from "../../../assets/images/usps-logo.png"
import dhl from "../../../assets/images/dhl-logo.png"
import logo from "../../../assets/images/nullship-logo.png"
import { amountFormat } from "../../../assets/js/numberFormatter"
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Line,
  Image,
  StyleSheet,
} from "@react-pdf/renderer"
import customStyles from "../../../assets/css/customTableStyle"
let selectedOrders = []
function OrdersTrackingAdminView(props) {
  const fileInput = useRef(null)
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }

  const dispatch = useDispatch()

  const [data, setData] = useState([])
  const [tableData, setTableData] = useState([])
  const [pending, setPending] = useState(true)

  const [viewModal, setViewModal] = useState(false)

  const [rowId, setRowId] = useState()

  const [filterType, setFilterType] = useState(
    localStorage.getItem("order-filter")
      ? localStorage.getItem("order-filter")
      : "FedEx"
  )

  const [invoiceData, setInvoiceData] = useState(null)
  const [invoiceModal, setInvoiceModal] = useState(false)

  const columns = [
    // {
    //   name: (
    //     <input
    //       type={"checkbox"}
    //       title={"Select All"}
    //       className={"select-all-checkbox"}
    //       onChange={checkAll}
    //     />
    //   ),
    //   selector: "pdf",
    //   // sortable: true,
    //   width: "60px",
    //   cell: row => (
    //     <input
    //       type={"checkbox"}
    //       className={"download-checkbox"}
    //       value={row.pdf}
    //       name="order_id"
    //       onChange={handleOrderCheck}
    //     />
    //   ),
    // },
    // {
    //   name: 'Shipment ID',
    //   selector: 'shipping_details',
    //   sortable: true,
    //   width: "330px",
    //   cell: row => row.shipping_details !== null ? JSON.parse(row.shipping_details).shipment_id : "-"
    // },
    {
      name:"Email",
      selector: "email",
    //  sortable: true,
      width: "200px",
      cell:row => <span>{row.email}</span>
    },
    {
      name: "Service Name",
      // selector: "service_name",
      selector: row=> row.service_name,
    //  sortable: true,
      // width: "130px",
    },
    // {
    //   name: 'From Name',
    //   selector: 'from_name',
    //   sortable: true,
    //   minWidth: "150px",
    //   cell: row => row.from_name ? row.from_name : "-"
    // },
    {
      name: "To Name",
      selector: "to_name",
    //  sortable: true,
      // width: "150px",
      cell: row => (row.to_name ? row.to_name : "-"),
    },
    {
      name: "Updated At",
      selector: "update_at",
     sortable: true,
      // width: "150px",
      cell: row => moment(row.update_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A"),
    },
    {
      name: "Tracking Code",
      // selector: 'after_ship_id',
      selector: ["tracking_code"],
      sortable: false,
      width: "200px",
      cell: row =>
        row.tracking_code ? (
          // <a
          //   target={"_blank"}
          //   rel={"noreferrer"}
          //   href={`/track/${row.tracking_code}`}
          // >
          //   {
              row.tracking_code
          // }
          // </a>
        ) : (
          "-"
        ),
    },
    {
      name: "Tracking Status",
      selector: ["tracking_status"],
      sortable: false,
      width: "130px",
      cell: row =>
        row.tracking_status ? (
          <span className={"badge badge-success"}>{row.tracking_status}</span>
        ) : (
          "-"
        ),
    },
    {
      name: "Actions",
      selector: ["id", "order_id", "tracking_status", "carrier_name", "aftership_tracking_id"],
    //  sortable: true,
      width: "150px",
      // cell: row => row.shipping_details!==null?<><button className={"btn btn-success btn-sm"} onClick={() => {handleView(row.order_id) }}>View Order</button><button className={"btn btn-warning btn-sm"} onClick={() => window.open(JSON.parse(row.shipping_details).public_url, '_blank')} style={{marginLeft:"10px"}}>Track Order</button></>:<button className={"btn btn-success btn-sm"} onClick={() => {handleView(row.order_id) }}>View Order</button>
      cell: row => (
        <>
          {" "}
          <button
            className={"btn btn-success btn-sm"}
            onClick={() => {
              setRowId(row.order_id)
              setViewModal(true)
            }}
          >
            View Order
          </button>
          {/*<button*/}
          {/*  className={"btn btn-warning btn-sm"}*/}
          {/*  onClick={() => handleInvoice(row.id)}*/}
          {/*  style={{ marginLeft: "10px" }}*/}
          {/*>*/}
          {/*  Invoice*/}
          {/*</button>*/}
          {row.aftership_tracking_id?"":<button
            className={"btn btn-warning btn-sm"}
            onClick={() => createAftershipOrder(row.order_id, row.tracking_code, row.carrier_name)}
            style={{ marginLeft: "10px" }}
          >
            Enable Tracking
          </button>}

        </>
      ),
    },
  ];

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "white",
    },
    section: {
      margin: 20,
      padding: 20,
      flexGrow: 1,
    },
  })
  const styless = StyleSheet.create({
    page: {
      padding: 20,
    },
    table: {
      display: "table",
      width: "auto",
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    table2: {
      display: "table",
      width: "auto",
      marginTop: "15px",
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      margin: "5px",
      flexDirection: "row",
    },
    tableRow3: {
      margin: "5px",
      width: "100%",
      flexDirection: "row",
    },

    tableColHeader: {
      width: "40%",
      borderStyle: "solid",
      fontSize: "14px",
      fontWeight: "800",
    },
    tableRowHeader1: {
      width: "65%",
      borderStyle: "solid",
      backgroundColor: "#2a3042",
      height: "50px",
      fontSize: "18px",
      fontWeight: "800",
    },
    tableData1: {
      width: "65%",
      borderStyle: "solid",
      fontSize: "13px",
      fontWeight: "600",
    },
    tableData2: {
      width: "35%",
      borderStyle: "solid",
      fontSize: "13px",
      fontWeight: "600",
    },
    tableRowHeader2: {
      width: "35%",
      height: "50px",
      backgroundColor: "#2a3042",
      borderStyle: "solid",
      fontSize: "18px",
      fontWeight: "800",
    },
    tableCol: {
      width: "60%",
      fontSize: "14px",
      fontWeight: "600",
      borderStyle: "solid",
    },
    tableCol3: {
      width: "65%",
      fontSize: "12px",
      fontWeight: "600",
      borderStyle: "solid",
      marginRight: "40px",
    },
    tableColHeader3: {
      width: "65%",
      borderStyle: "solid",
      fontSize: "12px",
      fontWeight: "600",
    },
    header: {
      fontSize: 20,
      marginBottom: 10,
      fontWeight: "bold",
    },
    tableCol2: {
      width: "50%",
      borderRightWidth: 1,
      borderRightColor: "#000",
      paddingLeft: 5,
      paddingRight: 5,
    },
    tableCell2: {
      fontSize: 10,
    },
  })

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image
            src={logo}
            style={{
              width: "200px",
              position: "absolute",
              right: "10px",
              backgroundColor: "lightgray",
              padding: "10px",
              borderRadius: "5px",
            }}
          />
          <Text style={{ fontSize: "22px", fontWeight: "bold" }}>
            ShipCheap LLC
          </Text>
          <Text
            style={{ fontSize: "14px", marginTop: "13px", fontWeight: "light" }}
          >
            2093 PHILADELPHIA PIKE, Suite 3944
          </Text>
          <Text style={{ fontSize: "14px" }}>CLAYMONT, DE 19703</Text>
          <Text style={{ fontSize: "14px" }}>UNITED STATES</Text>
          <Text
            style={{ fontSize: "14px", marginTop: "14px", fontWeight: "light" }}
          >
            support@shipcheap.io
          </Text>
          <Line
            style={{
              marginTop: 20,
              marginBottom: 10,
              borderBottomWidth: 1,
              borderBottomColor: "gray",
            }}
          ></Line>
          <Text
            style={{
              fontSize: "26px",
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            RECEIPT
          </Text>
          <View style={styless.table}>
            <View style={styless.tableRow}>
              <View style={styless.tableColHeader}>
                <Text>Account:</Text>
              </View>
              <View style={styless.tableCol}>
                <Text>{invoiceData ? invoiceData.email : "email"}</Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableColHeader}>
                <Text>Name:</Text>
              </View>
              <View style={styless.tableCol}>
                <Text>
                  {invoiceData ? invoiceData.first_name : "-"}{" "}
                  {invoiceData ? invoiceData.last_name : "-"}
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableColHeader}>
                <Text>Date:</Text>
              </View>
              <View style={styless.tableCol}>
                <Text>
                  {moment(invoiceData ? invoiceData.date : new Date()).format(
                    "MMM DD, YYYY"
                  )}
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableColHeader}>
                <Text>Service Fee:</Text>
              </View>
              <View style={styless.tableCol}>
                <Text>
                  $
                  {amountFormat(
                    parseFloat(invoiceData ? invoiceData.price : 0).toFixed(2)
                  )}{" "}
                  USD
                </Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableColHeader}>
                <Text>Order ID:</Text>
              </View>
              <View style={styless.tableCol}>
                <Text>{invoiceData ? invoiceData.order_unique_id : "-"}</Text>
              </View>
            </View>
          </View>
          <View style={styless.table2}>
            <View style={styless.tableRow}>
              <View style={styless.tableRowHeader1}>
                <Text style={{ margin: "15px", color: "white" }}>
                  Description
                </Text>
              </View>
              <View style={styless.tableRowHeader2}>
                <Text style={{ margin: "auto", color: "white" }}>Amount</Text>
              </View>
            </View>
            <View style={styless.tableRow}>
              <View style={styless.tableData1}>
                <Text style={{ margin: "10px" }}>
                  {invoiceData ? invoiceData.carrier_name : "-"} -{" "}
                  {invoiceData ? invoiceData.service_name : "-"}
                </Text>
              </View>
              <View style={styless.tableData2}>
                <Text style={{ margin: "10px 10px 10px 40px",position:'absolute',right:"0px" }}>
                  $
                  {amountFormat(
                    parseFloat(invoiceData ? invoiceData.price : 0)
                  )}{" "}
                  USD
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )

  const handleInvoice = async id => {
    // console.log("id of order complete", id)
    let body = {
      id: id,
    }
    let loading = toast.loading("Exporting invoice...")
    post("/user/get-completed-order-by-id", body, { headers }).then(
      response => {
        // console.log("responseee", response)
        if (response.status === 200) {
          setInvoiceData(response.data[0])
          setTimeout(() => {
            document.getElementsByClassName("pdf-downloader")[0].click()
            // const input = document.getElementById('invoice');
            // html2canvas(input).then((canvas) => {
            //   const imgData = canvas.toDataURL('image/png');
            //   const pdf = new jsPDF();
            //   pdf.addImage(imgData, 'PNG', 0, 0);
            //   pdf.save(`${response.data[0].order_unique_id}.pdf`); // Replace 'myPDF' with the name you want to give your PDF file
            //   setInvoiceData(null);
            toast.remove(loading)
            toast.success("Invoice exported")
            // });
          }, 1000)
        } else {
          toast.error(response.message)
          toast.remove(loading)
        }
      }
    )
  }

  const createAftershipOrder = async (
    order_id,
    tracking_code,
    carrier_name
  ) => {
    const loading = toast.loading("Enabling Tracking...")
    try {
      // let carrier_name = 'fedex'
      // console.log(
      //   "order_id: ",
      //   order_id,
      //   "tracking_code",
      //   tracking_code,
      //   "carrier_name:",
      //   carrier_name.toLowerCase()
      // )

      let body = {
        order_id: order_id,
        tracking_code: tracking_code,
        slug: carrier_name,
      };
      // console.log("body", body)

      post("/user/save-afteship-order", body, { headers })
        .then(response => {
          // console.log(response)
          toast.remove(loading)
          if (response.status == 200) {
            toast.success(response.message)
          } else {
            toast.error(response.message)
          }
          // console.log("localStorage.getItem(order-filter)",localStorage.getItem("order-filter"));
          retrieveOrdersCompleted(localStorage.getItem("order-filter"))
        })

    } catch (e) {
      toast.error(e.response.data.meta.message)
      toast.remove(loading)
      // console.log("createAftershipOrder", e)
    }
  }

  function checkAll(e) {
    const { checked } = e.target
    let checkboxes = document.getElementsByClassName("download-checkbox")
    selectedOrders = []

    for (let i = 0; i < checkboxes.length; i++) {
      if (!checked) {
        checkboxes[i].checked = false
        selectedOrders = []
      } else {
        checkboxes[i].checked = true
        selectedOrders.push(checkboxes[i].value)
      }
    }

    // console.log("selected", selectedOrders)
  }

  function uncheckAll() {
    let checkboxes = document.getElementsByClassName("download-checkbox")
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false
    }
  }

  const handleOrderCheck = e => {
    const { value, checked } = e.target
    if (checked) {
      selectedOrders.push(value)
    } else {
      let index = selectedOrders.findIndex(el => el == value)
      selectedOrders.splice(index, 1)
      // selectedOrders.filter((el) => el !== value);
    }

    // console.log("selectedOrders", selectedOrders)
  }

  const handleDownload = async () => {
    if (selectedOrders.length > 0) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading("Downloading orders...")
      const zip = new JSZip()
      for (let i = 0; i < selectedOrders.length; i++) {
        await get("user/get-pdf/" + selectedOrders[i], { headers }).then(
          async response => {
            await zip.file(`order-${i}.pdf`, response)
          }
        )
      }

      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "orders.zip")
        uncheckAll()
        selectedOrders = []
        document.body.style.pointerEvents = "auto"
        document.getElementsByClassName(
          "select-all-checkbox"
        )[0].checked = false
        toast.remove(loading)
      })
    } else {
      toast.error("Please select at least one order")
      document.body.style.pointerEvents = "auto"
      document.getElementsByClassName("select-all-checkbox")[0].checked = false
    }
  }

  const getTrackingStatus = data => {
    // axios.post("",)
  }

  const retrieveOrdersCompleted = async type => {
    localStorage.setItem("order-filter", type)
    try {
      setPending(true)
      let body = {
        carrier: type,
      }
      post("/admin/get-orders-completed-by-carrier", body, { headers }).then(
        response => {
          // // console.log("response", response);
          setData(response.data)
          // // console.log("shipping_details", JSON.parse(response.data[0].shipping_details).shipment_id);
          let data = response.data
          // console.log("table data", { columns, data })
          setTableData({ columns, data })
          setPending(false)
          getTrackingStatus(data)
        }
      )
    } catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // // console.log("here");
    retrieveOrdersCompleted(filterType)
  }, [])

  const handleFedExOrders = async () => {
    setFilterType("FedEx")
    localStorage.setItem("order-filter", "FedEx")
    await retrieveOrdersCompleted("FedEx")
  }

  const handleUPSOrders = async () => {
    setFilterType("UPS")
    localStorage.setItem("order-filter", "UPS")
    await retrieveOrdersCompleted("UPS")
  }

  const handleUPSv2Orders = async () => {
    setFilterType("UPSv2")
    localStorage.setItem("order-filter", "UPSv2")
    await retrieveOrdersCompleted("UPSv2")
  }

  const handleDHLOrders = async () => {
    setFilterType("DHL")
    localStorage.setItem("order-filter", "DHL")
    await retrieveOrdersCompleted("DHL")
  }

  const handleUSPSOrders = async () => {
    setFilterType("USPS")
    localStorage.setItem("order-filter", "USPS")
    await retrieveOrdersCompleted("USPS")
  }

  //meta title
  document.title = "Orders Tracking | ShipCheap"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" breadcrumbItem="Orders Tracking" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            {/*<button*/}
            {/*  className={"btn btn-sm btn-warning"}*/}
            {/*  style={{ float: "right" }}*/}
            {/*  onClick={handleDownload}*/}
            {/*>*/}
            {/*  Download <i className={"bx bx-download"}></i>*/}
            {/*</button>*/}
            <button
              className={
                "btn btn-sm btn-outline-dark " +
                (filterType === "FedEx" ? "active" : "")
              }
              style={{ marginRight: "5px" }}
              onClick={handleFedExOrders}
            >
              FedEx
            </button>
            <div className={"vr"}></div>
            <button
              className={
                "btn btn-sm btn-outline-dark " +
                (filterType === "UPS" ? "active" : "")
              }
              style={{ marginLeft: "5px", marginRight: "5px" }}
              onClick={handleUPSOrders}
            >
              UPS
            </button>
            <div className={"vr"}></div>
            <button
              className={
                "btn btn-sm btn-outline-dark " +
                (filterType === "UPSv2" ? "active" : "")
              }
              style={{ marginLeft: "5px", marginRight: "5px" }}
              onClick={handleUPSv2Orders}
            >
              UPSv2
            </button>
            <div className={"vr"}></div>
            <button
              className={
                "btn btn-sm btn-outline-dark " +
                (filterType === "DHL" ? "active" : "")
              }
              style={{ marginLeft: "5px", marginRight: "5px" }}
              onClick={handleDHLOrders}
            >
              DHL
            </button>
            <div className={"vr"}></div>
            <button
              className={
                "btn btn-sm btn-outline-dark " +
                (filterType === "USPS" ? "active" : "")
              }
              style={{ marginLeft: "5px", marginRight: "5px" }}
              onClick={handleUSPSOrders}
            >
              USPS
            </button>
            <br />
            <br />
            <h4 style={{ marginBottom: "-40px" }}>{filterType} Orders</h4>
            <PDFDownloadLink
              className={"pdf-downloader"}
              document={<MyDocument />}
              style={{display:"none"}}
              fileName={`${invoiceData?invoiceData.order_unique_id:"order"}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Orders Tracking...`}
            >
              <DataTable
                columns={columns}
                data={data} customStyles={customStyles}
                pagination={50}
                progressPending={pending}
                progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                paginationPerPage={50}
                paginationRowsPerPageOptions={[50, 100, 200, 500]}
                highlightOnHover={true}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>

        <OrderViewModal
          orderId={rowId}
          viewModal={viewModal}
          showPrice={false}
          setViewModal={data => {
            setViewModal(data)
          }}
        />

        {/*{invoiceData?*/}
        <div className={"invoice"} id={"invoice"}>
          <div className={"invoice_header"}>
            <div className={"header_info"}>
              <h2>ShipCheap LLC</h2>
              <h5>
                2093 PHILADELPHIA PIKE, Suite 3944
                <br />
                CLAYMONT, DE 19703
                <br />
                UNITED STATES
              </h5>
              <h5>support@shipcheap.io</h5>
            </div>
            <div className={"header_image"}>
              <img src={logo} />
            </div>
          </div>
          <hr />
          <h1 className={"text-center"}>RECEIPT</h1>
          <br />
          <table className={"info_table"}>
            <tr>
              <th>
                <h5>Account:</h5>
              </th>
              <td>
                <h5>{invoiceData ? invoiceData.email : "email"}</h5>
              </td>
            </tr>
            <tr>
              <th>
                <h5>Name:</h5>
              </th>
              <td>
                <h5>
                  {invoiceData ? invoiceData.first_name : "-"}{" "}
                  {invoiceData ? invoiceData.last_name : "-"}
                </h5>
              </td>
            </tr>
            <tr>
              <th>
                <h5>Date:</h5>
              </th>
              <td>
                <h5>
                  {moment(invoiceData ? invoiceData.date : new Date()).format(
                    "MMM DD, YYYY"
                  )}
                </h5>
              </td>
            </tr>
            <tr>
              <th>
                <h5>Service Fee:</h5>
              </th>
              <td>
                <h5>
                  $
                  {amountFormat(
                    parseFloat(invoiceData ? invoiceData.price : 0).toFixed(2)
                  )}{" "}
                  USD
                </h5>
              </td>
            </tr>
            <tr>
              <th>
                <h5>Order ID:</h5>
              </th>
              <td>
                <h5>{invoiceData ? invoiceData.order_unique_id : "-"}</h5>
              </td>
            </tr>
          </table>
          <br />
          <table
            className={
              "info_table table_description table table-bordered color-dark"
            }
          >
            <thead>
            <tr className={"table-dark"}>
              <th>
                <h5>Description</h5>
              </th>
              <th>
                <h5>Amount</h5>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <h6>
                  {invoiceData ? invoiceData.carrier_name : "-"} -{" "}
                  {invoiceData ? invoiceData.service_name : "-"}
                </h6>
              </td>
              <td>
                <h6>
                  $
                  {amountFormat(
                    parseFloat(invoiceData ? invoiceData.price : 0).toFixed(2)
                  )}{" "}
                  USD
                </h6>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        {/*:""}*/}
      </div>
    </div>
  )
}
OrdersTrackingAdminView.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default OrdersTrackingAdminView;
