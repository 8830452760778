import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react"
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";
// import namelogo from "assets/images/nullship-logo.png";
import namelogo from "assets/images/nullship_christmas_logo_uncropped.gif";
import img11 from "assets/images/img11.png";
import img12 from "assets/images/img12.png";
import anim_logo from '../../assets/images/ainimationlogo.gif'
// import header background img
import bgimg from "assets/images/bgimg.png";
import LoginFooter from "components/LoginFooter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/nullship-icon.png";
import { post } from "../../helpers/api_helper"
import { Toaster } from "react-hot-toast"
import {toast} from "react-hot-toast";
import config from "../../config"

// Images
import Logo from '../../assets/images/shipcheap.svg'
import LiningBg from '../../assets/images/lining-bg.svg'
import USPS from '../../assets/images/usps-service.svg'
import DHL from '../../assets/images/dhl-service.svg'
import UPS from '../../assets/images/ups-service.svg'

const Otp = props => {
  const inputRef = useRef(null);
  //meta title
  document.title="OTP | ShipCheap";
  const dispatch = useDispatch();
  const {
    match: { params },
  } = props

  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [token, setToken] = useState(params.token);
  const [code, setCode] = useState("");
  const [buttonDisable, setButtonDisable] = useState(true);
  const [resendEnable, setResendEnable] = useState(false);
  const [seconds, setSeconds] = useState(60);

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${params.token}`,
  };

  useEffect(() => {

      let body = {
        token: params.token
      }
     post('/user/verify-otp-expiry', body, {headers}).then((response) => {
       if(response.status === 500) {
         toast.error("Otp expired");
          props.history.push("/");
       }
       else {
         inputRef.current.focus()
       }
     })


  },[]);

  useEffect(() => {
    const interval = setInterval(() => {
      if(seconds == 0) {
        setResendEnable(true);
      }

      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);



  const handleOtp = async () => {
    try {
      if(code.length === 6) {
        const body = {
          code: code,
          token: token
        }
        await post('/user/verify-otp', body, {headers}).then((response) => {
          // console.log("response", response.message);
          if(response.status === 200) {
            localStorage.removeItem("authUser");
            localStorage.removeItem("token");
            localStorage.setItem("authUser", JSON.stringify(response.user));
            localStorage.setItem("token", response.token);
            localStorage.setItem("skt", "false");
            localStorage.setItem("store", 0);
            if(response.user.role_id === 2) {
              localStorage.setItem("balance", response.user.balance);
            }
            props.history.push('/dashboard');
          }
          else {
              toast.error(response.message);
          }
        })
      }
      else {
        toast.error("Otp code must contain 6 numbers");
      }
    }
    catch (e) {
      // console.log(e);
    }
  }

  const handleCode = (e) => {
    setCode(e.target.value);
    if(e.target.value.length === 6) {
      setButtonDisable(false);
    }
    else {
      setButtonDisable(true);
    }
  }

  const handleResend = () => {
    if(resendEnable) {
      let body = {
        token: params.token
      }
      let loading = toast.loading("Resending OTP...");
      post('/user/resend-otp', body, {headers}).then((response) => {
        toast.remove(loading);
        if(response.status === 200) {
          toast.success(response.message);
          //  props.history.push("/");
        }
        else {
          toast.error(response.message);
        }
      })
    }
  }

  return (
    <React.Fragment>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      {/* <div className=" home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div> */}
      {/* <div style={{ backgroundImage: `url(${bgimg})`, width: "100%", marginBottom: "20px", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "95px", position: "fixed", top: "0", left: "0", zIndex: "999" }} className="navbar-header">
        <a href={`${config.main_web.URL}`}><img className="mx-5" height={"120px"}
          // width="230px"
                                                src={namelogo} alt="" /></a>
      </div> */}
      <div className={"col-md-12"}>
        <div className="col-xl-12 mx-auto">
          {/* <Card>
            <CardBody> */}
              <Row >
              <Col xl={6} lg={6} className="d-none d-sm-none d-md-none d-lg-block">
                  <div className="relative overflow-hidden h-[100vh] md:flex flex-col bg-gradient-to-tr from-[#070A1F] to-[#070A1F] i justify-between">
                    <div className="absolute -top-36 -left-36 w-4/5 h-4/5 bg-[#2F3885] rounded-full blur-[250px] z-0"></div>
                    <div className="px-20 mt-10 w-11/12 z-[1]">
                      <img src={Logo} alt="Null Ship Logo" className="mt-20 mb-6" width={300} />
                      <h1 className="text-white font-bold text-4xl">Welcome to Simpler Shipping</h1>
                      <p className="text-white text-1xl mt-3">Where your packages are our top priority. We pride ourselves on delivering reliability, speed, and satisfaction. Our commitment extends beyond borders, ensuring your shipments reach their destination with precision and care. Trust us to connect you to a world of possibilities.</p>
                    </div>
                    <div className="absolute top-0 bottom-0 right-0">
                      <img src={LiningBg} alt="Lining bg animation" />
                    </div>
                    <div className="shipping-group relative self-end mr-24 mb-20 w-[570px] h-[375px] ">
                      <div className="absolute bottom-[125px] right-[370px] z-[3]">
                        <img src={USPS} alt="service bg animation" />
                      </div>
                      <div className="absolute bottom-0 right-[180px] z-[2]">
                        <img src={DHL} alt="service bg animation" />
                      </div>
                      <div className="absolute top-0 right-[0px] z-[1]">
                        <img src={UPS} alt="service bg animation" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} className="flex items-center form_div">
                  <div className="w-[320px] my-6 m-auto">
                    <CardBody className="pt-0">
                      <div className="text-center">
                        <h2 className="mb-3 text-4xl font-extrabold text-dark-gray-900 text-[#000]">OTP Verification</h2>
                        <p className="mb-2 text-gray-700">OTP code sent to your email address</p>
                      </div>
                      <div className="p-2">
                      <Form
                        className="form-horizontal"
                      >
                        <div className="mb-3">
                          <Label className="form-label text-dark">Otp Code</Label>
                          <Input
                            name="number"
                            className="custom_input text-center"
                            style={{fontSize:"20px",letterSpacing:"20px"}}
                            placeholder="------"
                            type="code"
                            minLength={6}
                            maxLength={6}
                            ref={inputRef}
                            autoFocus
                            onChange={handleCode}
                          />
                        </div>
                        <div className="m-auto text-center mt-3">
                          {/* <Col className="text-center mt-3"> */}
                            <button
                              className="btn custom_btn btn-dark w-full m-auto btn-block ntb"
                              type="submit"
                              disabled={buttonDisable}
                              onClick={handleOtp}
                            >
                              Verify
                            </button>
                          {/* </Col> */}
                        </div>
                      </Form>
                    </div>
                    <div className="mt-2 text-center">
                      <a className={resendEnable?"text-danger":"text-secondary"} onClick={handleResend}> Resend Otp {!resendEnable?" (" + seconds + (parseInt(seconds)<10?" second)" : " seconds)"):"" }</a>
                    </div>
                    </CardBody>
                  </div>
                </Col>
              </Row>
            {/* </CardBody>
          </Card> */}
        </div>
      </div>
      {/* <LoginFooter /> */}
    </React.Fragment>
  );
};

Otp.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Otp);
