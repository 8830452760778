// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import customStyles from "../../../assets/css/customTableStyle"
import config from "../../../config"

export default function PaymentMethods() {
  //meta title
  document.title = "Payment Methods | ShipCheap";

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }

  const [filteredData, setFilteredData] = useState([])
  const [tableData, setTableData] = useState(null)

  const columns = [
    {
      name: "",
      selector: "image",
      //  sortable: true,
      width: "70px",
      cell: row => <img alt={"img"} src={config.site.BASE_URL_FOR_IMAGES + "payment-option/" + row.image_url} height={"20px"} />
    },
    {
      name: 'Name',
      // selector: 'name',
      selector: row => row.name,

      //  sortable: true,
    },
    {
      name: 'Account Details',
      // selector: 'account_details',
      selector: row => row.account_details,

      //  sortable: true,
      // cell: row => row.account_details,
    },
    {
      name: "Status",
      // selector: 'status',
      sortable: true,
      width: "120px",
      cell: row => row.status === 1 ? <span className={"badge badge-success"}>Active</span> : <span className={"badge badge-danger"}>Inactive</span>
    },
    {
      name: "Created At",
      // selector: 'created_at',
      sortable: true,
      cell: row =>
        moment(row.created_at).tz(moment.tz.guess()).format("MMM DD, YYYY"),
    },
    {
      name: "Actions",
      // selector: 'status',
      width: "160px",
      cell: row => (
        <>
          <select onChange={handleUpdate} style={{ width: "120px" }} className={"form-select"}><option selected={row.status === 1 ? true : false} value={1 + "," + row.id}>Activate</option><option selected={row.status === 0 ? true : false} value={0 + "," + row.id}>Deactivate</option></select>
          {/*{row.status == 1?*/}
          {/*  <button className={"btn btn-danger btn-sm waves-effect waves-light"} onClick={() => handleUpdate(row.id, 0)}>Deactivate</button>:*/}
          {/*  <button className={"btn btn-success btn-sm waves-effect waves-light"} onClick={() => handleUpdate(row.id, 1)}>Activate</button>*/}
          {/*}*/}
        </>
      ),
    },
  ];

  const handleUpdate = async (e) => {
    const { value } = e.target;
    console.log("handleUpdate clicked", value)

    if (confirm("Are you sure! you want to update this payment option")) {
      try {
        const { value } = e.target
        const id = value.split(",")[1]
        const status = value.split(",")[0]
        let body = {
          id: id,
          status: status,
        }
        console.log("Value", value, "Body", body)
        post('/admin/update-payment-option', body, { headers }).then(async (response) => {
          if (response.status === 200) {
            await retrievePaymentOptions();
          }
        })

      } catch (error) {

      }
    }
  }

  const retrievePaymentOptions = async () => {
    try {
      get('/admin/get-payment-options', { headers }).then((response) => {
        if (response.status === 200) {
          let data = response.data
          setTableData({ columns, data })
        }
      })
    } catch (error) {

    }
  }

  useEffect(() => {
    retrievePaymentOptions()
  }, [])

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" breadcrumbItem="Payment Methods" />
        {          console.log("TableData", tableData)
}
        <Card>
          <CardBody>
            <Link to={"/add-payment-method"}>
              <button
                className={"btn btn-primary btn-sm waves-effect waves-light"}
              >
                Add New Payment Method
              </button>
            </Link>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Payment Method...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles} pagination={30} paginationPerPage={30}
                highlightOnHover={true} />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

PaymentMethods.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};
