// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import customStyles from "../../../assets/css/customTableStyle"


function TablesInfo(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const fileInput = useRef(null);
  const dispatch = useDispatch();

  const [mainData, setMainData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [pending, setPending] = useState(true);

  const [tables, setTables] = useState([]);



  const columns = [
    {
      name: 'Table Name',
      // selector: 'table_name',
      selector: row=>row.table_name,

      //  sortable: true,
    },
    {
      name: 'Column Name',
      // selector: 'column_data',
      selector: row=>row.column_data,

      //  sortable: true,
    },
    {
      name: 'Value',
      // selector: 'values_data',
      selector: row=>row.values_data,

      //  sortable: true,
    },
    {
      name: 'Description',
      // selector: 'description',
      selector: row=>row.description,

      width: "600px"
      //  sortable: true,
    },
    {
      name: 'Created At',
      // selector: 'created_at',
      selector: row=>row.created_at,

      sortable: true,
      width: "160px",
      cell: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
  ];

  // const tableData = ;
  const getVariableColumnValues = async () => {
    try {
      get('/admin/get-variable-column-values', {headers}).then((response) => {
        // // console.log("response", response);
        setMainData(response.data);
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);

        if(response.data.length > 0) {
          let tb = [];
          for (let i=0;i<response.data.length;i++) {
            if(!tb.includes(response.data[i].table_name)) {
              tb.push(response.data[i].table_name);
            }
          }

          setTables(tb);
        }
      })
    }
    catch (e) {
      // console.log(e)
    }
  }


  useEffect(() => {
    // // console.log("here");
    getVariableColumnValues();
  }, []);

  //meta title
  document.title = "Table's Information | ShipCheap";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" breadcrumbItem="Table's Information" />
        {/*<a href={"/table-information/create"}>Create</a>*/}
        <Card>
          <CardBody>
            <div className={"col-md-4"}>
              <label>Tables</label>
              <select className={"form-select"} onChange={(e) => {
                if(e.target.value == "all") {
                  setFilteredData(mainData)
                  let data = mainData;
                  setTableData({ columns, data });
                }
                else {
                  // console.log("e.target.value",e.target.value);
                  // console.log("data",mainData);
                  let arr = mainData.filter(el => el.table_name == e.target.value);
                  // console.log("arr",arr);
                  setFilteredData(arr)
                  let data = arr;
                  setTableData({ columns, data });
                }
              }}>
                <option selected={true} value={"all"}>All</option>
                {tables.length>0?
                  tables.map((el, id) => (
                    <option key={id} value={el}>{el}</option>
                  ))
                  :""}
              </select>
            </div>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Table's Information...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
TablesInfo.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default TablesInfo;