// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import customStyles from "../../../assets/css/customTableStyle"


function Blogs(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const fileInput = useRef(null);
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [modal, setModal] = useState(false);

  const [pending, setPending] = useState(true);

  const [id, setId] = useState(null);
  const [title, setTitle] = useState(null);
  const [excerpt, setExcerpt] = useState(null);
  const [description, setDescription] = useState(null);
  const [category_id, setCategoryID] = useState(null);
  const [blogImage, setblogImage] = useState("");

  const handleImage = (e) => {
    const file = e.target.files[0];
    // console.log("file",);
    const reader = new FileReader();
    reader.onloadend = () => {
      // Use a regex to remove data url part
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');

      // console.log("base64String",base64String);
      setServiceImage(base64String);
      // Logs wL2dvYWwgbW9yZ...
    };
    reader.readAsDataURL(file);
  }

  const columns = [
    {
      name: '',
      selector: 'image',
    //  sortable: true,
      width: "70px",
      cell: row => row.image?<img src={`data:image/jpeg;base64,${row.image}`} height={"20px"} />:"-"
    },
    {
      name: 'Title',
      // selector: 'title'/
      selector: row=>row.title,
    //  sortable: true,
    },
    {
      name: 'Slug',
      // selector: 'slug',
      selector: row=>row.slug,
    //  sortable: true,
    },
    {
      name: 'Category',
      // selector: 'blog_category_title',
      selector: row=>row.blog_category_title

    //  sortable: true,
    },
    {
      name: 'Excerpt',
      // selector: 'excerpt',
      selector: row=>row.excerpt

    //  sortable: true,
    },
    // {
    //   name: 'Description',
    //   selector: 'description',
    //   sortable: true,
    // },
    {
      name: 'Status',
      selector: 'status',
     sortable: true,
      width: "120px",
      cell: row => row.status === 1 ? <span className={"badge badge-success"}>Active</span>:<span className={"badge badge-danger"}>Inactive</span>
    },
    {
      name: 'Created At',
      selector: 'created_at',
     sortable: true,
      cell: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Actions',
      selector: 'id',
      width: "160px",
      cell: (row) =>
        <>
          <button className={"btn btn-warning btn-sm waves-effect waves-light"} onClick={() => handleEdit(row.id)}>Edit</button>
          <button className={"btn btn-danger btn-sm waves-effect waves-light"} onClick={() => handleDelete(row.id)} style={{marginLeft:"10px"}}>Delete</button>
        </>
    },
  ];

  const handleEdit = async (id) => {
    props.history.push(`/blog/edit/${id}`);
    // const body = {
    //   id: id
    // };
    // post('/admin/get-blog-category-by-id', body, {headers}).then((response) => {
    //   if(response.status === 200) {
    //     const data = response.data;
    //     setId(data.id);
    //     setTitle(data.title);
    //     setDescription(data.description);
    //     setModal(!modal);
    //   }
    //   else {
    //     toast.success(response.message);
    //   }
    // })
  }

  const handleDelete = async (id) => {
    let confrm = confirm("Are you sure you want to delete this blog?");
    if(confrm) {
      const body = {
        id: id
      };
      post('/admin/delete-blog', body, { headers }).then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          retrieveBlogs();
        } else {
          toast.success(response.message);
        }
      })
    }
  }

  // const tableData = ;
  const retrieveBlogs = async () => {
    try {
      get('/admin/get-blogs', {headers}).then((response) => {
        // // console.log("response", response);
        setFilteredData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: title || '',
      description: description || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      description: Yup.string().required("Please Enter Description"),
    }),
    onSubmit: (values) => {
      handleEditService(values)
    }
  });


  const handleEditService = async (values) => {
    try {
      const {title, description} = values;

      const body = {
        id: id,
        title: title,
        description: description,
        image: serviceImage
      };

      // console.log("body",body);
      post('/admin/update-blog-category', body, {headers}).then((response) => {
        // // console.log("resss",response);
        if(response.status === 200) {
          toast.success(response.message);
          retrieveBlogCategories();
          setModal(!modal);
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // // console.log("here");
    retrieveBlogs();
  }, []);

  //meta title
  document.title = "Blogs | ShipCheap";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" breadcrumbItem="Blogs" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <Link to={"/blog/add"}><button className={"btn btn-primary btn-sm waves-effect waves-light"}>Add New Blogs</button></Link>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Blogs...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>

        {/*Modal*/}
        <Modal
          isOpen={modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setModal(!modal);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModal(!modal);
              }}
            >
              Edit Blog
            </ModalHeader>
            <ModalBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <label>Category Image</label>
                  <input type={"file"} multiple={false} ref={fileInput} onChange={handleImage} className={"form-control"} accept={".png"}/>
                </div>
                <div className="mb-3">
                  <Label className="form-label">Service Name</Label>
                  <Input
                    name="title"
                    className="form-control"
                    placeholder="Enter Service Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.title || ""}
                    invalid={
                      validation.touched.title && validation.errors.title ? true : false
                    }
                  />
                  {validation.touched.title && validation.errors.title ? (
                    <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Description</Label>
                  <Input
                    name="description"
                    className="form-control non-arrow-number"
                    placeholder="Enter Description"
                    type="textarea"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.description || ""}
                    invalid={
                      validation.touched.description && validation.errors.description ? true : false
                    }
                  />
                  {validation.touched.description && validation.errors.description ? (
                    <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                  ) : null}
                </div>
                <Row className="mb-3">
                  <Col className="text-end">
                    <button
                      className="btn btn-primary w-md "
                      type="submit"
                    >
                      Save
                    </button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}
      </div>
    </div>
  );
}
Blogs.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Blogs;