import React, { useState, useEffect } from "react";
import Breadcrumbs from "components/Common/Breadcrumb";
import PropTypes from 'prop-types';
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable, { createTheme } from "react-data-table-component"
import { post, get } from "../../../helpers/api_helper"
import customStyles from "../../../assets/css/customTableStyle"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"
import Select from 'react-select';
import {
   Container,
   Row,
   Col,
   Card,
   Alert,
   CardBody,
   Button,
   Label,
   Input,
   FormFeedback,
   Form,
   Modal,
   ModalHeader,
   ModalBody,
   CardHeader, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
 } from "reactstrap"
 import moment from "moment"

function ReferralUser() {

   // Page Title
   document.title = "Referral || ShipCheap"

   const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("token")}`,
   };

   // Data filter type
   const [filterType, setFilterType] = useState(localStorage.getItem('referral-filter') ? localStorage.getItem('referral-filter') : 'referralData');
   const [pending, setPending] = useState(true)
   const [referrerData, setReferrerData] = useState([])
   const [referrerPaymentData, setReferrerPaymentData] = useState([])
   const [filterData, setFilterData] = useState([])
   const [searchTerm, setSearchTerm] = useState("")
   const [from, setFrom] = useState("")
   const [to, setTo] = useState("")

   // Tabs Buttons
   const buttonTypes = [
      {value: "Referral Data", key: "referralData"},
      {value: "Payment History", key: "paymentHistory"}
   ]

   // Resuable handle for filter state update
   const handleToggleData = (type) => {
      console.log("clicked", type)
      setFilterType(type)
      localStorage.setItem('referral-filter', type)
   }

   // Get Referral Data from API
   const retrieveReferrerData = () => {
      try {
         const body = {
            user_id: JSON.parse(localStorage.getItem("authUser")).id
         }
         post('/user/get-user-referrer', body, {headers}).then((response) => {
            // console.log("Referrer Data", response)
            setReferrerData(response)
            console.log("Referrer Data", referrerData)
         })
      } 
      catch(error) {
         console.log()
      }
   }

   // Get Referrer Payment Data from API
   const retrieveReferrerPaymentData = () => {
      try {
         const body = {
            user_id: JSON.parse(localStorage.getItem("authUser")).id
         }
         post('/user/get-user-payment-history', body, {headers}).then((response) => {
            // console.log("retrieveReferrerPaymentData", response)
            setReferrerPaymentData(response.data)
            console.log("Reffered Payment Data", referrerPaymentData)
         })
      } catch (error) {

      }
   }

   // Get Filter Data From API
   const retrieveFilteredData = (search, from, to) => {
      try {
         const body = {
            search: search,
            from: from,
            to: to,
            user_id: JSON.parse(localStorage.getItem("authUser")).id
         }
         post('/user/get-filtered-user-referrers', body, {headers}).then((response) => {
            console.log("Filtered data", response)
            if(response.status === 200) {
               setFilterData(response.data)
            }
         })
      } catch (error) {

      }
      // const body = {
      //    search: search,
      //    from: from,
      //    to: to,
      //    user_id: JSON.parse(localStorage.getItem("authUser")).id
      // }
      // post('/user/get-filtered-user-referrers', body, {headers}).then((response) => {
      //    console.log("Filtered data", response)
      //    if(response.status === 200) {
      //       setFilterData(response.data)
      //    }
      // })
   }

   const dataToDisplay = filterData ? filterData : referrerData;

   // console.log(filterData)
   
   useEffect(() => { 
      // console.log("Filter Type", filterType)
      retrieveReferrerData()
      retrieveReferrerPaymentData()
      retrieveFilteredData()
      setPending(false)
   },[filterType])


   // Referral Data Columns
   const referrer_data_columns = [
      {
         name: "User Id",
         // selector: "refer_user_id",
         selector: row=>row.refer_user_id,
         width: "250px", 
      },
      {
         name: "Referred To",
         // selector: "refer_to_email",
         selector: row=>row.refer_to_email,
      },
      {
         name: "Total Labels",
         // selector: "completed_order_count",
         selector: row=>row.completed_order_count,
           width: "250px", 
      },
      {
         name: "Labels Refunded",
         // selector: "refunded_order_count",
         selector: row=>row.refunded_order_count,
         width: "250px",
      },
      {
         name: "Created At",
         selector: row=>row.created_at,
         width: "250px",
         format: (row) => {
         const formattedDate = moment(row.created_at).utc(false).utcOffset('-07:00').format("MMM DD, YYYY hh:mm A")
         return formattedDate;
         },
      }
   ]

   // Payment History Columns
   const payment_history_columns = [
      {
         name: "Payment Mode",
         selector: row => row.payment_mode,
      },
      {
         name: "Amount (USD)",
         selector: row => row.amount,
         width: "250px",
      },
      {
         name: "Total Labels",
         selector: row => row.order_count,
         width: "250px",
      },
      {
         name: "Payment Date Time",
         selector: row => row.created_at,
         width: "300px", 
         format: (row) => {
            const formattedDate = moment(row.created_at).utc(false).utcOffset('-07:00').format("MMM DD, YYYY hh:mm A")
            return formattedDate;
         },
      }
   ]

   createTheme('solarized', {
      text: {
        primary: '#fffff',
        secondary: '#fffff',
      },
      background: {
        default: '#2a3042',
      },
      context: {
        background: '#cb4b16',
        text: '#2a3042',
      },
      divider: {
        default: '#32394e',
      },
      action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
      },
    }, 'dark');

   return (
      <div className="page-content">
         <div className="container-fluid">
            <Breadcrumbs title="ShipCheap" breadcrumbItem="Referral" />
            {/* Tabs Section */}
            <div
               style={{ width: "fit-content", background: localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8" }}
               className="rounded-top"
            >
               {buttonTypes.map((button, index) => {
                  return (
                     <button 
                        key={index}
                        className={"btn btn-md rounded-top" +
                        (filterType === "Reports" ? " text-dark" : " text-secondary")}
                        style={{ marginLeft: "0px", marginRight: "0px" , borderRadius:"4px 4px 0px 0px",borderColor:"rgba(0,0,0,0)",backgroundColor: filterType == button?.key ? (localStorage.getItem("theme") == "dark"?"#2a3042":"white") : (localStorage.getItem("theme") == "dark"?"#222224":"#E1E5E8") }}
                        onClick={()=> handleToggleData(button?.key)}
                        disabled={pending}
                     >{button.value}</button>
                  )
               })}
            </div>
            {/* Filter and Data Content Section */}
            <Card style={{borderRadius: "0px 3px 3px 3px"}}>
               <CardBody>
                  <Form  className="form-horizontal">
                     <Row>
                        {filterType === 'referralData' && <>
                           <div className={"col-xl-3 col-lg-3 col-md-3 p-1"}>
                              <div className="mb-3">
                                 <Label className="form-label">Filter</Label>
                                 <input
                                 type={"text"}
                                 className={"form-control"}
                                 placeholder={"Filter Data..."}
                                 value={searchTerm}
                                 onChange={async (e) => {
                                    setSearchTerm(e.target.value)
                                    await retrieveFilteredData(e.target.value, from, to);
                                 }}
                                 />
                              </div>
                           </div>
                           <div className={"col-xl-2 col-lg-3 col-md-3 p-1"}>
                              <div className="mb-3">
                                 <Label className="form-label">From</Label>
                                 <Input
                                 name="from"
                                 className="form-control"
                                 type="date"
                                 max={to}
                                 value={from}
                                 onChange={async (e) => {
                                    setFrom(e.target.value)
                                    await retrieveFilteredData(searchTerm, e.target.value, to);
                                 }}
                                 />
                              </div>
                           </div>
                           <div className={"col-xl-2 col-lg-3 col-md-3 p-1"}>
                              <div className="mb-3">
                                 <Label className="form-label">To</Label>
                                 <Input
                                 name="to"
                                 className="form-control"
                                 type="date"
                                 disabled={from == "" ? true : false}
                                 title={from == "" ? "Please select from date" : ""}
                                 min={from}
                                 value={to}
                                 onChange={async (e) => {
                                    setTo(e.target.value)
                                    await retrieveFilteredData(searchTerm, from, e.target.value);
                                 }}
                                 />
                              </div>
                           </div>
                        </>}
                     </Row>
                  </Form>

                  <DataTable
                     columns={filterType === 'paymentHistory' ? payment_history_columns :  referrer_data_columns}
                     data={ filterType === 'paymentHistory' ? referrerPaymentData : dataToDisplay}
                     pagination={50}
                     // progressPending={pending}
                     progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                     paginationPerPage={50}
                     paginationRowsPerPageOptions={[50, 100, 200, 500]}
                     highlightOnHover={true}
                     // onChangePage={handleOnPageChange}
                     // onRowClicked={handleRowClick}
                     // expandableRows={true} expandableRowsComponent={expandableRows}
                     // expandableRowDisabled={row => row.custom_items_data && JSON.parse(row.custom_items_data).length > 0?false:true}
                     className={"order-table"}
                     theme={localStorage.getItem("theme")=="dark"?"solarized":null} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}
                  />
               </CardBody>
            </Card>
         </div>
      </div>
   )
}

export default ReferralUser