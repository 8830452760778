// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"
import Select from "react-select/base"


function CreateBlogCategory(props) {
  const fileInput = useRef(null);
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");

  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: '',
      description: ''
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      description: Yup.string().required("Please Enter Description")
    }),
    onSubmit: (values) => {
      handleAddBlogCategory(values)
    }
  });

  const handleImage = (e) => {
    const file = e.target.files[0];
    // console.log("file",);
    const reader = new FileReader();
    reader.onloadend = () => {
      // Use a regex to remove data url part
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');

      // console.log("base64String",base64String);
      setCategoryImage(base64String);
      // Logs wL2dvYWwgbW9yZ...
    };
    reader.readAsDataURL(file);
  }



  // const tableData = ;
  const handleAddBlogCategory = async () => {
    try {
      if(title && slug && description && categoryImage !== "") {

        const body = {
          title: title,
          description: description,
          image: categoryImage,
          created_by: JSON.parse(localStorage.getItem("authUser")).id
        };

        post('/admin/add-blog-category', body, {headers}).then((response) => {
          if(response.status === 200) {
            toast.success(response.message);
            setTimeout(() => {
              props.history.push("/blog/categories");
            },2000)
          }
          else {
            toast.error(response.message);
          }
        })
      }
      else {
        toast.error("Please fill all required fields");
      }
    }
    catch (e) {
      // console.log(e)
    }
  }

  //meta title
  document.title = "Add Blog Category | ShipCheap";

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')    // Replace spaces with hyphens
      .replace(/[^\w-]+/g, '')  // Remove non-word characters
      .replace(/--+/g, '-')     // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, ''); // Trim hyphens from the beginning and end of the slug
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" sourceItem="Blog Categories" breadcrumbItem="Add Blog Category" />
        {/* <Table columns={columns} data={data} /> */}
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <CardBody>
                <CardTitle className="mb-4">Blog Category Information</CardTitle>
                <div className="">
                  {responseSuccess && !responseSuccess ? (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {responseMessage}
                    </Alert>
                  ) : null}
                  {responseSuccess && responseSuccess ? (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                      {responseMessage}
                    </Alert>
                  ) : null}

                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleAddBlogCategory();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <label>Category Image</label>
                      <input type={"file"} multiple={false} ref={fileInput} required={true} onChange={handleImage} className={"form-control"} accept={".png"}/>
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Title</Label>
                      <Input
                        name="title"
                        className="form-control"
                        placeholder="Enter Title"
                        type="text"
                        onChange={(e) => {
                          let value = e.target.value;
                          // let slug = value.toString().toLowerCase().replace("-"," ").replace(" ","-");
                          setTitle(e.target.value)
                          setSlug(slugify(value));
                        }}
                        value={title}
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Slug</Label>
                      <Input
                        name="slug"
                        className="form-control"
                        placeholder="Slug"
                        type="text"
                        readOnly={true}
                        // onChange={(e) => setSlug(e.target.value)}
                        value={slug}
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Description</Label>
                      <Input
                        name="description"
                        className="form-control non-arrow-number"
                        placeholder="Enter Description"
                        type="textarea"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      />
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <Button type="button" onClick={() => props.history.push('/blog/categories') } color="danger" style={{marginRight:"10px"}}>
                          Cancel
                        </Button>
                        <button
                          className="btn btn-success w-md "
                          type="submit"
                        >
                          Save
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
CreateBlogCategory.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default CreateBlogCategory;