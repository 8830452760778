// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import axios from "axios"
import { amountFormat } from "../../../assets/js/numberFormatter"
import customStyles from "../../../assets/css/customTableStyle"


function CoinbasePayments(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState(null);

  const [pending, setPending] = useState(true);

  function capitalizeWords(str) {
    // split the string into an array of words
    let words = str.split(' ');

    // capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }

    // join the words back together into a string and return it
    return words.join(' ');
  }

  const columns = [
    {
      name: 'User Email',
      // selector: 'email',
      selector: row=>row.email,

    //  sortable: true,
    },
    {
      name: 'Coinbase Code',
      // selector: 'coinbase_payment_code',
      selector: row=>row.coinbase_payment_code,

      width:"200px",
      cell: row => <span title={row.coinbase_payment_code}>{row.coinbase_payment_code}</span>
    //  sortable: true,
    },
    {
      name: 'Amount',
      selector: 'json',
    //  sortable: true,
      width:"120px",
      cell: row => JSON.parse(row.json).payments.length > 0 ? "$" + amountFormat(JSON.parse(row.json).payments[0].value.local.amount) : "-"
    },
    {
      name: 'Crypto',
      selector: 'json',
    //  sortable: true,
      width:"160px",
      cell: row => JSON.parse(row.json).payments.length > 0 ? JSON.parse(row.json).payments[0].value.crypto.amount + " " + JSON.parse(row.json).payments[0].value.crypto.currency : "-"
    },
    {
      name: 'Updated At',
      selector: 'updated_at',
     sortable: true,
      width:"170px",
      cell: (row) => moment(row.updated_at).tz(moment.tz.guess()).format('MMM DD, YYYY')
    },
    {
      name: 'Status',
      selector: 'charge_status',
     sortable: true,
      width: "150px",
      cell: row => row.charge_status == "charge:created" ? <span className={"badge badge-warning"}>{capitalizeWords(row.charge_status.replaceAll(":"," "))}</span>:(row.charge_status == "charge:failed"?<span className={"badge badge-danger"}>{capitalizeWords(row.charge_status.replaceAll(":"," "))}</span>:(row.charge_status == "charge:confirmed"?<span className={"badge badge-success"}>{capitalizeWords(row.charge_status.replaceAll(":"," "))}</span>:<span className={"badge badge-light"}>{capitalizeWords(row.charge_status.replaceAll(":"," "))}</span>))
      // cell: row => row.charge_status == "charge.created" ? <span className={"badge badge-danger"}>Pending</span>:<span className={"badge badge-success"}>Charged</span>
    },
    // {
    //   name: 'Actions',
    //   selector: ['coinbase_payment_code', 'charge_json', 'user_id'],
    //   width: "160px",
    //   cell: (row) => row.coinbase_payment_code?
    //     (row.charge_json == null ?
    //       <>
    //         <button className={"btn btn-success btn-sm waves-effect waves-light"} onClick={() => handleCoinbasePaymentSuccess(row.coinbase_payment_code, row.user_id)}>Approve</button>
    //       </>:"-")
    //     :"-"
    // },
  ];

  const handleCoinbasePaymentSuccess = async (code, user_id) => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://api.commerce.coinbase.com/charges/${code}`,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CC-Version': '2018-03-22'
      }
    };
    // console.log("config", config)

    axios(config)
      .then((response) => {
        // console.log("uccccc",response.data);
        if(response.data.data.payments.length > 0) {
          const body = {
            coinbase_payment_code: response.data.data.code,
            charge_json: response.data.data
          };
          post('/user/update-payment-logs-charge', body, { headers }).then(async (updateResponse) => {
            if(updateResponse.status === 200) {
              const walletBody = {
                id: user_id,
                balance: updateResponse.amount,
                payment_mode: 'coinbase'
              };
              // console.log("walletBody", walletBody);
              await post('/admin/user/add-balance', walletBody, { headers }).then(async (addBalanceResponse) => {
                // console.log("addBalanceResponse", addBalanceResponse);
                if (response.status === 200) {
                  toast.success(addBalanceResponse.message);
                  retrievePaymentLogs();
                }
                else {
                  toast.error(addBalanceResponse.message);
                  retrievePaymentLogs();
                }
              });
            }
            else {
              toast.error(updateResponse.message);
            }
          });
        }
        else {
          toast.error("Payment not confirmed yed, Please try again later.")
        }
      })
      .catch((error) => {
        // console.log("loooogg error",error);
        retrievePaymentLogs();
      });
  }

  // const tableData = ;
  const retrievePaymentLogs = async () => {
    try {
      let arr = [];
      get('/admin/get-coinbase-payment-logs', {headers}).then((response) => {
        // console.log("response", response);
        for (let i=0;i<response.data.length;i++) {
          // if(response.data[i].charge_status == "charge:confirmed") {
            // console.log(response.data[i]);
            arr.push(response.data[i]);
          // }
        }
        setFilteredData(arr);
        let data = arr;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }


  useEffect(() => {
    // // console.log("here");
    retrievePaymentLogs();
  }, []);

  //meta title
  document.title = "Coinbase Payments | ShipCheap";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" breadcrumbItem="Coinbase Payments" />
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Coinbase Payments...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                         highlightOnHover={true}/>
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
CoinbasePayments.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default CoinbasePayments;