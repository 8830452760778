// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import DataTable, { ExpanderComponentProps } from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import templateCsv from '../../../assets/templete/Carrier ID import template.xlsx'
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody, ModalFooter, ToastBody
} from "reactstrap"
import { saveAs } from 'file-saver';
import customStyles from "../../../assets/css/customTableStyle"
import Select from "react-select"

let selectedIds = [];
function CarriersID() {
  const fileInput = useRef(null);
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [user_id, setUserId] = useState([]);
  const [bulk_user_id, setBulkUserId] = useState(null);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [pending, setPending] = useState(true);
  const [fileValid, setFileValid] = useState(null);
  const [fileError, setFileError] = useState("");
  const [selectedCarrier, setSelectedCarrier] = useState("");

  const [carrier_id, setCarrierID] = useState("");
  const [max_usage, setMaxUsage] = useState("");
  const [max_usage_import, setMaxUsageImport] = useState("");
  const [service_and_limits, setServiceAndLimits] = useState([]);
  const [selectedCreateCarrier, setSelectedCreateCarrier] = useState("");
  const [selectedCreateCarrierID, setSelectedCreateCarrierID] = useState("");
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [services, setServices] = useState([]);
  const [createServices, setCreateServices] = useState([]);
  const [createService, setCreateService] = useState(null);
  const [max_limit, setMaxLimit] = useState("");
  const [modal, setModal] = useState(false);
  const [limitModal, setLimitModal] = useState(false);
  const [serviceLimitModal, setServiceLimitModal] = useState(false);
  const [servicesAndLimit, setServicesAndLimit] = useState({ "id_of_carrier_id": "", "carrier_id": "", "carrier": "", "max_usage": "", "data": [] });
  const [limitCarrier, setLimitCarrier] = useState(null);
  const [modalCreate, setModalCreate] = useState(false);
  const [carriers, setCarriers] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [assignedUserEmail, setAssignedUserEmail] = useState(null);
  const [addModal, setAddModal] = useState(false)
  const [addCarrierId, setAddCarrierId] = useState('')
  const [deleteCarrierId, setDeleteCarrierId] = useState('')



  let newUsers;
  const columns = [
    {
      name: "#",
      // selector: ['id','carrier_id'],
      // sortable: true,
      width: "45px",
      selector: row => <input type={"checkbox"} className={"carrier-id-checkbox"} value={row.carrier_id} name="carrier_id" onChange={handleCarrierIdCheck} />
    },
    {
      name: 'Carrier ',
      // selector: 'carrier_name',
      // sortable: true,
      width: '100px',
      selector: row => row.carrier_name ? row.carrier_name : "-"
    },
    {
      name: 'Carrier ID',
      selector: row => row.carrier_id,
      // sortable: true,
      width: '320px'
    },
    {
      name: 'Usage',
      selector: row => row.record_completed,
      // sortable: true,
      width: '90px',
    },
    {
      name: 'Max Usage',
      // selector: row => row.max_usage,
      // sortable: true,
      width: '120px',
      selector: row => row.max_usage ? row.max_usage : "-"
    },
    // {
    //   name: 'Assign To',
    //   // selector: row => row.max_usage,
    //   // sortable: true,
    //   width: '250px',
    //   selector: row => row.email ? row.email : "-",


    // },
    {
      name: 'Status',
      // selector: 'status',
      // sortable: true,
      width: '90px',
      selector: (row) =>
        <>
          {row.status === 1 ? <span className={"badge badge-success"}>Active</span> : (row.status === 2 ? <span className={"badge badge-danger"}>Expired</span> : <span className={"badge badge-warning"}>Inactive</span>)}
        </>
    },
    {
      name: 'Created At',
      // selector: 'created_at',
      sortable: true,
      width: "210px",
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
    {
      name: 'Updated At',
      // selector: 'updated_at',
      sortable: true,
      width: "210px",
      selector: (row) => moment(row.updated_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
    {
      name: 'Actions',
      // selector: ['id', 'status', 'carrier', 'carrier_name', 'max_usage', 'carrier_id', 'services_and_limit'],
      //  sortable: true,
      width: "950px",
      selector: (row) => row.status !== 2 ? <>
        <select onChange={handleStatusChange} style={{ width: "120px", float: "left" }} className={"form-select"}><option selected={row.status === 1 ? true : false} value={1 + "," + row.id}>Activate</option><option selected={row.status === 0 ? true : false} value={0 + "," + row.id}>Deactivate</option></select>
        <button className={"btn btn-danger"} onClick={() => handleDelete(row.id)} style={{ marginLeft: "10px" }}>Delete</button>
        <button className={"btn btn-info"} onClick={() => {
          // console.log("row.services_and_limit",row)
          // console.log("row.services_and_limit",{"carrier_id": row.carrier_id,"max_usage": row.max_usage,"data": row.services_and_limit?JSON.parse(row.services_and_limit):[]})
          setServicesAndLimit({ "id_of_carrier_id": row.id, "carrier_id": row.carrier_id, "carrier": row.carrier_name, "max_usage": row.max_usage, "data": row.services_and_limit ? JSON.parse(row.services_and_limit) : [] });
          setServiceLimitModal(true);
        }} style={{ marginLeft: "10px" }}>View Services And Limit</button>
        <button className={"btn btn-warning"} style={{ marginLeft: "10px" }} onClick={async (e) => {
          await retrieveServicesOfCarrierToCreate(row.id);
          setMaxLimit("");
          setSelectedCreateCarrierID(row.id);
          setCreateService(null);
          setLimitModal(true);
          // console.log("setLimitModal is true")
        }}>Add New Service Limit</button>
        <button className={"btn btn-primary waves-effect waves-light"} style={{ marginLeft: "10px" }} onClick={() => handleViewAssignUser(row.user_id, row.id)}>
          View Assign User
        </button>
        <button className={"btn btn-light  waves-effect waves-light"} onClick={() => handleAddModal(row.id)} style={{ marginLeft: "10px" }}>Add User</button>
      </>
        : "-"
    }
  ];

  const toggleViewModal = () => {
    console.log("Toggling modal");
    setViewModal(!viewModal);
  };

  const handleViewAssignUser = (user_id, deletecarrierID) => {
    console.log("User ID:", user_id, "Delete Carrier ID:", deletecarrierID);
    setAssignedUserEmail(JSON.parse(user_id));
    setDeleteCarrierId(deletecarrierID);
    setViewModal(true);
  };

  const toggleAddModal = () => {

    setAddModal(!addModal)

  }

  const handleAddModal = (id) => {
    setAddCarrierId(id)
    toggleAddModal()
  }

  const handleDeleteUser = (user_id) => {
    let confrm = confirm("Are you sure you want to remove this user?");
    if (confrm) {
      const body = {
        user_id: user_id,
        id: deleteCarrierId

      };
      console.log("delete", body)
      post('/admin/delete-user-carrier-id', body, { headers }).then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          // setAssignedUserEmail(null)
          //toggleViewModal()
          setViewModal(false);
          retrieveCarrierIds()
        } else {
          toast.success(response.message);
        }
      })

    }
  }
  const handleDelete = async (id) => {
    let confrm = confirm("Are you sure you want to delete this carrier id?");
    if (confrm) {
      const body = {
        id: id
      };
      post('/admin/delete-carrier-id', body, { headers }).then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          retrieveCarrierIds();
        } else {
          toast.success(response.message);
        }
      })
    }
  }

  const handleStatusChange = async (e) => {
    const { value } = e.target;
    const id = value.split(",")[1];
    const status = value.split(",")[0];
    let body = {
      id: id,
      status: status
    };
    post('/admin/update-carrier-id', body, { headers }).then((response) => {
      if (response.status === 200) {
        retrieveCarrierIds();
        toast.success(response.message);
      }
      else {
        toast.error(response.message);
      }
    })
  }

  function uncheckAll() {
    let checkboxes = document.getElementsByClassName('carrier-id-checkbox');
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
  }

  // const tableData = ;
  const retrieveCarrierIds = async () => {
    try {
      setPending(true);
      get('/admin/get-carrier-ids', { headers }).then((response) => {
        console.log("response", response);
        setData(response.data);
        let data = response.data;
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });
        setPending(false);
      })
    }
    catch (e) {
      console.log(e)
    }
  }


  const retrieveCarriers = async () => {
    try {
      get('/admin/get-carrier-names', { headers }).then((response) => {
        setCarriers(response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }
  const retrieveUsers = async () => {
    try {
      get('/admin/getActiveUsers', { headers }).then((response) => {
        if (response.data.length > 0) {
          response.data.sort((a, b) => {
            const nameA = a.email.toLowerCase();
            const nameB = b.email.toLowerCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        }

        let users = [];
        for (let i = 0; i < response.data.length; i++) {
          users.push({
            label: response.data[i].email,
            value: response.data[i].id
          })
        }

        setUsers(users);
        newUsers = users
        // console.log("response.data",response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    // // console.log("here");
    retrieveUsers();
    retrieveCarrierIds();
    retrieveCarriers();
  }, []);

  const handleChange = event => {
    // console.log(fileInput.current.files);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (selectedCarrier !== "") {
      // console.log("service_and_limits",service_and_limits);
      if (service_and_limits.length > 0) {
        if (!service_and_limits.some(el => el.limit == max_usage_import)) {
          toast.error("Please set a priority service");
          return true;
        }
      }


      const formData = new FormData();
      formData.append('file', fileInput.current.files[0]);
      formData.append('carrier_id', selectedCarrier);
      formData.append('max_usage', max_usage_import);
      formData.append('user_id', bulk_user_id);
      formData.append('service_and_limits', service_and_limits.length > 0 ? JSON.stringify(service_and_limits) : null);

      postFile("/admin/import-carrier-ids", formData).then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          retrieveCarrierIds();
          setMaxUsageImport("");
          setSelectedCarrier("");
          setBulkUserId(null)
          setModal(!modal);
        }
        else {
          toast.error(response.message);
        }
      })


      // You can now send the formData object using Axios or fetch
    }
    else {
      toast.error("Please select a carrier");
    }
  };

  const handleCarrierIdCheck = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      selectedIds.push(value);
    }
    else {
      let index = selectedIds.findIndex(el => el == value);
      selectedIds.splice(index, 1);
      // selectedOrders.filter((el) => el !== value);
    }

    // console.log("selectedIds",selectedIds);
  }

  const handleExport = async () => {
    if (selectedIds.length > 0) {
      const data = selectedIds.toString().replaceAll(",", "\n");
      const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
      saveAs(blob, 'carrier-ids-' + moment(new Date).format("DD-MMM-YYYY") + '.csv');
      selectedIds = [];
      toast.success("Carrier ids exported successfully");
      uncheckAll();
    }
    else {
      toast.error("Please select at least one carrier id");
    }
  }

  const handleCarrier = async (e) => {
    const { value } = e.target;
    setSelectedCarrier(value);
    await retrieveCarrierServices(value);
  }

  const retrieveCarrierServices = (id_of_carrier) => {
    try {
      // [{"service_id":1,"service_name":"UPS Ground","limit":100,"used":38}]
      setServiceAndLimits([]);
      get('/admin/service-by-carrier/' + id_of_carrier, { headers }).then((response) => {
        if (response.status == 200) {
          let arr = [];
          for (let i = 0; i < response.data.length; i++) {
            arr.push({ "service_id": response.data[i].id, "service_name": response.data[i].name, "limit": 0, "used": 0 })
          }

          setServiceAndLimits(arr);
          console.log("setServiceAndLimits", arr);
        }
      })
    }
    catch (e) {

    }
  }

  const handleCreateCarrier = async (e) => {
    const { value } = e.target;
    setSelectedCreateCarrier(value);
    await retrieveServicesOfCarrier(value);
    setSelectedPriority("");
  }

  const retrieveServicesOfCarrier = async (carrier_id) => {
    get("/admin/service-by-carrier/" + carrier_id, { headers }).then((response) => {
      if (response.status === 200) {
        setServices(response.data);
      }
      else {
        toast.error(response.message);
      }
    })
  }

  const retrieveServicesOfCarrierToCreate = async (carrier_id) => {
    get("/admin/service-by-carrier-for-create/" + carrier_id, { headers }).then((response) => {
      if (response.status === 200) {
        setCreateServices(response.data);
      }
      else {
        toast.error(response.message);
      }
    })
  }

  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    let body = {
      carrier: selectedCreateCarrier,
      carrier_id: carrier_id,
      max_usage: max_usage,
      selectedPriority: selectedPriority,
      user_id: user_id
    };
    console.log(body, "body of create")
    post('/admin/create-carrier-id', body, { headers }).then((response) => {

      if (response.status === 200) {
        toast.success(response.message);
        retrieveCarrierIds();
        setMaxUsage("");
        setSelectedCreateCarrier("");
        setCarrierID("");
        setUserId([])
        setModalCreate(!modalCreate);

      }
      else {
        toast.error(response.message);
      }
    })
  }

  const handleAddNewuser = async (e) => {
    e.preventDefault();
    let body = {
      id: addCarrierId,
      user_id: user_id
    }
    console.log(body, "body of create")
    post('/admin/add-user-carrier-id', body, { headers }).then((response) => {
      if (response.status === 200) {
        toast.success(response.message);

        setAddCarrierId("");
        setUserId([])
        setAddModal(!addModal);
        retrieveCarrierIds();
      }
      else {
        toast.error(response.message);
      }

    })

  }

  const handleCreateNewLimitSubmit = async (e) => {
    e.preventDefault();
    let body = {
      carrier_id: selectedCreateCarrierID,
      max_limit: max_limit,
      service_id: createService
    };

    post('/admin/create-new-carrier-and-limit', body, { headers }).then((response) => {
      if (response.status === 200) {
        toast.success(response.message);
        retrieveCarrierIds();
        setCreateService("");
        setSelectedCreateCarrierID("");
        setMaxLimit("");
        setLimitModal(false);
      }
      else {
        toast.error(response.message);
      }
    })
  }

  const handleServiceAndLimitSubmit = (e) => {
    e.preventDefault();
    // console.log("e",e);
    // console.log("id_of_carrier_id",e.target[0].value);
    // console.log("service_id",e.target[1].value);
    // console.log("limit",e.target[2].value);
    let body = {
      carrier_id: e.target[0].value,
      max_limit: e.target[2].value,
      service_id: e.target[1].value
    };
    post('/admin/update-carrier-and-limit', body, { headers }).then((response) => {
      if (response.status === 200) {
        toast.success(response.message);
        retrieveCarrierIds();
      }
      else {
        toast.error(response.message);
      }
    })
  }


  //meta title
  document.title = "Carrier IDs | ShipCheap";
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" breadcrumbItem="Carrier IDs" />
        {/* <Table columns={columns} data={data} /> */}
        <Card>
          <CardBody>
            <button onClick={() => setModalCreate(!modalCreate)} style={{ marginRight: "10px" }} className={"btn btn-success btn-sm waves-effect waves-light"}>Create Carrier ID</button>
            <button onClick={() => setModal(!modal)} className={"btn btn-primary btn-sm waves-effect waves-light"}>Import Carrier IDs</button>
            {/*<a href={templateCsv} download={true}><button className={"btn btn-warning btn-sm waves-effect waves-light"} style={{marginLeft:"10px"}}>Download TXT Template</button></a>*/}
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            {/*<Alert color="info" style={{marginBottom:"0px"}}>*/}
            {/*  Carrier id usage will be updated to 0 if you activate a carrier id.*/}
            {/*</Alert>*/}
            <button className={"btn btn-warning"} onClick={handleExport} style={{ marginBottom: "-70px" }}>Export Carrier Ids</button>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Carriers IDs...`}
            >
              <DataTable columns={columns} data={data} customStyles={customStyles} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                highlightOnHover={true} />
            </DataTableExtensions>
          </CardBody>
        </Card>

        {/*Modal*/}
        <Modal
          isOpen={serviceLimitModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setServiceLimitModal(!serviceLimitModal);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setServiceLimitModal(!serviceLimitModal);
              }}
            >
              Services and Limit
            </ModalHeader>
            <ModalBody className={"modal-lg"}>
              <label className={" mb-1"}>Carrier</label>
              <input type={"text"} readOnly={true} value={servicesAndLimit.carrier} className={"form-control"} />
              <label className={"mt-2 mb-1"}>Carrier ID</label>
              <input type={"text"} readOnly={true} value={servicesAndLimit.carrier_id} className={"form-control"} />
              {/*<h6>Carrier: {servicesAndLimit.carrier}</h6>*/}
              {/*<h6>Carrier ID: {servicesAndLimit.carrier_id}</h6>*/}
              <div className={"p-2"} style={{ width: "100%" }}>
                <div className={"row"}>
                  <div className={"col-md-6 border pt-2 pb-2"} style={{ fontWeight: "600", width: "50%" }}>Service Name</div>
                  <div className={"col-md-6 border pt-2 pb-2"} style={{ fontWeight: "600", width: "25%" }}>Used</div>
                  <div className={"col-md-6 border pt-2 pb-2"} style={{ fontWeight: "600", width: "25%" }}>Limit</div>
                  {/*<div className={"col-md-6 border pt-2 pb-2"} style={{fontWeight:"600",width:"25%"}}>Actions</div>*/}
                </div>
                {servicesAndLimit.data.length > 0 ?
                  servicesAndLimit.data.map((el, id) => (
                    <form key={id} className={"row"} onSubmit={handleServiceAndLimitSubmit}>
                      <div className={"border pt-2 pb-2"} style={{ width: "50%" }}>{el.service_name} {servicesAndLimit.max_usage == el.limit ? <span className={"badge badge-success"}>Priority</span> : ""}</div>
                      <div className={"border pt-2 pb-2"} style={{ width: "25%" }}>{el.used}</div>
                      <div className={"border pt-2 pb-2"} style={{ width: "25%" }}>{el.limit}</div>
                      {/*<div className={"border pt-2 pb-2"} style={{width:"25%"}}><input type={"hidden"} name={"id_of_carrier_id"} value={servicesAndLimit.id_of_carrier_id}/><input type={"hidden"} name={"service_id"} value={el.service_id}/><input type={"number"} name="limit" value={el.limit} min={1} minLength={1} required={true} onInput={(e) => {*/}
                      {/*  let updatedValue = servicesAndLimit;*/}
                      {/*  let newValue = servicesAndLimit.data[id];*/}
                      {/*  newValue.limit = e.target.value;*/}
                      {/*  updatedValue.data.splice(id, 1, newValue);*/}
                      {/*  setServicesAndLimit(updatedValue)*/}
                      {/*}} readOnly={servicesAndLimit.max_usage==el.limit?true:false} style={{border:"1px solid #efefef"}} className={"form-control non-arrow-number"} /></div>*/}
                      {/*<div className={"border pt-2 text-center pb-2"} style={{width:"25%"}}>{servicesAndLimit.max_usage==el.limit?<span className={"text-success text-center w-full"}>Priority Service</span>:<button className={"btn btn-sm btn-success"} style={{backgroundColor:"#34c38f",color:"white",padding:"5px 10px"}} type={"submit"}>Update</button>}</div>*/}
                    </form>
                  ))
                  :
                  <div className={"row"}>
                    <div className={"col-md-12 border pt-2 pb-2"}>No Limit Found</div>
                  </div>
                }
              </div>
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}
        <Modal
          isOpen={limitModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setLimitModal(!limitModal);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setLimitModal(!limitModal);
              }}
            >
              Create New Service Limit
            </ModalHeader>
            <ModalBody>
              <form onSubmit={handleCreateNewLimitSubmit}>
                {createServices.length > 0 ?
                  <>
                    <Label className="form-label">Service <span className={"text-danger"}>*</span></Label>
                    <select
                      name={"service"}
                      className="form-select mb-3"
                      required={true}
                      onChange={(e) => { setCreateService(e.target.value) }}
                      value={createService}
                    >
                      <option disabled={true} value={""} selected={true}>Select a service</option>
                      {createServices.map((part, id) => {
                        return (<option key={id} value={part.id}>{part.name}</option>);
                      })};
                    </select>
                  </> : <p className={"text-danger"}>There are no services available to add limit</p>}
                <Label className="form-label">Max Limit <span className={"text-danger"}>*</span></Label>
                <input type="number" disabled={createServices.length ? false : true} onWheel={(e) => e.target.blur()} placeholder={"Enter Max Limit"} value={max_limit} onInput={(e) => setMaxLimit(e.target.value)} className="form-control non-arrow-number" />
                <button disabled={createServices.length ? false : true} className="btn btn-primary w-md mt-3" type="submit">Add</button>
              </form>
            </ModalBody>
            {/*<ModalFooter>*/}
            {/*  <Button*/}
            {/*    type="button"*/}
            {/*    color="secondary"*/}
            {/*    onClick={() => {*/}
            {/*      setModal(!modal);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Close*/}
            {/*  </Button>*/}
            {/*</ModalFooter>*/}
          </div>
        </Modal>
        {/*Modal*/}

        {/*Modal*/}
        <Modal
          isOpen={modalCreate}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setModalCreate(!modalCreate);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModalCreate(!modalCreate);
              }}
            >
              Create Carrier ID
            </ModalHeader>
            <ModalBody>
              <form onSubmit={handleCreateSubmit}>
                <div className="mb-3">
                  <label>Carriers <span className={"text-danger"}>*</span></label>
                  <select
                    name={"carrierIdcreate"}
                    className="form-select"
                    required={true}
                    onChange={handleCreateCarrier}
                    value={selectedCreateCarrier}
                  >
                    <option disabled={true} value={""}>Select a carrier</option>
                    {carriers.map((part, id) => {
                      return (part.is_active == 1 ? <option key={id} value={part.id}>{part.name}</option> : "");
                    })};
                  </select>
                </div>
                <Label className="form-label">Carrier ID <span className={"text-danger"}>*</span></Label>
                <input type="text" required={true} value={carrier_id} placeholder={"Enter Carrier ID"} onInput={(e) => setCarrierID(e.target.value)} className="form-control mb-3" />
                <Label className="form-label">Max Usage <span className={"text-danger"}>*</span></Label>
                <input type="number" onWheel={(e) => e.target.blur()} placeholder={"Enter Max Usage"} value={max_usage} onInput={(e) => setMaxUsage(e.target.value)} className="form-control non-arrow-number mb-3" />
                {services.length > 0 ?
                  <>
                    <Label className="form-label">Priority Service <span className={"text-danger"}>*</span></Label>
                    <select
                      name={"service"}
                      className="form-select"
                      required={true}
                      onChange={(e) => { setSelectedPriority(e.target.value) }}
                      value={selectedPriority}
                    >
                      <option disabled={true} value={""} selected={true}>Select a priority service</option>
                      {services.map((part, id) => {
                        return (<option key={id} value={part.id}>{part.name}</option>);
                      })};
                    </select>
                  </> : ""}
                <div className="mt-3 mb-3">
                  <Label className="form-label">Assign User</Label>
                  <Select

                    isMulti
                    options={users}

                    onChange={(selectedGroup) => {
                      const selectedUserIds = selectedGroup ? selectedGroup.map(user => user.value) : [];
                      setUserId(selectedUserIds);
                    }}

                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
                <button className="btn btn-primary w-md mt-3" type="submit">Create</button>
              </form>
            </ModalBody>
            {/*<ModalFooter>*/}
            {/*  <Button*/}
            {/*    type="button"*/}
            {/*    color="secondary"*/}
            {/*    onClick={() => {*/}
            {/*      setModal(!modal);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Close*/}
            {/*  </Button>*/}
            {/*</ModalFooter>*/}
          </div>
        </Modal>
        {/*Modal*/}

        {/*Modal*/}
        <Modal
          isOpen={modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setModal(!modal);
            setServiceAndLimits([]);
            setSelectedCarrier("");
            setMaxUsageImport("");
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setModal(!modal);
                setServiceAndLimits([]);
                setSelectedCarrier("");
                setMaxUsageImport("");
              }}
            >
              Import Bulk Carrier IDs
            </ModalHeader>
            <ModalBody>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label>Carriers</label>
                  <select
                    name={"carrierId"}
                    className="form-select"
                    required={true}
                    onChange={handleCarrier}
                    value={selectedCarrier}
                  >
                    <option disabled={true} value={""}>Select a carrier</option>
                    {carriers.map((part, id) => {
                      return (part.is_active == 1 ? <option key={id} value={part.id}>{part.name}</option> : "");
                    })};
                  </select>
                </div>

                {service_and_limits.length > 0 ?
                  <fieldset className={"mt-2"}>
                    <legend>Set Service Limit</legend>
                    <p className={"text-danger"}>Note:<br />Priority service limit must be equal to the max usage of carrier id.<br />Only one service is accepted as priority service.</p>
                    {
                      service_and_limits.map((el, index) => (
                        <div key={index} className={"col-md-12 mt-2 mb-2"}>
                          <Label style={{ width: "100%" }}>{el.service_name}
                            <span onClick={() => {
                              // if(service_and_limits.length>1) {
                              let updatedServiceLimits = service_and_limits.filter(sl => sl.service_id != el.service_id);
                              setServiceAndLimits(updatedServiceLimits);
                              // }
                            }} title={"Remove service"} style={{
                              color: "red", fontWeight: "500", marginRight: "10px", cursor: "pointer", border: "1px solid red", borderRadius: "50%", width: "15px", height: "15px", display:
                                // (service_and_limits.length > 1 ?
                                "block"
                              // :"none")
                              , textAlign: "center", lineHeight: "12px", float: "left"
                            }}>x</span>
                          </Label>
                          <input required={true} type="number" placeholder={"Enter limit"} onInput={(e) => {
                            let indexOfService = service_and_limits.findIndex(sl => sl.service_id == el.service_id);
                            service_and_limits[indexOfService].limit = parseInt(e.target.value);
                            setServiceAndLimits(service_and_limits);
                          }} onWheel={(e) => e.target.blur()} className="form-control non-arrow-number" />
                        </div>
                      ))
                    }
                  </fieldset>
                  : null}
                <Label className="form-label">Select A File (.txt)</Label>
                <input type="file" required={true} className="form-control mb-3" ref={fileInput} onChange={handleChange} accept={".txt"} />
                <Label className="form-label">Max Usage</Label>
                <input type="number" placeholder={"Enter max usage"} onWheel={(e) => e.target.blur()} value={max_usage_import} onInput={(e) => setMaxUsageImport(e.target.value)} className="form-control non-arrow-number" />
                <div className="mt-3 mb-3">
                  <Label className="form-label">Assign User</Label>
                  <Select
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={users}
                    // onChange={(selectedGroup) => {
                    //   // console.log("selectedGroup",selectedGroup);
                    //   setBulkUserId(selectedGroup.value)
                    // }}
                    onChange={(selectedGroup) => {
                      const selectedUserIds = selectedGroup ? selectedGroup.map(user => user.value) : [];
                      setBulkUserId(selectedUserIds);
                    }}
                  />
                </div>

                <button className="btn btn-primary w-md mt-3" type="submit">Import</button>
              </form>
            </ModalBody>
            {/*<ModalFooter>*/}
            {/*  <Button*/}
            {/*    type="button"*/}
            {/*    color="secondary"*/}
            {/*    onClick={() => {*/}
            {/*      setModal(!modal);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Close*/}
            {/*  </Button>*/}
            {/*</ModalFooter>*/}
          </div>
        </Modal>
        {/* viewmodal */}
        <Modal
          isOpen={viewModal}
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={toggleViewModal}>
          <ModalHeader toggle={toggleViewModal}>Assigned User</ModalHeader>

          <ModalBody style={{ padding: '20px', backgroundColor: '#f7f7f7', borderTop: '1px solid #dee2e6' }}>
            {Array.isArray(assignedUserEmail) && assignedUserEmail.length > 0 ? (
              <div>
                {assignedUserEmail.map(id => {
                  const user = users.find(u => u.value === id);
                  return (
                    <div key={id} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                      <p style={{ margin: 0 }}>Email: {user ? user.label : "-"}</p>
                      <button className={"btn btn-danger btn-sm"} title={"Remove User"} onClick={() => handleDeleteUser(id)}><i className={"bx bx-x"}></i></button>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p>This carrier ID is not assigned to any user.</p>
            )}
          </ModalBody>
          {/* <ModalFooter  >
            <Button color="secondary" onClick={toggleViewModal}>Close</Button>
          </ModalFooter > */}
        </Modal >

        {/* Add Modal */}
        <Modal
          isOpen={addModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setAddModal(!addModal);
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setAddModal(!addModal);
              }}
            >
              Add New User
            </ModalHeader>
            <ModalBody>
              <form onSubmit={handleAddNewuser}>

                <div className="mt-3 mb-3">
                  <Label className="form-label">Assign User</Label>
                  <Select

                    isMulti
                    options={users}

                    onChange={(selectedGroup) => {
                      const selectedUserIds = selectedGroup ? selectedGroup.map(user => user.value) : [];
                      setUserId(selectedUserIds);

                    }}

                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
                <button className="btn btn-primary w-md mt-3" type="submit">Add</button>
              </form>
            </ModalBody>

          </div>
        </Modal>
        {/*Modal*/}
      </div >
    </div >
  );
}
CarriersID.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default CarriersID;