// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, postFile } from "../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, CardTitle, DropdownMenu, DropdownItem
} from "reactstrap"
import Select from "react-select/base"

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { CKEditor } from 'ckeditor4-react';

import { EditorState, convertFromRaw, convertToRaw, convertFromHTML,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import config from '../../../config';
let descriptionData = "";
function EditBlog(props) {
  const {
    match: { params },
  } = props

  const API_URL= config.site.BASE_URL;
  const UPLOAD_ENDPOINT = "api/user/upload-blog-image";

  const fileInput = useRef(null);
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty()
  );

  const handleEditorStateChange = async (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const htmlString = await stateToHTML(contentState);
    // console.log(htmlString);
    setDescription(htmlString);
    setEditorState(newEditorState);
  };

  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [blogImage, setBlogImage] = useState("");
  const [description, setDescription] = useState("");
  const [blogCategories, setBlogCategories] = useState([]);
  const [blog, setBlog] = useState({title:"",excerpt:"",category_id:""});
  // const [selectedCategory, setSelectedCategory] = useState(null);


  const handleEditBlog = async () => {
    try {
      // if(blogImage !== "") {
      // console.log("title",title)
      // console.log("excerpt",excerpt)
      // console.log("category_id",category_id)
      // console.log("description",description)
      if(title && excerpt && category_id && slug && description != "" && description != "<p></p>") {
          const body = {
            id: params.id,
            title: title,
            slug: slug,
            excerpt: excerpt,
            description: description,
            category_id: category_id,
            image: blogImage,
            // created_by: JSON.parse(localStorage.getItem("authUser")).id
          };

          post('/admin/update-blog', body, {headers}).then((response) => {
            if(response.status === 200) {
              toast.success(response.message);
              setTimeout(() => {
                props.history.push("/blogs");
              },2000)
            }
            else {
              toast.error(response.message);
            }
          })
        }
        else {
          toast.error("Please fill all required fields");
        }
      // }
      // else {
      //   toast.error("Please select blog image");
      // }
    }
    catch (e) {
      // console.log(e)
    }
  }


  const handleImage = (e) => {
    const file = e.target.files[0];
    // console.log("file",);
    const reader = new FileReader();
    reader.onloadend = () => {
      // Use a regex to remove data url part
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');

      // console.log("base64String",base64String);
      setBlogImage(base64String);
      // Logs wL2dvYWwgbW9yZ...
    };
    reader.readAsDataURL(file);
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const formData = new FormData();
            formData.append('file', file);
            // console.log("file",file);

            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            //
            postFile('/user/upload-blog-image', formData)
              .then((res) => {
                // console.log("response", res);
                resolve({
                  default: `${API_URL}/apis/blog-image/${res.filename}`
                });
              })
              .catch((err) => {
                reject(err);
              });

            // fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
            //   method: "post",
            //   body: body
            //   // mode: "no-cors"
            // })
            //   .then((res) => res.json())
            //   .then((res) => {
            //     resolve({
            //       default: `${API_URL}/blog-image/${res.filename}`
            //     });
            //   })
            //   .catch((err) => {
            //     reject(err);
            //   });
          });
        });
      }
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const retrieveBlog = async () => {
    try {
      let body = {
        id: params.id
      }
      post('/admin/get-blog-by-id', body, {headers}).then(async (response) => {
        setBlog(response.data);
        // console.log("blog",response.data);
        setCategoryId(response.data.category_id);
        setTitle(response.data.title);
        setSlug(response.data.slug);
        setExcerpt(response.data.excerpt);
        setDescription(response.data.description);
        descriptionData = response.data.description;
        // console.log("api called",descriptionData)


        // if (editorRef.current) {
        //   // Access the CKEditor instance
        //   const editorInstance = editorRef.current.editor;
        //
        //   // Set the data in CKEditor
        //   editorInstance.setData(response.data.description);
        // }
        //
        // const htmlContent = response.data.description;
        // const blocksFromHtml = convertFromHTML(htmlContent);
        // const contentState = ContentState.createFromBlockArray(
        //   blocksFromHtml.contentBlocks,
        //   blocksFromHtml.entityMap
        // );
        // setEditorState(EditorState.createWithContent(contentState));
      })
    }
    catch (e) {
      // console.log(e)
    }
  }
  const editorRef = useRef(null);
  useEffect(() => {
    retrieveBlog();
    retrieveBlogCategories();
    // console.log("blog id",params.id);
  }, []);

  function getHtmlFromEditorState() {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlString = convertToHTML(rawContentState);
    // console.log("html",htmlString)
    return htmlString;
  }

  const retrieveBlogCategories = async () => {
    try {
      get('/admin/get-blog-categories', {headers}).then((response) => {
        setBlogCategories(response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  // const tableData = ;
  //meta title
  document.title = "Edit Blog | ShipCheap";

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')    // Replace spaces with hyphens
      .replace(/[^\w-]+/g, '')  // Remove non-word characters
      .replace(/--+/g, '-')     // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, ''); // Trim hyphens from the beginning and end of the slug
  };


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" sourceItem="Blogs" breadcrumbItem="Edit Blog" />
        {/* <Table columns={columns} data={data} /> */}
        <Row className="justify-content-center">
          <Col md={10} lg={10} xl={10}>
            <Card className="overflow-hidden">
              <CardBody>
                <CardTitle className="mb-4">Blog Information</CardTitle>
                <div className="">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleEditBlog();
                      return false;
                    }}
                  >
                    <Row>
                      <div className={"col-md-6"}>
                        <div className="mb-3">
                          <label>Category</label>
                          <select
                            name={"category_id"}
                            className="form-select"
                            onChange={(e) => setCategoryId(e.target.value)}
                            value={category_id}
                            required={true}
                          >
                            <option disabled={true} value={""}>Select a category</option>
                            {blogCategories.map((part, id) => {
                              return (<option key={id} value={part.id}>{part.title}</option>);
                            })};
                          </select>
                        </div>
                      </div>
                      <div className={"col-md-6"}>
                        <div className="mb-3">
                          <label>Blog Image</label>
                          <input type={"file"} multiple={false} ref={fileInput} onChange={handleImage} className={"form-control"} accept={".png"}/>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className={"col-md-4"}>
                        <div className="mb-3">
                          <Label className="form-label">Title</Label>
                          <Input
                            name="title"
                            className="form-control"
                            placeholder="Enter Service Name"
                            type="text"
                            onChange={(e) => {
                              let value = e.target.value;
                              // let slug = value.toString().toLowerCase().replace("-"," ").replace(" ","-");
                              setTitle(e.target.value)
                              setSlug(slugify(value));
                            }}
                            value={title}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className={"col-md-4"}>
                        <div className="mb-3">
                          <Label className="form-label">Slug</Label>
                          <Input
                            name="slug"
                            className="form-control"
                            placeholder="Slug"
                            type="text"
                            readOnly={true}
                            // onChange={(e) => setSlug(e.target.value)}
                            value={slug}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className={"col-md-4"}>
                        <div className="mb-3">
                          <Label className="form-label">Excerpt</Label>
                          <Input
                            name="excerpt"
                            className="form-control non-arrow-number"
                            placeholder="Enter Excerpt"
                            type="text"
                            onChange={(e) => setExcerpt(e.target.value)}
                            value={excerpt}
                            required={true}
                          />
                        </div>
                      </div>
                    </Row>
                    <div className="mb-3">
                      <Label className="form-label">Description</Label>
                      {/*<Editor*/}
                      {/*  editorState={editorState}*/}
                      {/*  onEditorStateChange={handleEditorStateChange}*/}
                      {/*/>*/}

                      <CKEditor
                        config={ {
                          extraPlugins: ['colorbutton','font','blockquote','image2','emoji']
                        }}
                        onInstanceReady={ evt => {
                          // setTimeout(() => {
                            // console.log("evt",evt);
                            // console.log("description",descriptionData);
                            // You can store the "editor" and use when it is needed.
                            evt.editor.setData(descriptionData);
                          // },2000)
                        }
                        }
                        onChange={(event) => {
                          const data = event.editor.getData();
                          // // console.log("data", data);
                          setDescription(data);
                        }
                        }
                      />

                      {/*<CKEditor*/}
                      {/*  config={{*/}
                      {/*    extraPlugins: [uploadPlugin]*/}
                      {/*  }}*/}
                      {/*  data={description}*/}
                      {/*  editor={ClassicEditor}*/}
                      {/*  onReady={(editor) => {*/}
                      {/*    editorRef.current = editor;*/}
                      {/*  }}*/}
                      {/*  onBlur={(event, editor) => {}}*/}
                      {/*  onFocus={(event, editor) => {}}*/}
                      {/*  onChange={(event, editor) => {*/}
                      {/*    const data = editor.getData();*/}
                      {/*    setDescription(data);*/}
                      {/*    // // console.log("data",data);*/}
                      {/*  }}*/}
                      {/*/>*/}
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <Button type="button" onClick={() => props.history.push('/blogs') } color="danger" style={{marginRight:"10px"}}>
                          Cancel
                        </Button>
                        <button
                          className="btn btn-success w-md "
                          type="submit"
                        >
                          Save
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
EditBlog.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EditBlog;