import React from "react"
import { useRef } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { get, post } from "../../helpers/api_helper"
import moment from "moment-timezone"
import { Link } from "react-router-dom"
import ebay from '../../assets/images/integrations/ebay.svg';
import walmart from '../../assets/images/integrations/walmart.svg';
import shopify from '../../assets/images/integrations/shopify.svg';
import walmart_icon from '../../assets/images/integrations/walmart-icon-yellow.png';
import default_store from '../../assets/images/integrations/default-store.png';
import zenventory from '../../assets/images/integrations/1651688363447.jfif';
import sellercloudSmall from '../../assets/images/integrations/sellercloud-small.png';
import shipstationSmall from '../../assets/images/integrations/shipstation-small.png';
import {socket} from "../../assets/js/initializeSocket";

const SiderBarStoreDropDown = (props) => {

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const [showDropDown, setShowDropDown] = useState(false)
  const [stores, setStores] = useState([]);
  const [currentStore, setCurrentStore] = useState([]);

  const dropdownRef = useRef(null)

  const retrieveStores = async () => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      };
      post('/user/getStores', body, {headers}).then((response) => {
        // console.log("stores in nav", response);
        let arr = [];
        arr.push({
          id: 0,
          store_id: null,
          store_name: "Manual Store",
          last_synced: null,
          store_type: "store",
        })
        for (let i=0;i<response.data.length;i++) {
          arr.push({
            id: response.data[i].id,
            store_id: response.data[i].id,
            store_name: response.data[i].name,
            last_synced: response.data[i].last_synced,
            store_type: response.data[i].store_type,
          })
        }
        // console.log("arr",arr);
        setStores(arr);
        // setCurrentStore(arr.filter(el => el.id == localStorage.getItem("store"))[0]);

        setInterval(() => {
          // console.log("stores.length",arr.length);
          if(arr.length > 0) {
            // console.log("selected store =",arr.filter(el => el.id == localStorage.getItem("store"))[0]);
            setCurrentStore(arr.filter(el => el.id == localStorage.getItem("store"))[0]);
          }
        },100)
      })
    }
    catch (e) {
    // console.log(e)
    }
  }


  useEffect(() => {
    const handleBodyClick = event => {
      // Check if the clicked element is outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropDown(false)
      }
    }

    retrieveStores();

    document.body.addEventListener("click", handleBodyClick)

    return () => {
      document.body.removeEventListener("click", handleBodyClick)
    }
  }, [])

  useEffect(() => {
    async function storeConnected() {
      console.log("emit storeConnected")
      retrieveStores();
    }
    async function storeDisconnected() {
      console.log("emit storeDisconnected")
      retrieveStores();
    }

    socket.on('storeConnected', storeConnected);
    socket.on('storeDisconnected', storeDisconnected);

    return () => {
      socket.off('storeConnected', storeConnected);
      socket.off('storeDisconnected', storeDisconnected);
    };
  },[]);

  const handleStoreSelect = (val) => {
    setCurrentStore(val);
    localStorage.setItem("store", val.id);
    document.getElementById("main-drop").click();
    if(document.title == "Awaiting Shipment | ShipCheap") {
      document.body.style.display = 'none';
      // document.getElementById("dsh").click();
      // console.log("currentStore.store_name ",val.store_name)
      if(val.store_name == "Manual Store") {
        localStorage.removeItem("order-filter")
        get('/admin/get-carriers', { headers }).then((response) => {
          // console.log("carriers", response.data);
          if (response.data.length > 0) {
              // console.log("response.data.filter(el => el.is_active == 1)[0]",response.data.filter(el => el.is_active == 1)[0]);
              localStorage.setItem("order-filter", response.data.filter(el => el.is_active == 1)[0].name);
          }
          window.location = "/orders/awaiting-shipment";
        });
      }
      else {
        window.location = "/orders/awaiting-shipment";
      }
    }
    else if (document.title == "Completed Orders | ShipCheap") {
      document.body.style.display = 'none';
      if(val.store_name == "Manual Store") {
        localStorage.removeItem("order-filter")
        get('/admin/get-carriers', { headers }).then((response) => {
          // console.log("carriers", response.data);
          if (response.data.length > 0) {
            // console.log("response.data.filter(el => el.is_active == 1)[0]",response.data.filter(el => el.is_active == 1)[0]);
            localStorage.setItem("order-filter", response.data.filter(el => el.is_active == 1)[0].name);
          }
          window.location = "/orders/completed";
        });
      }
      else {
        window.location = "/orders/completed";
      }
    }
  }

  return (currentStore?
    <>
    <Link to={"/orders/awaiting-shipment"} id={"dsh"} style={{display:"none"}}></Link>
    <li id={"store-nav"} style={{backgroundColor:"#16182b",width:"85%",margin:"auto",marginBottom:"5px",borderRadius:"5px"}}>
      <Link id={"main-drop"} to="/#" className="has-arrow" style={{display:"flex",lineHeight:"30px",paddingLeft:"16px",paddingRight:"10px",fontSize:"15px",borderBottom:"2px solid #070A1F",color:"white"}}>
        <img src={currentStore.store_type == "shopify" ? shopify:(currentStore.store_type=="ebay" ? ebay: (currentStore.store_type == "walmart" ? walmart_icon:  (currentStore.store_type == "zenventory"?zenventory:(currentStore.store_type == "sellercloud"?sellercloudSmall:(currentStore.store_type == "shipstation"?shipstationSmall:default_store)))))} width={"25px"} height={"25px"} style={{marginRight:"5px",marginTop:"2px"}}/>
        {/*<i style={{ fontSize: "20px",marginTop:"5px",color:"white" }} className={currentStore.id != 0? (currentStore.store_type =="walmart"?"bx bx-store":"bx bxl-"+currentStore.store_type):"bx bx-"+currentStore.store_type}></i>*/}
        <span style={{width:"160px",overflow:"hidden",whiteSpace: "nowrap",textOverflow:"ellipsis"}}>{currentStore.store_name}</span>
      </Link>
      <ul className="sub-menu pb-2">
        {stores.map((val, index) => {
          return (<li key={index}>
            <Link onClick={() => {val.store_name != currentStore.store_name?handleStoreSelect(val):""}} title={val.store_name} to={"#"} style={{display:"flex",paddingLeft:"20px"}}>
              <img src={val.store_type == "shopify" ? shopify:(val.store_type=="ebay" ? ebay: (val.store_type == "walmart" ? walmart_icon: (val.store_type == "zenventory"?zenventory:(val.store_type == "sellercloud"?sellercloudSmall:(val.store_type == "shipstation"?shipstationSmall:default_store)))))} width={"20px"} height={"20px"} style={{marginRight:"5px"}}/>
              {/*<i style={{ fontSize: "18px",color:(val.store_name == currentStore.store_name?"white":"#79829c") }} className={val.id != 0? (val.store_type =="walmart"?"bx bx-store":"bx bxl-"+val.store_type):"bx bx-"+val.store_type}></i>*/}
              <span style={{width:"150px",marginTop:"2px",overflow:"hidden",whiteSpace: "nowrap",textOverflow:"ellipsis",color:(val.store_name == currentStore.store_name?"white":"#79829c")}}>{val.store_name}</span>
            </Link>
          </li>)
        })}
      </ul>
    </li>
    </>:""
    // <div>
    //   <div
    //     ref={dropdownRef}
    //     onClick={() => setShowDropDown(!showDropDown)}
    //     style={{ background: "#f3f3f9", cursor: "pointer", color:"#333", marginTop:"12px" }}
    //     className="d-flex justify-content-between align-items-center mx-3 px-3 py-2 rounded"
    //   >
    //     <div className="d-flex flex-row align-items-center">
    //       <div className="">
    //         <i style={{ fontSize: "20px",marginTop:"5px" }} className={currentStore.id != 0? "bx bxl-"+currentStore.store_type:"bx bx-"+currentStore.store_type}></i>
    //       </div>
    //       <div title={currentStore.store_name} className="px-2" style={{width:"130px",maxWidth:"130px",whiteSpace: "nowrap",overflow: "hidden",
    //         textOverflow: "ellipsis"}}>
    //         {currentStore.store_name}
    //       </div>
    //     </div>
    //     <div className="">
    //       <i
    //         className={`${
    //           showDropDown ? "bx bx-chevron-down" : "bx bx-chevron-right"
    //         }`}
    //       ></i>
    //     </div>
    //   </div>
    //   <div
    //     style={{
    //       background: "#ffffff",
    //       zIndex: "1",
    //       display: !showDropDown && "none",
    //       width: "100%",
    //       color:"#333",
    //       boxShadow:"0px 10px 10px 2px rgba(0,0,0,0.05)"
    //     }}
    //     className={`m-0  mx-3 position-absolute z-index-1`}
    //   >
    //     {stores.map((val, index) => {
    //       return (
    //         <div key={index} className="d-flex flex-center py-2 px-2 store-hover" style={{height:"fit-content"}}>
    //           <div className="p-2">
    //             <i style={{ fontSize: "18px" }} className={val.id != 0? "bx bxl-"+val.store_type:"bx bx-"+val.store_type}></i>
    //           </div>
    //           <div
    //             style={{ cursor: "pointer" }}
    //             onClick={() => handleStoreSelect(val)}
    //             className="p-2"
    //           >
    //             {val.store_name}
    //             {val.last_synced?
    //             <>
    //               <br/>
    //               <span style={{fontSize:"12px"}}>{moment(val.last_synced).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")}</span>
    //             </>:""
    //             }
    //           </div>
    //         </div>
    //       )
    //     })}
    //   </div>
    // </div>
  )
}

export default SiderBarStoreDropDown
