import PropTypes from "prop-types";
import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import config from '../../../config';
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import logo from "assets/images/nullship-icon.png";
import namelogo from "assets/images/shipcheap.svg";
import img11 from "assets/images/img11.png";
import img12 from "assets/images/img12.png";
// import header background img
import bgimg from "assets/images/bgimg.png";
import Recaptcha from "components/Recaptcha";
import LoginFooter from "components/LoginFooter";
import '../../../assets/css/loginfooter.css'
//Import config
import { post, get } from "../../../helpers/api_helper"
import { Toaster } from "react-hot-toast"
import {toast} from "react-hot-toast";
import DataTableExtensions from "react-data-table-component-extensions"
import DataTable from "react-data-table-component"
import moment from "moment-timezone"
import loader from '../../../assets/images/loader.gif';
import fedex from '../../../assets/images/fedex.png';
import ups from '../../../assets/images/ups-logo.png';
import usps from '../../../assets/images/usps-logo.png';
import dhl from '../../../assets/images/dhl-logo.png';
import defaultBox from '../../../assets/images/default-box.png';
import { doc } from "prettier"
import axios from "axios"
const Tracking = props => {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };
  const {
    match: { params },
  } = props;

  const [isShow, setIsShow] = useState(true);
  const [data, setData] = useState([]);
  const [serviceImage, setServiceImage] = useState("loading");
  const [currentState, setCurrentState] = useState({status: "-",aftership_updated_at: "-"});
  const [pending, setPending] = useState(true);

  const images = [
    {
      "tag": "InfoReceived",
      "enum": "2643e8a28995471cfb70e4ce1789c995"
    },
    {
      "tag": "InTransit",
      "enum": "81c9368125eb43a7e2ffd7fa10668fc1"
    },
    {
      "tag": "OutForDelivery",
      "enum": "f7139b1eeb164be3fd38d4181dab839d"
    },
    {
      "tag": "AttemptFail",
      "enum": "ad9982a948144acaee0fbd211fe7eb5f"
    },
    {
      "tag": "Delivered",
      "enum": "9ea84d540ce143b7f0860b57126a6aa4"
    },
    {
      "tag": "AvailableForPickup",
      "enum": "43013fad6d88413be9d46bdf14d8e9ad"
    },
    {
      "tag": "Exception",
      "enum": "a36a77ee7f214270e5699d461d05b070"
    },
    {
      "tag": "Expired",
      "enum": "1c62e75b04494c45ffd626f814c0a16c"
    },
    {
      "tag": "Pending",
      "enum": "0cc21407bd0a459ef0ba7e201505872d"
    },
  ]

  // const columns = [
  //   {
  //     name: 'Date',
  //     selector: 'created_at',
  //     sortable: false,
  //     cell: (row) => moment(row.updated_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A'),
  //     // width: "210px"
  //   },
  //   {
  //     name: 'Status',
  //     selector: 'tag',
  //     sortable: false,
  //     cell: row => <span><img width={"20px"} style={{marginRight:"10px",marginTop:"-3px"}} src={`https://assets.am-static.com/api_docs_center/aftership/testing/docs_api.aftership.com/enum/${images.filter(el => el.tag == row.tag)[0].enum}`}/> {row.tag.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}</span>
  //   },
  //   {
  //     name: 'Location',
  //     selector: 'location',
  //     sortable: false,
  //     // width: "250px",
  //     cell: row => row.location?<span>{row.location}</span>:"-"
  //   }
  // ];

  const columns = [
      {
        name: '',
        // selector: 'tag',
        // sortable: false,
        selector: row=>row.subtag_message,
 
        width: "50px",
        cell: row => <img width={"20px"} src={`https://assets.am-static.com/api_docs_center/aftership/testing/docs_api.aftership.com/enum/${images.filter(el => el.tag == row.tag)[0].enum}`}/>
      },
      {
        name: '',
        // selector: 'checkpoint_time',
        selector: row=>row.subtag_message,

        // sortable: false,
        width:"280px",
        cell: (row) => moment(row.checkpoint_time).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
      },
      {
        name: '',
        // selector: ['subtag_message', 'location'],      
        selector: row=>row.subtag_message,

        
        // sortable: false,
        cell: row => <span><span style={{fontWeight:"500"}}>{row.subtag_message}</span><br/><span style={{fontSize:"10px",color:"gray"}}>{row.location?row.location:'-'}</span></span>
      }
  ]

  //meta title
  document.title = "Tracking | ShipCheap";

  const retrieveTracking = async () => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        tracking_code: params.tracking_code
      }
      post('/user/get-tracking-details-by-tracking-code', body, {headers}).then(async (response) => {
        // console.log("data",response);
        if(response.status === 200) {
          setData(JSON.parse(response.data[0].json).checkpoints.reverse());
          // setServiceImage(response.data[0].carrier_name);
          setCurrentState(response.data[0]);
          // // console.log("response.data[0]",JSON.parse(response.data[0].json).checkpoints.reverse());

          get('/user/get-service-image/' + response.data[0].order_id, {headers}).then(async (imageResponse) => {
            if (imageResponse.status === 200) {
              setServiceImage(imageResponse.image);
            }
          });


          setTimeout(() => {
            setIsShow(false)
            document.body.style.overflowY = "visible";
            setPending(false);
          },1200);


          // if(JSON.parse(response.data[0].json).checkpoints.length > 0) {
          // }
          // else {
          //   setData([{
          //     'created_at': response.data[0].aftership_updated_at,
          //     'subtag_message': "Pending",
          //     'tag': "Pending",
          //     'location': null
          //   }]);
          // }
        }
        else {
          let msg = toast.error(response.message);
          setTimeout(() => {
            toast.remove(msg);
            props.history.push("/");
          },5000);
        }
      }).catch((e) => {
      // console.log("e",e);
        let msg = toast.error("Unauthenticated request");
        setTimeout(() => {
          toast.remove(msg);
          props.history.push("/");
        },4000);
      });
    } catch(e) {
    // console.log("e",e);
      let msg = toast.error("Unauthenticated request");
      setTimeout(() => {
        toast.remove(msg);
        props.history.push("/");
      },4000);
    }
  }

  const updateTracking = async () => {
      let body = {
        tracking_code: params.tracking_code
      }
      post('/user/get-tracking-details-by-tracking-code', body, {headers}).then(async (response) => {
        // console.log("data",response);
        if(response.status=== 200) {
          // console.log("response.data[0]",response.data[0])
          // const getOptions = {
          //   method: 'GET',
          //   url: `https://api.aftership.com/v4/trackings/${params.tracking_code}`,
          //   headers: { 'Content-Type': 'application/json', 'as-api-key': 'asat_b6605998b5854da7bbcefdc668a70341' }
          // };
          // // console.log("getOptions",":::",getOptions);
          // const { data: { data: { tracking } } } = await axios.request(getOptions)
          // let json = JSON.stringify(tracking);
          // // console.log("json", json);
          // let body = {
          //   order_id: response.data[0].order_id,
          //   data: tracking,
          //   json,
          // }
          // // console.log("body post", body)
          // post('/user/save-afteship-order', body, { headers })
          //   .then(response => {


              // });
        }
        else {
          toast.error("Tracking does'nt associate with your account")
          setTimeout(() => {
            props.history.push("/");
          },3000);
        }
      }).catch((e) => {
        // console.log("unotrh",e);
        toast.error("Unauthenticated request");
        setTimeout(() => {
          props.history.push("/");
        },3000);
      })
  }



  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsShow(true)
    // setTimeout(() => {
    //   setIsShow(false)
    // },2000);
    document.body.style.overflowY = "hidden";
    document.body.style.overflowX = "hidden";
    // updateTracking().then(() => {
    // });
    retrieveTracking().then(() => {

    });
  },[]);

  return (
    <React.Fragment>
      <Toaster
        position="top-center"
        containerStyle={{zIndex:"99999"}}
        reverseOrder={false}
      />
      <div id='loader' className={"align-items-center justify-content-center"} style={{display:(isShow?"flex":"none"),alignItems:"center",margin:"auto", position: "absolute",width:"100%",height:"100vh",backgroundColor:"white",zIndex:"9999",top:"0",left:"0"}}>
        <img width={120} src={loader}/>
      </div>
      <div style={{ backgroundImage: `url(${bgimg})`, width: "100%", marginBottom: "20px", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "95px", position: "fixed", top: "0", left: "0", zIndex: "999" }} className="navbar-header">
        <a href={`${config.main_web.URL}`}><img className="mx-5" width="230px" src={namelogo} alt="" /></a>
      </div>
      <div className={"col-md-12 p-2"}>
        <div className="col-md-10 col-lg-8 col-xl-8 mx-auto loginpage">
          <Card>
            <CardBody style={{minHeight:"400px"}}>
              <h2 className={"text-center text-dark mb-4"}>Order Tracking</h2>
              <div className={"row"}>
                <div className={"col-md-6 mt-3"}>
                  <h5 className={"text-center text-secondary mt-3"}>{currentState.status.replace(/([a-z0-9])([A-Z])/g, '$1 $2')} On</h5>
                  <h1 className={"text-center text-primary"} style={{fontSize:"2.5rem"}}>{moment(currentState.aftership_updated_at).format('MMM DD, YYYY')}</h1>
                </div>
                <div className={"col-md-6 mt-3"}>
                  <h5 className={"text-center"}>
                    {/*<img className={"center-block rotateAnimation"} height={"50px"} src={serviceImage=="FedEx"?fedex:(*/}
                    {/*  serviceImage=="UPS" || serviceImage=="UPSv2"? ups: (*/}
                    {/*    serviceImage=="USPS"? usps : (*/}
                    {/*      serviceImage=="DHL"? dhl: defaultBox*/}
                    {/*    )*/}
                    {/*  )*/}
                    {/*)}/>*/}
                    {serviceImage=="loading"?<img src={loader} width={120} height={"50px"} className={"center-block rotateAnimation"}/>:(
                      serviceImage?<img src={`data:image/jpeg;base64,${serviceImage}`} height={"50px"} className={"center-block rotateAnimation"}/>:<img src={defaultBox} height={"50px"} className={"center-block rotateAnimation"}/>
                    )}

                  </h5>
                  <h4 className={"text-center"}>{params.tracking_code}</h4>
                </div>
              </div>
              <hr style={{width:"60%",marginLeft:"auto",marginRight:"auto"}}/>
              <h4 className="card-title text-center" style={{marginBottom:"20px"}}>Checkpoints</h4>

              <DataTable columns={columns} data={data} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} noTableHead={true}/>
            </CardBody>
          </Card>
        </div>
      </div>
      <LoginFooter />
    </React.Fragment>
  )
};

Tracking.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Tracking;

