// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import customStyles from "../../../assets/css/customTableStyle"
import config from "../../../config"


function PaymentRequests() {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [tableData, setTableData] = useState([])
  const [filteredData, setFilteredData] = useState([])

  const [modal, setModal] = useState(false)
  const [id, setId] = useState()
  const [userId, setUserId] = useState()
  const [email, setEmail] = useState()
  const [code, setCode] = useState()
  const [paidAmount, setPaidAmount] = useState()
  const [paymentDate, setPaymentDate] = useState()

  const columns = [
    {
      name: 'Email',
      //  selector: 'email',
      selector: row => row.email,

      //  sortable: true,
      // width: "250px",
    },
    {
      name: 'Name',
      //  selector: 'name',
      selector: row => row.name,

      width: "200px",
      //  sortable: true,
    },
    {
      name: 'Payment Code',
      //  selector: 'code',
      selector: row => row.code,

      width: "160px",
      //  sortable: true,
    },
    {
      name: 'Payment Status',
      selector: 'is_paid',
      sortable: true,
      width: "160px",
      cell: row => row.is_paid === 1 ? <span className={"badge badge-success"}>Paid</span> : <span className={"badge badge-danger"}>Unpaid</span>
    },
    {
      name: 'Paid Amount',
      selector: 'paid_amount',
      sortable: true,
      width: "160px",
      cell: (row) => row.paid_amount ? row.paid_amount : "-"
    },
    {
      name: 'Payment Date',
      selector: 'payment_date',
      sortable: true,
      width: "160px",
      cell: (row) => row.payment_date ? row.payment_date : "-"
    },
    {
      name: 'Created At',
      selector: 'created_at',
      sortable: true,
      width: "200px",
      cell: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
    {
      name: 'Actions',
      selector: 'id',
      width: "180px",
      cell: (row) =>
        <>
          <button className={"btn btn-warning btn-sm waves-effect waves-light"} onClick={() => handleApprove(row)}>Approve</button>
          <button className={"btn btn-danger btn-sm waves-effect waves-light"} onClick={() => handleDelete(row.id)} style={{ marginLeft: "10px" }}>Cancel</button>
        </>
    },
  ];

  const handleApprove = (data) => {
    setId(data.id);
    setUserId(data.user_id);
    setCode(data.code);
    setEmail(data.email)
    setModal(true)
  }

  const handleDelete = (id) => {
    try {
      const body = {
        id: id
      }
      post('/admin/cancel-payment', body, { headers }).then(async (response) => {
        if (response.status === 200) {
          toast.success(response.message)
          await retrievePaymentRequests()
        } else {
          toast.error(response.message)
        }
      })
    } catch {

    }
  }

  const retrievePaymentRequests = async () => {
    try {
      get('/admin/get-payment-references', { headers }).then((response) => {
        if (response.status === 200) {
          setFilteredData(response.data)
          let data = response.data
          setTableData({ columns, data })
        }
      })
    } catch (error) {

    }
  }

  useEffect(() => {
    retrievePaymentRequests()
  }, [])

  const handleApprovePayment = () => {
    try {
      const body = {
        id: id,
        user_id: userId,
        paid_by: JSON.parse(localStorage.getItem("authUser")).id,
        paid_amount: paidAmount,
        payment_date: paymentDate
      }
      post('/admin/approve-payment', body, { headers }).then(async (response) => {
        if (response.status === 200) {
          setId("")
          setUserId("")
          setEmail("")
          setCode("")
          setPaidAmount("")
          setPaymentDate("")
          setModal(false)
          toast.success(response.message)
          await retrievePaymentRequests();
        } else {
          toast.error(response.message)
        }
      })
    } catch (error) {

    }
  }

  //meta title
  document.title = "Payment Requests | ShipCheap";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="ShipCheap" breadcrumbItem="Payment Requests" />
        <Card>
          <CardBody>
            <DataTableExtensions
              {...tableData}
              exportHeaders={true}
              filterPlaceholder={`Filter Payment Requests...`}
            >
              <DataTable columns={columns} data={filteredData} customStyles={customStyles} pagination={30} paginationPerPage={30}
                highlightOnHover={true} />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
      {/*Modal*/}
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
      // toggle={() => {
      //   setModal(!modal);
      // }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModal(!modal);
              setId("");
              setUserId("");
              setEmail("");
              setCode("");
              setPaidAmount("");
              setPaymentDate("");
            }}
          >
            Approve Payment Request
          </ModalHeader>
          <ModalBody>
            <Form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                handleApprovePayment();
                return false;
              }}
            >
              <h5>Email: {email}</h5>
              <h5>Payment Code: {code}</h5>
              <hr />
              <div className="mb-3">
                <Label className="form-label">Paid Amount</Label>
                <Input
                  name="Payment_Amount"
                  className="form-control"
                  placeholder="Enter Payment Amount"
                  type="number"
                  step="any"
                  required={true}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    setPaidAmount(e.target.value)
                  }}
                  value={paidAmount}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Payment Date</Label>
                <Input
                  name="Payment_Date"
                  className="form-control"
                  placeholder="Enter Payment Date"
                  required={true}
                  type="date"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    setPaymentDate(e.target.value)
                  }}
                  value={paymentDate}
                />
              </div>
              <Row className="mb-3">
                <Col className="text-end">
                  <button
                    className="btn btn-primary w-md "
                    type="submit"
                  >
                    Approve
                  </button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </div>
      </Modal>
      {/*Modal*/}
    </div>
  );
}

export default PaymentRequests;