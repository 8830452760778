import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Toast,
  ToastHeader, ToastBody
} from "reactstrap"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";
import { post } from '../../helpers/api_helper';

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
// import logoImg from "../../assets/images/logo.svg";
// import logoImg from "../../assets/images/nullship-icon.png";
import logoImg from '../../assets/images/ainimationlogo.gif'
import logo from "../../assets/images/logo.svg"
import { Toaster } from "react-hot-toast"
import {toast} from "react-hot-toast";
import LoginFooter from "components/LoginFooter";
import Recaptcha from "components/Recaptcha";

import '../../assets/css/loginfooter.css'
// import namelogo from "assets/images/nullship-logo.png";
import namelogo from "assets/images/nullship_christmas_logo_uncropped.gif";
import img11 from "assets/images/img11.png";
import img12 from "assets/images/img12.png";
// import header background img
import bgimg from "assets/images/bgimg.png";
import config from "../../config"
import { GoogleTagManager, initGA } from "../../assets/js/gtag"

// Images
import Logo from '../../assets/images/shipcheap.svg'
import LiningBg from '../../assets/images/lining-bg.svg'
import USPS from '../../assets/images/usps-service.svg'
import DHL from '../../assets/images/dhl-service.svg'
import UPS from '../../assets/images/ups-service.svg'

// Import Tailwind in page because of css conflict
// import '../../assets/css/output.css';

const Register = props => {

  //meta title
  document.title = "Register | ShipCheap";
  const [responseSuccess, setResponseSuccess] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const [PWFocus, setPWFocus] = useState(false);
  const [passValid, setPassValid] = useState(null);
  const [confirmValid, setConfirmValid] = useState(true);
  const [firstCondition, setFirstCondition] = useState(false);
  const [secondCondition, setSecondCondition] = useState(false);
  const [thirdCondition, setThirdCondition] = useState(false);
  const [forthCondition, setForthCondition] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();

  let registering = false;

  const handleSubmit = async () => {
    try {
      if(password == confirmPassword) {
        if(recaptchaToken) {
          if(!registering) {
            registering = true;
            let body = {
              firstName: firstName,
              lastName: lastName,
              email: email,
              password: password
            }

            const loading = toast.loading("Registering account");
            await post('/user/register', body).then((response) => {
              if(response.status === 200) {
                toast.remove(loading);
                toast.success(response.message);
                setTimeout(() => {
                  props.history.push(response.url);
                },1000)
              }
              else {
                toast.remove(loading);
                toast.error(response.message);
              }
              registering = false;
            })
          }
        }
        else {
          toast.error("Please verify that you are not a robot")
        }
      }
      else {
        toast.error("Password and confirm password do not match")
      }
    }
    catch (e) {
      // console.log(e);
    }
  }

  // // console.log("user", user);

  useEffect(() => {
    dispatch(apiError(""));

    if(config.site.MODE == "production") {
      // initGA();
      GoogleTagManager('UA-254226105-1');
      GoogleTagManager('G-YQ0XXFE0NR');
    }
  }, []);

  const handleUpdate = (newValue) => {
    setRecaptchaToken(newValue)
  }

  function hasLowerCase(str) {
    return (/[a-z]/.test(str));
  }

  function hasUpperCase(str) {
    return (/[A-Z]/.test(str));
  }

  function hasNumber(str) {
    // return (/[1-9]/.test(str));
    return (/[0-9]/.test(str));
  }

  function hasSpecial(str) {
    let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return (format.test(str));
  }

  const handleFocus = () => {
    // console.log("setPWFocus true")
    setPWFocus(true);
  }

  const handleFocusOut = () => {
    // console.log("setPWFocus false")
    setPWFocus(false);
  }

  const handlePassword = (e) => {
    setPassword(e.target.value);
    //// console.log("e.target.value.length",e.target.value.length);
    if(e.target.value.length >= 8) {
      setFirstCondition(true);
      // //// console.log(firstCondition)
    }
    else {
      setFirstCondition(false);
    }

    if(hasUpperCase(e.target.value) && hasLowerCase(e.target.value)) {
      setSecondCondition(true);
    }
    else {
      setSecondCondition(false);
    }

    if(hasNumber(e.target.value)) {
      setThirdCondition(true);
    }
    else {
      setThirdCondition(false);
    }


    if(hasSpecial(e.target.value)) {
      setForthCondition(true);
    }
    else {
      setForthCondition(false);
    }

    if(e.target.value.length >= 7 && hasUpperCase(e.target.value) && hasLowerCase(e.target.value) && hasNumber(e.target.value) && hasSpecial(e.target.value)) {
      setPassValid(true);
    }
    else{
      setPassValid(false);
    }

    if(confirmPassword === e.target.value) {
      setConfirmValid(true);
    }
    else {
      setConfirmValid(false);
    }
  }

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    if(password === e.target.value) {
      setConfirmValid(true);
    }
    else {
      setConfirmValid(false);
    }
  }

  return (
    <React.Fragment>
      {/* <div style={{ backgroundImage: `url(${bgimg})`, width: "100%", marginBottom: "20px", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "95px", position: "fixed", top: "0", left: "0", zIndex: "999" }} className="navbar-header">
        <a href={`${config.main_web.URL}`}><img className="mx-5" height={"120px"}
          // width="230px"
                                                src={namelogo} alt="" /></a>
      </div> */}
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className={"col-md-12"}>
        <div className="col-xl-12 mx-auto">
          {/* <Card> */}
            {/* <CardBody> */}
              <Row >
                <Col xl={6} lg={6} className="d-none d-sm-none d-md-none d-lg-block">
                  <div className="relative overflow-hidden h-[100vh] md:flex flex-col bg-gradient-to-tr from-[#070A1F] to-[#070A1F] i justify-between">
                    <div className="absolute -top-36 -left-36 w-4/5 h-4/5 bg-[#2F3885] rounded-full blur-[250px] z-0"></div>
                    <div className="px-20 mt-10 w-11/12 z-[1]">
                      <img src={Logo} alt="Null Ship Logo" className="mt-20 mb-6" width={300} />
                      <h1 className="text-white font-bold text-4xl">Welcome to Simpler Shipping</h1>
                      <p className="text-white text-1xl mt-3">Where your packages are our top priority. We pride ourselves on delivering reliability, speed, and satisfaction. Our commitment extends beyond borders, ensuring your shipments reach their destination with precision and care. Trust us to connect you to a world of possibilities.</p>
                    </div>
                    <div className="absolute top-0 bottom-0 right-0">
                      <img src={LiningBg} alt="Lining bg animation" />
                    </div>
                    <div className="shipping-group relative self-end mr-24 mb-20 w-[570px] h-[375px] ">
                      <div className="absolute bottom-[125px] right-[370px] z-[3]">
                        <img src={USPS} alt="service bg animation" />
                      </div>
                      <div className="absolute bottom-0 right-[180px] z-[2]">
                        <img src={DHL} alt="service bg animation" />
                      </div>
                      <div className="absolute top-0 right-[0px] z-[1]">
                        <img src={UPS} alt="service bg animation" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} className="flex items-center form_div">
                  <div className="w-[320px] my-6 m-auto">
                    <CardBody className="pt-0">
                      <div className="text-center">
                        <h2 className="mb-3 text-4xl font-extrabold text-dark-gray-900 text-[#000]">Sign Up</h2>
                        <p className="mb-2 text-gray-700">Get started in seconds</p>
                      </div>
                      <div className="p-2">
                        <Form
                          className="form-horizontal"
                          onSubmit={async (e) => {
                            e.preventDefault();
                            await handleSubmit();
                            return false;
                          }}
                        >
                          {responseSuccess ? (
                            <Alert color="success">
                              {responseMessage}
                            </Alert>
                          ) : null}

                          {responseError ? (
                            <Alert color="danger">
                              {responseMessage}
                            </Alert>
                          ) : null}


                          <div className="mb-3">
                            <Label className="form-label">First Name</Label>
                            <Input
                              name="firstName"
                              type="text"
                              className="custom_input"
                              placeholder="Enter First Name"
                              onChange={(e) => { setFirstName(e.target.value) }}
                              value={firstName}
                              required={true}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Last Name</Label>
                            <Input
                              name="lastName"
                              type="text"
                              className="custom_input"
                              placeholder="Enter Last Name"
                              onChange={(e) => { setLastName(e.target.value) }}
                              value={lastName}
                              required={true}
                            />
                          </div>


                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              className="custom_input"
                              placeholder="Enter Email"
                              type="email"
                              onChange={(e) => { setEmail(e.target.value) }}
                              value={email}
                              required={true}
                            />
                          </div>
                          <div className="mb-3" style={{position:"relative"}}>
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              className="custom_input"
                              placeholder="Enter Password"
                              value={password}
                              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{7,20}$"
                              onChange={handlePassword}
                              onFocus={handleFocus}
                              onBlur={handleFocusOut}
                              required={true}
                            />
                            <span style={{color:"red",fontSize:"11px"}}>{passValid == null || passValid == true ? "" : "Please fulfill the password requirements"}</span>
                            <div className={"bg-white"} style={{display:(PWFocus?"block":"none"),position:"absolute",bottom:"-130px",backgroundColor:"white",right:"0",paddingRight:"30px",paddingLeft:"30px",borderRadius:"10px",border:"1px solid #333",zIndex:"99999"}}>
                              <p style={{fontWeight:"600"}}>Password Requirements</p>
                              <ul id="ul" style={{fontSize:"12px",listStyleType:"none",marginLeft:"-50px"}}>
                                <li className={firstCondition?"conditionPass":"conditionFail"}>At least 8 characters.</li>
                                <li className={secondCondition?"conditionPass":"conditionFail"}>Contains uppercase and lowercase letters.</li>
                                <li className={thirdCondition?"conditionPass":"conditionFail"}>Contains numbers.</li>
                                <li className={forthCondition?"conditionPass":"conditionFail"}>Contains at least one special character, <br/>e.g. _ ! @ # ? ]</li>
                              </ul>
                            </div>
                          </div>
                          <div className={"mb-3"}>
                            <label className="form-label">
                              Confirm Password
                            </label>
                            <Input
                              type="password"
                              className="custom_input"
                              placeholder="Confirm password"
                              // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{6,20}$"
                              onChange={handleConfirmPassword}
                              value={confirmPassword}
                              // onClick={() => {setConfirmValid(false)}}
                              required={true}
                              disabled={password?false:true}
                            />
                            <span style={{color:"red",fontSize:"11px"}}>{confirmValid ? "" : "Password do not match"}</span>
                          </div>
                          <Row className="rec">
                            <Col>
                              <Recaptcha onUpdate={handleUpdate}/>
                            </Col>
                          </Row>
                          <div className="text-center">
                            <button
                              className="btn custom_btn btn-dark w-full m-auto btn-block ntb"
                              type="submit"
                              disabled={
                                (confirmValid
                                  ? (passValid ?
                                      false :
                                      true)
                                  : true)}
                            >
                              Sign Up
                            </button>
                          </div>
                        </Form>
                      </div>
                      <div className="mt-2 text-center">
                        <p>
                          Already have an account ?{" "}
                          <Link to="/login" className="fw-medium text-danger">
                            {" "}
                            Login
                          </Link>{" "}
                        </p>
                      </div>
                    </CardBody>
                  </div>
                  {/* <hr/> */}
                  
                </Col>
              </Row>
            {/* </CardBody> */}
          {/* </Card> */}
        </div>
      </div>
      {/* <LoginFooter/> */}
    </React.Fragment>
  );
};

export default Register;
