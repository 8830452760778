// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from 'prop-types';

import axios from 'axios'

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get, baseUrl } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-hot-toast";
import moment from "moment-timezone"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, ModalHeader, ModalBody
} from "reactstrap"
import ups_logo from "../../../assets/images/ups-logo.png"

import CoinbaseCommerceButton from 'react-coinbase-commerce';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';


import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PaypalButton from '../../../components/PaypalButton'
import coinbase from '../../../assets/images/Coinbase.svg.png'
import paypalbtn from '../../../assets/images/paypalbtn.PNG'
import stripelogo from '../../../assets/images/stripelogo.png'
import config from '../../../config';
import OrderViewModal from "components/OrderViewModal";
import payment_successful from '../../../assets/images/Successful Payment.gif'
import payment_failed from '../../../assets/images/Payment Error.gif'
import logo from '../../../assets/images/shipcheap-icon.png';
import { amountFormat } from "../../../assets/js/numberFormatter"
import InputMask from "react-input-mask"
import customStyles from "../../../assets/css/customTableStyle"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"
import Payment from "./stripe/payment"
import Wrapper from "./stripe/wrapper"
import ManualPayment from "./manualPayment"



let isPlaidProcessing = false;
let eventListened = false;

function WalletHistory(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [batchLogData, setBatchLogData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [showManualPaymentModal, setShowManualPaymentModal] = useState(false);

  const [balance, setBalance] = useState("-");

  const [is_credit_enable, setIsCreditEnable] = useState("");
  const [max_credit_amount, setMaxCreditAmount] = useState("");

  // const [coinbaseLogs, setCoinbaseLogs] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentWithCardModal, setPaymentWithCardModal] = useState(false);

  const [inputAmount, setInputAmount] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const [coinbaseKey, setCoinbaseKey] = useState(null);
  const [coinbaseWebhookSecret, setCoinbaseWebhookSecret] = useState(null);

  const [card_number, setCardNumber] = useState("");
  const [card_code, setCardCode] = useState("");
  const [card_expire_at, setCardExpireAt] = useState("");
  const [amount, setAmount] = useState("");

  const [batchLogModal, setBatchLogModal] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState("");

  const [stripeModal, setStripeModal] = useState(false);
  const [plaidModal, setPlaidModal] = useState(false);

  const [plaidAccounts, setPlaidAccount] = useState([]);



  const [isPayWithCard, setIsPayWithCard] = useState(false);

  const [product, setProduct] = useState({
    "name": "ShipCheap",
    "description": "Add balance to your account",
    "logo_url": logo,
    "pricing_type": "fixed_price",
    "local_price": {
      "amount": '',
      "currency": "USD"
    },
    "metadata": {
      "user_id": JSON.parse(localStorage.getItem("authUser")).id
    },
    "redirect_url": `${baseUrl()}/user/coinbase/redirect-to-wallet?status=Successful`,
    "cancel_url": `${baseUrl()}/user/coinbase/redirect-to-wallet?status=Failed`
  });

  const [rowId, setRowId] = useState();

  const [paymentStatus, setPaymentStatus] = useState(null);

  const [pending, setPending] = useState(true);
  const [batch_pending, setBatchPending] = useState(true);

  const [checkoutToken, setCheckoutToken] = useState("");
  const [checkoutRetrieved, setCheckoutRetrieved] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);


  const plaid_columns = [
    {
      name: "",
      width: "80px",
      button: true,
      selector: (row) => <input type="radio" name="plaid_default" onChange={handlePlaidDefault} value={row.id} defaultChecked={row.is_default} />
    },
    {
      name: "Account Name",
      // width:"130px",
      selector: (row) => JSON.parse(row.accounts).official_name
    },
    {
      name: "Actions",
      width: "130px",
      selector: (row) => (
        row.is_default == 0 ?
          <button className={"btn btn-sm btn-danger"} onClick={() => handleDisconnectPlaid(row.id)}>Disconnect</button>
          : "-"
      )
    },
  ]

  // NEW WAY 2
  const columns = [
    {
      name: "Payment Mode",
      selector: 'payment_mode',
      // sortable: true,
      width: "160px",
      cell: (row) => row.payment_mode !== null ? row.payment_mode : "Wallet"
    },
    {
      name: "Batch ID",
      selector: ['batch_id', 'payment_mode'],
      // sortable: true,
      // width:"230px",
      cell: (row) => row.batch_id ? !row.payment_mode ? row.batch_id : "-" : "-"
    },
    {
      name: "Credit Amount",
      selector: 'credit_amount',
      // sortable: true,
      width: "135px",
      cell: row => row.credit_amount ? "$" + amountFormat(row.credit_amount) : "-"
    },
    {
      name: 'Debit Amount',
      selector: 'debit_amount',
      //  sortable: true,
      width: "120px",
      cell: row => row.debit_amount ? "$" + amountFormat(row.debit_amount) : "-"
    },
    {
      name: 'Success Orders',
      selector: 'success_orders',
      //  sortable: true,
      width: "130px",
      cell: row => row.success_orders ? row.success_orders : "-"
    },
    {
      name: 'Failed Orders',
      selector: 'failed_orders',
      //  sortable: true,
      width: "115px",
      cell: row => row.failed_orders ? row.failed_orders : "-"
    },
    {
      name: 'Date',
      selector: 'created_at',
      sortable: true,
      // width:"230px",
      cell: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    },
    {
      name: 'Actions',
      selector: 'batch_id',
      button: true,
      cell: row => (
        <button className={"btn btn-warning btn-sm"} onClick={() => handleBatchDetails(row.batch_id)} title={"View Batch Logs"}><i className={"bx bx-detail"}></i></button>
      )
    }
  ];

  const batch_logs_columns = [
    {
      name: "Order ID",
      selector: 'order_unique_id',
      // sortable: true,
      // width:"250px",
      cell: (row) => row.order_unique_id !== null ? row.order_unique_id : "-"
    },
    {
      name: 'Type',
      selector: 'type',
      //  sortable: true,
      width: "80px",
      button: true,
      cell: (row) => row.type === "credit" ? <span className={"badge badge-success"}>{row.type}</span> : <span className={"badge badge-danger"}>{row.type}</span>
    },
    {
      name: 'Balance',
      selector: 'balance',
      //  sortable: true,
      width: "110px",
      cell: row => row.balance ? "$" + amountFormat(row.balance) : "-"
    },
    {
      name: 'Remaining Balance',
      selector: 'remaining_amount',
      //  sortable: true,
      width: "180px",
      cell: row => row.remaining_amount ? "$" + amountFormat(row.remaining_amount) : "-"
    },
    {
      name: 'Date',
      selector: 'created_at',
      sortable: true,
      width: "230px",
      cell: (row) => moment(row.created_at).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A')
    }
  ]


  const handlePlaidDefault = (e) => {
    const { value } = e.target;
    try {
      const body = {
        id: value,
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      }
      post('/user/set-default-plaid', body, { headers }).then(async (response) => {
        if (response.status == 200) {
          await retrievePlaidAccounts();
          toast.success(response.message);
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {
      console.log("error", e)
    }
  }

  const handleDisconnectPlaid = (id) => {
    try {
      if (confirm("Are you sure! you want to disconnect this plaid account?")) {
        const body = {
          id
        }
        post('/user/disconnect-plaid', body, { headers }).then(async (response) => {
          if (response.status == 200) {
            await retrievePlaidAccounts();
            toast.success(response.message);
          }
          else {
            toast.error(response.message);
          }
        })
      }
    }
    catch (e) {
      console.log("error", e)
    }
  }



  const handleBatchDetails = async (batch_id) => {
    try {
      setSelectedBatch(batch_id);
      setBatchPending(true);
      setBatchLogData([]);
      setBatchLogModal(true);
      let body = {
        id: JSON.parse(localStorage.getItem('authUser')).id,
        batch_id: batch_id
      }
      post("/user/get-batch-logs", body, { headers }).then((response) => {
        setBatchPending(false);
        if (response.status == 200) {
          setBatchLogData(response.data);
        }
        else {
          toast.error(response.message);
        }
      })
    }
    catch (e) {

    }
  }


  function getQueryParam(name) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(name);
  }

  const recursiveRetrieve = (page, orderData = []) => {
    const body = {
      id: JSON.parse(localStorage.getItem('authUser')).id,
      page: page
    };
    // post('/user/get-wallet-history', body, { headers }).then((response) => {
    //   // // console.log("response", response);
    //   setData(response.data);
    //   let data = response.data;
    //   // console.log("table data" , { columns, data });
    //   setTableData({ columns, data });
    //   setPending(false);
    // })
    post("/user/get-wallet-history", body, { headers }).then(
      async (response) => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            let res;
            if (orderData.length == 0) {
              res = response.data;
            }
            else {
              res = orderData.concat(response.data);
            }
            setFilteredData(res)
            let data = res;
            // console.log("table data", { columns, data })
            setTableData({ columns, data })
            setPending(false)
            let nextPage = +page + +1;
            // console.log("next page", nextPage)
            await recursiveRetrieve(nextPage, res);
          }
          else {
            setFilteredData(orderData)
            let data = orderData;
            // console.log("table data", { columns, data })
            setTableData({ columns, data })
            setPending(false)
            return data;
          }
        }
        else {
          setFilteredData(orderData)
          let data = orderData;
          // console.log("table data", { columns, data })
          setTableData({ columns, data })
          setPending(false)
          return data;
        }
      }
    )
  }

  const retrieveBalance = async () => {
    try {
      setPending(true);
      // let page = 1;
      // const fetchingOrders = await recursiveRetrieve(page);
      // // console.log("fetchingOrders",fetchingOrders);

      const body = {
        id: JSON.parse(localStorage.getItem('authUser')).id
      };
      post('/user/get-wallet-history', body, { headers }).then((response) => {
        // console.log("responseeeeeee", response);
        // // Old Way
        //         // setFilteredData(response.data);
        //         // let data = response.data;
        //         // // console.log("table data" , { columns, data });
        //         // setTableData({ columns, data });
        let sorted = [];
        if (response.status == 200) {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].batch_id) {
              let found = sorted.some(el => el.batch_id == response.data[i].batch_id);
              if (!found) {
                if (response.data[i].type == "credit") {
                  sorted.push({
                    payment_mode: response.data[i].payment_mode,
                    batch_id: response.data[i].batch_id,
                    debit_amount: 0,
                    credit_amount: response.data[i].total_amount,
                    created_at: response.data[i].created_at,
                    success_orders: 0,
                    failed_orders: response.data[i].payment_mode != "Order Refund" ? (response.data[i].order_ids ? response.data[i].order_ids.split(",").length : 0) : "-"
                  });
                }
                else if (response.data[i].type == "debit") {
                  sorted.push({
                    payment_mode: response.data[i].payment_mode,
                    batch_id: response.data[i].batch_id,
                    debit_amount: response.data[i].total_amount,
                    credit_amount: 0,
                    created_at: response.data[i].created_at,
                    success_orders: response.data[i].order_ids ? response.data[i].order_ids.split(",").length : 0,
                    failed_orders: 0
                  });
                }
              }
              else {
                let index = sorted.findIndex(el => el.batch_id == response.data[i].batch_id);
                // console.log("found at index", index);
                if (response.data[i].type == "credit") {
                  // console.log("credit ",sorted[index]);
                  sorted[index].credit_amount = response.data[i].total_amount;
                  sorted[index].debit_amount = parseFloat(sorted[index].debit_amount) - parseFloat(response.data[i].total_amount);
                  sorted[index].failed_orders = (parseInt(sorted[index].success_orders) - response.data[i].order_ids.split(",").length);
                }
                if (response.data[i].type == "debit") {
                  // console.log("debit ", sorted[index]);
                  // console.log("response.data[i].total_amount",response.data[i].total_amount);
                  // sorted[index].debit_amount = parseFloat(response.data[i].total_amount) - parseFloat(sorted[index].credit_amount);
                  sorted[index].debit_amount = parseFloat(response.data[i].total_amount);
                  sorted[index].success_orders = (response.data[i].order_ids.split(",").length - parseInt(sorted[index].failed_orders));
                }
              }
            }
          }
        }
        // console.log("sorted", sorted);
        let data = sorted;
        setFilteredData(data);
        // console.log("table data" , { columns, data });
        setTableData({ columns, data });

        setPending(false);
      })
    }
    catch (e) {

    }
  }

  const getUserBalance = async () => {
    const body = {
      id: JSON.parse(localStorage.getItem("authUser")).id
    };
    await post('/user/get-balance', body, { headers }).then((response) => {
      // console.log("response", response);
      if (response.status === 200) {
        localStorage.setItem("balance", response.balance);
        setBalance(response.balance);

        setIsCreditEnable(response.is_credit_enable);
        setMaxCreditAmount(response.max_credit_amount);

      }
      else {
        // setResponseSuccess(false);
        // setResponseMessage(response.message);
      }
    })
  }

  const getCheckoutToken = async (amount) => {
    const body = {
      amount: amount
    };
    await post('/user/get-token', body, { headers }).then((response) => {
      // console.log("response", response);
      if (response.status === 200) {
        setCheckoutToken(response.data.checkoutToken);
        setCheckoutRetrieved(true);
      }
      else {
        setCheckoutRetrieved(false);
      }
    })
  }

  const handleAmount = async (e) => {
    setProduct({
      ...product, local_price: {
        amount: parseFloat(e.target.value),
        currency: "USD"
      }
    });
    if (e.target.value === "") {
      setDisabled(true);
    }
    else if (parseInt(e.target.value) === 0) {
      setDisabled(true);
    }
    else {
      setDisabled(false);
      // setButtonLoading(true);
      // await getCheckoutToken(e.target.value);
      setButtonLoading(false);
    }
  }

  const retrieveCoinbaseKey = async () => {
    await get('/admin/get-coinbase-key', { headers }).then((response) => {
      // console.log("key response", response);
      if (response.status === 200) {
        setCoinbaseKey(response.key);
        setCoinbaseWebhookSecret(response.secret);
      }
      else {
        setCoinbaseKey(null);
        setCoinbaseWebhookSecret(null);
      }
    })
  }

  useEffect(() => {
    // console.log("product",product);
    retrieveBalance();
    getUserBalance();
    retrieveCoinbaseKey();
    if (getQueryParam('status') == "Successful" || getQueryParam('status') == "Failed") {
      setPaymentStatus(getQueryParam('status'));
    }

  }, []);

  const savePaymentLog = async (data) => {
    const body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
      type: "Debit or Credit Card",
      json: data,
      coinbase_payment_code: null
    };
    await post('/user/save-payment-logs', body, { headers }).then((response) => {
      // console.log("save-payment-logs response", response);
      // if (response.status === 200) {
      //   setCheckoutToken(response.data.checkoutToken);
      //   setCheckoutRetrieved(true);
      // }
      // else {
      //   setCheckoutRetrieved(false);
      // }
    })
  }

  const handleAddBalance = async (balance) => {
    const id = JSON.parse(localStorage.getItem("authUser")).id;
    const body = {
      id,
      balance: balance,
      payment_mode: 'Debit or Credit Card'
    };
    // console.log("body", body);
    await post('/admin/user/add-balance', body, { headers }).then(async (response) => {
      // console.log("res", response);
      if (response.status === 200) {
        await retrieveBalance();
        toast.success(response.message);
      }
      else {
        toast.error(response.message);
      }
    });
  }

  window.addEventListener('message', async (event) => {
    if (checkoutToken && !eventListened) {
      const helcimPayJsIdentifierKey = 'helcim-pay-js-' + checkoutToken;
      if (event.data.eventName === helcimPayJsIdentifierKey) {
        eventListened = true;
        // console.log("event.data",event.data);
        if (event.data.eventStatus === 'ABORTED') {
          console.error('Transaction failed!', event.data.eventMessage);
          await savePaymentLog(event.data.eventMessage);
        }

        if (event.data.eventStatus === 'SUCCESS') {
          // console.log('Transaction success!', event.data.eventMessage);
          await savePaymentLog(event.data.eventMessage);
          // if(event.data.eventMessage.status == 200) {
          await handleAddBalance(product.local_price.amount);
          // }

        }

        if (event.data.eventStatus === 'HIDE') {
          setProduct({
            "name": "ShipCheap",
            "description": "Add balance to your account",
            "logo_url": logo,
            "pricing_type": "fixed_price",
            "local_price": {
              "amount": '',
              "currency": "USD"
            },
            "metadata": {
              "user_id": JSON.parse(localStorage.getItem("authUser")).id
            },
            "redirect_url": `${baseUrl()}/user/coinbase/redirect-to-wallet?status=Successful`,
            "cancel_url": `${baseUrl()}/user/coinbase/redirect-to-wallet?status=Failed`
          });
          setDisabled(true);
        }
        setTimeout(() => {
          eventListened = false;
        }, 5000);
      }
    }
  });


  const handlePayWithPlaid = async () => {
    if (!isPlaidProcessing) {
      isPlaidProcessing = true;
      let loading = toast.loading("Processing Plaid");
      const id = JSON.parse(localStorage.getItem("authUser")).id;
      const body = {
        id
      };
      await post('/user/generate-plaid-token', body, { headers }).then(async (response) => {
        console.log("res", response);
        if (response.status === 200) {
          const handler = Plaid.create({
            token: response.code.link_token,
            onSuccess: async (publicToken, metadata) => {
              isPlaidProcessing = false;
              // console.log(`ONSUCCESS: Metadata ${JSON.stringify(metadata)}`);
              // console.log(
              //   `I have a public token: ${publicToken} I should exchange this`
              // );
              const body = {
                public_token: publicToken,
                user_id: id
              };
              toast.remove(loading);
              await post('/user/plaid-exchange-token', body, { headers }).then(async (response) => {
                console.log("res", response);
                if (response.status === 200) {
                  await retrievePlaidAccounts();
                  toast.success(response.message);
                } else {
                  toast.error(response.message);
                }
              });
              //await exchangeToken(publicToken);
            },
            onExit: (err, metadata) => {
              toast.remove(loading);
              isPlaidProcessing = false;
              console.log(
                `Exited early. Error: ${JSON.stringify(err)} Metadata: ${JSON.stringify(
                  metadata
                )}`
              );
            },
            onEvent: (eventName, metadata) => {
              toast.remove(loading);
              isPlaidProcessing = false;
              console.log(`Event ${eventName}, Metadata: ${JSON.stringify(metadata)}`);
            },
          });
          handler.open();
          await retrieveBalance();
          // toast.success(response.message);
        }
        else {
          toast.error(response.message);
        }
      });
    }
  }


  const handlePayWithCoinbase = async () => {
    if (coinbaseKey && coinbaseWebhookSecret) {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.commerce.coinbase.com/charges',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CC-Api-Key': coinbaseKey,
          'X-CC-Version': '2018-03-22',
          'X-CC-Webhook-Signature': coinbaseWebhookSecret
        },
        data: JSON.stringify(product)
      };

      // console.log("config", config);

      axios(config)
        .then((response) => {
          // console.log("create coinbase",response.data.data);
          window.location.href = response.data.data.hosted_url;
        })
        .catch((error) => {
          // console.log("catch");
          // console.log(error);
          toast.error("Something went wrong, Please try again later");
        });
    }
    else {
      toast.error("Something went wrong, Please try again later");
    }
  }

  const handlePayWithCard = async (e) => {
    e.preventDefault();
    if (card_number && card_code && card_expire_at && amount) {
      if (card_number.length < 16) {
        toast.error("Card number must be 16 digits");
        return true;
      }

      if (card_code.length < 3) {
        toast.error("Card cvc must be 3 or 4 digits");
        return true;
      }

      if (amount <= 0) {
        toast.error("Amount must be greater than 0");
        return true;
      }

      document.body.style.pointerEvents = "none";
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id,
        card_number: card_number,
        card_code: card_code,
        card_expire_at: card_expire_at,
        amount: amount
      }
      const loading = toast.loading("Loading...");
      await post('/user/pay-with-card', body, { headers }).then(async (response) => {
        // console.log("res", response);
        document.body.style.pointerEvents = "auto";
        toast.remove(loading);
        if (response.status === 200) {
          retrieveBalance();
          getUserBalance();
          setPaymentWithCardModal(false);
          setCardNumber("");
          setCardExpireAt("");
          setCardCode("");
          setAmount("");
          toast.success(response.message);
        }
        else {
          toast.error(response.message);
        }
      }).catch((e) => {
        toast.remove(loading);
        document.body.style.pointerEvents = "none";
      });

      // document.body.style.pointerEvents = "none";
      // const loading = toast.loading("Loading...");
      // setPaymentModal(false);
      // setTimeout(() => {
      //   document.body.style.pointerEvents = "auto";
      //   toast.remove(loading);
      // }, 2500)
    }
    else {
      toast.error("Please fill all required fields");
    }
  }

  const handlePayWithHelcim = () => {
    const loading = toast.loading("Loading...");
    document.body.style.pointerEvents = "none";
    setPaymentModal(false);
    appendHelcimPayIframe(checkoutToken, true);
    setTimeout(() => {
      document.body.style.pointerEvents = "auto";
      toast.remove(loading);
    }, 2500)
  }

  const handlePayWithStripe = () => {
    const loading = toast.loading("Loading...");
    document.body.style.pointerEvents = "none";
    setIsPayWithCard(true);
    setPaymentModal(false);
    setTimeout(() => {
      document.body.style.pointerEvents = "auto";
      toast.remove(loading);
      setStripeModal(true);
    }, 2500)
  }

  const handleClose = async (res) => {
    console.log("res", res)
    if (res == "close") {
      setStripeModal(false);
      await retrieveBalance();
    }
  }


  const retrievePlaidAccounts = async () => {
    const body = {
      id: JSON.parse(localStorage.getItem("authUser")).id
    };
    await post('/user/get-plaid-accounts', body, { headers }).then((response) => {
      console.log("response", response);
      if (response.status === 200) {
        setPlaidAccount(response.data);
      }
      else {
        toast.error(response.message);
      }
    })
  }

  const handlePlaid = async () => {
    await retrievePlaidAccounts();
    setPlaidModal(true)
  }


  createTheme('solarized', {
    text: {
      primary: '#fffff',
      secondary: '#fffff',
    },
    background: {
      default: '#0f0f0f',
    },
    context: {
      background: '#cb4b16',
      text: '#0f0f0f',
    },
    divider: {
      default: '#282828',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  //meta title
  document.title = "Wallet History | ShipCheap";

  return (
    <PayPalScriptProvider
      options={{ "client-id": config.paypal.CLIENT_ID }}
    >
      <div className={"align-items-center justify-content-center"} style={{ display: (paymentStatus ? "flex" : "none"), alignItems: "center", margin: "auto", position: "absolute", width: "100%", height: "100vh", backgroundColor: "rgba(0,0,0,0.5)", zIndex: "99999", top: "0", left: "0" }}>
        <div className={"col-sm-10 col-md-4 bg-white rounded shadow p-2"}>
          <h4 className={"text-center"}>Payment {paymentStatus}</h4>
          <img className={"center-block"} src={paymentStatus == "Successful" ? payment_successful : payment_failed} height={"200px"} />
          <h2 className={"text-center"}>{paymentStatus == "Successful" ? "It may take several minutes to process your transaction." : ""}</h2>
          <hr />
          <span style={{ width: "100%", textAlign: "center" }}>
            <button className={"btn btn-warning btn-sm mb-2"} onClick={() => {
              props.history.replace("wallet-history");
              setPaymentStatus(null);
            }} style={{ float: "right" }}>Continue</button>
          </span>
        </div>
      </div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ShipCheap" breadcrumbItem="Wallet History" />
          {/* <Table columns={columns} data={data} /> */}
          <Card>
            <CardBody>
              <div className={"row"}>
                <div className={"col-md-12 col-lg-10 col-xl-8 col-xxl-6 btn-div"} style={{ height: "fit-content", display: "flex" }}>
                  <button className={"btn btn-success"} onClick={() => { setPaymentModal(true) }}>Add Balance</button>
                  <button className={"btn btn-light"} onClick={() => { setShowManualPaymentModal(true) }}>Manual Payment Options</button>

                  {/*We need live keys to uncomment this line of code*/}
                  {/*<button className={"btn btn-light"} onClick={handlePlaid}>Pay with Plaid</button>*/}

                  {/* <button className={"btn btn-light"} onClick={handlePayWithPlaid}>Connect Plaid</button> */}


                  {/*<button className={"btn btn-success btn-sm"} onClick={() => {setPaymentWithCardModal(true)}}><i className={"bx bx-credit-card"}></i> Pay with Debit or Credit Card</button>*/}

                  {/*<button onClick={() => handleCoinbasePaymentSuccess('ZBJBTHYE')}>Show Charge</button>*/}
                  {/*<button onClick={() => { setPaymentModal(!paymentModal); }} className={"btn btn-light btn-sm waves-effect waves-light"}>Pay with <span style={{fontWeight:"600",color:"#003087",fontStyle:"italic"}}>Pay</span><span style={{fontWeight:"600",color:"#009cde",fontStyle:"italic"}}>Pal</span></button>*/}
                  {/*<div className="vr" style={{ border: "1px solid black", margin: "0px 10px" }}></div>*/}
                  {/*{coinbaseKey?<CoinbaseCommerceButton*/}
                  {/*    style={{*/}
                  {/*      justifyContent: 'center', marginLeft: "10px", cursor: "pointer"*/}
                  {/*    }}*/}
                  {/*    className={"btn btn-light btn-sm waves-effect waves-light"*/}
                  {/*    // + (disabled ? "button-coinbase-disabled" : "button-coinbase")*/}
                  {/*    }*/}
                  {/*    placeholder={"shipcheap"}*/}
                  {/*    checkoutId={coinbaseKey}*/}
                  {/*    onChargeSuccess={async (MessageData) => {*/}
                  {/*      // let load = toast.loading("Payment processing...");*/}
                  {/*      // console.log("Payment successful", MessageData)*/}
                  {/*      await handleCoinbasePaymentLogs(MessageData).then( () => {*/}
                  {/*        toast.success("Your transaction was successful. The payment shall be added to your waller after approval from admin");*/}
                  {/*        // setTimeout(async() => {*/}
                  {/*        //   await handleCoinbasePaymentSuccess(MessageData.code).then(() => {*/}
                  {/*        //     toast.remove(load);*/}
                  {/*        //     toast.success("Payment successful");*/}
                  {/*        //   });*/}
                  {/*        // },30000);*/}
                  {/*      });*/}
                  {/*    }}*/}
                  {/*    onChargeFailure={*/}
                  {/*      (MessageData) => {*/}
                  {/*        toast.error("Payment failed");*/}
                  {/*        // console.log("Payment failed", MessageData.code)*/}
                  {/*        try {*/}
                  {/*          handleCoinbasePaymentLogs(MessageData)*/}
                  {/*        }*/}
                  {/*        catch (e) {*/}
                  {/*          // console.log("Error handleCoinbasePaymentLogs", e)*/}
                  {/*        }*/}
                  {/*      }*/}
                  {/*    }*/}
                  {/*    onPaymentDetected={*/}
                  {/*      (MessageData) => {*/}
                  {/*        alert("Payment detected")*/}
                  {/*        // console.log("Payment detected", MessageData)*/}
                  {/*        handleCoinbasePaymentLogs(MessageData)*/}
                  {/*      }*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    Pay with <span style={{fontWeight:"600",color:"#0052ff"}}>coinbase</span>*/}
                  {/*    /!*<img src={coinbase} height={'15px'} />*!/*/}
                  {/*  </CoinbaseCommerceButton>:""}*/}
                </div>
                <div className={"col-md-2 col-lg-2 col-xl-4 col-xxl-6"} style={{ position: "relative" }}>
                  {
                    JSON.parse(localStorage.getItem('authUser')).role_id === 2 ?
                      <div className={"order-desktop-balance"} style={{ right: '10px', top: '-10px', position: "absolute" }}>
                        <h6>Balance<br /><span style={{ fontSize: "1.5rem" }}>${amountFormat(balance)}</span>
                          {is_credit_enable == "1" ?
                            <>
                              <h6 style={{ width: "100%", marginTop: "10px" }}>Total Credit Amount: ${amountFormat(parseFloat(max_credit_amount))}</h6>
                              <h6 style={{ width: "100%", marginBottom: "0px" }}>Remaining Credit Amount: ${balance > 0 ? amountFormat(parseFloat(max_credit_amount)) : amountFormat(parseFloat(max_credit_amount) + parseFloat(balance))}</h6>
                            </> : null
                          }
                        </h6>
                      </div> : ""
                  }
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className={"order-mobile-balance"}>
            {/*<Card>*/}
            <CardBody>
              {
                JSON.parse(localStorage.getItem('authUser')).role_id === 2 ?
                  <div>
                    <h6 style={{ textAlign: "right" }}>Balance: <span style={{ fontSize: "1.5em" }}>${balance != "-" ? amountFormat(parseFloat(balance).toFixed(2)) : "..."}</span>
                      {is_credit_enable == "1" ?
                        <>
                          <h6 style={{ textAlign: "right", width: "100%" }}>Total Credit Amount: ${amountFormat(parseFloat(max_credit_amount))}</h6>
                          <h6 style={{ textAlign: "right", width: "100%" }}>Available Credit Amount: ${balance > 0 ? amountFormat(parseFloat(max_credit_amount)) : amountFormat(parseFloat(max_credit_amount) + parseFloat(balance))}</h6>
                        </> : null
                      }
                    </h6>
                  </div> : null
              }
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white'
              }}>
              </div>
              <DataTableExtensions
                {...tableData}
                // exportHeaders={true}
                filterPlaceholder={`Filter Wallet History...`}
              >
                <DataTable columns={columns} data={filteredData} pagination={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationPerPage={30}
                  paginationRowsPerPageOptions={[30, 50, 100, 200, 500]}
                  highlightOnHover={true}
                  theme={localStorage.getItem("theme") == "dark" ? "solarized" : ""} customStyles={localStorage.getItem("theme") == "dark" ? customDarkStyles : customStyles} />
              </DataTableExtensions>
            </CardBody>
          </Card>


          {/*Modal*/}
          <Modal
            isOpen={paymentModal}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={() => {
              setPaymentModal(!paymentModal);
            }}
            onClosed={() => {
              if (!isPayWithCard) {
                setProduct({
                  local_price: {
                    amount: ""
                  }
                });
              }
              setDisabled(true);
            }}
          >
            <div>
              <ModalHeader
                toggle={() => {
                  setPaymentModal(!paymentModal);
                }}
              >
                Add Balance To Your Wallet
              </ModalHeader>
              <ModalBody>
                <div className={"row"}>
                  <div className="form-group">
                    <Label className="form-label">Amount</Label>
                    <div className="input-group">
                      <div className="input-group-text">$</div>
                      <Input
                        // name="input_amount"
                        className="form-control  non-arrow-number"
                        placeholder="Enter Amount"
                        type="number"
                        step="any"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleAmount}
                        value={product.local_price.amount}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className={"payment-btns"} style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: "wrap",
                  textAlign: "center",
                  color: 'white',
                  margin: 10
                }}>
                  {/* <div style={{ marginRight: "10px", marginTop: "5px",marginBottom:"5px" }}>
                    {!disabled?
                      <PaypalButton
                        isDisabled={disabled}
                        product={product}
                        updateData={() => {
                          // console.log("handle update data");
                          retrieveBalance();
                          getUserBalance();
                          setPaymentModal(!paymentModal);
                          setDisabled(true);
                          setProduct({
                            price: 0,
                            description: "ShipCheap add balance"
                          });
                        }}
                      />:<img src={paypalbtn} style={{height:"42px",opacity:"0.5",marginTop:"-2px"}} />
                    }
                  </div>
                  <div className={"vr"} style={{ height: "44px", border: "1px solid #efefef", margin: "0px 20px" }}></div> */}
                  <button
                    style={{
                      justifyContent: 'center', marginLeft: "12px", marginBottom: "5px", cursor: "pointer", width: "164px", padding: "8px 30px 10px 30px", backgroundColor: "#efefef"
                    }}
                    className={"btn btn-light waves-effect waves-light"}
                    disabled={disabled}
                    onClick={handlePayWithCoinbase}
                  >
                    <img src={coinbase} height={'15px'} />
                  </button>

                  {/*STRIPE*/}
                  {/*<button disabled={disabled?true:false} className={"btn btn-success"} style={{height:"40px",marginBottom:"5px",marginTop:"20px",lineHeight:"40px",fontWeight:"500",fontSize:"16px",width:"fit-content",padding:"0px 30px"}}*/}
                  {/*        onClick={handlePayWithStripe}*/}
                  {/*>*/}
                  {/*  /!*<img src={stripelogo} height={"25px"}/>*!/*/}
                  {/*  {buttonLoading?"Loading...":"Pay with Debit or Credit Card"}  <i className={"bx bx-credit-card"}></i>*/}
                  {/*</button>*/}
                  {/*STRIPE*/}

                  {/*<button disabled={disabled?true:(checkoutRetrieved?false:true)} className={"btn btn-light"} style={{height:"40px",marginBottom:"5px",marginTop:"20px",lineHeight:"40px",fontWeight:"500",fontSize:"16px",width:"fit-content",padding:"0px 30px"}}*/}
                  {/*        onClick={handlePayWithStripe}*/}
                  {/*>*/}
                  {/*  {buttonLoading?"Loading...":"Pay with Debit or Credit Card"}  <i className={"bx bx-credit-card"}></i>*/}
                  {/*</button>*/}

                </div>
              </ModalBody>
            </div>
          </Modal>
          {/*Modal*/}

          {/*Modal*/}
          <Modal
            isOpen={paymentWithCardModal}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={() => {
              setPaymentWithCardModal(false);
              setCardNumber("");
              setCardExpireAt("");
              setCardCode("");
              setAmount("");
              setPaymentWithCardModal(!paymentWithCardModal);
            }}
          >
            <div>
              <ModalHeader
                toggle={() => {
                  setPaymentWithCardModal(false);
                  setCardNumber("");
                  setCardExpireAt("");
                  setCardCode("");
                  setAmount("");
                  setPaymentWithCardModal(!paymentWithCardModal);
                }}
              >
                Add Balance To Your Wallet
              </ModalHeader>
              <ModalBody>
                <form onSubmit={handlePayWithCard}>
                  <div className={"row"}>
                    <div className="form-group">
                      <Label className="form-label">Card Number</Label>
                      <div className="input-group">
                        <InputMask
                          mask="9999 9999 9999 9999"
                          className="form-control input-color"
                          placeholder={"____ ____ ____ ____"}
                          onChange={(e) => {
                            const input = e.target.value.replace(/\D/g, '');
                            // console.log("card", input)
                            setCardNumber(input)
                          }}
                          required={true}
                          value={card_number}
                        >
                        </InputMask>
                        {/*<Input*/}
                        {/*  // name="input_amount"*/}
                        {/*  className="form-control  non-arrow-number"*/}
                        {/*  placeholder="Enter Card Number"*/}
                        {/*  type="number"*/}
                        {/*  onWheel={(e) => e.target.blur()}*/}
                        {/*  onChange={(e) => {*/}
                        {/*    const input = e.target.value.replace(/\D/g, '');*/}
                        {/*    setCardNumber(input)*/}
                        {/*  }}*/}
                        {/*  value={card_number}*/}
                        {/*  maxLength={16}*/}
                        {/*  required={true}*/}
                        {/*/>*/}
                      </div>
                    </div>
                    <div className={"mt-2"} style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                      <div style={{ width: "49%" }}>
                        <div className="form-group">
                          <Label className="form-label">Expiry</Label>
                          <Input
                            // name="input_amount"
                            className="form-control"
                            placeholder="Enter Card Expiry"
                            type="month"
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              // console.log("expiry", e.target.value)
                              setCardExpireAt(e.target.value)
                            }}
                            value={card_expire_at}
                            required={true}
                          />
                        </div>
                      </div>
                      <div style={{ width: "49%" }}>
                        <div className="form-group">
                          <Label className="form-label">CVC</Label>
                          <Input
                            // name="input_amount"
                            className="form-control  non-arrow-number"
                            placeholder="Enter Card CVC"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              const input = e.target.value.replace(/\D/g, '');
                              if (input.length <= 4) {
                                setCardCode(input)
                              }

                            }}
                            value={card_code}
                            required={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-2">
                      <Label className="form-label">Amount</Label>
                      <div className="input-group">
                        <div className="input-group-text">$</div>
                        <Input
                          // name="input_amount"
                          className="form-control  non-arrow-number"
                          placeholder="Enter Amount"
                          type="number"
                          step="any"
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) => setAmount(e.target.value)}
                          value={amount}
                        />
                      </div>
                    </div>
                  </div>
                  <button className={"btn btn-light"} style={{ height: "40px", marginBottom: "5px", marginTop: "20px", lineHeight: "40px", fontWeight: "500", fontSize: "16px", width: "fit-content", padding: "0px 30px", float: "right" }} onClick={handlePayWithCard}>
                    Pay
                  </button>
                </form>
                <br />
                <br />
                <br />
              </ModalBody>
            </div>
          </Modal>
          {/*Modal*/}

          {/*Modal*/}
          <Modal
            isOpen={batchLogModal}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal modal-xl"
            tabIndex="-1"
            toggle={() => {
              setBatchLogModal(!batchLogModal);
            }}
          >
            <div>
              <ModalHeader
                toggle={() => {
                  setBatchLogModal(!batchLogModal);
                }}
              >
                Batch Logs {selectedBatch.includes("ns_batch") ? "(" + selectedBatch + ")" : ""}
              </ModalHeader>
              <ModalBody>
                <div className={"row"}>
                  <DataTable columns={batch_logs_columns} data={batchLogData} pagination={30} progressPending={batch_pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationPerPage={30}
                    paginationRowsPerPageOptions={[30, 50, 100, 200, 500]}
                    highlightOnHover={true} theme={localStorage.getItem("theme") == "dark" ? "solarized" : ""} customStyles={localStorage.getItem("theme") == "dark" ? customDarkStyles : customStyles} />
                </div>
              </ModalBody>
            </div>
          </Modal>
          {/*Modal*/}

          {/*Stripe Modal*/}
          <Modal
            isOpen={stripeModal}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            // toggle={() => {
            //   setStripeModal(!stripeModal);
            // }}
            onClosed={() => {
              setProduct({
                local_price: {
                  amount: ""
                }
              });
              setDisabled(true);
            }}
          >
            <div>
              <ModalHeader
                toggle={() => {
                  setStripeModal(!stripeModal);
                }}
              >
                Pay with Debit / Credit Card
              </ModalHeader>
              <ModalBody>
                <Wrapper amount={product.local_price.amount} closeModal={handleClose} />
              </ModalBody>
            </div>
          </Modal>

          {/*Plaid Modal*/}
          <Modal
            isOpen={plaidModal}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal modal-lg"
            tabIndex="-1"
            toggle={() => {
              setPlaidModal(!plaidModal);
            }}
          >
            <div>
              <ModalHeader
                toggle={() => {
                  setPlaidModal(!plaidModal);
                }}
              >
                Connect your account to pay with Plaid
              </ModalHeader>
              <ModalBody>
                {
                  plaidAccounts.length == 0 ?
                    <>
                      <div>
                        <ul>
                          <li>Transaction is for recurring payments.</li>
                          <li>Is an ACH transaction.</li>
                          <li>Transaction amount: Email will be sent before each transaction.</li>
                          {/*<li>Time of transaction (Frequency if applicable).</li>*/}
                          <li>Customer Name: NullShip Shipping Solutions LLC.</li>
                          <li>User can disconnect the account anytime they want.</li>
                        </ul>
                      </div>
                    </>
                    : ""
                }
                <button className={"btn btn-success"} style={{ float: "right" }} onClick={handlePayWithPlaid}>Connect Plaid</button>
                <br />
                <br />
                {
                  plaidAccounts.length > 0 ?
                    <>
                      <hr />
                      <h5>Connected Accounts</h5>
                      <DataTable columns={plaid_columns} data={plaidAccounts} pagination={30} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationPerPage={30}
                        paginationRowsPerPageOptions={[30, 50, 100, 200, 500]}
                        highlightOnHover={true}
                        theme={localStorage.getItem("theme") == "dark" ? "solarized" : ""} customStyles={localStorage.getItem("theme") == "dark" ? customDarkStyles : customStyles} />
                    </>
                    : ""
                }
              </ModalBody>
            </div>
          </Modal>
          {/*Modal*/}




          <OrderViewModal
            orderId={rowId}
            viewModal={viewModal}
            showPrice={false}
            setViewModal={(data) => { setViewModal(data) }}
          />

          <ManualPayment
            viewModal={showManualPaymentModal}
            setViewModal={(data) => { setShowManualPaymentModal(data) }}
          />

        </div>
      </div>
    </PayPalScriptProvider >
  );
}
WalletHistory.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};

export default WalletHistory;